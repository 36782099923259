import { atom } from 'jotai';

import { WalletTxAdmin } from '../shared/models/WalletTx';
import { asyncAtom } from '../utils/atoms';
import { walletAPI } from '../utils/firebase/walletAPI';
import { AsyncValue } from '../utils/types';

type PaymentsValue = {
  count: number;
  record: Record<string, Record<number, WalletTxAdmin[]>>;
};

type AtomData = { id: string; page: number };

export const paymentsAtom = atom<AsyncValue<PaymentsValue>>({
  loading: false,
});

export const getAgentPaymentsAtom = asyncAtom<PaymentsValue, AtomData>(
  paymentsAtom,
  async ({ id, page }, value) => {
    const { data } = value;
    const agentPayments = data?.record ?? {};

    const { data: txsData } = await walletAPI.getTxs(id, page);

    return {
      count: txsData.count,
      record: {
        ...agentPayments,
        [id]: {
          ...agentPayments[id],
          [page]: txsData.txs,
        },
      },
    };
  },
);

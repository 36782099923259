import { Button as AntdButton, ButtonProps } from 'antd';
import React from 'react';

export const Button: React.FC<ButtonProps> = React.memo((props) => {
  const { htmlType, disabled, onClick, onSubmit, ...rest } = props;

  return (
    <AntdButton
      {...rest}
      htmlType={htmlType === 'submit' && disabled ? 'button' : htmlType}
      disabled={disabled}
      onClick={!disabled ? onClick : (e) => e.preventDefault()}
      onSubmit={!disabled ? onSubmit : (e) => e.preventDefault()}
    />
  );
});

import { ColumnsType } from 'antd/es/table';
import { parse } from 'date-fns';

import {
  getClientManualDataColRef,
  getClientManualDataRef,
  getClientMissingDataColRef,
} from '../../firebase/clientManualData';
import { ClientManualData } from '../../firebase/models/ClientManualData';
import { generalConverter } from '../../firebase/utils';
import {
  DATE_FORMAT,
  TEMPLATE_CONTENT_HEAD,
  TEMPLATE_CONTENT_TAIL,
} from './consts';
import { MissingRowItem, RowItem } from './types';

export const getTableColumns: ColumnsType<RowItem> = [
  {
    title: 'Upload Date',
    key: 'uploadDate',
    dataIndex: 'uploadDate',
    defaultSortOrder: 'descend',
    sorter: (a, b) =>
      parse(a.uploadDate, DATE_FORMAT, new Date()).getTime() -
      parse(b.uploadDate, DATE_FORMAT, new Date()).getTime(),
  },
  {
    title: 'Total Contact Handled',
    key: 'totalContactsHandled',
    dataIndex: 'totalContactsHandled',
    render: (value?) => value ?? '--',
  },
  {
    title: 'Total Unique Agents',
    key: 'totalUniqueAgent',
    dataIndex: 'totalUniqueAgent',
    render: (value?) => value ?? '--',
  },
  {
    title: 'Missing Data',
    key: 'totalMissingDataRows',
    dataIndex: 'totalMissingDataRows',
    render: (value?) => value ?? '--',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
  },
];

export const getClientManualData = async (
  projectId: string,
  from: Date,
  to: Date,
): Promise<ClientManualData[]> => {
  const clientManualDataRef = getClientManualDataColRef(
    projectId,
  ).withConverter(generalConverter<ClientManualData>());
  const snapshot = await clientManualDataRef
    .where('uploadedAt', '>=', from)
    .where('uploadedAt', '<=', to)
    .get();
  return snapshot.docs.map((query) => query.data());
};

export const updateClientManualData = async (
  projectId: string,
  docId: string,
  manualData: Partial<ClientManualData>,
) =>
  getClientManualDataRef(projectId, docId).set(
    { ...manualData },
    { merge: true },
  );

export const getClientMissingData = async (
  projectId: string,
  docId: string,
): Promise<MissingRowItem[]> => {
  const missingDataColSnapshot = await getClientMissingDataColRef(
    projectId,
    docId,
  ).get();
  return missingDataColSnapshot.docs.map((m) => {
    const { row } = m.data();
    return {
      contactId: row.CTR?.ContactId ?? '',
    };
  });
};

export const getCsvTemplate = (instanceId: string | number) =>
  `${TEMPLATE_CONTENT_HEAD}${instanceId.toString()}${TEMPLATE_CONTENT_TAIL}`;

import React from 'react';

import {
  WorkExperienceDetail,
  WorkingExperienceType,
} from '../../../firebase/agent/model';
import { WORKING_YEARS } from '../../consts';
import { ExperienceContainer } from '../ExperienceContainer';

type Props = {
  workExperience?: WorkExperienceDetail;
};

export const FreelanceSection: React.FC<Props> = ({ workExperience }) => {
  const freelanceExp =
    workExperience?.[WorkingExperienceType.ONLINE_FREELANCER];

  if (!freelanceExp) {
    return null;
  }

  const { tenure } = freelanceExp;
  return (
    <ExperienceContainer
      title="Online Freelance Experience"
      subTitle="Years of Tenure"
      content={WORKING_YEARS[tenure]}
    />
  );
};

import { Form as AntdForm, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const StyledFormItem = styled(AntdForm.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  .anticon {
    vertical-align: top;
  }
`;

export const Label = styled(Text)`
  margin-right: 24px;
`;

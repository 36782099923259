import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { projectByIdAtom } from '../../atoms/project';
import { getAllProjects } from '../../firebase/project';

export const useFetchProjects = () => {
  const [projects, setProjects] = useAtom(projectByIdAtom);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);
      try {
        const results = await getAllProjects();

        // CLA/CTR things are going to happen errors
        // Exclude project which does not have ccaasProjectProps or connectDetails

        const projectsById = results.reduce(
          (acc, cur) =>
            cur.ccaasProjectProps ?? cur.connectDetails
              ? {
                  ...acc,
                  [cur.id]: cur,
                }
              : acc,
          {},
        );

        setProjects(projectsById);
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message);
        }
      }
      setLoading(false);
    };

    if (Object.keys(projects).length === 0) {
      fetchProjectData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { projects, loading };
};

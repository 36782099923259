import { Tabs } from 'antd';

import { DEFAULT_TAB_KEY, TABS } from './consts';

export const ChatConfig = () => (
  <Tabs
    defaultActiveKey={DEFAULT_TAB_KEY}
    items={TABS}
    destroyInactiveTabPane
  />
);

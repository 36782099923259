import styled from 'styled-components';

export const LiveBehaviorContainer = styled.div<{ $hoverPointer?: string }>`
  width: 100%;
  background: #ffffff;
  border: 1px dashed #a5b1b9;
  border-radius: 8px;
  padding: 29px 24px;
  margin-top: 16px;

  &:hover {
    cursor: ${({ $hoverPointer }) => $hoverPointer || 'pointer'};
  }
`;

export const EmptyBehaviorContent = styled.div`
  width: 70%;
  height: 70%;
  padding-left: 30px;
`;

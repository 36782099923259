import { App, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';

import { getUserRoles, updateUserRole } from '../../firebase/adminUsers';
import { Role } from '../../shared/models/Role';

type Props = { agentId: string; disabled: boolean };

export const AccessDropDown = ({ agentId, disabled }: Props) => {
  const { notification } = App.useApp();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setRoles(await getUserRoles(agentId));
    })();
  }, [agentId]);

  const onChange = async (e: RadioChangeEvent) => {
    try {
      const updatedRole = e.target.value;
      setRoles([updatedRole]);
      await updateUserRole(agentId, updatedRole);
      notification.success({ message: `updated to ${updatedRole}` });
    } catch (err) {
      notification.error({ message: `${err}` });
    }
  };

  return (
    <Radio.Group
      onChange={onChange}
      value={roles[0] ?? Role.User}
      disabled={disabled}
    >
      <Radio value={Role.User}>User</Radio>
      <Radio value={Role.Admin}>Admin</Radio>
    </Radio.Group>
  );
};

import { Project } from '@agentsonly/models';
import type { TabsProps } from 'antd';
import { Col, Row, Select, Tabs } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useAtom } from 'jotai';
import React, { lazy, Suspense, useState } from 'react';

import { currentProjectIdAtom } from '../atoms/project';
import { ChatConfig } from './ChatConfig';
import { ClientManualData } from './ClientManualData';
import { Coaching } from './Coaching';
import { Demo } from './Demo';
import { EditProjectDetails } from './Details';
import { getIntegrationDetails } from './helpers';
import { useFetchProjects } from './hooks/useFetchProjects';
import { MissingCLA } from './MissingCLA';
import { PreferredAgents } from './PreferredAgents';
import { ProductivePay } from './ProductivePay';
import { MenuItem } from './styledComponents';

const AITemplateEditor = lazy(() =>
  import('./AITemplateEditor').then((module) => ({
    default: module.AITemplateEditor,
  })),
);

const DEFAULT_KEY = 'details';

const tabItems = (project: Project, disabled: boolean): TabsProps['items'] => {
  const items = [
    {
      key: DEFAULT_KEY,
      label: <MenuItem>Details</MenuItem>,
      children: <EditProjectDetails project={project} disabled={disabled} />,
    },
    {
      key: 'missingCLA',
      label: <MenuItem>MissingCLA</MenuItem>,
      children: (
        <MissingCLA
          project={project}
          // disabled={disabled}
        />
      ),
    },
    {
      key: 'productivePay',
      label: <MenuItem>Productive Pay</MenuItem>,
      children: <ProductivePay project={project} disabled={disabled} />,
    },
    {
      key: 'preferredAgents',
      label: <MenuItem>Preferred Agents</MenuItem>,
      children: <PreferredAgents projectId={project.id} disabled={disabled} />,
    },
    {
      key: 'clientManualData',
      label: <MenuItem>Client Manual Data</MenuItem>,
      children: <ClientManualData project={project} disabled={disabled} />,
    },
    {
      key: 'coaching',
      label: <MenuItem>Coaching</MenuItem>,
      children: <Coaching project={project} disabled={disabled} />,
    },
    {
      key: 'demo',
      label: <MenuItem>Demo</MenuItem>,
      children: <Demo project={project} disabled={disabled} />,
    },
    {
      key: 'aiConfig',
      label: <MenuItem>AI Config</MenuItem>,
      children: (
        <Suspense>
          <AITemplateEditor />
        </Suspense>
      ),
    },
    {
      key: 'chatConfig',
      label: <MenuItem>Chat Config</MenuItem>,
      children: (
        <Suspense>
          <ChatConfig />
        </Suspense>
      ),
    },
  ];

  const integrationDetails = getIntegrationDetails(
    project.id,
    project?.ccaasProjectProps?.type,
  );
  if (integrationDetails) {
    items.push(integrationDetails);
  }

  return items;
};

const { Option } = Select;

type Props = {
  disabled: boolean;
};

export const EditProject: React.FC<Props> = ({ disabled }) => {
  const [currentProject, setCurrentProject] = useAtom(currentProjectIdAtom);

  const [selectedTabKey, setSelectedTabKey] = useState(DEFAULT_KEY);
  const { projects, loading } = useFetchProjects();

  const project = currentProject ? projects[currentProject] : undefined;

  const onChange = (value: SelectValue) => {
    const projectId = value as string;
    setCurrentProject(projects[projectId].id);
    setSelectedTabKey(DEFAULT_KEY);
  };

  const onTabChange = (key: string) => {
    setSelectedTabKey(key);
  };

  return (
    <Row
      style={{
        height: '100%',
        flexDirection: 'column',
        gap: '32px',
        overflowY: 'scroll',
      }}
    >
      <Col span={24}>
        <Row>
          <Col>
            Select a project
            <Row>
              <Select
                defaultValue={currentProject && projects[currentProject].name}
                showSearch
                placeholder="Choose a project"
                loading={loading}
                onChange={onChange}
                style={{ marginTop: '0.5rem', width: '200px' }}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {projects &&
                  Object.keys(projects).map((key) => (
                    <Option key={key} value={key}>
                      {projects[key].name}
                    </Option>
                  ))}
              </Select>
            </Row>
          </Col>
        </Row>
        {project && (
          <Tabs
            activeKey={selectedTabKey}
            onChange={onTabChange}
            items={tabItems(project, disabled)}
            destroyInactiveTabPane
          />
        )}
      </Col>
    </Row>
  );
};

import firebase from 'firebase/compat/app';

export const CSV_UPLOAD_TYPE = {
  FEEDBACK: 'feedback_csv',
  ACHIEVEMENT: 'achievement_csv',
  PRODUCTIVE_PAY: 'productive_csv',
} as const;

export type CsvUploadType =
  (typeof CSV_UPLOAD_TYPE)[keyof typeof CSV_UPLOAD_TYPE];

export type CsvUpload = {
  processingStatus: string;
  uploadedAt: firebase.firestore.Timestamp;
  templateType: CsvUploadType;
  filePath?: string;
  filterConditions?: Record<string, any>;
  id: string;
  metadata: Record<string, string | number>;
  totalRows: number;
  totalErrorRows?: number;
};

export type CsvUploadError = {
  id: string;
  type: string;
  error: string;
  row: Record<string, string>;
};

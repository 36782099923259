import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import { useAtom } from 'jotai';
import { FC, memo } from 'react';

import { deleteAdminRoleAtom } from '../../atoms/adminRoles';
import { AdminRole } from '../../firebase/adminRoles/model';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { EditRoleModal } from './EditRoleModal';

type Props = {
  role: AdminRole;
  disabled: boolean;
};

const { confirm } = Modal;

export const ActionsColumn: FC<Props> = memo(({ role, disabled }) => {
  const [, deleteRole] = useAtom(deleteAdminRoleAtom);
  const { closeModal } = useModal();

  const handleDelete = async () => {
    try {
      await deleteRole(role.id);
    } catch (e: any) {
      if (e instanceof Error) {
        ShowNotification(e.message, IconType.Error);
      }
    }
  };

  const showConfirm = () => {
    confirm({
      title: `Do you want to delete ${role.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: handleDelete,
      onCancel: closeModal,
    });
  };

  return role.id === 'admin' ? null : (
    <Space>
      <EditRoleModal role={role} disabled={disabled} />
      <Button
        disabled={disabled}
        icon={<DeleteOutlined />}
        size="small"
        danger
        onClick={showConfirm}
      />
    </Space>
  );
});

import { Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';

import { csvUploadErrorAtom } from '../../atoms/csvUpload';
import { CsvUploadError } from '../../firebase/models/CsvUpload';
import { Button } from '../../shared/ui/Button';
import { createFileToDownload } from '../../utils/file';
import { StyledTable } from './styledComponents';

type Props = {
  projectId: string;
  fileId: string;
  onClose: () => void;
  columns: ColumnsType<CsvUploadError>;
  headers: string[];
  title?: string;
  getDownloadRow: (csvError: CsvUploadError) => string[];
};

export const CsvUploadErrorModal: React.FC<Props> = ({
  projectId,
  fileId,
  onClose,
  columns,
  headers,
  title,
  getDownloadRow,
}) => {
  const [{ data: allErrors, loading }, fetchError] =
    useAtom(csvUploadErrorAtom);
  useEffect(() => {
    (async () => {
      if (!allErrors?.[projectId]?.[fileId])
        await fetchError({ projectId, fileId });
    })();
  }, [projectId, fileId]);

  const data = allErrors?.[projectId]?.[fileId];

  const createCSVData = () => {
    const content = data?.map((d) => getDownloadRow(d).join(',')) ?? [];
    return [headers.join(','), ...content].join('\n');
  };

  const handleDownloadFile = () => {
    createFileToDownload(`${title ?? 'Error CSV Upload'}.csv`, createCSVData());
  };

  return (
    <Modal
      footer={
        <Button
          disabled={loading || (data?.length ?? 0) === 0}
          onClick={handleDownloadFile}
          type="primary"
        >
          Download CSV File
        </Button>
      }
      width={800}
      open
      onCancel={onClose}
      title={title}
    >
      <StyledTable
        columns={columns}
        loading={loading}
        rowKey="id"
        dataSource={data}
      />
    </Modal>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { createAdminRoleAtom } from '../../atoms/adminRoles';
import { AdminPermissions, AdminTab } from '../../firebase/adminRoles/model';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { Modal } from '../../shared/ui/Modal';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { adminRoleSchema } from '../../utils/validator';
import { AdminRoleForm } from './AdminRoleForm';

type Props = {
  disabled: boolean;
};

type FormValues = {
  id?: string;
  name: string;
  permissions: AdminPermissions;
};

export const AddRoleModal: FC<Props> = memo(({ disabled }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [, createRole] = useAtom(createAdminRoleAtom);
  const form = useForm<FormValues>({
    resolver: yupResolver(adminRoleSchema),
    mode: 'all',
    defaultValues: {
      name: '',
      permissions: Object.values(AdminTab).reduce<AdminPermissions>(
        (r, tab) => {
          const role = r;
          role[tab as AdminTab] = undefined;
          return role;
        },
        {},
      ),
    },
  });

  const handleClose = () => {
    form.reset();
    closeModal();
  };

  return (
    <>
      <Button onClick={openModal} disabled={disabled}>
        Add Role
      </Button>
      <Modal
        title="Add Role"
        open={isOpen}
        onCancel={handleClose}
        footer={null}
      >
        <FormProvider {...form}>
          <AdminRoleForm
            disabled={disabled}
            onSubmit={form.handleSubmit(async (data) => {
              try {
                await createRole(data);
                handleClose();
              } catch (e: any) {
                if (e instanceof Error) {
                  ShowNotification(e.message, IconType.Error);
                }
              }
            })}
          />
        </FormProvider>
      </Modal>
    </>
  );
});

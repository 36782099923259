import {
  Agent,
  BACKGROUND_STATUS,
  BackgroundCheck,
  BackgroundCheckStatus,
} from '@agentsonly/models';

import { sendNotification } from '../../../../../shared/utils/notification';
import { getBackgroundCheckStatuses } from '../backgroundCheckStatus';
import { buildBackgroundCheckEmail } from './template';

const getStatusForEmail = (
  values: Array<BackgroundCheckStatus | undefined>,
): BackgroundCheckStatus | undefined => {
  const passed = values.filter((value) => value === BACKGROUND_STATUS.PASSED);
  const failed = values.filter((value) => value === BACKGROUND_STATUS.FAILED);
  const incorrect = values.filter((value) =>
    (
      [
        BACKGROUND_STATUS.INCORRECT,
        BACKGROUND_STATUS.MISSING,
      ] as BackgroundCheckStatus[]
    ).includes(value as BackgroundCheckStatus),
  );

  if (passed.length === values.length) {
    return BACKGROUND_STATUS.PASSED;
  }
  if (failed.length >= 1) {
    return BACKGROUND_STATUS.FAILED;
  }
  if (incorrect.length >= 1) {
    return BACKGROUND_STATUS.INCORRECT;
  }

  return undefined;
};

const sendBuiltBackgroundCheckEmail = async (
  agent: Agent,
  status: BackgroundCheckStatus,
) => {
  const body = buildBackgroundCheckEmail(
    `${agent.firstName} ${agent.lastName}`,
    status,
  );
  await sendNotification(
    'email',
    agent.id,
    'Agents Only Background verification status',
    body,
    'recruitment@agentsonly.com',
  );
};

export const sendBackgroundCheckEmail = async (
  agent: Agent,
  backgroundCheck: BackgroundCheck,
) => {
  const backgroundCheckStatuses = getBackgroundCheckStatuses(
    agent.address.countryCode,
    backgroundCheck,
  );

  /**
    send the ‘passed’ email when all document values = passed
    send the ‘failed’ email when at least one document value = failed
    send the 'incorrect || missing' email when at least one
    document value = incorrect or missing.
  */
  if (!agent) {
    // no agent - no email
    console.log('BackgroundCheckNotification - no agent no email');
    return;
  }

  const pendingOrNull = backgroundCheckStatuses.filter(
    (value) => !value || (value && value === BACKGROUND_STATUS.PENDING_REVIEW),
  );

  if (pendingOrNull.length === backgroundCheckStatuses.length) {
    // all pending or not information at all
    console.log(
      'BackgroundCheckNotification - all pending or not information at all',
    );
    return;
  }
  const emailStatus: BackgroundCheckStatus | undefined = getStatusForEmail(
    backgroundCheckStatuses,
  );

  if (!emailStatus) {
    console.log(
      'BackgroundCheckNotification - there is not defined status (passed | failed | incorrect)',
    );
    return;
  }

  await sendBuiltBackgroundCheckEmail(agent, emailStatus);
};

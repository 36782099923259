import { useAtom } from 'jotai/index';
import { useEffect, useMemo } from 'react';

import {
  AiPlaceholdersAtom,
  fetchAiPlaceholders,
} from '../../../atoms/aiPlaceholders';

export const useAiPlaceholders = (fetchFn = fetchAiPlaceholders) => {
  const asyncAtom = useMemo(() => AiPlaceholdersAtom(fetchFn), []);
  const [{ data, loading }, fetch] = useAtom(asyncAtom);

  useEffect(() => {
    fetch();
  }, []);

  return { placeholders: data ?? [], loading };
};

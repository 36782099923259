import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { Resource } from '../../../../firebase/models/Coaching';
import { BaseColors } from '../../../../shared/ui/colors';
import { chunk } from '../../../../utils';
import { CoachingResourceIcon } from '../../consts';
import { getIcon } from '../../helpers';
import {
  Container,
  FlexCol,
  IconPreview,
  IconPreviewContainer,
  IconsWrapper,
  IconWrapper,
} from './styledComponents';

type Props = {
  resources: Resource[];
  onIconSelected: (icon: CoachingResourceIcon) => void;
  selectedResource?: Resource;
};
const icons = [
  CoachingResourceIcon.ACTIVITY,
  CoachingResourceIcon.AIRPLAY,
  CoachingResourceIcon.CIRCLE,
  CoachingResourceIcon.TRENDING_UP,
  CoachingResourceIcon.TRIANGLE,
  CoachingResourceIcon.APERTURE,
  CoachingResourceIcon.ARCHIVE,
  CoachingResourceIcon.DOWN_CIRCLE,
  CoachingResourceIcon.HEADPHONE,
  CoachingResourceIcon.BOOK_OPEN,
];
export const ResourceListIcon: React.FC<Props> = ({
  resources,
  onIconSelected,
  selectedResource,
}) => {
  const [selected, setSelected] = useState<CoachingResourceIcon | undefined>(
    selectedResource?.icon
      ? (selectedResource.icon as CoachingResourceIcon)
      : undefined,
  );
  const handleSelectIcon = (i: CoachingResourceIcon) => {
    setSelected(i);
    onIconSelected(i);
  };

  return (
    <Container justify="space-between">
      <Col span={6}>
        <IconPreviewContainer>
          <IconPreview>{selected ? getIcon(selected) : null}</IconPreview>
        </IconPreviewContainer>
      </Col>
      <Col span={17}>
        <IconsWrapper>
          <FlexCol span={24}>
            {chunk(icons, 5).map((group, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row justify="center" key={`icon-row-${idx}`}>
                {group.map((i) => {
                  const isDisableIcon =
                    resources.find((r) => r.icon === i) !== undefined &&
                    selectedResource?.icon !== i;
                  return (
                    <IconWrapper
                      key={`icon-${i}`}
                      flex={1}
                      onClick={() => !isDisableIcon && handleSelectIcon(i)}
                      $opacity={isDisableIcon ? 0.2 : 1}
                      $stroke={
                        selected === i
                          ? BaseColors.primaryDark
                          : BaseColors.neutral40
                      }
                    >
                      {getIcon(i)}
                    </IconWrapper>
                  );
                })}
              </Row>
            ))}
          </FlexCol>
        </IconsWrapper>
      </Col>
    </Container>
  );
};

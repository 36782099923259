type Criteria = {
  min: number;
  max: number;
};

export type CriteriaKey =
  | 'adherence'
  | 'cancellation'
  | 'surgeMins'
  | 'starRating';

export type TierConfig = {
  durationSeconds: number;
  weeklyEligibilityCriteria: Record<CriteriaKey, Criteria>;
};

export type TierEnum = 'tier-1' | 'tier-2' | 'tier-3';

export type ScheduleConfig = {
  preferential: Record<TierEnum, TierConfig>;
};

export const TIER_ORDER: TierEnum[] = ['tier-1', 'tier-2', 'tier-3'];

export const SUPPORTED_TIERS = [1, 2, 3];

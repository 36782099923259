import axios from 'axios';

import { auth } from '../../firebase';
import { FIREBASE_FUNCTIONS_BASE_URL } from '../../shared/api/consts';

const aiAssistantsAxios = axios.create({
  baseURL: `${FIREBASE_FUNCTIONS_BASE_URL}/aiAssistantsAPI`,
  headers: {
    'Content-Type': 'application/json',
  },
});

aiAssistantsAxios.interceptors.request.use(async (req) => {
  const token = await auth().currentUser?.getIdToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  return req;
});

export type AIAssistant = {
  id: string;
  name: string | null;
};

export type GetAIAssistantsResponse = {
  data: AIAssistant[];
};

const getAIAssistants = async (
  projectId: string,
  pageSize: number,
  after?: string,
) =>
  aiAssistantsAxios.get<GetAIAssistantsResponse>('/assistants', {
    params: { startAfter: after, pageSize, projectId },
  });

export const aiAssistantsAPI = { getAIAssistants };

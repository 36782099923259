import styled from 'styled-components';

import { Button } from '../../../../../../shared/ui/Button';

type ThumbnailContainerProps = {
  $isSelected?: boolean;
};

export const ThumbnailContainer = styled(Button)<ThumbnailContainerProps>`
  width: 100%;
  height: 100%;
  border: ${({ $isSelected }) => ($isSelected ? 3 : 0)}px solid #000;
  padding: 10px;
`;

export const HorizontalListContainer = styled.ul`
  width: 100%;
  height: 100px;
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export const HorizontalListItem = styled.li`
  display: block;
  width: 100px;
  height: 100%;
  bottom: 0px;
  margin: 0px 5px;
  float: left;
`;

import { AgentAssist } from './components/AgentAssist';
import { ClientContacts } from './components/ClientContacts';

export const TABS = [
  {
    key: 'agentAssist',
    label: 'Agent Assist',
    children: <AgentAssist />,
  },
  {
    key: 'clientContacts',
    label: 'Client Contacts',
    children: <ClientContacts />,
  },
];

export const DEFAULT_TAB_KEY = TABS[0].key;

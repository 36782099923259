import { Form as AntdForm, Input, InputNumber } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const StyledFormItem = styled(AntdForm.Item)`
  flex-direction: column;
`;

export const StyledInput = styled(Input)<{ height?: number }>`
  width: 100%;
  ${(prop) => prop.height && `height: ${prop.height}px;`}
`;

export const StyledInputNumber = styled(InputNumber)<{ height?: number }>`
  width: 100%;
  ${(prop) => prop.height && `height: ${prop.height}px;`}
`;

export const StyledTextArea = styled(TextArea)<{ height?: number }>`
  resize: none;
  ${(prop) => prop.height && `height: ${prop.height}px !important;`}
`;

import { Image } from 'antd';
import React, { useCallback } from 'react';

import { ThumbnailContainer } from './styledComponents';

type ImageProps = {
  image: string;
  onClick: (selectedImage: string) => void;
  isSelected: boolean;
};

export const ThumbnailItem: React.FC<ImageProps> = ({
  image,
  isSelected,
  onClick,
}) => {
  const onChange = useCallback(() => {
    onClick(image);
  }, [image, onClick]);

  return (
    <ThumbnailContainer $isSelected={isSelected} onClick={onChange}>
      <Image src={image} preview={false} />
    </ThumbnailContainer>
  );
};

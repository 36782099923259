import { Typography } from 'antd';
import { FC, memo } from 'react';

import {
  AdminPermission,
  AdminPermissions,
  AdminTab,
} from '../../firebase/adminRoles/model';
import { capitalize } from '../../utils/string';

type Props = {
  adminPermissions: AdminPermissions;
};

type PartitionedPermission = {
  [AdminPermission.Edit]: string[];
  [AdminPermission.View]: string[];
};

export const PermissionsColumn: FC<Props> = memo(({ adminPermissions }) => {
  const permissionTabsAmount = Object.keys(AdminTab).length ?? 0;

  const initialPartitionedPermission: PartitionedPermission = {
    [AdminPermission.Edit]: [],
    [AdminPermission.View]: [],
  };

  const partitionedPermissions = Object.entries(
    adminPermissions,
  ).reduce<PartitionedPermission>((pp, [permission, value]) => {
    switch (value) {
      case AdminPermission.Edit:
        pp[AdminPermission.Edit].push(capitalize(permission));
        return pp;
      case AdminPermission.View:
        pp[AdminPermission.View].push(capitalize(permission));
        return pp;
      default:
        return pp;
    }
  }, initialPartitionedPermission);

  const renderPermission = (permission: AdminPermission) => {
    const permissionOptionsLen = partitionedPermissions[permission].length;
    let permissionActions = !permissionOptionsLen
      ? 'None'
      : partitionedPermissions[permission].join(' | ');

    if (permissionOptionsLen === permissionTabsAmount) {
      permissionActions = 'ALL';
    }

    return (
      <Typography>{`${capitalize(
        permission,
      )}: ${permissionActions}`}</Typography>
    );
  };

  const isAllEdit =
    partitionedPermissions[AdminPermission.Edit].length ===
    permissionTabsAmount;

  return (
    <>
      {renderPermission(AdminPermission.Edit)}
      {!isAllEdit && renderPermission(AdminPermission.View)}
    </>
  );
});

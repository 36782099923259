export function randomIntFromRange(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type RecordWithId = {
  id: string;
  [key: string]: any;
};

export function reduceToObjectByIds<T extends RecordWithId>(arr: T[] = []) {
  return arr.reduce<Record<string, T>>(
    (result, item) => ({
      ...result,
      [item.id]: item,
    }),
    {},
  );
}

import { ISOCountryKeys } from '@agentsonly/models';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { AnyObject } from 'yup/lib/types';

import { AdminPermission, AdminTab } from '../firebase/adminRoles/model';
import { TimePeriod } from '../firebase/models/Demo';
import type { Prefix } from './bank';
import {
  isValidDate,
  WEEK_FORMAT,
  YEAR_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  YEAR_MONTH_FORMAT,
} from './date';

const CURP_REGEX =
  /^([A-Z][A,E,I,O,U,X][A-Z]{2})(\d{2})((01|03|05|07|08|10|12)(0[1-9]|[12]\d|3[01])|02(0[1-9]|[12]\d)|(04|06|09|11)(0[1-9]|[12]\d|30))([M,H])(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)([B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3})([0-9,A-Z][0-9])$/;

type ReturnValue = string | undefined;

export enum LengthRule {
  max,
  min,
  equal,
  between,
}

type LengthValidation = {
  range: number[];
  rule: LengthRule;
};

export const validateLength = (
  title: string,
  value: string,
  validation?: LengthValidation,
): ReturnValue => {
  if (
    ((validation?.rule === LengthRule.max ||
      validation?.rule === LengthRule.min ||
      validation?.rule === LengthRule.equal) &&
      validation?.range.length !== 1) ||
    (validation?.rule === LengthRule.between && validation?.range.length !== 2)
  ) {
    console.error('invalid range length');
    return undefined;
  }

  if (!value) return undefined;

  let message: string | undefined;
  const strValue = `${value}`;
  switch (validation?.rule) {
    case LengthRule.max:
      if (strValue.length >= validation.range[0]) {
        message = `${title} must be at most ${validation.range[0]} characters long.`;
      }
      break;
    case LengthRule.min:
      if (strValue.length <= validation.range[0]) {
        message = `${title} must be at least ${validation.range[0]} characters long.`;
      }
      break;
    case LengthRule.equal:
      if (strValue.length !== validation.range[0]) {
        message = `${title} must be ${validation.range[0]} characters long.`;
      }
      break;
    case LengthRule.between:
      if (
        validation.range[0] > strValue.length ||
        strValue.length > validation.range[1]
      ) {
        message = `${title} must be between ${validation.range[0]} and ${validation.range[1]} characters long.`;
      }
      break;
    default:
      break;
  }
  return message;
};
export const validateBeginWith = (
  title: string,
  value: string,
  prefix: Prefix,
) => {
  if (!prefix) {
    return undefined;
  }

  return value.trim().startsWith(`${prefix}`)
    ? undefined
    : `${title} must begin with ${prefix}`;
};
export const validateIntString = (title: string, value: string) => {
  if (!/^[0-9]+$/.test(value)) return `${title} must be [0-9] number`;
  return undefined;
};

export const validateCURP = (curp: string): ReturnValue => {
  const validateWithRegex = curp.match(CURP_REGEX);
  if (!validateWithRegex) {
    return 'Invalid CURP';
  }

  const verifyDigit = (curp17: string): string => {
    const dictionary = '0123456789ABCDEFGHJIKLMNNÑOPQRSTUVWXYZ';
    let lngSum = 0;
    let lngDigit = 0;

    lngSum = [...Array(curp17.length).keys()].reduce(
      (acc, c) => acc + dictionary.indexOf(curp17.charAt(c)) * (18 - c),
    );

    lngDigit = 10 - (lngSum % 10);
    return lngDigit === 10 ? '0' : `${lngDigit}`;
  };

  return validateWithRegex[2] !== verifyDigit(validateWithRegex[1])
    ? undefined
    : 'Invalid CURP';
};

export const emailSchema = yup.object({
  email: yup.string().email().required(),
});

const amountSchema = yup.object({
  parentAmount: yup.number().min(0).required(),
  milestone: yup.number().min(0).required(),
});

export const payoutSchema = yup
  .object({
    [ISOCountryKeys.PH]: amountSchema.required(),
    [ISOCountryKeys.MX]: amountSchema.required(),
    [ISOCountryKeys.US]: amountSchema.required(),
    [ISOCountryKeys.CA]: amountSchema.required(),
  })
  .required();

export const addAdminUserSchema = yup.object({
  email: yup.string().email().required(),
});

export const adminRolePermissionsSchema = yup.object(
  Object.values(AdminTab).reduce<
    Record<
      string,
      StringSchema<
        string | null | undefined,
        AnyObject,
        string | null | undefined
      >
    >
  >(
    (r, tab) => ({
      ...r,
      [tab]: yup
        .string()
        .oneOf([...Object.values(AdminPermission), null])
        .nullable(),
    }),
    {},
  ),
);

export const adminRoleSchema = yup.object({
  name: yup.string().required(),
  permissions: adminRolePermissionsSchema,
});

const splitAt = (index: number, arr: string) => [
  arr.slice(0, index),
  arr.slice(index),
];

export const validateDemoClientCSV = (timePeriod: TimePeriod) =>
  yup.array(
    yup.object({
      totalAvailableShiftSeconds: yup
        .number()
        .required()
        .typeError(
          'Please make sure total available shift seconds format is valid',
        ),
      totalProductiveTimeSeconds: yup
        .number()
        .required()
        .typeError(
          'Please make sure total productive time seconds format is valid',
        ),
      platformSpend: yup
        .number()
        .required()
        .typeError('Please make sure platform spend format is valid'),
      date: yup
        .number()
        .test({
          name: 'date',
          test(value, ctx) {
            if (!value) {
              return ctx.createError({ message: 'Date(s) is/are missing.' });
            }

            switch (timePeriod) {
              case 'daily': {
                if (!isValidDate(value.toString(), YEAR_MONTH_DAY_FORMAT)) {
                  return ctx.createError({
                    message: 'Invalid daily format.',
                  });
                }
                break;
              }
              case 'weekly': {
                const [year, week] = splitAt(4, value.toString());
                if (!isValidDate(year, YEAR_FORMAT)) {
                  return ctx.createError({
                    message: 'Invalid week format.',
                  });
                }

                if (!isValidDate(week, WEEK_FORMAT)) {
                  return ctx.createError({
                    message: 'Invalid week format.',
                  });
                }
                break;
              }
              case 'monthly': {
                if (!isValidDate(value.toString(), YEAR_MONTH_FORMAT)) {
                  return ctx.createError({
                    message: 'Invalid month format.',
                  });
                }
                break;
              }
              default:
                console.error(`unexpected timePeriod: ${timePeriod}`);
                break;
            }

            return true;
          },
        })
        .required()
        .typeError('Please make sure the date format is valid'),
    }),
  );

import { Row } from 'antd';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Blue } from '../../../shared/ui/themes/colors/blue';
import { Gray } from '../../../shared/ui/themes/colors/gray';

export const BorderedRow = styled(Row)<{ $borderRadius?: number }>`
  background-color: white;
  padding: 16px;
  border-top: 1px solid ${Gray[200]};
  border-bottom-left-radius: ${({ $borderRadius }) => $borderRadius ?? 0}px;
  border-bottom-right-radius: ${({ $borderRadius }) => $borderRadius ?? 0}px;
`;

export const StyledEndRow = styled(Row)`
  background-color: white;
  padding: 16px 16px 32px;
  border-top: 1px solid ${Gray[200]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SectionStyle: CSSProperties = {
  marginBottom: '24px',
  backgroundColor: Blue[25],
  borderRadius: '8px',
  border: `1px solid ${Gray[200]}`,
  boxShadow:
    '0px 4px 4px 0px rgba(29, 173, 142, 0.05), 0px 12px 24px 0px rgba(29, 173, 142, 0.05)',
};

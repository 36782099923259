import { ProcessingStatus } from '../../Project/consts';
import { db } from '..';
import {
  ClientManualDataCollections,
  CsvUploadFilesCollections,
  RootCollections,
} from '../consts';
import {
  ClientErrorData,
  ClientManualData,
  ErrorType,
} from '../models/ClientManualData';
import { generalConverter } from '../utils';

export const getClientManualDataRef = (projectId: string, docId: string) =>
  db
    .collection(RootCollections.ClientManualData)
    .doc(projectId)
    .collection(ClientManualDataCollections.Files)
    .doc(docId)
    .withConverter(generalConverter<ClientManualData>());

export const getClientManualDataColRef = (projectId: string) =>
  db
    .collection(RootCollections.ClientManualData)
    .doc(projectId)
    .collection(ClientManualDataCollections.Files);

export const createClientManualData = (
  projectId: string,
  status: ProcessingStatus,
) =>
  getClientManualDataColRef(projectId)
    .withConverter(generalConverter<Partial<ClientManualData>>())
    .add({
      processingStatus: status,
    });

export const getClientMissingDataColRef = (projectId: string, docId: string) =>
  getClientManualDataRef(projectId, docId)
    .collection(CsvUploadFilesCollections.ErrorRows)
    .withConverter(generalConverter<ClientErrorData>())
    .where('type', '==', ErrorType.MISSING);

import React from 'react';

import { Spacer } from '../../shared/ui/Spacer/styledComponents';
import { Header } from './components/Header';
import { WithdrawalsList } from './components/WithdrawalsList';

export const Withdrawals: React.FC = () => (
  <>
    <Header />
    <Spacer axis="vertical" size={24} />
    <WithdrawalsList />
  </>
);

import { Col, Row } from 'antd';
import React from 'react';

import { CustomQuota } from './CustomQuota';
import { GlobalQuota } from './GlobalQuota';

type Props = {
  disabled: boolean;
};

export const Quota: React.FC<Props> = ({ disabled }) => (
  <Row>
    <Col span={24}>
      <Row>
        <Col span={24}>
          <GlobalQuota disabled={disabled} />
        </Col>
        <Col span={24}>
          <CustomQuota disabled={disabled} />
        </Col>
      </Row>
    </Col>
  </Row>
);

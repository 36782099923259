import { Typography } from 'antd';
import React, { ReactNode } from 'react';

import { LabelContainer } from './styledComponents';

type Props = {
  label?: ReactNode;
  RightLabelComponent?: JSX.Element;
};

export const InputLabel: React.FC<Props> = ({ label, RightLabelComponent }) => (
  <LabelContainer>
    {label && <Typography>{label}</Typography>}
    {RightLabelComponent}
  </LabelContainer>
);

import { PartialBy } from '../../utils/types';
import { RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';
import {
  AdminPermission,
  AdminPermissions,
  AdminRole,
  AdminTab,
} from './model';

export const getAdminPermissions = () =>
  Object.values(AdminTab).reduce<Partial<AdminPermissions>>((obj, tab) => {
    // eslint-disable-next-line no-param-reassign
    obj[tab as AdminTab] = AdminPermission.Edit;
    return obj;
  }, {});

export const getAdmin = () => ({
  id: 'admin',
  name: 'Admin',
  permissions: getAdminPermissions(),
});

export const getAdminRoles = async () => {
  const snapshots = await db
    .collection(RootCollections.AdminRoles)
    .withConverter(generalConverter<AdminRole>())
    .get();

  return [getAdmin()].concat(snapshots.docs.map((doc) => doc.data()) ?? []);
};

const filterEmptyPermissions = (permissions: AdminPermissions) =>
  Object.entries(permissions).reduce<
    Partial<Record<AdminTab, AdminPermission>>
  >((result, [permission, value]) => {
    if (value) {
      // eslint-disable-next-line no-param-reassign
      result[permission as AdminTab] = value;
    }
    return result;
  }, {});

const checkAdminRoleName = async ({ id, name }: PartialBy<AdminRole, 'id'>) => {
  const roles = await getAdminRoles();

  return roles
    .filter((r) => r.id !== id)
    .some((role) => role.name.toLowerCase() === name.toLowerCase());
};

export const createAdminRole = async (role: Omit<AdminRole, 'id'>) => {
  const nameExists = await checkAdminRoleName(role);
  if (nameExists) {
    throw new Error(`Role with name ${role.name} already exists.`);
  }

  const res = await db.collection(RootCollections.AdminRoles).add({
    ...role,
    permissions: filterEmptyPermissions(role.permissions),
  });

  return { id: res.id, ...role };
};

export const updateAdminRole = async (role: AdminRole) => {
  const nameExists = await checkAdminRoleName(role);
  if (nameExists) {
    throw new Error(`Role with name ${role.name} already exists.`);
  }

  const { id, ...data } = role;

  const permissions = filterEmptyPermissions(role.permissions);

  await db
    .collection(RootCollections.AdminRoles)
    .doc(id)
    .update({ ...role, permissions });

  return { id, ...data, permissions };
};

export const deleteAdminRole = async (id: string) => {
  const roleRef = db.collection(RootCollections.AdminRoles).doc(id);
  const adminsRef = db.collection(RootCollections.AdminUsers);
  const adminSnapshot = await adminsRef
    .where('roles', 'array-contains', id)
    .get();

  if (adminSnapshot.docs.length > 0) {
    throw new Error('Users are still assigned to the role.');
  }

  const batch = db.batch();

  adminSnapshot.docs.forEach((doc) => {
    const { id: adminId, ...docData } = doc.data();
    if (docData?.roles?.length === 1) {
      batch.delete(doc.ref);
      return;
    }
    batch.set(doc.ref, {
      ...docData,
      roles: docData?.roles?.filter((r: string) => r !== id) ?? [],
    });
  });

  batch.delete(roleRef);

  await batch.commit();
};

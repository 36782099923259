import { PlatformRate, Project } from '@agentsonly/models';
import { Col, Row, Typography } from 'antd';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import React from 'react';

import { platformRatesAtom } from '../../../atoms/platformRates';
import { updateProjectAtom } from '../../../atoms/project';
import { Heading } from '../styledComponents';
import { PlatformRatesForm } from './PlatformRatesForm';
import { ProjectPlatformRate } from './types';

const { Text } = Typography;

const DATE_TIME_FORMAT = 'MMMM dd, yyyy, HH:mm';
const DEFAULT_VALUE = '--';
type Props = {
  project: Project;
};

export const PlatformRates: React.FC<Props> = ({ project }) => {
  const [platformRates] = useAtom(platformRatesAtom);
  const [, updateProject] = useAtom(updateProjectAtom);
  const data: PlatformRate[] = platformRates.data ?? [];
  const handleUpdateProjectPlatformRates = (rates: ProjectPlatformRate[]) =>
    updateProject({
      id: project.id,
      platformRates: {
        updatedAt: new Date(),
        rates: rates.map((r) => ({ rateId: r.id, rateType: r.rateType })),
      },
    });

  const latestSavedDate =
    project.platformRates?.updatedAt ?? data[data.length - 1]?.createdAt;
  const latestSavedDateStr = `Last saved on ${
    latestSavedDate ? format(latestSavedDate, DATE_TIME_FORMAT) : DEFAULT_VALUE
  }`;
  return (
    <Row>
      <Col span={22}>
        <Row align="middle" justify="space-between">
          <Col span={10}>
            <Heading level={5}>Project Pay Rates</Heading>
          </Col>
          <Col span={14}>
            <Row justify="end" data-testid="project:PlatformRates:lastUpdated">
              <Text type="secondary">{latestSavedDateStr}</Text>
            </Row>
          </Col>
        </Row>
        {data?.length > 0 ? (
          <PlatformRatesForm
            platformRates={data}
            project={project}
            onUpdate={handleUpdateProjectPlatformRates}
          />
        ) : (
          <>
            {/* TODO: Minor, if someone has time please implement empty state */}
            <div>Empty State</div>
          </>
        )}
      </Col>
    </Row>
  );
};

import { addDays, addMonths, addWeeks, format } from 'date-fns';

import { TimePeriod } from '../../../firebase/models/Demo';
import { csvTransform } from '../../../utils/csv';
import {
  YEAR_MONTH_DAY_FORMAT,
  YEAR_MONTH_FORMAT,
  YEAR_WEEK_FORMAT,
} from '../../../utils/date';

const getFormatFromTimePeriod = (timePeriod: TimePeriod) => {
  if (timePeriod === 'daily') {
    return YEAR_MONTH_DAY_FORMAT;
  }

  if (timePeriod === 'weekly') {
    return YEAR_WEEK_FORMAT;
  }

  return YEAR_MONTH_FORMAT;
};

const getDateTitleFromTimePeriod = (
  timePeriod: TimePeriod,
  date: Date,
  dateFormat: string,
) => {
  const presetHeadings = [
    'totalAvailableShiftSeconds',
    'totalProductiveTimeSeconds',
    'platformSpend',
  ];

  if (timePeriod === 'daily') {
    return [`date #Daily date format: (${format(date, dateFormat)})`].concat(
      presetHeadings,
    );
  }

  if (timePeriod === 'weekly') {
    return [`date #Weekly date format: (${format(date, dateFormat)})`].concat(
      presetHeadings,
    );
  }

  return [`date #Monthly date format: (${format(date, dateFormat)})`].concat(
    presetHeadings,
  );
};

const getRandomInt = (max = 100) => Math.floor(Math.random() * max);

export const generateTemplate = (timePeriod: TimePeriod = 'daily') => {
  const today = new Date();
  const formatString = getFormatFromTimePeriod(timePeriod);
  const heading = getDateTitleFromTimePeriod(timePeriod, today, formatString);

  let data: string[][] = [];

  switch (timePeriod) {
    case 'daily':
      data = Array(14)
        .fill(null)
        .map((_, index) => {
          const date = format(addDays(today, index), formatString);
          return [
            date,
            `${getRandomInt()}`,
            `${getRandomInt(200)}`,
            `${getRandomInt(500)}`,
          ];
        });
      break;
    case 'weekly':
      data = Array(8)
        .fill(null)
        .map((_, index) => {
          const date = format(addWeeks(today, index), formatString);
          return [
            date,
            `${getRandomInt()}`,
            `${getRandomInt(200)}`,
            `${getRandomInt(500)}`,
          ];
        });
      break;
    case 'monthly':
      data = Array(3)
        .fill(null)
        .map((_, index) => {
          const date = format(addMonths(today, index), formatString);
          return [
            date,
            `${getRandomInt()}`,
            `${getRandomInt(200)}`,
            `${getRandomInt(500)}`,
          ];
        });
      break;
    default:
      data = [];
      break;
  }

  const template = csvTransform(heading, data);
  return template;
};

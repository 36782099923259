import { Col, Row, Typography } from 'antd';
import { FC, FormEventHandler, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdminPermission } from '../../firebase/adminRoles/model';
import { Button } from '../../shared/ui/Button';
import { Form } from '../../shared/ui/form/Form';
import { FormInput } from '../../shared/ui/form/FormInput';
import { FormSelect } from '../../shared/ui/form/FormSelect';
import { capitalize } from '../../utils/string';

type Props = {
  disabled: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  edit?: boolean;
};

export const AdminRoleForm: FC<Props> = memo(({ disabled, onSubmit, edit }) => {
  const form = useFormContext();
  const permissionsPlaceholder = 'Select access level';
  const permissionOptions = Object.values(AdminPermission).map((p) => ({
    label: capitalize(p),
    value: p,
  }));

  return (
    <Form onFinish={onSubmit} disabled={disabled}>
      <Row>
        <Col span={24}>
          <FormInput name="name" label="Role name" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography>Permissions</Typography>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label="Agents"
            options={permissionOptions}
            name="permissions.agents"
            placeholder={permissionsPlaceholder}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label="Projects"
            options={permissionOptions}
            name="permissions.projects"
            placeholder={permissionsPlaceholder}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label="Wallet"
            options={permissionOptions}
            name="permissions.wallet"
            placeholder={permissionsPlaceholder}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label="Referrals"
            options={permissionOptions}
            name="permissions.referrals"
            placeholder={permissionsPlaceholder}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label="Platform Rates"
            options={permissionOptions}
            name="permissions.platformRates"
            placeholder={permissionsPlaceholder}
            allowClear
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button
            htmlType="submit"
            disabled={form.formState.isSubmitting || !form.formState.isValid}
          >
            {edit ? 'Update' : 'Create'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

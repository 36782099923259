import { Col, Row } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';

import {
  globalQuotaAtom,
  updateWeeklyReferralLimitAtom,
} from '../../../../atoms/quota';
import { FormInput } from '../../../../shared/ui/form/FormInput';
import { FormSwitch } from '../../../../shared/ui/form/FormSwitch';
import { InputButton, InputCol } from './styledComponents';

type Props = {
  disabled: boolean;
};

type FieldValues = {
  global: boolean;
  weeklyReferralLimit: number;
};

const getDefaultValues = (weeklyReferralLimit: number | undefined) => ({
  global: !!weeklyReferralLimit,
  weeklyReferralLimit: weeklyReferralLimit ?? 0,
});

export const GlobalQuota: React.FC<Props> = ({ disabled }) => {
  const [{ data: weeklyReferralLimit }] = useAtom(globalQuotaAtom);
  const [, updateWeeklyReferralLimit] = useAtom(updateWeeklyReferralLimitAtom);
  const form = useForm({
    defaultValues: getDefaultValues(weeklyReferralLimit),
    mode: 'all',
  });

  const {
    field: { value: global },
  } = useController({ name: 'global', control: form.control });

  const handleSubmit = useCallback(
    (values: FieldValues) => {
      updateWeeklyReferralLimit(values.global ? values.weeklyReferralLimit : 0);
    },
    [updateWeeklyReferralLimit],
  );

  const handleGlobalChange = useCallback<SwitchChangeEventHandler>(
    (checked) => {
      if (!checked) {
        updateWeeklyReferralLimit(0);
      }
    },
    [updateWeeklyReferralLimit],
  );

  useEffect(() => {
    form.reset(getDefaultValues(weeklyReferralLimit));
  }, [weeklyReferralLimit, form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Row>
          <Col span={24}>
            <FormSwitch
              disabled={disabled}
              label="Global"
              name="global"
              checkedChildren="Live"
              unCheckedChildren="Off"
              onChange={handleGlobalChange}
            />
          </Col>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <InputCol>
                <FormInput
                  disabled={!global || disabled}
                  name="weeklyReferralLimit"
                  label="Weekly Quota"
                  type="number"
                />
              </InputCol>
              <Col>
                <InputButton htmlType="submit" disabled={!global || disabled}>
                  Update
                </InputButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

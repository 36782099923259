import { Button, Col, Input } from 'antd';
import Title from 'antd/es/typography/Title';
import axios from 'axios';
import { FC, useState } from 'react';

import { auth } from '../../firebase';
import { FIREBASE_FUNCTIONS_BASE_URL } from '../../shared/api/consts';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { Spacer } from '../../shared/ui/Spacer/styledComponents';
import { createFileToDownload } from '../../utils/file';

type Props = {
  projectId: string;
  type?: 'Conversation' | 'Ticket';
};

// TODO: get the transcript from firebase storage if file exists
export const TranscriptDownloader: FC<Props> = ({
  projectId,
  type = 'Ticket',
}) => {
  const [transcriptNumStr, setTranscriptNumStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    const transcriptNumbers = transcriptNumStr.split(',');
    const token = await auth().currentUser?.getIdToken();

    for (let i = 0; i < transcriptNumbers.length; i += 1) {
      const transcriptNumber = transcriptNumbers[i];
      const fileName = `${transcriptNumber}_shrink.json`;
      try {
        // We still need the API call to cover all legacy conversations / tickets
        // eslint-disable-next-line
        const response = await axios.get(
          `${FIREBASE_FUNCTIONS_BASE_URL}/ccaasAPI/projects/${projectId}/contacts/${transcriptNumber}/conversation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.data.length === 0) {
          throw new Error(`Not found the ${type} ${transcriptNumber}`);
        }

        createFileToDownload(
          fileName,
          JSON.stringify(response.data, null, 2),
          'application/json',
        );
        ShowNotification(
          `The JSON file(${transcriptNumber}) is downloaded`,
          IconType.Success,
        );
      } catch (err) {
        ShowNotification(
          `Something went wrong with ${transcriptNumber}, please type in the correct ${type} number`,
          IconType.Error,
        );
        console.error(`Something went wrong: ${JSON.stringify(err)}`);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Title level={2}>{type} Downloader</Title>
      <Col>
        {type} Number:
        <Spacer axis="vertical" size={8} />
        <Input
          onChange={(event) => {
            setTranscriptNumStr(event.target.value);
          }}
        />
        <Spacer axis="vertical" size={8} />
        <Button
          type="primary"
          ghost
          htmlType="submit"
          loading={isLoading}
          onClick={handleDownload}
        >
          Download
        </Button>
      </Col>
    </>
  );
};

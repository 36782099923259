import { Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledParagraph = styled(Paragraph)<{ $color: string }>`
  color: ${({ $color }) => $color};
  &&& {
    margin-bottom: 0px;
  }
`;

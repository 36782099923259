import { PlatformRate } from '@agentsonly/models';
import { compareAsc } from 'date-fns';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { PlatformRateField } from '../../PlatformRates/consts';
import { RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { getProjectsWithPlatformRatesData } from '../project';
import { generalConverter, loadCollectionData } from '../utils';

/** basically this is PlatformRate, with extra information about where (projectIds) it is used */
export type PlatformRatesInUse = PlatformRate & {
  projectIds?: string[] | undefined;
};

export const getPlatformRatesCollectionRef = () =>
  collection(db, RootCollections.PlatformRates).withConverter(
    generalConverter<PlatformRate>({ convertTimestamp: true }),
  );

export const getPlatformRateDocRef = (platformRateId: string) =>
  doc(getPlatformRatesCollectionRef(), platformRateId);

/** Try to load all /projects/{projectId}}/platformRates/rates to find out which platformRate is used by which project.
 * Return a map of platformRateId - projectIds (which this rate is in used) */
export const getPlatformRatesInUse = async () => {
  const allProjectWithPlatformRates = await getProjectsWithPlatformRatesData();

  const mapRateIdProjectIds: Record<string, string[]> = {};
  allProjectWithPlatformRates.forEach((project) => {
    project.platformRates?.rates.forEach((platformRate) => {
      // init with empty array
      if (!mapRateIdProjectIds[platformRate.rateId]) {
        mapRateIdProjectIds[platformRate.rateId] = [];
      }

      if (platformRate.rateId) {
        mapRateIdProjectIds[platformRate.rateId].push(project.id);
      }
    });
  });

  return mapRateIdProjectIds;
};

export const getActivePlatformRatesData = async () => {
  const mapRateIdProjectIds = await getPlatformRatesInUse();

  const platformRates = await loadCollectionData<PlatformRate>(
    // only active rate (not is soft-deleted)
    query(getPlatformRatesCollectionRef(), where('isDeleted', '!=', true)),
  );

  return (
    platformRates
      .sort((a, b) => compareAsc(a.createdAt, b.createdAt))
      // add projectIds to each rate
      .map(
        (r) =>
          ({
            ...r,
            projectIds: mapRateIdProjectIds[r.id],
          }) as PlatformRatesInUse,
      )
  );
};

/**
 * If the platformRate has never been used before → permanent delete it from DB
 * If the Platform Rate was used by a project before → soft delete it from DB
 * @param platformRateId
 */
export const deletePlatformRate = async (platformRateId: string) => {
  const mapRateIdProjectIds = await getPlatformRatesInUse();
  if (mapRateIdProjectIds[platformRateId]) {
    // set isDeleted = true in /platformRates/{platformRateId}
    await updateDoc(getPlatformRateDocRef(platformRateId), { isDeleted: true });
  } else {
    await deleteDoc(getPlatformRateDocRef(platformRateId));
    console.log(`Permanently delete PlatformRate ${platformRateId}`);
  }
};

export const createPlatformRate = async (platformRate: PlatformRateField) => {
  const currentDate = new Date();

  const creatingData = {
    ...platformRate,
    createdAt: currentDate,
    updatedAt: currentDate,
    isDeleted: false,
  };
  const res = await addDoc(getPlatformRatesCollectionRef(), creatingData);
  return { ...creatingData, id: res.id };
};

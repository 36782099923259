import { Agent } from '@agentsonly/models';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Collapse, CollapseProps, Row } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { agentsTableAtom } from '../../../atoms/agent';
import { tableDataAtom } from '../../../atoms/agentTable';
import { Button } from '../../../shared/ui/Button';
import { useAgentTable } from '../../hooks/useAgentTable';
import { GeneralInformation } from './components/GeneralInformation';
import { GigReguirements } from './components/GigRequirements';
import { WorkExperienceAndPreference } from './components/WorkExperienceAndPreference';
import { SectionStyle } from './styledComponents';

type Props = {
  agent: Agent;
  disabled: boolean;
};

const informationItems = (
  agent: Agent,
  disabled: boolean,
): CollapseProps['items'] => [
  {
    key: 'personal-info',
    children: <GeneralInformation agent={agent} disabled={disabled} />,
    label: 'Personal Info',
    style: SectionStyle,
  },
  {
    key: 'work-experience',
    label: 'Work Experience & Preference',
    children: <WorkExperienceAndPreference agent={agent} />,
    style: SectionStyle,
  },
  {
    key: 'gig-requirement',
    label: 'Gig Requirements',
    children: <GigReguirements agent={agent} disabled={disabled} />,
    style: SectionStyle,
  },
];

export const AgentInformation: React.FC<Props> = ({ agent, disabled }) => {
  const navigate = useNavigate();
  const [agentsList] = useAtom(agentsTableAtom);
  const [tableData] = useAtom(tableDataAtom);
  const { fetchAgents } = useAgentTable();

  const agentListIndex =
    agentsList.length <= 0
      ? undefined
      : agentsList?.findIndex((a) => a.id === agent.id);

  const onClick = (id: string) => {
    navigate(`/agents/${id}`);
  };

  useEffect(() => {
    if (
      agentListIndex &&
      agentListIndex !== -1 &&
      agentListIndex === agentsList.length - 1
    ) {
      fetchAgents({
        pageIndex: tableData.currentPageIndex + 1,
        isKeepCurrentPageIndex: true,
      });
    }
  }, [agentsList, agentListIndex]);

  return (
    <>
      <Collapse
        defaultActiveKey="personal-info"
        bordered={false}
        items={informationItems(agent, disabled)}
        expandIconPosition="end"
        style={{
          backgroundColor: 'white',
        }}
      />
      {agentListIndex !== undefined && (
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={8}>
                {agentListIndex !== 0 && agentListIndex !== -1 ? (
                  <Button
                    type="link"
                    onClick={() => onClick(agentsList[agentListIndex - 1].id)}
                  >
                    <LeftOutlined />
                    Prev
                  </Button>
                ) : null}
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                {agentListIndex !== -1 && tableData.lastItem !== null ? (
                  <Button
                    type="link"
                    onClick={() => onClick(agentsList[agentListIndex + 1].id)}
                  >
                    Next
                    <RightOutlined />
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

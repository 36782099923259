import AntdSwitch, { SwitchChangeEventHandler } from 'antd/lib/switch';
import React, { ReactNode } from 'react';

import { Label, StyledFormItem } from './styledComponents';

type Props = {
  label?: string;
  checkedChildren?: ReactNode;
  unCheckedChildren?: ReactNode;
  onChange?: SwitchChangeEventHandler;
  checked?: boolean;
  disabled?: boolean;
};

export const Switch: React.FC<Props> = ({
  label,
  onChange,
  checkedChildren,
  unCheckedChildren,
  checked,
  disabled,
}) => (
  <StyledFormItem>
    {label && <Label>{label}</Label>}
    <AntdSwitch
      disabled={disabled}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      onChange={onChange}
      checked={checked}
    />
  </StyledFormItem>
);

import { Typography } from 'antd';
import styled from 'styled-components';

import { WalletTxStatus } from '../../../shared/models/WalletTx';
import { BaseColors } from '../../../shared/ui/colors';

export const StatusText = styled(Typography.Text)<{
  $status: WalletTxStatus;
}>`
  color: ${({ $status }) => {
    switch ($status) {
      case WalletTxStatus.Completed:
        return BaseColors.primaryDark;
      case WalletTxStatus.Error:
        return BaseColors.errors;
      case WalletTxStatus.InProgress:
        return BaseColors.lightBlue;
      default:
        return BaseColors.neutral40;
    }
  }};
`;

import { Col, Row } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../shared/ui/colors';

export const IconsWrapper = styled(Row)`
  border: 1px solid ${BaseColors.inputField};
  border-radius: 4px;
  height: 100%;
  padding: 28px;
`;

export const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconWrapper = styled(Col)<{ $stroke: string; $opacity: number }>`
  text-align: center;

  svg path {
    stroke: ${({ $stroke }) => $stroke};
    opacity: ${({ $opacity }) => $opacity};
  }
`;

export const IconPreviewContainer = styled.div`
  border: 1px solid ${BaseColors.inputField};
  border-radius: 4px;
  padding: 24px;
`;

export const IconPreview = styled.div`
  background-color: ${BaseColors.coachingIconBg};
  width: 135px;
  height: 135px;
  border-radius: 62.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 58px;
    height: 58px;
    path {
      stroke: ${BaseColors.primaryDark};
    }
  }
`;

export const Container = styled(Row)`
  .ant-col {
    align-self: stretch;
  }
`;

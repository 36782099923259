import { Project } from '@agentsonly/models';
import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { Behavior } from '../../../../firebase/models/Coaching';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import {
  BehaviorStatus,
  INITIAL_BEHAVIOR_FROM_VALUE,
  MAX_LIVE_BEHAVIOR,
} from '../../consts';
import { useDeleteBehavior, useFetchBehavior } from '../../hooks';
import { EmptyBehavior } from '../../types';
import { ConfirmationModal } from '../ConfirmationModal';
import { CreateBehavior } from '../CreateBehavior';
import { EditBehavior } from '../EditBehavior';
import { EmptyLiveBehaviorUI } from '../EmptyLiveBehaviorUI/index';
import { EmptyOfflineProjectBehavior } from '../EmptyOfflineProjectBehavior';
import { LiveBehavior } from '../LiveBehavior';
import { OfflineBehavior } from '../OfflineBehavior';
import { Resources } from '../Resources';
import { RestrictableDiv } from '../styledComponents';
import {
  SectionTitle,
  Star,
  StyledBehaviourContainer,
  StyledCancelButton,
  StyledDeleteButton,
  StyledLeftSettingsCol,
} from './styledComponents';

type Props = {
  project: Project;
  disabled: boolean;
};

export const Settings = React.memo(({ project, disabled }: Props) => {
  const {
    data,
    loadData,
    loading: loadingBehavior,
  } = useFetchBehavior(project.id);
  const [liveBehaviors, setLiveBehaviors] = useState<Behavior[]>([]);
  const [offlineBehaviors, setOfflineBehaviors] = useState<Behavior[]>([]);
  const [emptyLiveBehaviors, setEmptyLiveBehaviors] = useState<EmptyBehavior[]>(
    [],
  );
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);
  const [selectedBehavior, setSelectedBehavior] = useState<Behavior>();
  const [initialValue, setInitialValue] = useState<Behavior>(
    INITIAL_BEHAVIOR_FROM_VALUE,
  );

  const nextDisplayOrder = useMemo(() => {
    if (liveBehaviors[liveBehaviors.length - 1]) {
      return liveBehaviors[liveBehaviors.length - 1].displayOrder + 1;
    }
    return 0;
  }, [liveBehaviors]);

  const { remove, loading } = useDeleteBehavior({
    projectId: project.id,
    behaviorId: (selectedBehavior as any)?.id,
    onSuccess: () => {
      loadData();
      setShowConfirmDeleteModal(false);
      setShowEditModal(false);
      ShowNotification('Deleted behavior successfully!', IconType.Success);
    },
  });

  const generateEmptyBehaviors = (index: number): EmptyBehavior[] => {
    const result = [];

    for (let i = index; i < MAX_LIVE_BEHAVIOR; i += 1) {
      result.push({ displayOrder: i + 1 });
    }

    return result;
  };

  useEffect(() => {
    const liveBehaviorList = data.filter(
      (item) => item.status === BehaviorStatus.LIVE,
    );
    setOfflineBehaviors(
      data.filter((item) => item.status === BehaviorStatus.OFF),
    );

    if (liveBehaviorList.length === 0) {
      setEmptyLiveBehaviors(generateEmptyBehaviors(0));
    } else {
      setEmptyLiveBehaviors(generateEmptyBehaviors(liveBehaviorList.length));
    }

    setLiveBehaviors(liveBehaviorList);
  }, [data]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const onOpenCreateBehaviorModal = () => {
    setShowCreateModal(true);
    setInitialValue({
      ...INITIAL_BEHAVIOR_FROM_VALUE,
      status: BehaviorStatus.LIVE,
      displayOrder: nextDisplayOrder,
    });
  };

  const onOpenEditBehaviorModal = (behavior: Behavior) => {
    setShowEditModal(true);
    setSelectedBehavior(behavior);
  };

  const onOpenConfirmDeleteOffline = (behavior: Behavior) => {
    setShowConfirmDeleteModal(true);
    setSelectedBehavior(behavior);
  };

  const onClickAddOfflineBehavior = () => {
    setInitialValue({
      ...INITIAL_BEHAVIOR_FROM_VALUE,
      displayOrder: nextDisplayOrder,
      status: BehaviorStatus.OFF,
    });
    setShowCreateModal(true);
  };

  const handleShowConfirmationDelete = () => {
    setShowEditModal(false);
    setShowConfirmDeleteModal(true);
  };

  const handleCancelDeleteBehavior = () => {
    setShowConfirmDeleteModal(false);
    setShowEditModal(true);
  };

  return (
    <>
      <StyledBehaviourContainer>
        <StyledLeftSettingsCol span={16}>
          <Row align="middle">
            <SectionTitle>Current Live Project Behaviors:</SectionTitle>
            <Star>
              {liveBehaviors?.length}/{MAX_LIVE_BEHAVIOR}*
            </Star>
          </Row>
          {liveBehaviors.map((item) => (
            <RestrictableDiv
              key={`live-behavior-${item.id}`}
              $restricted={disabled}
            >
              <LiveBehavior
                behavior={item}
                key={item.displayOrder}
                onClick={() => onOpenEditBehaviorModal(item)}
              />
            </RestrictableDiv>
          ))}
          {!disabled && (
            <EmptyLiveBehaviorUI
              emptyBehaviors={emptyLiveBehaviors}
              onOpenCreateBehaviorModal={onOpenCreateBehaviorModal}
            />
          )}
        </StyledLeftSettingsCol>
        <Col
          span={8}
          style={{
            background: '#E4E4E4',
            padding: '24px 32px',
          }}
        >
          <SectionTitle>Offline Project Behaviors</SectionTitle>
          {offlineBehaviors.length ? (
            <RestrictableDiv $restricted={disabled}>
              {offlineBehaviors.map((item) => (
                <OfflineBehavior
                  behavior={item}
                  key={`${item.id}-${item.displayOrder}`}
                  onOpenEditBehaviorModal={() => onOpenEditBehaviorModal(item)}
                  onOpenConfirmDeleteModal={() =>
                    onOpenConfirmDeleteOffline(item)
                  }
                />
              ))}
            </RestrictableDiv>
          ) : (
            <EmptyOfflineProjectBehavior
              disableBtn={disabled || loadingBehavior}
              onClickAddOfflineBehavior={onClickAddOfflineBehavior}
            />
          )}
        </Col>
      </StyledBehaviourContainer>
      <Row>
        <Col span={24}>
          <Resources projectId={project.id} disabled={disabled} />
        </Col>
      </Row>
      {showCreateModal && (
        <CreateBehavior
          projectId={project.id}
          initialValue={initialValue}
          onDismiss={() => setShowCreateModal(false)}
          onCreateSuccess={loadData}
        />
      )}
      {showEditModal && selectedBehavior && (
        <EditBehavior
          projectId={project.id}
          behavior={selectedBehavior}
          onDismiss={() => setShowEditModal(false)}
          onEditSuccess={loadData}
          onShowConfirmDelete={handleShowConfirmationDelete}
        />
      )}
      <ConfirmationModal
        title="Delete Behavior Permanently?"
        content={`This action can't be undone`}
        onClose={() => setShowConfirmDeleteModal(false)}
        actionButtons={
          <>
            <StyledCancelButton onClick={handleCancelDeleteBehavior}>
              Cancel
            </StyledCancelButton>
            <StyledDeleteButton loading={loading} onClick={remove}>
              Delete
            </StyledDeleteButton>
          </>
        }
        open={showConfirmDeleteModal}
      />
    </>
  );
});

import { InitiationMethod } from '@agentsonly/models';
import { useCallback, useState } from 'react';

import {
  fetchUserNames,
  generateCallRecords,
  saveCallRecords,
} from '../../../service/contactRecord';

export const useGenerateCallData = (
  approvedAgentIds: string[],
  date: Date,
  time: Date,
  initiationMethod: InitiationMethod,
  onResult: ({ err, isCancel }: { err?: Error; isCancel?: boolean }) => void,
  connectInstanceId?: string,
) => {
  const [loading, setLoading] = useState(false);
  const handleGenerateCallData = useCallback(
    async ({
      agentIds,
      callsPerAgent,
      minsPerCall,
      acwMinsPerCall,
    }: {
      agentIds: string;
      callsPerAgent: number;
      minsPerCall: number;
      acwMinsPerCall: number;
    }) => {
      const callResult = (err?: Error) => {
        setLoading(false);
        onResult({ err });
      };

      setLoading(true);
      console.log('generate all call data');

      if (approvedAgentIds.length === 0) {
        callResult(new Error('No agents to generate data for'));
      }

      try {
        const specificAgentIds = agentIds ? agentIds.split(',') : undefined;
        const isSpecificAgentsApproved = specificAgentIds
          ? specificAgentIds.every((agentId) =>
              approvedAgentIds.includes(agentId),
            )
          : undefined;

        // If the specific agent id does not include approved agent list
        // The call data is broken (e.g. Missing Star Bonus, Pay rate id, etc)
        // It might be an error when invoice generate
        if (
          isSpecificAgentsApproved !== undefined &&
          !isSpecificAgentsApproved
        ) {
          throw new Error('The agent id(s) not include approved agent list');
        }

        const agents =
          specificAgentIds && isSpecificAgentsApproved
            ? specificAgentIds
            : approvedAgentIds;
        const userNames = await fetchUserNames(agents);

        const generatedCallRecords = generateCallRecords(
          date,
          time,
          userNames,
          callsPerAgent,
          minsPerCall,
          acwMinsPerCall,
          initiationMethod,
        );

        const savedRecords = await saveCallRecords(
          generatedCallRecords,
          connectInstanceId,
        );

        console.log(`created records: ${savedRecords}`);
        callResult();
      } catch (err) {
        if (err instanceof Error) {
          callResult(err);
        } else {
          callResult();
        }
      }
    },
    [
      connectInstanceId,
      approvedAgentIds,
      date,
      time,
      onResult,
      initiationMethod,
    ],
  );

  return [loading, handleGenerateCallData] as const;
};

import { PlatformRate } from '@agentsonly/models';
import { atom } from 'jotai';

import {
  createPlatformRate,
  deletePlatformRate,
  getActivePlatformRatesData,
  PlatformRatesInUse,
} from '../firebase/platformRates';
import { PlatformRateField } from '../PlatformRates/consts';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const platformRatesAsyncValueAtom = atom<AsyncValue<PlatformRatesInUse[]>>({
  loading: true,
});

export const platformRatesAtom = asyncAtom(
  platformRatesAsyncValueAtom,
  getActivePlatformRatesData,
);

platformRatesAtom.onMount = (run) => {
  run();
};

export const createPlatformRateAtom = asyncAtom<
  PlatformRate[],
  PlatformRateField
>(
  platformRatesAsyncValueAtom,
  async (data, prev) => {
    const rate = await createPlatformRate(data);
    const newRates = prev.data ?? [];
    return [...newRates, rate];
  },
  true,
);

export const deletePlatformRateAtom = asyncAtom<PlatformRate[], string>(
  platformRatesAsyncValueAtom,
  async (id, prev) => {
    await deletePlatformRate(id);
    return prev?.data?.filter((r) => r.id !== id) ?? [];
  },
  true,
);

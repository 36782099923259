import { Col, Row } from 'antd';
import React from 'react';

import { Button } from '../../../../shared/ui/Button';

type Props = {
  onCancel: () => void;
  onApply: () => void;
};

export const DatePickerFooter: React.FC<Props> = ({ onApply, onCancel }) => (
  <Row gutter={16} justify="end" align="middle">
    <Col>
      <Button onClick={onCancel} type="link">
        Cancel
      </Button>
    </Col>
    <Col>
      <Button onClick={onApply} type="primary">
        Apply
      </Button>
    </Col>
  </Row>
);

import { Project } from '@agentsonly/models';
import { App as AntdApp, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format, parse } from 'date-fns';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

import { feedbackGroupAtom } from '../../../../atoms/coaching';
import { FeedbackGroup } from '../../../../firebase/models/Coaching';
import { BaseColors } from '../../../../shared/ui/colors';
import { getErrorMessage } from '../../../../shared/utils/error';
import { toLocalTime, YEAR_MONTH_DAY_FORMAT } from '../../../../utils/date';
import { PAGE_SIZE } from '../../consts';
import { ColorfulTitle } from '../styledComponents';
import { DownloadCell } from './DownloadCell';

type Props = {
  project: Project;
};

const getColumns = (projectId: string): ColumnsType<FeedbackGroup> => [
  {
    title: 'Date',
    dataIndex: 'id',
    key: 'id',
    render: (value: string) =>
      format(parse(value, YEAR_MONTH_DAY_FORMAT, new Date()), 'MM/dd/yyyy'),
  },
  {
    title: 'Feedback Total',
    dataIndex: 'contactIds',
    key: 'numFeedbacks',
    render: (contactIds: string[]) => contactIds?.length ?? 0,
  },
  {
    title: 'Download',
    dataIndex: 'feedbackAt',
    key: 'feedbackAt',
    render: (utcDate: Date, record) => {
      const localTime = toLocalTime(utcDate);
      const formattedDate = format(localTime, YEAR_MONTH_DAY_FORMAT);
      return (
        <DownloadCell
          date={formattedDate}
          projectId={projectId}
          disabled={record.contactIds.length <= 0}
        />
      );
    },
  },
];

export const AgentFeedback: React.FC<Props> = ({ project }) => {
  const { notification } = AntdApp.useApp();
  const [page, setPage] = useState<number>(0);
  const [{ data, loading, error }, fetchFeedbackGroup] =
    useAtom(feedbackGroupAtom);

  useEffect(() => {
    fetchFeedbackGroup({
      projectId: project.id,
      offset: page * PAGE_SIZE,
    });
  }, [project.id]);

  useEffect(() => {
    if (error) {
      notification.error({ message: getErrorMessage(error) });
    }
  }, [error]);

  const handlePageChanged = (pageIndex: number) => {
    const nextPage = pageIndex - 1;
    setPage(nextPage);

    // Previous page does not need to fetch
    // Next page if user hasn't visit yet, the function will fetch
    if (page < nextPage) {
      fetchFeedbackGroup({
        projectId: project.id,
        offset: nextPage * PAGE_SIZE,
      });
    }
  };

  const { data: datastore = [], total = 0 } = data?.[project.id] ?? {};

  return (
    <Row>
      <Col span={24}>
        <ColorfulTitle level={5} color={BaseColors.black}>
          Agent Feedback
        </ColorfulTitle>
      </Col>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={datastore}
          columns={getColumns(project.id)}
          rowKey={(r) => r.id}
          pagination={{
            onChange: handlePageChanged,
            total,
            position: ['bottomLeft'],
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { AiPrompt } from '../../firebase/models/Prompts';
import { aiSchemaMap, resolverFn } from '../schema';

export const useTemplateFormValidator = (
  templateConfig: AiPrompt,
  schemaMap = aiSchemaMap,
) => {
  const form = useForm({
    resolver: (data, context, options) =>
      resolverFn(data, context, options, schemaMap),
    mode: 'all',
    defaultValues: templateConfig,
  });

  useEffect(() => {
    form.reset(templateConfig);
  }, [templateConfig, form]);
  return { form };
};

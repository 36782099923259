import { ModalProps } from 'antd/lib/modal';
import React from 'react';

import { StyledModal } from './styledComponents';

type Props = {
  width: string | number;
  className?: string;
  children: React.ReactNode;
};

export const Modal: React.FC<Props | ModalProps> = ({
  width = 620,
  className,
  children,
  ...props
}) => (
  <StyledModal width={width} className={className} {...props}>
    {children}
  </StyledModal>
);

import { notification } from 'antd';
import { add, endOfDay, format, formatISO, startOfDay } from 'date-fns';

import { functions } from '../../firebase';
import { toVancouverTime } from '../../utils/timezone';

export type ProductiveScheduleCSVData = {
  startinterval: Date;
  endinterval: Date;
  agent: string;
  contacts_handled: number;
  online_time: number;
  after_contact_work_time: number;
};

export type UploadingProductiveSchedule = {
  startInterval: string;
  endInterval: string;
  userName: string;
  csvNumCalls: number;
  onlineTimeSeconds: number;
  acwSeconds: number;
};

const convertHourlyInvoicesPayload = (
  projectId: string,
  date: Date,
  csvData: ProductiveScheduleCSVData[],
) => ({
  projectId,
  date: format(date, 'yyyy-MM-dd'),
  csvData: csvData.map(
    ({
      startinterval,
      endinterval,
      agent,
      contacts_handled,
      online_time,
      after_contact_work_time,
    }) =>
      ({
        startInterval: formatISO(startinterval),
        endInterval: formatISO(endinterval),
        userName: agent,
        csvNumCalls: contacts_handled,
        onlineTimeSeconds: online_time,
        acwSeconds: after_contact_work_time,
      }) as UploadingProductiveSchedule,
  ),
});

export const handleGenerateInvoices = async (
  projectId: string,
  date: Date,
  csvData: ProductiveScheduleCSVData[],
  onLoading: (isLoading: boolean) => void,
) => {
  onLoading(true);
  // Set timeout, default client-side 70 secs (70 * 1000 ms)
  const createHourlyInvoices = functions.httpsCallable('createHourlyInvoices', {
    timeout: 550 * 1000,
  });
  try {
    const payload = convertHourlyInvoicesPayload(projectId, date, csvData);
    const { data } = await createHourlyInvoices(payload);
    notification.success({ message: data.message });
  } catch (err) {
    notification.error({ message: (err as Error)?.message });
  }
  onLoading(false);
};

const yesterdayOfDay = (date: Date) => add(date, { days: -1 });
export const yesterdayInVancouverTime = () =>
  toVancouverTime(yesterdayOfDay(new Date()));

const convertTableData = (csvData: ProductiveScheduleCSVData[]) =>
  csvData.map(
    ({
      startinterval,
      endinterval,
      agent,
      contacts_handled,
      online_time,
      after_contact_work_time,
    }) => ({
      key: `${agent}_${startinterval}`,
      startinterval,
      endinterval,
      agent,
      contacts_handled,
      online_time,
      after_contact_work_time,
    }),
  );

export const normalizeCSVData = (
  date: Date,
  csv: ProductiveScheduleCSVData[],
) => {
  try {
    const selectedDateData = csv.filter(
      ({ startinterval }: ProductiveScheduleCSVData) =>
        toVancouverTime(startOfDay(date), true).getTime() <=
          startinterval.getTime() &&
        startinterval.getTime() <
          toVancouverTime(endOfDay(date), true).getTime(),
    );

    return convertTableData(selectedDateData);
  } catch (err) {
    notification.error({ message: 'Selected CSV is something wrong' });
    return [];
  }
};

import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  token: {
    fontFamily: `'DM Sans', sans-serif`,
    // Ant V4 default: @https://ant.design/docs/react/migration-v5#design-specification
    borderRadius: 2,
    wireframe: true,
  },
  components: {},
};

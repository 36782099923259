import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useState } from 'react';

import { Payout } from './Payout';
import { Quota } from './Quota';

type Props = {
  disabled: boolean;
};

const TABS = [
  {
    key: 'quota',
    title: 'Quota',
    Component: Quota,
  },
  {
    key: 'payout',
    title: 'Payout',
    Component: Payout,
  },
];

const MenuItems: MenuProps['items'] = TABS.map((tab) => ({
  label: tab.title,
  key: tab.key,
}));

export const Settings: React.FC<Props> = ({ disabled }) => {
  const [selectedTab, selectTab] = useState(TABS[0]);

  const handleClick: MenuProps['onClick'] = useCallback((e: MenuInfo) => {
    selectTab(TABS.find(({ key }) => e.key === key) ?? TABS[0]);
  }, []);

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[selectedTab.key]}
        mode="horizontal"
        style={{ marginBottom: 16 }}
        items={MenuItems}
      />
      {React.createElement(selectedTab.Component, { disabled })}
    </>
  );
};

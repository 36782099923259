// This file might be combine with eric demo firebase
import { db } from '..';
import { DemoCollections, RootCollections } from '../consts';
import { generalConverter } from '../utils';

export const CronJobStatuses = {
  PROCESSING: 'processing',
  DONE: 'done',
  FAILED: 'failed',
} as const;

export type CronJobStatusesType =
  (typeof CronJobStatuses)[keyof typeof CronJobStatuses];

export type DemoCronJobType = {
  createdAt: Date;
  jobAt: Date;
  status: CronJobStatusesType;
  error?: string;
  runBy: string;
};

export const getDemoCronJobRef = (projectId: string) =>
  db
    .collection(RootCollections.Demo)
    .doc(projectId)
    .collection(DemoCollections.CronJob)
    .orderBy('createdAt', 'desc')
    .withConverter(
      generalConverter<DemoCronJobType>({ convertTimestamp: true }),
    );

/**
 * format number to readable string
 * @param value
 * @param minimumFractionDigits default 0
 * @param maximumFractionDigits default 2
 * @param useGrouping default false
 * @param locale default en-US
 * @returns
 */
export const formatNumber = (
  value: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  useGrouping = false,
  locale = 'en-US',
): string =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  }).format(value);

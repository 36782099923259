import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 64px;
    border-radius: 8px;
  }

  &.noPadding .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    border-bottom: 0;
    padding: 0;
    padding-bottom: 24px;

    & > .ant-modal-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 41.66px;
    }
  }

  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 0;
    padding-top: 32px;
  }

  .ant-modal-close-x {
    margin-top: 10px;
    margin-right: 10px;

    & > span {
      background-color: #a5b1b9;
      border-radius: 50%;
      padding: 8px;
      color: white;
    }
  }
`;

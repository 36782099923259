import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import firebase from 'firebase/compat/app';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';

import { Referral } from '../../../firebase/models/interfaces';
import { getAllReferrals } from '../../../firebase/referrals';
import { BatchFixReferrals } from './BatchFixReferrals';
import { SingleFixReferrals } from './SingleFixReferrals';

type Props = {
  disabled: boolean;
};

type PaginationParam = {
  pageSize: number;
  pageIndex: number;
  startAfter?: firebase.firestore.DocumentSnapshot<Referral>;
};

const useReferrals = () => {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [paginationParam, setPaginationParam] = useState<PaginationParam>({
    pageSize: 10,
    pageIndex: 0,
  });
  const [loading, setLoading] = useState(false);

  const fetchReferrals = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const endOffset = pageSize * pageIndex;
      const startOffset = pageSize * (pageIndex - 1);
      if (referrals.length < endOffset) {
        const { data, lastItem } = await getAllReferrals(
          pageSize,
          paginationParam.startAfter,
        );
        data
          .map((r) => ({
            ...r,
            refereeName: `${r.refereeFirstName} ${r.refereeLastName}`,
          }))
          .forEach((record, index) => {
            referrals[index + startOffset] = record;
          });
        setReferrals([...referrals]);
        setPaginationParam({
          ...paginationParam,
          pageIndex,
          pageSize,
          startAfter: lastItem,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setReferral = (referral: Referral) => {
    const foundIndex = referrals.findIndex((r) => r.id === referral.id);
    if (foundIndex) {
      referrals[foundIndex] = referral;
      setReferrals([...referrals]);
    }
  };

  useEffect(() => {
    fetchReferrals(1, 10);
    // eslint-disable-next-line
  }, []);

  return { referrals, fetchReferrals, setReferral, loading };
};

const TABS = [
  {
    key: 'singleFixReferrals',
    title: 'SingleFixReferrals',
  },
  {
    key: 'batchFixReferrals',
    title: 'BatchFixReferrals',
  },
];

const MenuItems: MenuProps['items'] = TABS.map((tab) => ({
  label: tab.title,
  key: tab.key,
}));

export const FixReferralRelationships: React.FC<Props> = ({ disabled }) => {
  const { referrals, fetchReferrals, setReferral, loading } = useReferrals();

  const [selectedTab, selectTab] = useState(TABS[0]);

  const handleClick: MenuProps['onClick'] = useCallback((e: MenuInfo) => {
    selectTab(TABS.find(({ key }) => e.key === key) ?? TABS[0]);
  }, []);

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[selectedTab.key]}
        mode="horizontal"
        style={{ marginBottom: 16 }}
        items={MenuItems}
      />
      {selectedTab.key === 'singleFixReferrals' && (
        <SingleFixReferrals
          referrals={referrals}
          fetchReferral={fetchReferrals}
          disabled={disabled}
          loading={loading}
          onUpdated={setReferral}
        />
      )}
      {selectedTab.key === 'batchFixReferrals' && (
        <BatchFixReferrals referrals={referrals} disabled={disabled} />
      )}
    </>
  );
};

import { Row, Typography } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../shared/ui/colors';

const { Text } = Typography;

export const PlatformRateContainer = styled(Row)<{ $disabled: boolean }>`
  padding: 24px 26px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${BaseColors.dividers};
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.3;
    box-shadow: 0px 4px 4px 0px rgba(110, 129, 141, 0.05), 0px 0px 2px 0px rgba(110, 129, 141, 0.10);
  `}
`;

export const PlatformRateTitle = styled(Text)`
  display: block;
  font-size: 19px;
  font-weight: 700;
`;

export const PlatformRateDescription = styled(Text)`
  display: inline-block;
  font-size: 16px;
  margin-right: 16px;
  color: ${BaseColors.secondaryText};
`;

export const PlatformRateValue = styled(Text)`
  font-size: 24px;
  font-weight: 700;
`;

import { Button, Row } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { unsavedScheduleConfigAtom } from '../../../../../atoms/prefAgents';
import { useModal } from '../../../../../shared/hooks/useModal';
import { Modal } from '../../../../../shared/ui/Modal';
import {
  IconType,
  ShowNotification,
} from '../../../../../shared/ui/ShowNotification';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { usePrefConfig } from '../../hooks';
import { StyledButton } from './styledComponents';

export const UnsavedPrefScheduleConfigModal = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [unsavedScheduleConfigData, setUnsavedScheduleConfigData] = useAtom(
    unsavedScheduleConfigAtom,
  );
  const { savePrefConfig, deletePrefConfig } = usePrefConfig(
    unsavedScheduleConfigData?.projectId,
  );

  useEffect(() => {
    if (unsavedScheduleConfigData) openModal();
  }, [unsavedScheduleConfigData, openModal]);

  const onSave = async () => {
    const data = unsavedScheduleConfigData?.data;
    try {
      if (data && Object.keys(data).length === 0) {
        await deletePrefConfig();
      } else if (data) {
        await savePrefConfig(unsavedScheduleConfigData.data);
      }
      ShowNotification(
        'Saved Tier Configuration successfully',
        IconType.Success,
      );
    } catch (e) {
      console.error(e);
    } finally {
      closeModal();
      setUnsavedScheduleConfigData(undefined);
    }
  };

  return (
    <Modal
      data-testid="unsavedPrefModal"
      title="You haven’t saved Preferred Agent Settings"
      open={isOpen}
      closeIcon={null}
      onCancel={() => {
        setUnsavedScheduleConfigData(undefined);
        closeModal();
      }}
      footer={
        <Row justify="end">
          <Button type="text" onClick={closeModal} data-testid="cancel">
            Cancel
          </Button>
          <Spacer size={24} axis="horizontal" />
          <StyledButton
            onClick={onSave}
            htmlType="submit"
            type="primary"
            data-testid="submit"
          >
            Save
          </StyledButton>
        </Row>
      }
    >
      If you choose cancel, your inputs and selections will not be saved.
    </Modal>
  );
};

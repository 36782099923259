import { Select as AntSelect } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { FocusEventHandler, ReactNode, useCallback } from 'react';

import { useField } from '../../../../hooks/useField';
import { FormItem } from '../../FormItem';
import { InputLabel } from '../../InputLabel';

export type Option = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

type SelectProps = {
  label?: ReactNode;
  options: Option[];
  value: number;
  defaultValue?: number | string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler;
  allowClear?: boolean;
  placeholder?: ReactNode;
  loading?: boolean;
  showSearch?: boolean;
};

// docs: https://ant.design/components/select#examples (Please refer to antd@^5.6.1)
const filterOption = (input: string, option?: { children: string }) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const Select: React.FC<SelectProps> = ({
  label,
  value,
  defaultValue,
  disabled,
  onChange,
  options,
  onBlur,
  allowClear,
  placeholder,
  loading,
  showSearch,
}) => {
  const handleChange = useCallback(
    (selectedValue: SelectValue) => onChange(selectedValue as string),
    [onChange],
  );

  return (
    <div>
      {label && <InputLabel label={label} />}
      <AntSelect
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        disabled={!!disabled}
        onBlur={onBlur}
        allowClear={allowClear}
        loading={loading}
        showSearch={showSearch}
        filterOption={showSearch && filterOption}
      >
        {options.map((option) => (
          <AntSelect.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </div>
  );
};

type Props = Partial<SelectProps> &
  Omit<SelectProps, 'onChange' | 'value'> & {
    name: string;
  };

export const FormSelect: React.FC<Props> = ({
  name,
  onChange,
  label,
  options,
  defaultValue,
  disabled,
  allowClear,
  placeholder,
  loading,
  showSearch = true,
}) => {
  const {
    controller: { field },
    error,
  } = useField({ name });

  return (
    <FormItem help={error} validateStatus={error ? 'error' : ''}>
      <Select
        label={label}
        options={options}
        defaultValue={defaultValue}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
        onChange={(value) => {
          field.onChange(value ?? null);
          onChange?.(value);
        }}
        onBlur={field.onBlur}
        value={field.value}
        loading={loading}
        showSearch={showSearch}
      />
    </FormItem>
  );
};

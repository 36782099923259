import styled from 'styled-components';

import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';

export const StyledAddButton = styled(Button)`
  border-color: ${BaseColors.primaryDark};
  width: 232px;
  height: 45px;
  color: ${BaseColors.primaryDark};
  border: 2px solid ${BaseColors.primaryDark};
  border-radius: 4px;
  padding: 11px 30px;

  &:hover {
    border-color: ${BaseColors.primaryDark};
    color: ${BaseColors.primaryDark};
  }
`;

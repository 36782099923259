import axios from 'axios';

import { auth } from '../../firebase';
import { FIREBASE_FUNCTIONS_BASE_URL } from '../../shared/api/consts';

type DownloadTemplate = 'submission_csv' | 'feedback_csv';

const FILE_NAME_PREFIX_MAPPING: Record<DownloadTemplate, string> = {
  submission_csv: 'Coaching-Submission',
  feedback_csv: 'Feedback-Submission',
};

type DownloadFileProps = {
  templateType: DownloadTemplate;
  query: Record<string, string>;
  fileNameSuffix: string;
};

const downloadFile = async ({
  templateType,
  query,
  fileNameSuffix,
}: DownloadFileProps) => {
  const token = await auth().currentUser?.getIdToken();
  const baseUrl = `${FIREBASE_FUNCTIONS_BASE_URL}/downloadCsv`;
  const payload = {
    templateType,
    query,
  };

  const response = await axios.post(baseUrl, payload, {
    responseType: 'blob',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute(
    'download',
    `${FILE_NAME_PREFIX_MAPPING[templateType]}-${fileNameSuffix}.csv`,
  ); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const downloadSubmissionFile = async (
  projectId: string,
  uploadedDate: string,
) =>
  downloadFile({
    templateType: 'submission_csv',
    query: { projectId, submittedAt: uploadedDate },
    fileNameSuffix: uploadedDate,
  });

const downloadFeedbackSubmissionFile = async (
  projectId: string,
  date: string,
) =>
  downloadFile({
    templateType: 'feedback_csv',
    query: { projectId, date },
    fileNameSuffix: date,
  });

export const coachingAPI = {
  downloadSubmissionFile,
  downloadFeedbackSubmissionFile,
};

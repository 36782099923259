import { auth } from '../../firebase';
import { Auth0API } from '../api/auth0';
import { getErrorMessage } from './error';

export const getFirebaseAuthFromAuth0Token = async (accessToken: string) => {
  if (!accessToken) {
    throw new Error('Failed to get Firebase Token');
  }

  try {
    const firebaseToken = await Auth0API.getFirebaseCustomToken(accessToken);
    return await auth().signInWithCustomToken(firebaseToken);
  } catch (e) {
    console.log('Auth0/Firebase Auth Error:', getErrorMessage(e));
  }

  return undefined;
};

export enum CoachingResourceIcon {
  ACTIVITY = 'ic-coaching-activity',
  AIRPLAY = 'ic-coaching-airplay',
  APERTURE = 'ic-coaching-aperture',
  ARCHIVE = 'ic-coaching-archive',
  BOOK_OPEN = 'ic-coaching-book-open',
  CIRCLE = 'ic-coaching-circle',
  DOWN_CIRCLE = 'ic-coaching-down-circle',
  HEADPHONE = 'ic-coaching-headphones',
  TRENDING_UP = 'ic-coaching-trending-up',
  TRIANGLE = 'ic-coaching-triangle',
}

export const ACHIEVEMENT_TEMPLATE =
  'agentId,agentName,proficiencyAchievement,assessedBy\nabcd-efgh-1234-0000,John Doe,Intermediate,Ethan Burns';
export const COACHING_BUCKET = 'coaching';
export const ACHIEVEMENT_BUCKET = 'achievement';
export const FEEDBACK_BUCKET = 'feedback';
export const ACHIEVEMENT_UPLOAD_TEMPLATE = 'achievement_csv';
export const FEEDBACK_UPLOAD_TEMPLATE = 'feedback_csv';
export const FEEDBACK_TEMPLATE =
  'agentId,contactId,contactURL,contactDate,ownFeedbackDate,ownFeedback,expertFeedbackDate,expertFeedback,redFlag,cancellation,behaviorId_0,ownBehaviorScores_0,expertBehaviorScores_0,behaviorId_1,ownBehaviorScores_1,expertBehaviorScores_1,behaviorId_2,ownBehaviorScores_2,expertBehaviorScores_2,behaviorId_3,ownBehaviorScores_3,expertBehaviorScores_3,behaviorId_4,ownBehaviorScores_4,expertBehaviorScores_4,behaviorId_5,ownBehaviorScores_5,expertBehaviorScores_5,behaviorId_6,ownBehaviorScores_6,expertBehaviorScores_6,behaviorId_7,ownBehaviorScores_7,expertBehaviorScores_7\n';
export const MAX_LIVE_BEHAVIOR = 8;

export enum BehaviorStatus {
  LIVE = 'live',
  OFF = 'off',
}

export const DEFAULT_ACTION = {
  description: '',
  link: '',
};

export const INITIAL_BEHAVIOR_FROM_VALUE = {
  name: '',
  description: '',
  displayOrder: 0,
  actions: [DEFAULT_ACTION],
  status: BehaviorStatus.LIVE,
  id: '',
};

export const PROFICIENCY_MAPPING: Record<string, string> = {
  intermediate: 'Intermediate',
  advanced: 'Advanced',
};

export const PAGE_SIZE = 10;

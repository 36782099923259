import { Col, Row, Typography } from 'antd';

import { TIERS } from '../../consts';
import { TierCriteria } from './components/TierCriteria';

const { Paragraph } = Typography;

type Props = {
  disabled: boolean;
  parentFormKey: string;
};
export const WeeklyEligibilityCriteria = ({
  disabled,
  parentFormKey,
}: Props) => (
  <Row>
    <Col span={24}>
      <Paragraph strong>Weekly Eligibility Criteria</Paragraph>
    </Col>

    <Col span={24}>
      <Row wrap={false} justify="space-between">
        {Object.entries(TIERS).map(([key, label]) => (
          <TierCriteria
            key={key}
            tierKey={key}
            label={label}
            disabled={disabled}
            parentFormKey={parentFormKey}
          />
        ))}
      </Row>
    </Col>
  </Row>
);

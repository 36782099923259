/**
 * chunk [1,2,3,4,5] with pageSize = 2 into [1,2] [3,4] [5]
 * @param input
 * @param size
 * @returns
 */
export const chunk = <T>(input: T[], size: number) =>
  input.reduce(
    (arr: T[][], item: T, idx: number) =>
      idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    [],
  );

export const toNumber = (value: number | string | undefined) => {
  const num = +`${value ?? 0}`;
  return Number.isNaN(num) ? 0 : num;
};

// remove undefined, null, {} values from object recursively
export const strip = <T>(obj: Record<string, any>) => {
  const recursiveRemove = (target: Record<string, any>) =>
    Object.entries(target).forEach(([key, value]) => {
      if (typeof value === 'object') recursiveRemove(value);
      const isEmpty =
        value === undefined ||
        value === null ||
        value === '' ||
        (typeof value === 'object' && Object.keys(value).length === 0);

      if (isEmpty) {
        // eslint-disable-next-line no-param-reassign
        delete target[key];
      }
    });

  const clone = structuredClone(obj);
  recursiveRemove(clone);
  return clone as T;
};

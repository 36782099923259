import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useDidUpdateEffect = (
  fn: EffectCallback,
  dependencies: DependencyList,
) => {
  const fncRef = useRef<EffectCallback>();
  fncRef.current = fn;
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      fncRef.current?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

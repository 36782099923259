import { Row } from 'antd';
import { FormProvider } from 'react-hook-form';

import { fetchAiClientChatPlaceholders } from '../../../../atoms/aiPlaceholders';
import { Submit } from '../../../components/button/Submit';
import { AIConfigs } from '../../../components/fields/AIConfigs';
import { PromptSection } from '../../../components/fields/PromptSection';
import { SUPPORTIVE_AI_PROVIDERS } from '../../../consts';
import { useChatConfig } from '../../hooks/useChatConfig';

export const ClientContacts = () => {
  const { form, handleSubmit, disabled, chatConfig } = useChatConfig('clients');

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Row gutter={20}>
          <AIConfigs supportedAIProviders={SUPPORTIVE_AI_PROVIDERS} />
        </Row>
        <PromptSection fetchPlaceholders={fetchAiClientChatPlaceholders} />
        <Row justify="end">
          <Submit disabled={disabled} loading={chatConfig.loading} />
        </Row>
      </form>
    </FormProvider>
  );
};

import { Col, Row, Typography } from 'antd';
import React from 'react';

import { TierEnum } from '../../../../../firebase/models/ScheduleConfig';
import { FormSelect } from '../../../../../shared/ui/form/FormSelect';
import { TIER_OPTIONS, TIERS } from '../../consts';
import { DropdownContainer } from './styledComponents';

const { Paragraph } = Typography;
const tierOptions = Object.keys(TIER_OPTIONS).map((k) => ({
  label: TIER_OPTIONS[+k],
  value: +k,
}));

type Props = {
  disabled: boolean;
  onChange: (value: string) => void;
  parentFormKey: string;
};

export const LeadTime: React.FC<Props> = ({
  disabled,
  onChange,
  parentFormKey,
}) => (
  <Row>
    <Col span={24}>
      <Paragraph strong>
        Preferred Agent Lead Time (vs all other agents)
      </Paragraph>
      <Row wrap={false} justify="space-between">
        {Object.keys(TIERS).map((tier) => (
          <DropdownContainer key={`tier-dropdown-${tier}`} span={24}>
            <FormSelect
              data-testid={`${parentFormKey}.${tier}.durationSeconds`}
              label={TIERS[tier as TierEnum]}
              options={tierOptions}
              name={`${parentFormKey}.${tier}.durationSeconds`}
              placeholder="Please select"
              disabled={disabled}
              onChange={onChange}
            />
          </DropdownContainer>
        ))}
      </Row>
    </Col>
  </Row>
);

import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  agentByIdAtom,
  fetchAgentsLoadingAtom,
  getAgentByIdAtom,
} from '../atoms/agent';
import { AgentInformation } from './components/AgentInformation';

type Props = {
  disabled: boolean;
};

export const AgentAccount: FC<Props> = ({ disabled }) => {
  const { agentId } = useParams<{ agentId: string }>();
  const [agentById] = useAtom(agentByIdAtom);
  const [, getAgentById] = useAtom(getAgentByIdAtom);
  const [isFetching] = useAtom(fetchAgentsLoadingAtom);
  const agent = agentById[agentId ?? ''];

  useEffect(() => {
    if (!agent && agentId) getAgentById(agentId);
  }, [agent, agentId, getAgentById]);

  if (isFetching) {
    return <Spin />;
  }

  if (!agent || !agentId) {
    return <div>Agent Not Found</div>;
  }

  return <AgentInformation agent={agent} disabled={disabled} />;
};

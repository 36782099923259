import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';

export type BankKey =
  | 'routing'
  | 'account'
  | 'institution'
  | 'clabe'
  | 'curp'
  | 'abartn'
  | 'bankCode'
  | 'legalName';

export const BANK_DISPLAY_ELEMENTS: Record<ISOCountry, BankKey[] | undefined> =
  {
    [ISOCountryKeys.US]: ['abartn', 'account', 'legalName'],
    [ISOCountryKeys.CA]: ['routing', 'account', 'legalName', 'institution'],
    [ISOCountryKeys.MX]: ['clabe', 'curp', 'legalName'],
    [ISOCountryKeys.PH]: ['bankCode', 'account', 'legalName'],
  };

import {
  LogoutOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import type { MenuProps } from 'antd';
import { Image, Menu, Typography } from 'antd';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { useAtom } from 'jotai';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  currentAdminPermissionsAtom,
  isCurrentUserAdmin,
} from '../../../atoms/adminRoles';
import { auth0Consts } from '../../../auth/Auth0/consts';
import { AdminTab } from '../../../firebase/adminRoles/model';
import logo from '../../../logo.svg';
import { isDev, isProd, isStage } from '../../../utils/consts';
import { FIREBASE_FUNCTIONS_BASE_URL } from '../../api/consts';
import { useBrowserId } from '../../hooks/useBrowserId';
import { getErrorMessage } from '../../utils/error';
import {
  BottomContainer,
  LogoContainer,
  MenuWrapper,
  StyledSider,
} from './styledComponents';

const { Title } = Typography;

export const NavigationMenu: React.FC = () => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [isAdmin] = useAtom(isCurrentUserAdmin);
  const [permissions] = useAtom(currentAdminPermissionsAtom);
  const { getBrowserId } = useBrowserId();

  const handleClickItem: MenuProps['onClick'] = async (e: { key: string }) => {
    if (e.key === 'logout') {
      const browserId = getBrowserId();
      const portal = auth0Consts.ADMIN;

      // TODO: Sometimes we got an error Login required from Auth0, when we try to logout
      // It could be that refresh token won't refresh properly
      // We don't know where it cause and come from
      // Once we found, we'll fix that later
      let auth0AccessToken;
      try {
        auth0AccessToken = await getAccessTokenSilently();
      } catch (err) {
        console.error(getErrorMessage(err));
      }

      try {
        const mfaParam = new URLSearchParams({
          browserId,
          portal,
        }).toString();

        await axios.post(
          `${FIREBASE_FUNCTIONS_BASE_URL}/authentication-api/logout?${mfaParam}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth0AccessToken}`,
            },
          },
        );
      } catch (err) {
        console.error(getErrorMessage(err));
      }

      firebase.auth().signOut();
      logout({ returnTo: window.location.origin, federated: true });
    }
  };

  const ProdTransferDataItem = isProd()
    ? [
        {
          label: <Link to="/transferData">Transfer Data</Link>,
          key: 'transferData',
          icon: <UnorderedListOutlined />,
          disabled: !isAdmin,
        },
      ]
    : [];

  const MenuItems: MenuProps['items'] = [
    {
      label: <Link to="/agents">Agents</Link>,
      key: 'agents',
      icon: <UserOutlined />,
      disabled: !permissions[AdminTab.Agents],
    },
    {
      type: 'divider',
    },
    {
      label: <Link to="/project">Project</Link>,
      key: 'project',
      icon: <UnorderedListOutlined />,
      disabled: !permissions[AdminTab.Projects],
    },
    {
      label: <Link to="/wallet">Wallet</Link>,
      key: 'wallet',
      icon: <UnorderedListOutlined />,
      disabled: !permissions[AdminTab.Wallet],
    },
    {
      label: <Link to="/referrals">Referrals</Link>,
      key: 'referrals',
      icon: <UnorderedListOutlined />,
      disabled: !permissions[AdminTab.Referrals],
    },
    ...ProdTransferDataItem,
    {
      label: <Link to="/platform-rates">Platform Rates</Link>,
      key: 'platformRates',
      icon: <UnorderedListOutlined />,
      disabled: !permissions[AdminTab.PlatformRates],
    },
    {
      label: <Link to="/admin-users">Admin Users</Link>,
      key: 'roles',
      icon: <UnorderedListOutlined />,
      disabled: !isAdmin,
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
    {
      type: 'divider',
    },
  ];

  return (
    <MenuWrapper>
      <LogoContainer>
        <Image src={logo} preview={false} />
        <Title level={3} style={{ color: 'white' }}>
          Admin Portal
        </Title>
        <Title
          level={4}
          style={{ color: 'white', marginBottom: 0, paddingBottom: 8 }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            isDev() ? 'Development' : isStage() ? 'Staging' : 'Production'
          }
        </Title>
      </LogoContainer>
      <StyledSider width={200}>
        <Menu
          defaultSelectedKeys={['1']}
          style={{
            borderRight: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          items={MenuItems}
          onClick={handleClickItem}
        />
      </StyledSider>
      <BottomContainer>
        <p>{`${process.env.REACT_APP_NAME}: v${process.env.REACT_APP_VERSION}`}</p>
      </BottomContainer>
    </MenuWrapper>
  );
};

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

export enum IconType {
  Success = 'success',
  Error = 'error',
}

const getIcon = (type: IconType): JSX.Element | undefined => {
  if (type === IconType.Success) {
    return <CheckCircleFilled style={{ fontSize: '48px', color: '#2593FC' }} />;
  }
  if (type === IconType.Error) {
    return <CloseCircleFilled style={{ fontSize: '48px', color: '#B64E2D' }} />;
  }
  return undefined;
};

export const ShowNotification = (
  message: string | React.ReactNode,
  icon?: IconType,
  style?: React.CSSProperties,
): void => {
  notification.open({
    message,
    duration: 5,
    placement: 'bottomLeft',
    icon: icon && getIcon(icon),
    className: 'showNotificaton',
    style,
  });
};

import Paragraph from 'antd/lib/typography/Paragraph';
import styled from 'styled-components';

import { BaseColors } from '../../../shared/ui/colors';

export const StyledParagraph = styled(Paragraph)<{ color?: string }>`
  color: ${({ color }) => color ?? BaseColors.primaryDark};
  && {
    margin-bottom: 0;
  }
`;

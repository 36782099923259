import { yupResolver } from '@hookform/resolvers/yup';
import { ResolverOptions } from 'react-hook-form';
import * as yup from 'yup';

import {
  AI_PROVIDERS,
  AiPrompt,
  AiProvider,
  BaseAiSettings,
  BaseAiTemplateConfig,
  CHAT_GPT_MODEL,
  ChatGptModel,
  GenericAiPrompt,
  OpenAiAssistantSettings,
  OpenAiSettings,
} from '../firebase/models/Prompts';
import { OPENAI_TEMPERATURE_RANGE } from './AITemplateEditor/const';

type OpenAiSchemaType = yup.SchemaOf<GenericAiPrompt<OpenAiSettings>>;

type OpenAiAssistantSchemaType = yup.SchemaOf<
  GenericAiPrompt<OpenAiAssistantSettings>
>;

type AnthropicSchemaType = yup.SchemaOf<
  Omit<GenericAiPrompt<BaseAiSettings>, 'query'>
>;

type NoneAiSchemaType = yup.SchemaOf<GenericAiPrompt<BaseAiSettings>>;

export type SchemaType =
  | OpenAiSchemaType
  | OpenAiAssistantSchemaType
  | AnthropicSchemaType
  | NoneAiSchemaType;

const querySchema = yup.object({
  query: yup.string().required(),
});

export const aiProviderBaseSchema = yup.object({
  settings: yup.object({
    provider: yup
      .mixed<AiProvider>()
      .oneOf([...Object.values(AI_PROVIDERS)])
      .required(),
    apiCredentials: yup
      .object({
        maskedKey: yup.string().optional(),
        encryptedKey: yup.string().optional(),
      })
      .optional(),
  }),
});

const openAiBaseSchema = aiProviderBaseSchema.concat(
  yup.object({
    settings: yup.object({
      temperature: yup
        .number()
        .min(OPENAI_TEMPERATURE_RANGE[0])
        .max(OPENAI_TEMPERATURE_RANGE[1])
        .required()
        .label('Temperature'),
      preferredModel: yup
        .mixed<ChatGptModel>()
        .oneOf([...CHAT_GPT_MODEL])
        .required()
        .label('Model'),
    }),
  }),
);

export const openAiAssistantBaseSchema = aiProviderBaseSchema.concat(
  yup.object({
    settings: yup.object({
      assistantId: yup.string().required().label('Assistant ID'),
    }),
  }),
);

export const anthropicBaseSchema = aiProviderBaseSchema.concat(
  yup.object({
    settings: yup.object({
      model: yup.string().required('Model is a required field'),
      systemMessage: yup.string().required('Instructions is a required field'),
    }),
  }),
);

export const noneAiSchema: NoneAiSchemaType = yup.object();

export const aiSchemaMap: Record<AiProvider, SchemaType> = {
  openai: querySchema.concat(openAiBaseSchema),
  'openai-assistant': querySchema.concat(openAiAssistantBaseSchema),
  anthropic: querySchema.concat(anthropicBaseSchema),
  none: noneAiSchema,
};

export const baseTemplateSchema: yup.SchemaOf<BaseAiTemplateConfig> = yup
  .object({
    template: yup.string().required(),
  })
  .required();

export const resolverFn = async (
  data: AiPrompt,
  context: any,
  options: ResolverOptions<AiPrompt>,
  schemaMap: Record<AiProvider, SchemaType> = aiSchemaMap,
) => {
  const schema = schemaMap[data?.settings?.provider] ?? yup.object();
  return yupResolver(schema)(data, context, options);
};

import firebase from 'firebase/compat/app';
import { getBlob } from 'firebase/storage';

import { storage } from '../firebaseEntity';

const baseAgentDirectory = 'agents';
export enum Directories {
  SampleVoices = 'voice/',
  ProjectInfo = 'info/',
  TrainingDocuments = 'training/',
  LegalDocuments = 'documents/',
  Verifications = 'verifications/',
  Temp = 'temp/',
  DescriptionImages = 'descriptionImages/',
  PoliceClearances = 'policeClearances/',
}

export const getVerificationImageDirectory = (agentId: string) =>
  `${baseAgentDirectory}/${agentId}/${Directories.Verifications}`;

export const getAgentVerificationPoliceClearancesDirectory = (
  agentId: string,
): string => `${baseAgentDirectory}/${agentId}/${Directories.PoliceClearances}`;

export const listStorageDocuments = async (
  url: string,
): Promise<Array<firebase.storage.Reference>> => {
  const storageRef = url.startsWith('gs:')
    ? storage.refFromURL(url)
    : storage.ref(url);

  const references = await storageRef.listAll();

  return references.items;
};

export const getStorageBlobWithURL = async (url: string): Promise<string> => {
  const storageRef = url.startsWith('gs:')
    ? storage.refFromURL(url)
    : storage.ref(url);
  const blob = await getBlob(storageRef);

  return window.URL.createObjectURL(blob);
};

export const getImagePaths = async (imagesDirectory: string) => {
  const documents = await listStorageDocuments(imagesDirectory);
  return Promise.all(documents.map((doc) => doc.getDownloadURL()));
};

import { atom } from 'jotai';

import { PreferentialSchedules } from '../firebase/models/PreferentialSchedules';
import { ScheduleConfig } from '../firebase/models/ScheduleConfig';
import { getProjectPreferentialScheduleColRef } from '../firebase/project';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

type UnsavedScheduleConfig = {
  projectId: string;
  data: ScheduleConfig;
};

export const unsavedScheduleConfigAtom = atom<
  UnsavedScheduleConfig | undefined
>(undefined);

const preferentialWeeksAsyncValueAtom = atom<
  AsyncValue<Record<string, PreferentialSchedules[]>>
>({
  loading: true,
});

export const preferentialWeeksAtom = asyncAtom<
  Record<string, PreferentialSchedules[]>,
  string
>(preferentialWeeksAsyncValueAtom, async (projectId, prev) => {
  const snapshots = await getProjectPreferentialScheduleColRef(projectId).get();
  return {
    ...(prev.data ?? {}),
    [projectId]: snapshots.docs
      .map((doc) => doc.data())
      .filter((s) => s.id !== 'config'),
  };
});

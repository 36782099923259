import { Project } from '@agentsonly/models';
import { Button, Col, Row, Table } from 'antd';
import { format } from 'date-fns';
import React, { useState } from 'react';

import { CronJobStatusesType } from '../../../firebase/demoCronJob';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { toLocalTime } from '../../../utils/date';
import { DatePicker } from '../../../utils/DatePicker';
import { useCrobJob } from './hooks';
import { StatusLabel } from './styledComponents';

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: Date) => format(date, 'yyyy-MM-dd pp'),
  },
  {
    title: 'Job At',
    dataIndex: 'jobAt',
    key: 'jobAt',
    render: (utcDate: Date) => {
      const convertToLocalTime = toLocalTime(utcDate);
      return format(convertToLocalTime, 'yyyy-MM-dd');
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CronJobStatusesType) => (
      <StatusLabel $type={status}>{status}</StatusLabel>
    ),
  },
];

type Props = {
  project: Project;
  disabled: boolean;
};

export const CronJob: React.FC<Props> = ({ project, disabled }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { data, onHandleCronJob, loading } = useCrobJob({
    project,
    date: selectedDate,
  });

  return (
    <>
      <Row>
        <Col>
          <DatePicker
            key="datePicker"
            disabled={disabled}
            defaultValue={selectedDate}
            allowClear={false}
            onChange={(date: Date | null) =>
              setSelectedDate(date ?? new Date())
            }
          />
        </Col>
        <Spacer axis="horizontal" size={16} />
        <Col>
          <Button type="primary" onClick={onHandleCronJob} loading={loading}>
            Run Cron Job
          </Button>
        </Col>
      </Row>
      <Spacer axis="vertical" size={32} />
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          total: data.length,
        }}
      />
    </>
  );
};

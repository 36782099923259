export const AdminPermission = {
  Edit: 'edit',
  View: 'view',
} as const;

export const EditableAdminRole = {
  Agents: 'agents',
  Projects: 'projects',
  Referrals: 'referrals',
  Wallet: 'wallet',
  PlatformRates: 'platformRates',
} as const;

export const AdminTab = {
  ...EditableAdminRole,
  TransferData: 'transferData',
  Roles: 'roles',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AdminPermission =
  (typeof AdminPermission)[keyof typeof AdminPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AdminTab = (typeof AdminTab)[keyof typeof AdminTab];

export type AdminPermissions = Partial<Record<AdminTab, AdminPermission>>;

export type AdminRole = {
  id: string;
  name: string;
  permissions: AdminPermissions;
};

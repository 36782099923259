import { Bank, ISOCountry, ISOCountryKeys } from '@agentsonly/models';

import { countryNames } from '../../../../utils/country';
import { currencyIdentifiers } from '../../../../utils/currency';
import { CSVEntry, formatCSVData } from './invoices';

export class WisePaymentEntry implements CSVEntry {
  constructor(
    name: string,
    recipientEmail: string,
    paymentReference: string,
    amount: string,
    country: ISOCountry,
    addressCity: string,
    addressFirstLine: string,
    addressState: string,
    addressPostCode: string,
    bank: Bank,
    childReferral: string,
    referralLevel: string,
    referralGate: string,
  ) {
    this.name = name;
    this.recipientEmail = recipientEmail;
    this.paymentReference = paymentReference;
    this.amount = amount;
    this.country = country;
    this.addressCity = addressCity;
    this.addressFirstLine = addressFirstLine;
    this.addressState = addressState;
    this.addressPostCode = addressPostCode;
    this.bank = bank;
    this.childReferral = childReferral;
    this.referralLevel = referralLevel;
    this.referralGate = referralGate;
  }

  readonly name: string;

  readonly recipientEmail: string;

  readonly paymentReference: string;

  private receiverType = 'PERSON';

  readonly amountCurrency = 'source';

  readonly country: ISOCountry;

  /**
   * Amount in USD
   * string because an exact 2 decimal value should be passed (number.toFixed(2))
   */
  readonly amount: string;

  readonly sourceCurrency = 'USD';

  readonly addressCity: string;

  readonly addressFirstLine: string;

  readonly addressState: string;

  readonly addressPostCode: string;

  readonly bank: Bank;

  readonly childReferral: string;

  readonly referralLevel: string;

  readonly referralGate: string;

  private getAccountNumber = (): string =>
    ISOCountryKeys.MX === this.country ? '' : this.bank.account || '';

  private getAccountType = (): string => {
    const value =
      this.bank.type && this.bank.type.length > 0 ? this.bank.type : 'CHECKING';
    return ISOCountryKeys.MX === this.country ? '' : value;
  };

  private getTargetCurrency = (): string =>
    currencyIdentifiers[this.country] ?? '';

  private getAbartn = (): string =>
    ISOCountryKeys.US === this.country ? this.bank.abartn ?? '' : '';

  private getInstitutionInfo = (): string => {
    if (ISOCountryKeys.CA === this.country) {
      return this.bank.institution ?? '';
    }
    return '';
  };

  private getRoutingInfo = (): string => {
    if (ISOCountryKeys.CA === this.country) {
      return this.bank.routing ?? '';
    }
    return '';
  };

  private getClabe = (): string =>
    ISOCountryKeys.MX === this.country ? this.bank.clabe || '' : '';

  toCSV(newLine: boolean = true): string {
    const rowContent = [
      this.name,
      this.recipientEmail,
      this.paymentReference,
      this.receiverType,
      this.amountCurrency,
      this.amount,
      this.sourceCurrency,
      this.getTargetCurrency(),
      this.getAbartn(),
      this.getAccountNumber(),
      this.getAccountType(),
      countryNames[this.country],
      this.addressCity,
      this.addressFirstLine,
      this.addressState,
      this.addressPostCode,
      this.getInstitutionInfo(),
      this.getRoutingInfo(),
      this.getClabe(),
      this.bank.bankCode ?? '',
      this.childReferral,
      this.referralLevel,
      this.referralGate,
    ];
    return formatCSVData(rowContent, newLine);
  }
}

export const wiseTitles = [
  'name',
  'recipientEmail',
  'paymentReference',
  'receiverType',
  'amountCurrency',
  'amount',
  'sourceCurrency',
  'targetCurrency',
  'abartn',
  'accountNumber',
  'accountType',
  'addressCountryCode',
  'addressCity',
  'addressFirstLine',
  'addressState',
  'addressPostCode',
  'institutionNumber',
  'transitNumber',
  'clabe',
  'bankCode',
  'childReferral',
  'referralLevel',
  'referralGate',
];

import { Project } from '@agentsonly/models';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import React, { useMemo, useState } from 'react';

import { FeedbackSubmission } from '../../../../firebase/models/Coaching';
import { useModal } from '../../../../shared/hooks/useModal';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { createFileToDownload } from '../../../../utils/file';
import { MAPPING_PROCESSING_STATUS, ProcessingStatus } from '../../../consts';
import { FEEDBACK_TEMPLATE, PAGE_SIZE } from '../../consts';
import { ColorfulTitle } from '../styledComponents';
import Timestamp = firebase.firestore.Timestamp;
import {
  CSV_UPLOAD_TYPE,
  CsvUpload,
} from '../../../../firebase/models/CsvUpload';
import { ImportCSVModal } from '../../../../shared/ui/ImportCSVModal';
import { useCSVUpload } from '../../../hooks';
import { CoachingCsvUploadErrorModal } from '../CoachingCsvUploadErrorModal';
import { StyledTable } from './styledComponents';

type Props = {
  project: Project;
  disabled: boolean;
};

const TABLE_ID = 'feedback_table';

const convertCSVUploadToFeedbackSubmission = (
  csvUpload: CsvUpload,
): FeedbackSubmission => {
  const {
    uploadedAt,
    metadata,
    totalRows,
    processingStatus,
    id,
    totalErrorRows,
  } = csvUpload;
  const { missingOwnFeedback = 0, missingExpertFeedback = 0 } = metadata ?? {};
  return {
    uploadedAt,
    totalRows,
    processingStatus,
    totalErrorRows: totalErrorRows ?? 0,
    missingAgentFeedbackCnt: Number(missingOwnFeedback),
    missingExpertFeedbackCnt: Number(missingExpertFeedback),
    id,
  };
};

const columns: ColumnsType<FeedbackSubmission> = [
  {
    title: 'Upload Date',
    dataIndex: 'uploadedAt',
    key: 'uploadedAt',
    render: (date: Timestamp) => format(date.toDate(), 'MM-dd-yyyy'),
  },
  {
    title: 'Status',
    dataIndex: 'processingStatus',
    key: 'processingStatus',
    render: (status: ProcessingStatus) => MAPPING_PROCESSING_STATUS[status],
  },
  {
    title: 'Total Rows',
    dataIndex: 'totalRows',
    key: 'totalRows',
  },
  {
    title: 'Error Rows',
    dataIndex: 'totalErrorRows',
    key: 'totalErrorRows',
  },
  {
    title: 'Missing Agent Feedback',
    dataIndex: 'missingAgentFeedbackCnt',
    key: 'missingAgentFeedbackCnt',
  },
  {
    title: 'Missing Expert Feedback',
    dataIndex: 'missingExpertFeedbackCnt',
    key: 'missingExpertFeedbackCnt',
  },
];

export const Feedback: React.FC<Props> = ({ project, disabled }) => {
  const { isOpen, openModal, closeModal } = useModal(false);
  const [tableId, setTableId] = useState<string>(TABLE_ID);

  const { handleImportCSVFile, data, refreshData, loading } = useCSVUpload(
    CSV_UPLOAD_TYPE.FEEDBACK,
    project.id,
  );

  const [selectedRow, setSelectedRow] = useState<FeedbackSubmission>();

  const feedbackSubmissions = useMemo(
    () => data.map((d) => convertCSVUploadToFeedbackSubmission(d)),
    [data],
  );

  const handleDownloadCSVTemplate = () => {
    createFileToDownload('Coaching-Feedback-template.csv', FEEDBACK_TEMPLATE);
  };

  const onUpload = async (file: File | Blob | undefined) => {
    await handleImportCSVFile(file);
  };

  const handleRefreshData = async () => {
    await refreshData();
    setTableId(`${TABLE_ID}_${Date.now()}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={16}>
          <Col>
            <Button
              onClick={handleDownloadCSVTemplate}
              ghost
              type="primary"
              disabled={disabled}
            >
              Download Import Template
            </Button>
          </Col>
          <Col>
            <Button
              onClick={openModal}
              ghost
              type="primary"
              disabled={disabled}
            >
              Import a CSV File
            </Button>
          </Col>
          <Col>
            <Button onClick={handleRefreshData} type="primary">
              Refresh
            </Button>
          </Col>
        </Row>
      </Col>
      <Spacer size={24} axis="vertical" />
      <Col span={24}>
        <ColorfulTitle level={5} color={BaseColors.black}>
          Upload Summary
        </ColorfulTitle>
      </Col>
      <Spacer size={18} axis="vertical" />
      <Col span={24}>
        <StyledTable
          pagination={{
            pageSize: PAGE_SIZE,
            position: ['bottomLeft'],
            showSizeChanger: false,
          }}
          key={tableId}
          rowKey="id"
          columns={columns}
          loading={loading}
          dataSource={feedbackSubmissions}
          rowClassName={(record) =>
            record.totalErrorRows ? 'clickable' : 'not-clickable'
          }
          onRow={(row) => ({
            onClick: () => {
              if (row.totalErrorRows) {
                setSelectedRow(row);
              }
            },
          })}
        />
      </Col>
      <ImportCSVModal open={isOpen} onClose={closeModal} onUpload={onUpload} />
      {selectedRow && (
        <CoachingCsvUploadErrorModal
          onClose={() => setSelectedRow(undefined)}
          projectId={project.id}
          fileId={selectedRow.id}
          type={CSV_UPLOAD_TYPE.FEEDBACK}
        />
      )}
    </Row>
  );
};

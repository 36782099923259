import React from 'react';

import { Behavior } from '../../../../firebase/models/Coaching';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { useCreateBehavior } from '../../hooks';
import { BehaviorModal } from '../BehaviorModal';

type Props = {
  projectId: string;
  initialValue: Behavior;
  onCreateSuccess: () => void;
  onDismiss: () => void;
};

export const CreateBehavior: React.FC<Props> = ({
  projectId,
  initialValue,
  onCreateSuccess,
  onDismiss,
}) => {
  const { create, loading } = useCreateBehavior({
    projectId,
    onSuccess: () => {
      onDismiss();
      ShowNotification('Create behavior successfully!', IconType.Success);
      onCreateSuccess();
    },
  });

  return (
    <BehaviorModal
      header="Create New Behaviors"
      isLoading={loading}
      initialValue={initialValue}
      onSubmit={create}
      onDismiss={onDismiss}
      projectId={projectId}
    />
  );
};

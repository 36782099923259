import { Project } from '@agentsonly/models';
import { setDoc } from 'firebase/firestore';
import { atom } from 'jotai';
import get from 'lodash.get';

import {
  AiPrompt,
  AiPromptConfig,
  AiPromptDefaultKeys,
  AiTemplateType,
} from '../firebase/models/Prompts';
import { getProjectRefV9 } from '../firebase/project';
import {
  getAiPromptDefaultKeys,
  getPromptConfig,
  promptDefaultKeysDoc,
  saveAiPromptTemplateConfig,
} from '../firebase/project/prompts';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

export const projectByIdAtom = atom({} as Record<string, Project>);

export const updateProjectAtom = atom(
  null,
  async (
    _,
    set,
    { id, ...data }: Partial<Omit<Project, 'id'>> & Pick<Project, 'id'>,
  ) => {
    await setDoc(getProjectRefV9(id), data, { merge: true });
    set(projectByIdAtom, (prev) => ({
      ...prev,
      [id]: { ...prev[id], ...data, id },
    }));
  },
);

export const currentProjectIdAtom = atom<string | undefined>(undefined);

export const aiPromptsByProjectId = atom<
  AsyncValue<Record<string, AiPromptConfig>>
>({ loading: false });

export const aiPromptsByProjectIdAtom = asyncAtom<
  Record<string, AiPromptConfig>,
  string
>(aiPromptsByProjectId, async (projectId, prev) => {
  const promptConfig = await getPromptConfig(projectId);

  if (!promptConfig) {
    return prev.data ?? {};
  }

  return { ...(prev.data ?? {}), [projectId]: promptConfig };
});

type UpdateAPIKeyPayload = {
  projectId: string;
  encryptedKey: string;
  maskedKey: string;
  type: AiTemplateType;
};

export const updateAIAPIKeysByProjectIdAtom = asyncAtom<
  Record<string, AiPromptConfig>,
  UpdateAPIKeyPayload
>(aiPromptsByProjectId, async (payload, prev) => {
  const { projectId, encryptedKey, maskedKey, type } = payload;
  const existingAIConfig = get(prev, `data.${projectId}.${type}.settings`, {
    provider: 'none',
  });
  const updatedValue = {
    ...get(prev, `data.${projectId}.${type}`, { template: '' }),
    settings: {
      ...existingAIConfig,
      apiCredentials: { encryptedKey, maskedKey },
    },
  } as AiPrompt;

  await saveAiPromptTemplateConfig(projectId, updatedValue, type);

  return {
    ...(prev.data ?? {}),
    [projectId]: {
      ...get(prev, `data.${projectId}`, {}),
      [type]: updatedValue,
    } as AiPromptConfig,
  };
});

export const aiPromptDefaultKeysByProjectId = atom<
  AsyncValue<Record<string, AiPromptDefaultKeys>>
>({ loading: false });

export const aiPromptsDefaultKeysByProjectIdAtom = asyncAtom<
  Record<string, AiPromptDefaultKeys>,
  string
>(aiPromptDefaultKeysByProjectId, async (projectId: string, prev) => {
  const defaultKeys = await getAiPromptDefaultKeys(projectId);

  if (!defaultKeys) {
    return prev?.data ?? {};
  }

  return {
    ...(prev?.data ?? {}),
    [projectId]: defaultKeys,
  };
});

type UpdateAiPromptsDefaultKeysPayload = {
  projectId: string;
  data: AiPromptDefaultKeys;
};

export const updateAiPromptsDefaultKeysByProjectIdAtom = asyncAtom<
  Record<string, AiPromptDefaultKeys>,
  UpdateAiPromptsDefaultKeysPayload
>(aiPromptDefaultKeysByProjectId, async ({ data, projectId }, prev) => {
  await setDoc(promptDefaultKeysDoc(projectId), data);
  return {
    ...(prev?.data ?? {}),
    [projectId]: data,
  };
});

import { Typography } from 'antd';
import styled from 'styled-components';

import { Form } from '../../../../../shared/ui/form/Form';
import { Gray } from '../../../../../shared/ui/themes/colors/gray';

const { Paragraph } = Typography;

export const Label = styled(Paragraph)`
  font-size: 14px;
  color: ${Gray[500]};
`;

export const Value = styled(Paragraph)`
  font-size: 16px;
  color: ${Gray[900]};
  font-weight: 500;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

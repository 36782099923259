import { Col, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import React from 'react';

import { WalletTxAdmin } from '../../../../shared/models/WalletTx';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/csv';
import { formatAmount } from '../../../../utils/currency';
import { WalletStatusText } from '../../../components/WalletStatusText';

const { Text } = Typography;

type Props = {
  list: WalletTxAdmin[];
  loading: boolean;
  totalCount: number;
  page: number;
  onPageChange: (page: number) => void;
};

export const PaymentsList: React.FC<Props> = ({
  list,
  loading,
  page,
  totalCount,
  onPageChange,
}) => {
  const columns: ColumnsType<WalletTxAdmin> = [
    {
      title: 'Withdrawal Date',
      render: (_, tx) => format(new Date(tx.date), 'PP'),
    },
    {
      title: 'Reference#',
      render: (_, tx) => tx.referenceNumber,
    },
    {
      title: 'Agent Name',
      render: (_, tx) => `${tx.firstName} ${tx.lastName}`,
    },
    {
      title: 'Bank Code/CLABE',
      render: (_, tx) => tx.bankCode ?? tx.clabe,
    },
    {
      title: 'Account Details',
      render: (_, tx) => tx.account,
    },
    {
      title: 'Target Currency',
      render: (_, tx) => tx.currency,
    },
    {
      title: 'Amount',
      render: (_, tx) => formatAmount(tx.amount),
    },
    {
      title: 'Payment Status',
      render: (_, tx) => <WalletStatusText status={tx.txStatusId} />,
    },
  ];

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Text>Payments List</Text>
      </Col>
      <Col span={24}>
        <Table
          rowKey="referenceNumber"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            total: totalCount,
            showSizeChanger: false,
            hideOnSinglePage: true,
            current: page,
            pageSize: DEFAULT_PAGE_SIZE,
            onChange: onPageChange,
          }}
        />
      </Col>
    </Row>
  );
};

import { Form as AntdForm } from 'antd';
import styled from 'styled-components';

export const FormItem = styled(AntdForm.Item)`
  &:not(.ant-form-item-with-help) {
    margin-bottom: 16px;
  }
  .ant-radio-group {
    margin-top: 16px;
  }
`;

import { LinkOutlined } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';

import { Document } from '../../firebase/models/Document';
import { getStorageBlobWithURL } from '../../firebase/storage/storage';

type Props = {
  document: Document;
};

const { Text, Link } = Typography;

export const LegalDocumentRow: FC<Props> = ({ document }) => {
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();
  useEffect(() => {
    (async () => {
      const { documentUrl } = document;
      const fileUrl = await getStorageBlobWithURL(documentUrl);

      setDownloadUrl(fileUrl);
    })();
  }, [document.documentUrl]);

  if (!downloadUrl) {
    return <Text>{`${document.id}: Document is missing`}</Text>;
  }

  return (
    <Row justify="space-between">
      <Link href={downloadUrl} download={`${document.id}.pdf`} target="_blank">
        <LinkOutlined /> {document.id}
      </Link>
      <Text type="secondary">
        signed {format(document.dateSigned ?? new Date(), 'P')}
      </Text>
    </Row>
  );
};

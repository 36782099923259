import { useState } from 'react';

import { Behavior } from '../../firebase/models/Coaching';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { BehaviorStatus, MAX_LIVE_BEHAVIOR } from './consts';
import {
  createBehavior,
  deleteBehavior,
  editBehavior,
  getBehaviors,
  getLiveBehaviors,
} from './helpers';

interface IGeneralFormBehavior {
  loading: boolean;
}

interface IGeneralActionBehavior {
  projectId: string;
  onSuccess: () => void;
}

interface IHookFetchCoaching extends IGeneralFormBehavior {
  data: Behavior[];
  loadData: () => Promise<void>;
}

interface IHookCreateBehavior extends IGeneralFormBehavior {
  create: (data: Behavior) => Promise<void>;
}

interface IHookEditBehavior extends IGeneralFormBehavior {
  edit: (data: Behavior) => Promise<void>;
}

interface IHookDeleteBehavior extends IGeneralFormBehavior {
  remove: () => Promise<void>;
}

interface ICreateProps extends IGeneralActionBehavior {}

interface IEditProps extends IGeneralActionBehavior {
  behaviorId: string;
  initialValues: Partial<Behavior>;
}

interface IDeleteProps extends IGeneralActionBehavior {
  behaviorId: string;
}

const DEFAULT_DISPLAY_ORDER = 0;

export const useFetchBehavior = (projectId: string): IHookFetchCoaching => {
  const [data, setData] = useState<Behavior[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);

    try {
      const result = await getBehaviors(projectId);
      setData(result.sort((a, b) => a.displayOrder - b.displayOrder));
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, loadData };
};

export const useCreateBehavior = ({
  projectId,
  onSuccess,
}: ICreateProps): IHookCreateBehavior => {
  const [loading, setLoading] = useState<boolean>(false);
  const create = async (data: Behavior) => {
    setLoading(true);
    try {
      // Check reach max live behaviors
      const liveBehaviors = await getLiveBehaviors(projectId);
      const totalLiveBehaviors = liveBehaviors.length;
      if (
        totalLiveBehaviors >= MAX_LIVE_BEHAVIOR &&
        data.status === BehaviorStatus.LIVE
      ) {
        throw new Error('Reaching the max online behaviors');
      }

      await createBehavior(projectId, data);
      onSuccess();
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, create };
};

export const useEditBehavior = ({
  projectId,
  behaviorId,
  initialValues,
  onSuccess,
}: IEditProps): IHookEditBehavior => {
  const [loading, setLoading] = useState<boolean>(false);

  const edit = async (data: Behavior) => {
    try {
      setLoading(true);
      // Check reach max live behaviors
      const liveBehaviors = await getLiveBehaviors(projectId);
      const totalLiveBehaviors = liveBehaviors.length;

      const lastOrderNumber = liveBehaviors.sort((a, b) =>
        a.displayOrder > b.displayOrder ? -1 : 1,
      )[0]?.displayOrder;

      // If the new status change to live and reached max count
      if (
        totalLiveBehaviors >= MAX_LIVE_BEHAVIOR &&
        data.status === BehaviorStatus.LIVE &&
        liveBehaviors.find((b) => b.id === data.id) === undefined
      ) {
        throw new Error('Reaching the max online behaviors');
      }

      const updatedData = { ...data };

      if (
        initialValues.status === BehaviorStatus.OFF &&
        data.status === BehaviorStatus.LIVE
      ) {
        updatedData.displayOrder =
          lastOrderNumber === undefined
            ? DEFAULT_DISPLAY_ORDER
            : lastOrderNumber + 1;
      }

      await editBehavior(projectId, behaviorId, updatedData);
      onSuccess();
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, edit };
};

export const useDeleteBehavior = ({
  projectId,
  behaviorId,
  onSuccess,
}: IDeleteProps): IHookDeleteBehavior => {
  const [loading, setLoading] = useState<boolean>(false);
  const remove = async () => {
    setLoading(true);
    try {
      await deleteBehavior(projectId, behaviorId);
      onSuccess();
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, remove };
};

import {
  BACKGROUND_KEYS,
  BACKGROUND_STATUS,
  BackgroundCheck,
  PcCheck,
} from '@agentsonly/models';
import React, { useCallback, useEffect, useState } from 'react';

import { getAgentPcCheckRef } from '../../../../firebase/agent';
import { BackgroundCheckStatusLabels } from '../consts';
import { CheckItem } from './CheckItem';
import { CheckItemContainer, ResultContainer } from './Containers';
import { PCCheckResult } from './PCCheckResult';
import { SecurityCheckResult } from './SecurityCheckResult';

type Props = {
  agentId: string;
  backgroundCheck: BackgroundCheck;
  onBackgroundCheckChange: (backgroundCheck: BackgroundCheck) => void;
  disabled: boolean;
};

const usePCCheck = (agentId: string) => {
  const [pcCheck, setPCCheck] = useState<PcCheck | undefined>();
  useEffect(() => {
    (async () => {
      const result = await getAgentPcCheckRef(agentId).get();
      setPCCheck(result.data());
    })();
  }, [agentId]);
  return pcCheck;
};

export const PCCheck: React.FC<Props> = ({
  agentId,
  backgroundCheck,
  onBackgroundCheckChange,
  disabled,
}) => {
  const pcCheck = usePCCheck(agentId);

  const pcCheckKey = BACKGROUND_KEYS.PC_CHECK;
  const value = backgroundCheck[pcCheckKey] ?? BACKGROUND_STATUS.PENDING_REVIEW;

  const onChange = useCallback(
    (key: string, newValue: string) => {
      onBackgroundCheckChange({ ...backgroundCheck, [key]: newValue });
    },
    [backgroundCheck, onBackgroundCheckChange],
  );

  return (
    <>
      <ResultContainer>
        <PCCheckResult title="PC Check Result" pcCheck={pcCheck} />
        <SecurityCheckResult agentId={agentId} />
      </ResultContainer>
      <CheckItemContainer>
        <CheckItem
          key={pcCheckKey}
          id={pcCheckKey}
          value={BackgroundCheckStatusLabels[value]}
          onChange={onChange}
          disabled={disabled}
        />
      </CheckItemContainer>
    </>
  );
};

import { Col, Row, Spin, Typography } from 'antd';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { BaseColors } from '../../../../../shared/ui/colors';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { countWords } from '../../../helpers';
import { useAiPlaceholders } from '../../../hooks/useAiPlaceholders';
import { PlaceholderContainer, StyledBadge } from './styledComponents';

const { Text } = Typography;

type Props = {
  name: string;
  handleClick: (placeholder: string) => void;
  disabled?: boolean;
  fetchPlaceholders?: () => Promise<string[]>;
};

export const Placeholder: FC<Props> = ({
  name,
  handleClick,
  disabled,
  fetchPlaceholders,
}) => {
  const value = useWatch({ name });
  const { placeholders, loading } = useAiPlaceholders(fetchPlaceholders);
  const tokenInUse = countWords(value, placeholders);

  return (
    <PlaceholderContainer $disabled={disabled}>
      <Row>
        <Col span={24}>
          <Text
            style={{
              color: disabled ? BaseColors.secondaryMedium : BaseColors.primary,
            }}
            strong
          >
            Available placeholders
          </Text>
        </Col>
        <Spacer axis="vertical" size={9} />
        <Col span={24}>
          <Text style={{ color: BaseColors.secondaryText }}>
            Base template is a common template which inserts the text body
            before other templates
          </Text>
          <Spacer axis="vertical" size={16} />
        </Col>
        {loading ? (
          <Row align="middle" justify="center">
            <Col span={6}>
              <Spin />
            </Col>
          </Row>
        ) : (
          placeholders.length && (
            <Row align="top" justify="start" gutter={[10, 12]}>
              {placeholders.map((placeholder) => {
                const numOfTokens = tokenInUse[placeholder];
                return (
                  <Col key={placeholder}>
                    <StyledBadge
                      key={placeholder}
                      onClick={() => handleClick(placeholder)}
                      $inUse={numOfTokens > 0}
                      $disabled={disabled}
                    >
                      {placeholder}
                      {numOfTokens > 1 && <> ({numOfTokens})</>}
                    </StyledBadge>
                  </Col>
                );
              })}
            </Row>
          )
        )}
      </Row>
    </PlaceholderContainer>
  );
};

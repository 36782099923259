import { notification } from 'antd';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';

import { chunk } from '../../utils';
import {
  ConfigCollections,
  ConfigReferralsCollections,
  RootCollections,
} from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';
import {
  AgentReferralWeeklyLimit,
  BackgroundCheck,
  Referrals,
  WebAdmin,
} from './model';

export const getConfigBackgroundCheck = async () => {
  const snapshot = await db
    .collection(RootCollections.Configs)
    .doc(ConfigCollections.BackgroundCheck)
    .withConverter(generalConverter<BackgroundCheck>())
    .get();
  return snapshot.data();
};

export const getConfigReferrals = async () => {
  const res = await db
    .collection(RootCollections.Configs)
    .doc(ConfigCollections.Referrals)
    .withConverter(generalConverter<Referrals>())
    .get();
  return res.data();
};

export const updateReferral = async (referral: Partial<Referrals>) =>
  db
    .collection(RootCollections.Configs)
    .doc(ConfigCollections.Referrals)
    .set(referral, { merge: true });

export const AGENT_REFERRAL_WEEKLY_LIMIT = 10;

const weeklyLimitsRef = () =>
  db
    .collection(RootCollections.Configs)
    .doc(ConfigCollections.Referrals)
    .collection(ConfigReferralsCollections.Limits);

export const getWeeklyLimits = async (
  startAfter?: AgentReferralWeeklyLimit,
) => {
  let snapshot = weeklyLimitsRef().orderBy('weeklyReferralLimit', 'desc');

  if (startAfter) {
    snapshot = snapshot.startAfter(
      await weeklyLimitsRef().doc(startAfter.id).get(),
    );
  }

  const res = await snapshot
    .limit(AGENT_REFERRAL_WEEKLY_LIMIT)
    .withConverter(generalConverter<AgentReferralWeeklyLimit>())
    .get();

  return res.docs.map((document) => document.data());
};

export const replaceWeeklyLimits = async (
  limits: AgentReferralWeeklyLimit[],
) => {
  try {
    const oldLimits = await weeklyLimitsRef().get();
    const oldLimitsChunks = chunk(oldLimits.docs, 500);
    await Promise.all(
      oldLimitsChunks.map((oldLimitsChunk) => {
        const batch = db.batch();
        oldLimitsChunk.forEach((document) => batch.delete(document.ref));
        return batch.commit();
      }),
    );

    const limitsChunks = chunk(limits, 500);
    await Promise.all(
      limitsChunks.map((limitsChunk) => {
        const batch = db.batch();
        limitsChunk.forEach(({ id, ...data }) => {
          batch.set(weeklyLimitsRef().doc(id), data);
        });
        return batch.commit();
      }),
    );

    return limits;
  } catch (err) {
    notification.error({ message: `${err}` });
    return [];
  }
};

export const removeWeeklyLimit = async (limit: AgentReferralWeeklyLimit) =>
  weeklyLimitsRef().doc(limit.id).delete();

export const getWebAdminConfigRef = () =>
  db
    .collection(RootCollections.Configs)
    .doc(ConfigCollections.WebAdmin)
    .withConverter(generalConverter<WebAdmin>());

export const getCloudTasks = async () => {
  const cloudTasks = await getDoc(
    doc(
      collection(db, RootCollections.Configs),
      ConfigCollections.CloudTasks,
    ).withConverter(generalConverter<Record<string, Array<string>>>()),
  );

  const { id, ...result } = cloudTasks.data() || {};

  return result;
};

export const updateCloudTasks = async (
  payload: Record<string, Array<string>>,
) => {
  await setDoc(
    doc(collection(db, RootCollections.Configs), ConfigCollections.CloudTasks),
    payload,
  );
};

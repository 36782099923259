import { Project } from '@agentsonly/models';
import { Col, Row, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import { CSV_UPLOAD_TYPE } from '../../../../firebase/models/CsvUpload';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { ImportCSVModal } from '../../../../shared/ui/ImportCSVModal';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { createFileToDownload } from '../../../../utils/file';
import { useCSVUpload } from '../../../hooks';
import {
  ACHIEVEMENT_TEMPLATE,
  PAGE_SIZE,
  PROFICIENCY_MAPPING,
} from '../../consts';
import { getProjectAchievements } from '../../helpers';
import { AchievementItem } from '../../types';
import { ColorfulTitle } from '../styledComponents';

const columns: ColumnType<AchievementItem>[] = [
  {
    title: 'Upload Date',
    dataIndex: 'uploadDate',
    key: 'uploadDate',
    sorter: (a: AchievementItem, b: AchievementItem) =>
      a.uploadDate > b.uploadDate ? -1 : 1,
    defaultSortOrder: 'ascend',
    render: (date: Date) => format(date, 'MM/dd/yyyy'),
  },
  {
    title: 'Agent ID',
    dataIndex: 'agentId',
    key: 'agentId',
  },
  {
    title: 'Agent Name',
    dataIndex: 'agentName',
    key: 'agentName',
  },
  {
    title: 'Proficiency Achievement',
    dataIndex: 'proficiency',
    key: 'proficiency',
    render: (proficiency: string) =>
      PROFICIENCY_MAPPING[proficiency.toLowerCase()],
  },
  {
    title: 'Assessed by',
    dataIndex: 'assessedBy',
    key: 'assessedBy',
  },
];

type Props = {
  project: Project;
  disabled: boolean;
};

const TABLE_ID = 'achievement_table';

export const Achievement: React.FC<Props> = ({ project, disabled }) => {
  const [data, setData] = useState<AchievementItem[]>([]);
  const [isOpenImportModal, toggleImportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableId, setTableId] = useState(TABLE_ID);
  const { handleImportCSVFile } = useCSVUpload(
    CSV_UPLOAD_TYPE.ACHIEVEMENT,
    project.id,
  );

  const loadData = useCallback(async () => {
    setLoading(true);
    setData(await getProjectAchievements(project.id));
    setLoading(false);
  }, [project.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDownloadCSVTemplate = () => {
    createFileToDownload(
      'Coaching-Achievement-template.csv',
      ACHIEVEMENT_TEMPLATE,
    );
  };

  const onUpload = async (file: File | Blob | undefined) => {
    try {
      await handleImportCSVFile(file);
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    }
  };

  const openImportModal = () => {
    toggleImportModal(true);
  };

  const closeImportModal = () => {
    toggleImportModal(false);
  };

  const refreshData = async () => {
    await loadData();
    setTableId(`${TABLE_ID}_${Date.now()}`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Button
            ghost
            type="primary"
            onClick={handleDownloadCSVTemplate}
            disabled={disabled}
          >
            Download Import Template
          </Button>
          <Spacer size={16} axis="horizontal" />
          <Button
            onClick={openImportModal}
            ghost
            type="primary"
            disabled={disabled}
          >
            Import a CSV File
          </Button>
          <Spacer size={16} axis="horizontal" />
          <Button onClick={refreshData} type="primary">
            Refresh
          </Button>
        </Row>
      </Col>
      <Spacer size={24} axis="vertical" />
      <Col span={24}>
        <Row>
          <Col span={24}>
            <ColorfulTitle level={5} color={BaseColors.black}>
              Agent Achievements
            </ColorfulTitle>
          </Col>
          <Spacer size={18} axis="vertical" />
          <Col span={24}>
            <Table
              pagination={{
                pageSize: PAGE_SIZE,
                position: ['bottomLeft'],
                showSizeChanger: false,
              }}
              dataSource={data}
              key={tableId}
              rowKey="id"
              columns={columns}
              loading={loading}
            />
          </Col>
        </Row>
      </Col>
      <ImportCSVModal
        open={isOpenImportModal}
        onUpload={onUpload}
        onClose={closeImportModal}
      />
    </Row>
  );
};

import * as yup from 'yup';

import { getCoachingBehaviorByNameRef } from '../../../../firebase/coaching';

type TestContextParams = {
  projectId: string;
  behaviorId: string;
};

export const coachingBehaviorSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required()
    .label('Behavior Name')
    .test(
      'uniqueName',
      'Same behavior name already exists. Please change the name.',
      async (value, context) => {
        try {
          const { projectId, behaviorId } = (context.options?.context ??
            {}) as TestContextParams;
          const ref = getCoachingBehaviorByNameRef(
            projectId,
            (value ?? '') as string,
            2,
          );
          const snapshot = await ref.get();
          const behaviors = snapshot.docs.map((d) => d.data());
          return behaviors.find((b) => b.id !== behaviorId) === undefined;
        } catch (e) {
          return false;
        }
      },
    ),
  description: yup.string().label('Behavior Description').optional(),
  displayOrder: yup.number().optional(),
  actions: yup
    .array(
      yup.object({
        description: yup.string().label('Recommended Action').required(),
        link: yup
          .string()
          .url('Recommended Link format is not correct')
          .optional(),
      }),
    )
    .min(1, 'At least one Recommendation Action')
    .max(5)
    .required(),
  status: yup.string().required(),
});

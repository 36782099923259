import { useAtom } from 'jotai';
import React from 'react';

import {
  currentAdminPermissionsAtom,
  isCurrentUserAdmin,
} from '../../../atoms/adminRoles';
import { AdminPermission, AdminTab } from '../../../firebase/adminRoles/model';

type Props = {
  tab: AdminTab;
  component: React.ComponentType<{ disabled: boolean }>;
};

export const AdminPermissionRoute: React.FC<Props> = ({ tab, component }) => {
  const [isAdmin] = useAtom(isCurrentUserAdmin);
  const [permissions] = useAtom(currentAdminPermissionsAtom);
  const permission = permissions[tab];
  const disabled = permission !== AdminPermission.Edit;

  if (!permission || (tab === AdminTab.Roles && !isAdmin)) {
    return null;
  }

  return React.createElement(component, { disabled });
};

import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

import { FormInput } from '../../../../../../shared/ui/form/FormInput';
import { Spacer } from '../../../../../../shared/ui/Spacer/styledComponents';

const { Title, Text } = Typography;

type Props = {
  title?: string;
  label: string;
  fieldName: string;
  disabled?: boolean;
};

export const APIKeyInput: FC<Props> = ({
  title,
  label,
  fieldName,
  disabled,
}) => (
  <Row>
    <Col span={24}>
      {title && <Title level={5}>{title}</Title>}
      <Text>{label}</Text>
      <Spacer size={4} axis="vertical" />
      <FormInput disabled={disabled} placeholder="API Key" name={fieldName} />
    </Col>
  </Row>
);

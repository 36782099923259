import {
  getProjectExternalNumberDimensionsKeys,
  getProjectRefsByInstanceId,
} from '../firebase/project';

export const getExternalDataKeysByInstanceId = async (instanceId?: string) => {
  const projects = await getProjectRefsByInstanceId(instanceId);

  if (projects.empty) {
    return [];
  }

  const projectId = projects.docs[0].id;
  const numberDimensionsKeys =
    await getProjectExternalNumberDimensionsKeys(projectId);

  return [...numberDimensionsKeys];
};

import { ReferralPlan } from '@agentsonly/models';
import { atom } from 'jotai';

import {
  createReferralPlan,
  getActiveReferralPlan,
} from '../firebase/referrals';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const referralPlanAsyncValueAtom = atom<AsyncValue<ReferralPlan>>({
  loading: true,
});

export const referralPlanAtom = asyncAtom<ReferralPlan, void>(
  referralPlanAsyncValueAtom,
  () => getActiveReferralPlan(),
);

referralPlanAtom.onMount = (run) => {
  run();
};

export const createReferralPlanAtom = asyncAtom<
  ReferralPlan,
  Omit<ReferralPlan, 'id'>
>(referralPlanAsyncValueAtom, (data) => createReferralPlan(data));

import * as yup from 'yup';

import { getCoachingResourcesColRefByTitle } from '../../../../firebase/coaching';

type TestContextParams = {
  projectId: string;
  resourceId: string;
};

const MAXIMUM_RESOURCE_LENGTH = 24;

export const resourceValidator = yup.object({
  title: yup
    .string()
    .required('Title is a required field')
    .trim()
    .max(MAXIMUM_RESOURCE_LENGTH, 'Title only includes maximum 24 characters')
    .test(
      'titleUnique',
      'Same resource name already exists. Please change the name.',
      async (title, context: yup.TestContext) => {
        try {
          const { projectId, resourceId } = (context.options.context ??
            {}) as TestContextParams;
          const ref = getCoachingResourcesColRefByTitle(
            projectId,
            (title ?? '') as string,
          );
          const snapshot = await ref.get();
          const resources = snapshot.docs.map((d) => d.data());
          return resources.find((r) => r.id !== resourceId) === undefined;
        } catch (e) {
          return false;
        }
      },
    ),
  icon: yup.string().required('Icon is a required field'),
  link: yup
    .string()
    .url('Link format is not correct!')
    .required('Resource Link is a required field'),
});

import { Col, Row, Typography } from 'antd';
import React, { Fragment } from 'react';

import { Badge } from '../../../../shared/ui/Badge';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';

const { Text } = Typography;

type ResultValue = {
  id: string;
  label: string;
  value: string;
  highlight?: boolean;
  badge?: boolean;
  badgeColor?: string;
  badgeBgColor?: string;
};

type Props = {
  title?: string;
  contents: ResultValue[];
};

const ItemRow = ({
  id,
  label,
  value,
  highlight = false,
  badge = false,
  badgeColor = '#006650',
  badgeBgColor = '#EBFFF8',
}: ResultValue) => (
  <Fragment key={id}>
    <Spacer axis="vertical" size={8} />
    <Row justify="space-between">
      <Text style={{ color: '#666666', fontSize: 16 }}>{label}</Text>

      {badge ? (
        <Badge
          backgroundColor={badgeBgColor}
          color={badgeColor}
          content={
            <Text style={{ color: badgeColor, fontSize: 14, padding: '0 8px' }}>
              {value}
            </Text>
          }
        />
      ) : (
        <Text
          style={{ color: highlight ? '#08A17C' : '#202629', fontSize: 16 }}
        >
          {value}
        </Text>
      )}
    </Row>
    <Spacer axis="vertical" size={8} />
  </Fragment>
);

export const ResultContent: React.FC<Props> = ({ title, contents }) => (
  <>
    {title && (
      <>
        <Text style={{ color: '#252733', fontSize: 19, fontWeight: 'bold' }}>
          {title}
        </Text>
        <Spacer axis="vertical" size={32} />
      </>
    )}
    <Col>
      {contents.map(
        ({
          id,
          label,
          value,
          highlight = false,
          badge = false,
          badgeColor,
          badgeBgColor,
        }) => (
          <ItemRow
            key={id}
            id={id}
            label={label}
            value={value}
            highlight={highlight}
            badge={badge}
            badgeColor={badgeColor}
            badgeBgColor={badgeBgColor}
          />
        ),
      )}
    </Col>
  </>
);

import { Col } from 'antd';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  margin-top: 2rem;
`;

export const PlatformRateContainer = styled.div`
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
`;

export const FullContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

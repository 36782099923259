import { PlatformRate, Project } from '@agentsonly/models';
import { App, Button, Row } from 'antd';
import React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import {
  disableOtherPlatformRateTypes,
  getDefaultPlatformRatesFormParam,
  sortingProjectPlatformRates,
} from './helpers';
import { PlatformRateItem } from './PlatformRateItem';
import { ProjectPlatformRate, ProjectPlatformRatesFormParam } from './types';

type Props = {
  platformRates: PlatformRate[];
  project: Project;
  onUpdate: (projectPlatformRates: ProjectPlatformRate[]) => Promise<void>;
};

export const PlatformRatesForm: React.FC<Props> = ({
  platformRates,
  project,
  onUpdate,
}) => {
  const { notification } = App.useApp();
  const form = useForm<ProjectPlatformRatesFormParam>({
    defaultValues: getDefaultPlatformRatesFormParam(platformRates, project),
    mode: 'all',
  });
  const { isDirty, isSubmitting } = form.formState;
  const { fields } = useFieldArray({
    name: 'platformRates',
    control: form.control,
    keyName: 'index',
  });

  const handleOnSave = async (data: ProjectPlatformRatesFormParam) => {
    const { platformRates: projectPlatformRates } = data;
    try {
      await onUpdate(
        projectPlatformRates.filter((rate) => rate.active && !rate.isDeleted),
      );
      notification.success({ message: 'Updated Platform Rates' });
    } catch (e) {
      notification.error({ message: (e as Error).message });
    }
  };

  const handlePlatformRateSelected = (index: number) => (selected: boolean) => {
    const clonedFields = structuredClone(fields);
    clonedFields[index].active = selected;
    const updatedFields = sortingProjectPlatformRates(
      disableOtherPlatformRateTypes(clonedFields, fields[index].id),
    );

    form.setValue(
      'platformRates',
      [
        ...updatedFields.filter((f) => !f.disabled),
        ...updatedFields.filter((f) => f.disabled),
      ],
      {
        shouldDirty: true,
      },
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleOnSave)}>
        {fields.map((platformRate, index) => (
          <PlatformRateItem
            key={platformRate.index}
            rateName={platformRate.name}
            description={platformRate.description}
            rateInUSD={platformRate.rateInUSD}
            rateType={platformRate.rateType}
            onSelected={handlePlatformRateSelected(index)}
            active={platformRate.active}
            disabled={platformRate.disabled}
            data-testid={`project:PlatformRates:rateItem-${platformRate.name}`}
          />
        ))}
        <Row justify="end">
          <Button
            disabled={!isDirty}
            loading={isSubmitting}
            htmlType="submit"
            type="primary"
            data-testid="project:PlatformRates:saveButton"
          >
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  );
};

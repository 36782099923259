import { Col, Row } from 'antd';
import { useAtom } from 'jotai';
import { FC } from 'react';

import { currentProjectIdAtom } from '../../../../../atoms/project';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { useOpenAIAssistant } from '../../../hooks/useOpenAIAssistant';
import { StyledSelect } from '../styledComponents';
import { APIKeyInput } from './APIKeyInput/APIKeyInput';

type Props = {
  fieldName: string;
  disabled?: boolean;
};

export const AssistantId: FC<Props> = ({ fieldName, disabled }) => {
  const [projectId] = useAtom(currentProjectIdAtom);
  const { options, loading } = useOpenAIAssistant(projectId);

  if (!projectId) {
    return null;
  }

  return (
    <>
      <Col span={5}>
        <Row>Assistant ID</Row>
        <Spacer size={4} axis="vertical" />
        <StyledSelect
          name={fieldName}
          placeholder="Select Assistant"
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          disabled={disabled}
          loading={loading}
        />
      </Col>
      <Col span={5}>
        <APIKeyInput
          label="API Key(Optional)"
          fieldName="settings.apiCredentials.maskedKey"
          disabled={disabled}
        />
      </Col>
    </>
  );
};

import { ISOCountry } from '@agentsonly/models';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

import { FormInput } from '../../../../shared/ui/form/FormInput';
import { currencyIdentifiers } from '../../../../utils/currency';
import {
  COUNTRY_LABEL_MAPPING,
  COUNTRY_TITLE_MAPPING,
  COUNTRY_UNIT_MAPPING,
} from './consts';
import { CountryMilestoneContainer } from './styledComponents';

const { Title } = Typography;

type Props = {
  country: ISOCountry;
};

export const CountryMilestone: FC<Props> = ({ country }) => (
  <Col span={24}>
    <CountryMilestoneContainer>
      <Col span={24}>
        <Title level={5}>{COUNTRY_TITLE_MAPPING[country]}</Title>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={12}>
            <FormInput
              label={COUNTRY_LABEL_MAPPING[country]}
              name={`${country}.milestone`}
              suffix={COUNTRY_UNIT_MAPPING[country]}
              placeholder="0.00"
            />
          </Col>
          <Col span={12}>
            <FormInput
              label="Payout"
              name={`${country}.parentAmount`}
              suffix={currencyIdentifiers[country]}
              placeholder="0.00"
            />
          </Col>
        </Row>
      </Col>
    </CountryMilestoneContainer>
  </Col>
);

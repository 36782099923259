import { randomIntFromRange } from '../../utils/dataUtils';
import { db } from '..';

const generateInterruptions = (min: number, max: number) =>
  Array.from(new Array(randomIntFromRange(min, max))).map(() => ({
    BeginOffsetMillis: randomIntFromRange(0, 10000),
    DurationMillis: randomIntFromRange(0, 10000),
    EndOffsetMillis: randomIntFromRange(0, 10000),
  }));

export const saveAnalysisRecord = (
  connectInstanceId: string,
  contactId: string,
) => {
  const analysisData: Record<string, any> = {
    original: {
      ConversationCharacteristics: {
        Interruptions: {
          InterruptionsByInterrupter: {
            AGENT: generateInterruptions(0, 5),
            CUSTOMER: generateInterruptions(0, 5),
          },
        },
        Sentiment: {
          OverallSentiment: {
            AGENT: randomIntFromRange(-5, 5),
            CUSTOMER: randomIntFromRange(-5, 5),
          },
          SentimentByPeriod: {
            QUARTER: {
              AGENT: [
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
              ],
              CUSTOMER: [
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
                {
                  BeginOffsetMillis: randomIntFromRange(0, 10000),
                  EndOffsetMillis: randomIntFromRange(0, 10000),
                  Score: randomIntFromRange(-5, 5),
                },
              ],
            },
          },
        },
        TalkSpeed: {
          DetailsByParticipant: {
            AGENT: {
              AverageWordsPerMinute: randomIntFromRange(0, 500),
            },
            CUSTOMER: {
              AverageWordsPerMinute: randomIntFromRange(0, 500),
            },
          },
        },
      },
    },
  };

  return db
    .collection('contactLens')
    .doc(connectInstanceId)
    .collection('analysis')
    .doc(contactId)
    .set(analysisData);
};

import { Skill } from '@agentsonly/models';
import { atom } from 'jotai';

import { getSkills } from '../firebase/skills';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

export const allSkills = atom<AsyncValue<Skill[]>>({ loading: false });

export const fetchSkillsAtom = asyncAtom(allSkills, getSkills);

import { Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  color?: string;
};

const BaseTextStyle = styled(Typography.Text)<Props>`
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : '#202629')};

  &.ant-typography-disabled {
    opacity: 0.35;
  }
`;

export const StyledP0Text = styled(BaseTextStyle)<Props>`
  font-size: 24px;
  line-height: 31.25px;
`;

export const StyledP1Text = styled(BaseTextStyle)<Props>`
  font-size: 18px;
  line-height: 23.44px;
`;

export const StyledP2Text = styled(BaseTextStyle)<Props>`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledP3Text = styled(BaseTextStyle)`
  font-size: 14px;
`;

export const StyledP4Text = styled(BaseTextStyle)<Props>`
  font-size: 14px;
  font-weight: 400;
`;

export const StyledP5Text = styled(BaseTextStyle)<Props>`
  font-size: 12px;
`;

import { Agent } from '@agentsonly/models';

import { Role } from '../../shared/models/Role';
import { getAgentByEmail, getAgentById, getAgentsById } from '../agent';
import {
  getClientByEmail,
  getClientById,
  getClientsById,
} from '../clientUsers';
import { ClientUser } from '../clientUsers/model';
import { RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';
import { AdminUserRole } from './model';

export const getAdminUserRef = (uid: string) =>
  db
    .collection('adminUsers')
    .doc(uid)
    .withConverter(generalConverter<Omit<AdminUserRole, 'id'>>());

export const getAdminUserRoles = async () => {
  const snapshot = await db
    .collection(RootCollections.AdminUsers)
    .withConverter(generalConverter<AdminUserRole>())
    .get();

  return snapshot.docs.map((doc) => doc.data());
};

export const getAdminUserById = async (id: string) => {
  const agent = await getAgentById(id);
  if (agent) {
    return agent;
  }
  return getClientById(id);
};

export const getAdminUserByEmail = async (email: string) => {
  const agent = await getAgentByEmail(email);
  if (agent) {
    return agent;
  }
  const client = await getClientByEmail(email);
  if (client) {
    return client;
  }
  throw new Error('User not found');
};

export const getAdminUsers = async () => {
  const userRoles = await getAdminUserRoles();
  const userIds = userRoles.map((r) => r.id);
  const [agents, clients] = await Promise.all([
    getAgentsById(userIds),
    getClientsById(userIds),
  ]);

  const userRolesById = userRoles.reduce<Record<string, AdminUserRole>>(
    (ur, userRole) => {
      if (!ur[userRole.id]) {
        // eslint-disable-next-line no-param-reassign
        ur[userRole.id] = userRole;
      }
      return ur;
    },
    {},
  );

  const mapAgentClientToAdmin = (user: Agent | ClientUser) => ({
    ...userRolesById[user.id],
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });

  const agentAdmins = agents.map(mapAgentClientToAdmin) ?? [];

  return agentAdmins.concat(clients.map(mapAgentClientToAdmin) ?? []);
};

export const createAdminUser = async (data: AdminUserRole) => {
  const { id, ...roleData } = data;
  const ref = await getAdminUserRef(id);
  const doc = await ref.get();
  const prevRole = doc.data();

  const oldRoles = prevRole?.roles ?? [];
  const newRoles = roleData?.roles ?? [];

  if (oldRoles.some((r) => newRoles.indexOf(r) >= 0)) {
    throw new Error('User already has this role.');
  }

  const newData = {
    ...roleData,
    roles: newRoles.concat(oldRoles),
  };

  await ref.set(newData);

  return { id, ...newData };
};

export const removeAdminUser = async (id: string) =>
  db.collection(RootCollections.AdminUsers).doc(id).delete();

export const removeAdminUserRole = async (id: string, roleId: string) => {
  const ref = getAdminUserRef(id);
  const doc = await ref.get();
  const prevRole = doc?.data();

  if (prevRole?.roles && prevRole.roles.length > 1) {
    const newData = {
      role: prevRole?.role,
      roles: prevRole?.roles.filter((r) => r !== roleId),
    };
    return ref.set(newData);
  }

  return removeAdminUser(id);
};

export const getUserRoles = async (uid?: string) => {
  if (!uid) {
    return [];
  }
  const doc = await getAdminUserRef(uid).get();
  const data = doc.data();
  if (data?.roles) {
    return data.roles;
  }
  return data?.role ? [data.role] : [];
};

export const updateUserRole = async (uid: string, role: Role) => {
  const ref = getAdminUserRef(uid);
  if (role === Role.Admin) {
    await ref.set({ roles: [role] });
  } else {
    await ref.delete();
  }
};

import { ReferralPlan } from '@agentsonly/models';
import firebase from 'firebase/compat/app';

import { chunk } from '../../utils';
import { db } from '..';
import { RootCollections } from '../consts';
import {
  FirebasePlatformPayment,
  PlatformPayment,
  Referral,
  ReferralStatus,
} from '../models/interfaces';
import { generalConverter, getCollectionData } from '../utils';

const platformPaymentConverter = {
  toFirestore(data: PlatformPayment): firebase.firestore.DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): PlatformPayment {
    const data = snapshot.data(options) as FirebasePlatformPayment;
    return { ...data, createdAt: data.createdAt.toDate() };
  },
};

export const deletePlatformPayments = async (agentId: string) => {
  const refs = await db
    .collection(RootCollections.PlatformPayments)
    .where('agentId', '==', agentId)
    .withConverter(platformPaymentConverter)
    .get();

  await Promise.all(refs.docs.map((r) => r.ref.delete()));
};

export const resetAchievedMilestones = async (agentId: string) => {
  const refs = await db
    .collection(RootCollections.PlatformPayments)
    .where('agentId', '==', agentId)
    .withConverter(platformPaymentConverter)
    .get();

  const references = refs.docs.map((r) => r.data().reference);

  await Promise.all(
    references.map((r) =>
      db.collection(RootCollections.Referrals).doc(r).update({
        achievedMilestone: firebase.firestore.FieldValue.delete(),
      }),
    ),
  );
};

export const getPlatformPayments = async (agentId: string) => {
  const payments = await db
    .collection(RootCollections.PlatformPayments)
    .where('agentId', '==', agentId)
    .withConverter(platformPaymentConverter)
    .get();

  return payments.docs.map((p) => ({ ...p.data(), id: p.id }));
};

const referralsConverter = {
  toFirestore(data: Referral): firebase.firestore.DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Referral {
    const data = snapshot.data(options)!;
    return data as Referral;
  },
};

/** get single Referral document */
export const getReferral = async (referralId: string) => {
  const referral = await db
    .collection(RootCollections.Referrals)
    .withConverter(referralsConverter)
    .doc(referralId)
    .get();

  return referral.exists
    ? ({ ...referral.data(), id: referral.id } as Referral)
    : undefined;
};

export const getReferralsByIds = async (ids: string[]) => {
  const result = await Promise.all(
    chunk(ids, 10).map((chunkIds) =>
      db
        .collection(RootCollections.Referrals)
        .withConverter(referralsConverter)
        .where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds)
        .get(),
    ),
  );

  return result
    .map((referrals) =>
      referrals.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    )
    .flat();
};

export const updateReferrals = async (
  referralId: string,
  values: Partial<Referral>,
) => {
  await db
    .collection(RootCollections.Referrals)
    .withConverter(referralsConverter)
    .doc(referralId)
    .set(values, { merge: true });
};

type PaginateResult = {
  data: Referral[];
  lastItem: firebase.firestore.DocumentSnapshot<Referral>;
};

/** paging referrals (in any status) */
export const getAllReferrals = async (
  limit: number,
  startAfter?: firebase.firestore.DocumentSnapshot<Referral>,
): Promise<PaginateResult> => {
  const query = db
    .collection(RootCollections.Referrals)
    .orderBy('createdAt', 'desc')
    .limit(limit)
    .withConverter(generalConverter<Referral>());

  const snapshots = await (startAfter
    ? query.startAfter(startAfter)
    : query
  ).get();
  const data = snapshots.docs.map((doc) => doc.data());
  return { data, lastItem: snapshots.docs[snapshots.size - 1] };
};

export const getAcceptedReferrals = async (agentId?: string) => {
  let query = db
    .collection(RootCollections.Referrals)
    .withConverter(referralsConverter)
    .where('status', '==', ReferralStatus.Accepted);

  query = agentId ? query.where('referrerId', '==', agentId) : query;

  return getCollectionData<Referral>(query);
};

export const getAcceptedReferralsByEmail = async (email: string) => {
  const query = db
    .collection(RootCollections.Referrals)
    .withConverter(referralsConverter)
    .where('referrerEmail', '==', email)
    .where('status', '==', ReferralStatus.Accepted);

  return getCollectionData<Referral>(query);
};

export const getReferralPlan = async (planId: string) => {
  const plan = await db
    .collection(RootCollections.ReferralPlans)
    .doc(planId)
    .get();

  return plan.data() as ReferralPlan;
};

export const getActiveReferralPlan = async () => {
  const res = await db
    .collection(RootCollections.ReferralPlans)
    .orderBy('createdAt', 'desc')
    .withConverter(generalConverter<ReferralPlan>())
    .limit(1)
    .get();

  return res.docs[0]?.data();
};

export const createReferralPlan = async (data: Omit<ReferralPlan, 'id'>) => {
  const res = await db.collection(RootCollections.ReferralPlans).add(data);
  return { ...data, id: res.id };
};

export const isExistPlan = async (planId: string) => {
  const plan = await db
    .collection(RootCollections.ReferralPlans)
    .doc(planId)
    .get();

  return plan.exists;
};

import { Row } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../shared/ui/colors';

export const Container = styled(Row)<{ $marginRight: number }>`
  border: 1px solid ${BaseColors.inputField};
  padding: 49px 32px;
  border-radius: 8px;
  min-width: 400px;
  margin-left: 0 !important;
  margin-right: ${({ $marginRight }) =>
    $marginRight ? `${$marginRight}px` : 0} !important;

  .ant-col {
    align-self: stretch;
  }
`;

export const IconWrapper = styled.div`
  background-color: ${BaseColors.coachingIconBg};
  width: 135px;
  height: 135px;
  border-radius: 65.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: ${BaseColors.primaryDark};
`;

export const EditIconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  svg {
    width: 30px;
    height: 30px;
  }
`;

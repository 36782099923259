import { BackgroundCheck as BackgroundCheckType } from '@agentsonly/models';
import { App, Empty } from 'antd';
import React from 'react';

import { BackgroundCheckSection } from './components/BackgroundCheckSection';
import { useBackgroundCheck } from './hooks/useBackgroundCheck';
import { sendBackgroundCheckEmail } from './services/emailNotification';

type Props = {
  agentId: string;
  disabled: boolean;
};

export const BackgroundCheck: React.FC<Props> = ({ agentId, disabled }) => {
  const { message } = App.useApp();
  const { agent, saveBackgroundCheck } = useBackgroundCheck(agentId);

  const onReviewCompleted = async (backgroundCheck: BackgroundCheckType) => {
    try {
      await saveBackgroundCheck(backgroundCheck);
      await sendBackgroundCheckEmail(agent, backgroundCheck);
      message.success('Background check information has been updated');
    } catch (err) {
      message.error(`Couldn't update the background check information`);
      console.error(err);
    }
  };

  if (!agent) {
    return <Empty />;
  }

  return (
    <BackgroundCheckSection
      agent={agent}
      disabled={disabled}
      onReviewCompleted={onReviewCompleted}
    />
  );
};

import { Col } from 'antd';
import React from 'react';

import { BorderedCol, StyledSpace } from '../styledComponents';

export const ResultContainer: React.FC<{ children: React.ReactNode }> =
  React.memo(({ children }) => <BorderedCol span={12}>{children}</BorderedCol>);

export const CheckItemContainer: React.FC<{ children: React.ReactNode }> =
  React.memo(({ children }) => (
    <Col span={8}>
      <StyledSpace direction="vertical" size="middle">
        {children}
      </StyledSpace>
    </Col>
  ));

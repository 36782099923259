export const Success = {
  25: '#F5FFFC',
  50: '#EBFFF8',
  100: '#D0FFEE',
  200: '#A2F7DD',
  300: '#51E5B9',
  400: '#00D0A3',
  500: '#00AC87',
  600: '#00896B',
  700: '#006650',
  800: '#004435',
  900: '#00211A',
} as const;

import { Agent } from '@agentsonly/models';
import { FilterValue } from 'antd/es/table/interface';
import firebase from 'firebase/compat/app';
import { atom } from 'jotai';

export type OrderType = 'ascend' | 'descend';

export type TableDataType = {
  sort: OrderType;
  pageSize: number;
  filters: Record<string, FilterValue | null>;
  lastItem?: firebase.firestore.QueryDocumentSnapshot<Agent> | null;
  fetchedPageIndex: number;
  currentPageIndex: number;
};

export type TableFilterType = Omit<
  TableDataType,
  'fetchedPageIndex' | 'currentPageIndex'
>;

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_SORT: OrderType = 'descend';

const initialState: TableDataType = {
  filters: {
    status: null,
    reviewStatus: null,
  },
  pageSize: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
  lastItem: undefined,
  fetchedPageIndex: 0,
  currentPageIndex: 1,
};

export const tableDataAtom = atom(initialState);

import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import {
  readOnlyCloudTasksAtom,
  updateCloudTasksAtom,
} from '../../atoms/config';

export const useCloudTask = (projectId: string) => {
  const [cloudTasks, getCloudTasks] = useAtom(readOnlyCloudTasksAtom);
  const [, updateCloudTasks] = useAtom(updateCloudTasksAtom);
  const [isEnabledCloudTasks, setIsEnabledCloudTasks] = useState<
    boolean | undefined
  >(undefined);

  const updateCloudTask = useCallback(
    async (checked: boolean) => {
      await updateCloudTasks({ projectId, isAdding: checked });
      setIsEnabledCloudTasks(checked);
    },
    [projectId],
  );

  useEffect(() => {
    if (cloudTasks.data) {
      const hasProjectIdInAllCloudTasks = Object.values(cloudTasks.data).every(
        (ids) => ids.includes(projectId),
      );
      setIsEnabledCloudTasks(hasProjectIdInAllCloudTasks);
    }
  }, [cloudTasks.data, projectId]);

  useEffect(() => {
    getCloudTasks();
  }, []);

  return {
    isEnabledCloudTasks,
    isLoading: cloudTasks.loading,
    updateCloudTask,
  };
};

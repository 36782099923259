import {
  BACKGROUND_KEYS,
  BACKGROUND_STATUS,
  BackgroundCheck,
} from '@agentsonly/models';
import { Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import {
  getAgentVerificationPoliceClearancesDirectory,
  getImagePaths,
  getVerificationImageDirectory,
} from '../../../../../firebase/storage/storage';
import {
  BackgroundCheckKeysLabels,
  BackgroundCheckStatusLabels,
} from '../../consts';
import { CheckItem } from '../CheckItem';
import { CheckItemContainer } from '../Containers';
import { ImageCarousel } from './carousel';

type Props = {
  agentId: string;
  backgroundCheck: BackgroundCheck;
  onBackgroundCheckChange: (backgroundCheck: BackgroundCheck) => void;
  disabled: boolean;
};

const getImageURLs = async (agentId: string): Promise<string[]> => {
  const policeClearancesDirectory =
    getAgentVerificationPoliceClearancesDirectory(agentId);
  const verificationDirectory = getVerificationImageDirectory(agentId);
  const imagePaths = await Promise.all([
    getImagePaths(verificationDirectory),
    getImagePaths(policeClearancesDirectory),
  ]);

  return imagePaths.flat();
};

const BACKGROUND_CHECK_KEYS = [
  BACKGROUND_KEYS.GOVERNMENT_ID,
  BACKGROUND_KEYS.POLICE_CLEARANCE,
];

export const IDCheck: React.FC<Props> = ({
  agentId,
  backgroundCheck,
  onBackgroundCheckChange,
  disabled,
}) => {
  const [imageList, setImageList] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const urls = await getImageURLs(agentId);
      setImageList(urls);
    })();
  }, [agentId]);

  const onChange = useCallback(
    (key: string, newValue: string) => {
      onBackgroundCheckChange({ ...backgroundCheck, [key]: newValue });
    },
    [backgroundCheck, onBackgroundCheckChange],
  );

  return (
    <>
      <Col span={12}>
        <ImageCarousel imageList={imageList} />
      </Col>
      <CheckItemContainer>
        {BACKGROUND_CHECK_KEYS.map((key) => {
          const value =
            backgroundCheck[key] ?? BACKGROUND_STATUS.PENDING_REVIEW;
          return (
            <CheckItem
              key={key}
              id={key}
              title={BackgroundCheckKeysLabels[key]}
              value={BackgroundCheckStatusLabels[value]}
              onChange={onChange}
              disabled={disabled}
            />
          );
        })}
      </CheckItemContainer>
    </>
  );
};

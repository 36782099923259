import { UploadFile } from 'antd/lib/upload/interface';
import firebase from 'firebase/compat/app';
import { uploadBytes } from 'firebase/storage';

import { firebaseConfig, storage } from '../firebase';

export function createFileToDownload(
  title: string,
  body: string,
  type = 'text/plain',
) {
  const element = document.createElement('a');
  const file = new Blob([body], { type });
  element.href = URL.createObjectURL(file);
  element.download = title;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
}

const CSV_FILE_TYPE = ['text/csv', 'application/vnd.ms-excel'];

export const isCSV = (file: File | UploadFile<File>) => {
  if (!file.type) {
    return false;
  }
  const isCSVType = CSV_FILE_TYPE.includes(file.type);
  const isCSVExtension = file.name.split('.').slice(-1)?.[0] === 'csv';
  return isCSVType && isCSVExtension;
};

const MAX_NUM_OF_FILES_RETURNED = 100;

export const getLatestFileFromStorage = async (
  fileNamePattern: RegExp,
  bucketName: string,
) => {
  const folderRef = storage.refFromURL(
    `gs://${firebaseConfig.storageBucket}/${bucketName}`,
  );
  const fileNames = [];
  const metadataPromises = [];
  let token: string | null = '';
  while (token !== null) {
    // eslint-disable-next-line no-await-in-loop
    const files: firebase.storage.ListResult = await folderRef.list(
      token === ''
        ? { maxResults: MAX_NUM_OF_FILES_RETURNED }
        : { maxResults: MAX_NUM_OF_FILES_RETURNED, pageToken: token },
    );
    for (let i = 0; i < files.items.length; i += 1) {
      const itemRef = files.items[i];
      if (itemRef.name.match(fileNamePattern)) {
        fileNames.push(itemRef.name);
        metadataPromises.push(itemRef.getMetadata());
      }
    }
    token = files.nextPageToken;
  }
  if (fileNames.length === 0) {
    throw new Error('No file is available');
  }

  const metadata = await Promise.all(metadataPromises);
  const listTimeCreated = metadata.map(({ timeCreated }) =>
    Date.parse(timeCreated),
  );
  let maxTimeCreated = listTimeCreated[0];
  let maxTimeCreatedIdx = 0;
  for (let i = 1; i < listTimeCreated.length; i += 1) {
    if (maxTimeCreated < listTimeCreated[i]) {
      maxTimeCreated = listTimeCreated[i];
      maxTimeCreatedIdx = i;
    }
  }

  return fileNames[maxTimeCreatedIdx];
};

export const uploadFile = async (
  bucketName: string,
  blob: Blob | File,
  fileName: string,
): Promise<string | undefined> => {
  const fileRef = storage.refFromURL(
    `gs://${firebaseConfig.storageBucket}/${bucketName}/${fileName}`,
  );
  try {
    const snapshot = await uploadBytes(fileRef, blob);
    return snapshot.metadata.fullPath;
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

import styled from 'styled-components';

export const StyledBadge = styled.div<{ $backgroundColor?: string }>`
  border-radius: 20px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

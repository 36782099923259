import { useCallback, useEffect, useReducer, useState } from 'react';

import {
  getAbsoluteVoiceRecordPath,
  getDownloadURL,
} from '../../utils/storage';

export const useVoicePlayer = () => {
  const [player, setPlayer] = useState<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!player) return;

    player.addEventListener('pause', () => {
      setIsPlaying(false);
    });
    player.addEventListener('playing', () => {
      setIsPlaying(true);
    });
    player.addEventListener('loadeddata', () => {
      player.play();
    });
  }, [player]);

  const toggle = useCallback(
    async (agentId?: string) => {
      if (!agentId) return;
      if (player) {
        if (isPlaying) {
          player.pause();
        } else {
          player.currentTime = 0;
          player.play();
        }
        return;
      }
      const voiceRecordPath = getAbsoluteVoiceRecordPath(agentId);
      try {
        const url = await getDownloadURL(voiceRecordPath);
        setPlayer(new Audio(url));
      } catch (err) {
        console.log(err);
      }
    },
    [player, isPlaying],
  );

  return [toggle, isPlaying, player] as const;
};

export const useVoiceProgressPlayer = () => {
  const [toggle, isPlaying, player] = useVoicePlayer();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const handleTimeUpdate = () => {
      forceUpdate();
    };
    player?.addEventListener('timeupdate', handleTimeUpdate);
  }, [player]);

  const progress = ((player?.currentTime || 0) / (player?.duration || 0)) * 100;
  const remaining = (player?.duration || 0) - (player?.currentTime || 0);

  return [toggle, isPlaying, remaining, progress] as const;
};

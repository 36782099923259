import styled from 'styled-components';

import { BaseColors } from '../../../../../shared/ui/colors';
import { hexToRGB } from '../../../../../utils/color';

export const StyledTextarea = styled.textarea`
  height: calc(100vh - 480px);
  border: 2px solid ${BaseColors.dividers};
  border-top: 1px dashed ${BaseColors.neutral40};
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  caret-color: ${BaseColors.primary};
  resize: none;
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${BaseColors.neutral80};

  &::-moz-selection {
    /* Code for Firefox */
    color: ${BaseColors.white};
    background: ${BaseColors.primary};
  }
  &::selection {
    color: ${BaseColors.white};
    background: ${BaseColors.primary};
  }
  &:focus-visible {
    outline: none;
  }
`;

export const PlaceholderContainer = styled.div<{
  $disabled?: boolean;
}>`
  background-color: ${({ $disabled }) =>
    hexToRGB($disabled ? BaseColors.secondaryLight : BaseColors.primary, 0.1)};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border: 2px solid ${BaseColors.dividers};
  border-bottom: none;
  padding: 24px;
`;

export const StyledBadge = styled.div<{
  $inUse?: boolean;
  $disabled?: boolean;
}>`
  background-color: ${({ $inUse }) =>
    $inUse ? BaseColors.primary : BaseColors.white};
  color: ${({ $inUse, $disabled }) => {
    if ($inUse) return BaseColors.white;
    if ($disabled) return BaseColors.secondaryLight;
    return BaseColors.primary;
  }};
  border: 1px solid
    ${({ $disabled }) =>
      $disabled ? BaseColors.secondaryLight : BaseColors.primary};
  border-radius: 50px;
  padding: 3px 14px;
  cursor: pointer;
  ${({ $disabled }) => $disabled && { 'pointer-events': 'none' }};
  &:hover {
    opacity: 0.7;
  }
`;

import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

import { hexToRGB } from '../../../../../../utils/color';

const { Text } = Typography;

export const Container = styled(Col)`
  max-width: 360px;
  background: ${hexToRGB('#dae4ea', 0.3)};

  box-shadow:
    0px 0px 2px rgba(110, 129, 141, 0.1),
    0px 4px 4px rgba(110, 129, 141, 0.05);
  border-radius: 8px;
  padding: 0 0 12px;
`;

export const PaddingContainer = styled(Row)`
  padding: 24px;
`;

export const StyledText = styled(Text)`
  width: 50px;
  text-align: center;
  margin: 0 0 24px;
`;

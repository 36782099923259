import { Divider, Typography } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../shared/ui/colors';

const { Title } = Typography;

export const StyledDivider = styled(Divider)`
  border-color: ${BaseColors.neutral40};
  margin: 32px 0;
`;

export const Heading = styled(Title)`
  &&& {
    margin: 24px 0;
  }
`;

import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';

import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';

export const Star = styled.span`
  color: #08a17c;
  padding: 0 5px;
  font-weight: 700;
  line-height: 18px;
`;

export const StyledLeftSettingsCol = styled(Col)`
  padding: 20px 32px;
`;

export const StyledBehaviourContainer = styled(Row)`
  border-radius: 8px;
  border: 1px solid #dae4ea;
  box-shadow:
    0px 0px 2px rgba(110, 129, 141, 0.1),
    0px 4px 4px rgba(110, 129, 141, 0.05);
`;

export const StyledCancelButton = styled(Button)`
  background-color: white;
  color: #203d50;
  border-color: white;
  width: 180px;
  height: 45px;

  &:hover {
    border-color: #203d50;
    color: #203d50;
  }
`;

export const StyledDeleteButton = styled(Button)`
  background-color: ${BaseColors.alertWarning};
  width: 180px;
  height: 45px;
  color: white;

  &:hover {
    border-color: ${BaseColors.alertWarning};
    color: ${BaseColors.alertWarning};
  }
`;

export const SectionTitle = styled(Text)`
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: ${BaseColors.black};
`;

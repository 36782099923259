import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Typography } from 'antd';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getAgentPaymentsAtom, paymentsAtom } from '../../atoms/walletPayments';
import { getAgentByEmail } from '../../firebase/agent';
import { Button } from '../../shared/ui/Button';
import { Form } from '../../shared/ui/form/Form';
import { FormInput } from '../../shared/ui/form/FormInput';
import { csvTransform } from '../../utils/csv';
import { formatAmount } from '../../utils/currency';
import { createFileToDownload } from '../../utils/file';
import { walletAPI } from '../../utils/firebase/walletAPI';
import { emailSchema } from '../../utils/validator';
import { PaymentsList } from './components/PaymentsList';

const { Text } = Typography;

export const Payments = () => {
  const [id, setId] = useState('');
  const [page, setPage] = useState(1);
  const form = useForm({
    resolver: yupResolver(emailSchema),
    defaultValues: { email: '' },
  });
  const [paymentsValue] = useAtom(paymentsAtom);
  const [, getAgentPayments] = useAtom(getAgentPaymentsAtom);

  const agentPayments = paymentsValue.data?.record?.[id]?.[page] ?? [];
  const totalCount = paymentsValue.data?.count ?? 0;

  const handleSubmit = form.handleSubmit(async (data) => {
    const { email } = data;
    try {
      const agent = await getAgentByEmail(email);
      const resetPage = 1;
      if (!agent) {
        throw Error('Agent not found');
      }

      setId(agent.id);
      setPage(resetPage);
      getAgentPayments({ id: agent.id, page: resetPage });
    } catch (e) {
      if (e instanceof Error) {
        form.setError('email', { message: e.message });
      } else {
        throw e;
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handlePageChange = (page: number) => {
    setPage(page);
    getAgentPayments({ id, page });
  };

  const handleDownloadClick = async () => {
    const {
      data: { txs },
    } = await walletAPI.getTxs(id);

    const header = [
      'date',
      'name',
      'paymentReference (Withdrawal Reference)',
      'receiverType',
      'amountCurrency',
      'sourceCurrency',
      'targetCurrency',
      'bankCode',
      'accountNumber',
      'addressCountryCode',
      'addressCity',
      'addressFirstLine',
      'addressPostCode',
      'clabe',
      'amount',
      'paymentStatus',
    ];

    const body = txs.map((tx) => [
      format(new Date(tx.date), 'PP'),
      `${tx.firstName} ${tx.lastName}`,
      tx.referenceNumber,
      'PERSON',
      'target',
      'USD',
      tx.currency,
      tx.bankCode ?? '',
      tx.account,
      tx.countryCode,
      tx.city,
      tx.street,
      tx.zip,
      tx.clabe ?? '',
      formatAmount(tx.amount),
      tx.txStatus,
    ]);

    const data = csvTransform(header, body);

    createFileToDownload('payments.csv', data);
  };

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Text>Search for an Agent</Text>
              </Col>
              <Col span={24}>
                <FormProvider {...form}>
                  <Form onFinish={handleSubmit}>
                    <Row gutter={[14, 0]}>
                      <Col>
                        <FormInput name="email" placeholder="Email" />
                      </Col>
                      <Col>
                        <Button
                          loading={form.formState.isSubmitting}
                          htmlType="submit"
                          type="primary"
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              disabled={!id || totalCount === 0}
              onClick={handleDownloadClick}
            >
              Download CSV File
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <PaymentsList
          list={agentPayments}
          totalCount={totalCount}
          loading={paymentsValue.loading}
          page={page}
          onPageChange={handlePageChange}
        />
      </Col>
    </Row>
  );
};

import { Agent } from '@agentsonly/models';
import { atom } from 'jotai';

import { db } from '../firebase';
import { getAgentById, getAgentsById } from '../firebase/agent';
import { RootCollections } from '../firebase/consts';

export const agentByIdAtom = atom<Record<string, Agent>>({});

export const fetchAgentsLoadingAtom = atom<boolean | undefined>(undefined);

export const updateAgentStateAtom = atom(
  null,
  async (_, set, { id, ...data }: Partial<Agent> & Pick<Agent, 'id'>) => {
    set(agentByIdAtom, (prev) => ({
      ...prev,
      [id]: { ...prev[id], ...data },
    }));
  },
);

export const updateAgentAtom = atom(
  null,
  async (_, set, { id, ...data }: Partial<Agent> & Pick<Agent, 'id'>) => {
    await db
      .collection(RootCollections.Agents)
      .doc(id)
      .set(data, { merge: true });
    set(updateAgentStateAtom, { id, ...data });
  },
);

export const getAgentByIdAtom = atom(null, async (get, set, id: string) => {
  try {
    set(fetchAgentsLoadingAtom, true);
    const agent = await getAgentById(id);
    if (agent) {
      set(agentByIdAtom, (prev) => ({
        ...prev,
        [id]: agent,
      }));
    }
  } catch (e) {
    console.warn(e);
  } finally {
    set(fetchAgentsLoadingAtom, false);
  }
});

export const getAgentsByIdAtom = atom(null, async (get, set, ids: string[]) => {
  try {
    set(fetchAgentsLoadingAtom, true);

    const agents = get(agentByIdAtom);
    const diff = ids.filter((id) => !agents[id]);

    const response = await getAgentsById(diff);
    const newAgents = response.reduce<Record<string, Agent>>(
      (record, agent) => {
        // eslint-disable-next-line no-param-reassign
        record[agent.id] = agent;
        return record;
      },
      {},
    );
    set(agentByIdAtom, (prev) => ({ ...prev, ...newAgents }));
  } catch (e) {
    console.warn(e);
  } finally {
    set(fetchAgentsLoadingAtom, false);
  }
});

export const agentsTableAtom = atom<Agent[]>([]);

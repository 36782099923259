import { Col, Form, Typography } from 'antd';
import get from 'lodash.get';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { useQueryInput } from '../../../hooks/useQueryInput';
import { Placeholder } from './Placeholder';
import { StyledTextarea } from './styledComponents';

const { Title } = Typography;

type Props = {
  name?: string;
  title?: string;
  disabled?: boolean;
  fetchPlaceholders?: () => Promise<string[]>;
  disablePlaceholder?: boolean;
};

export const QueryInput: FC<Props> = ({
  name = 'query',
  title = 'Query',
  disabled,
  fetchPlaceholders,
  disablePlaceholder = false,
}) => {
  const { insertText, control, textareaRef } = useQueryInput();

  return (
    <Col span={24}>
      <Title level={5}>{title}</Title>
      <Spacer axis="vertical" size={16} />
      {!disablePlaceholder && (
        <Placeholder
          handleClick={(textToInsert: string) => insertText(name, textToInsert)}
          name={name}
          disabled={disabled}
          fetchPlaceholders={fetchPlaceholders}
        />
      )}
      <Controller
        render={({ field: { onChange, onBlur, value }, formState }) => {
          // Handle nested object errors
          const error = get(formState.errors, name)?.message as string;
          return (
            <Form.Item
              labelAlign="left"
              validateStatus={error ? 'error' : undefined}
              help={error}
            >
              <StyledTextarea
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={textareaRef}
                placeholder={disabled ? '' : 'Start typing here'}
                disabled={disabled}
              />
            </Form.Item>
          );
        }}
        name={name}
        control={control}
      />
    </Col>
  );
};

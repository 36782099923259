import { WalletTxAdmin } from '../../shared/models/WalletTx';
import { firebaseBaseAPI } from './baseAPI';

export type GetTxsData = { count: number; txs: WalletTxAdmin[] };
const getTxs = (agent: string, page?: number) => {
  let url = `/admin/wallet/txs/${agent}/withdrawals`;
  if (page) {
    url += `?page=${page}`;
  }
  return firebaseBaseAPI.get<GetTxsData>(url);
};

const getWithdrawals = (page?: number) => {
  let url = `/admin/wallet/txs`;
  if (page) {
    url += `?page=${page}`;
  }
  return firebaseBaseAPI.get<GetTxsData>(url);
};

export const walletAPI = { getTxs, getWithdrawals };

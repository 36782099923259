import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Resource } from '../../../../firebase/models/Coaching';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { Form } from '../../../../shared/ui/form/Form';
import { FormInput } from '../../../../shared/ui/form/FormInput';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { CoachingResourceIcon } from '../../consts';
import { createResource, deleteResource, updateResource } from '../../helpers';
import { ResourceListIcon } from '../ResourceListIcon';
import { ColorfulTitle } from '../styledComponents';
import { resourceValidator } from './consts';
import { ButtonSection } from './styledComponents';

type Props = {
  onClose: () => void;
  onOk: () => void;
  resources: Resource[];
  projectId: string;
  selectedResource?: Resource;
};

export const ResourceForm: React.FC<Props> = ({
  onClose,
  resources,
  onOk,
  projectId,
  selectedResource,
}) => {
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(resourceValidator),
    context: {
      resourceId: selectedResource?.id,
      projectId,
    },
    defaultValues: {
      title: selectedResource?.title ?? '',
      link: selectedResource?.link ?? '',
      icon: selectedResource?.icon ?? '',
    },
  });

  const {
    formState: { isValid, isDirty, isSubmitting },
  } = form;

  const isEditing = selectedResource !== undefined;

  const handleIconSelected = async (icon: CoachingResourceIcon) => {
    form.setValue('icon', icon);
    await form.trigger('icon');
  };

  const handleSubmitForm = () => {
    form.handleSubmit(async (data) => {
      const { title, icon, link } = data;
      if (isEditing) {
        await updateResource(projectId, {
          title,
          icon,
          link,
          id: selectedResource?.id,
        });
        ShowNotification('Updated Resource successfully!', IconType.Success);
      } else {
        await createResource(projectId, {
          title,
          icon,
          link,
        });
        ShowNotification('Created Resource successfully!', IconType.Success);
      }
      onOk();
    })();
  };

  const handleDelete = async () => {
    if (selectedResource?.id) {
      await deleteResource(projectId, selectedResource.id);
      ShowNotification('Deleted Resource Successfully!', IconType.Success);
      onOk();
    }
  };

  const getActionButtons = () => {
    const disabled = !isValid || !isDirty;
    if (isEditing) {
      return (
        <>
          <Button
            loading={isSubmitting}
            type="primary"
            danger
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Spacer size={16} axis="horizontal" />
          <Button
            loading={isSubmitting}
            disabled={disabled}
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>
        </>
      );
    }

    return (
      <>
        <Button onClick={onClose}>Cancel</Button>
        <Spacer size={16} axis="horizontal" />
        <Button
          loading={isSubmitting}
          disabled={disabled}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>
      </>
    );
  };

  const handlePasteLink = (e: any) => {
    form.setValue('link', e.clipboardData.getData('text'), {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    e.preventDefault();
  };

  return (
    <Modal
      open
      closable
      onCancel={onClose}
      footer={null}
      width={800}
      title={
        <ColorfulTitle color={BaseColors.neutral100} level={2}>
          {isEditing ? 'Editing Resource' : 'Create a New Resource'}
        </ColorfulTitle>
      }
    >
      <FormProvider {...form}>
        <Form disabled={false} onFinish={handleSubmitForm}>
          <FormInput
            name="title"
            type="string"
            label="Resource Name (Maximum: 24 Characters)"
          />
          <FormInput
            name="link"
            type="string"
            label="Resource Link"
            onPaste={handlePasteLink}
          />
          <ResourceListIcon
            onIconSelected={handleIconSelected}
            resources={resources}
            selectedResource={selectedResource}
          />
          <ButtonSection>{getActionButtons()}</ButtonSection>
        </Form>
      </FormProvider>
    </Modal>
  );
};

import { App as AntdApp, Spin } from 'antd';
import { type FC, useEffect } from 'react';

import { DownloadIcon } from '../../../../shared/assets';
import { BaseColors } from '../../../../shared/ui/colors';
import {
  DownloadIconWrapper,
  StyledDownloadButton,
  StyledDownloadIcon,
} from '../../styledComponents';
import { useDownloadFeedbackSubmission } from './hooks';

type Props = {
  projectId: string;
  date: string;
  disabled: boolean;
};
export const DownloadCell: FC<Props> = ({ projectId, date, disabled }) => {
  const { notification } = AntdApp.useApp();
  const { loading, error, download } = useDownloadFeedbackSubmission(
    projectId,
    date,
  );

  useEffect(() => {
    if (error) {
      notification.error({ message: 'Something went wrong' });
    }
  }, [error]);

  return (
    <DownloadIconWrapper>
      <Spin spinning={loading}>
        <StyledDownloadButton
          type="button"
          disabled={disabled}
          $disabled={disabled}
          onClick={download}
        >
          <StyledDownloadIcon
            component={DownloadIcon}
            $stroke={BaseColors.secondaryLight}
          />
        </StyledDownloadButton>
      </Spin>
    </DownloadIconWrapper>
  );
};

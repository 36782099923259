import { SwitchChangeEventHandler } from 'antd/lib/switch';
import React, { ReactNode, useCallback } from 'react';

import { useField } from '../../../../hooks/useField';
import { Switch } from './Switch';

type Props = {
  name: string;
  checkedChildren?: ReactNode;
  unCheckedChildren?: ReactNode;
  label?: string;
  onChange?: SwitchChangeEventHandler;
  disabled?: boolean;
};

export const FormSwitch: React.FC<Props> = ({
  label,
  name,
  onChange,
  checkedChildren,
  unCheckedChildren,
  disabled,
}) => {
  const { controller } = useField({ name });

  const handleChange = useCallback<SwitchChangeEventHandler>(
    (checked, e) => {
      controller.field.onChange(checked);
      controller.field.onBlur();
      onChange?.(checked, e);
    },
    [controller, onChange],
  );

  return (
    <Switch
      disabled={disabled}
      label={label}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      onChange={handleChange}
      checked={controller.field.value}
    />
  );
};

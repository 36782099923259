import { Error } from '../../../../shared/ui/themes/colors/error';
import { Success } from '../../../../shared/ui/themes/colors/success';
import { Warning } from '../../../../shared/ui/themes/colors/warning';

export const PASSED = 'Passed';
export const FAILED = 'Failed';
export const NEEDS_VALIDATION = 'Needs Validation';

export const getColorAndBgColor = (status: string) => {
  switch (status) {
    case PASSED:
      return { color: Success[700], bgColor: Success[50] };
    case FAILED:
      return { color: Error[700], bgColor: Error[50] };
    default:
      return { color: Warning[700], bgColor: Warning[50] };
  }
};

import { Row, Typography } from 'antd';
import React, { ComponentType, FC } from 'react';

import { ProjectStatus } from '../../../firebase/models/AgentsProject';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { ReactComponent as ApprovedIcon } from './assets/ic-approved.svg';
import { ReactComponent as PendingIcon } from './assets/ic-pending.svg';
import { ReactComponent as RemovedIcon } from './assets/ic-removed.svg';
import { ReactComponent as TrainingIcon } from './assets/ic-training.svg';
import { StyledText } from './styledComponents';

type Props = {
  status: ProjectStatus;
  projectNames: string[] | undefined;
};

const iconMapping: Partial<Record<ProjectStatus, ComponentType>> = {
  [ProjectStatus.Pending]: PendingIcon,
  [ProjectStatus.Training]: TrainingIcon,
  [ProjectStatus.Approved]: ApprovedIcon,
  [ProjectStatus.Removed]: RemovedIcon,
};

const projectStatusLabels: Partial<Record<ProjectStatus, string>> = {
  [ProjectStatus.Pending]: 'Pending',
  [ProjectStatus.Training]: 'Training',
  [ProjectStatus.Approved]: 'Approved',
  [ProjectStatus.Removed]: 'Removed',
};

const { Paragraph } = Typography;

export const ProjectsList: FC<Props> = ({ status, projectNames }) => (
  <>
    <Row align="middle">
      {iconMapping[status] !== undefined &&
        React.createElement(iconMapping[status]!!)}
      <Spacer axis="horizontal" size={8} />
      <StyledText>{projectStatusLabels[status]}</StyledText>
    </Row>
    <Spacer axis="vertical" size={16} />
    <Row>
      {!projectNames || projectNames.length <= 0 ? (
        '-'
      ) : (
        <ul>
          {projectNames?.sort().map((name) => {
            const key = name.replace(/\s+/g, '');
            return (
              <li key={key}>
                <Paragraph strong>{name}</Paragraph>
              </li>
            );
          })}
        </ul>
      )}
    </Row>
  </>
);

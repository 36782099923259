import { atom } from 'jotai';

import { AiPrompt, AiPromptChatConfig } from '../firebase/models/Prompts';
import {
  getPromptChatConfig,
  updatePromptChatConfig,
} from '../firebase/project/prompts';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const chatConfigAsyncValueAtom = atom<
  AsyncValue<Record<string, AiPromptChatConfig>>
>({
  loading: false,
});

export const chatConfigAtom = asyncAtom<
  Record<string, AiPromptChatConfig>,
  string
>(chatConfigAsyncValueAtom, async (projectId, previous) => {
  if (previous.data?.[projectId]) {
    return previous.data;
  }

  const promptChatConfig = await getPromptChatConfig(projectId);
  if (promptChatConfig) {
    return { ...previous.data, [projectId]: promptChatConfig };
  }

  return previous.data ?? {};
});

type UpdatePromptChatConfigPayload = {
  type: 'agents' | 'clients';
  projectId: string;
  data: Record<string, AiPrompt>;
};

export const updateChatConfigAtom = asyncAtom<
  Record<string, AiPromptChatConfig>,
  UpdatePromptChatConfigPayload
>(chatConfigAsyncValueAtom, async ({ type, data, projectId }, previous) => {
  await updatePromptChatConfig(projectId, { [type]: data });

  return {
    ...(previous.data ?? {}),
    [projectId]: { ...(previous.data?.[projectId] ?? {}), [type]: data },
  };
});

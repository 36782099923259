import { Form as AntdForm, Input, Table, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';

import { Referral } from '../../../firebase/models/interfaces';
import {
  getAcceptedReferrals,
  getAcceptedReferralsByEmail,
} from '../../../firebase/referrals';
import { Button } from '../../../shared/ui/Button';
import { Form } from '../../../shared/ui/form/Form';
import { ReferralModal } from './components';

type Props = {
  disabled: boolean;
};

type FormValues = {
  agentId: string;
  amount: number;
  description: string;
};

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'refereeFirstName',
    dataIndex: 'refereeFirstName',
    key: 'refereeFirstName',
  },
  {
    title: 'refereeLastName',
    dataIndex: 'refereeLastName',
    key: 'refereeLastName',
  },
  {
    title: 'referrerEmail',
    dataIndex: 'referrerEmail',
    key: 'referrerEmail',

    render: (email: string, referral: Referral) => (
      <Tooltip title={referral.referrerId}>
        <Typography.Text>{email}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'refereeEmail',
    dataIndex: 'refereeEmail',
    key: 'refereeEmail',
  },
  {
    title: 'planId',
    dataIndex: 'planId',
    key: 'planId',
  },
];

const useReferrals = () => {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const fetchReferrals = async ({ agentId }: FormValues) => {
    const data = await getAcceptedReferrals(agentId);
    const children = await Promise.all(
      data.map((r) => getAcceptedReferralsByEmail(r.refereeEmail)),
    );
    const acceptedReferrals = data.map((r, i) => ({
      ...r,
      children: children[i].map((c) => ({ ...c, key: c.id })),
      key: r.id,
    }));
    setReferrals(acceptedReferrals);
  };

  return [referrals, fetchReferrals] as const;
};

export const Referrals: React.FC<Props> = ({ disabled }) => {
  const [referrals, fetchReferrals] = useReferrals();
  const [selectedReferral, setSelectedReferral] = useState<Referral>();
  const clickRow = (referral: Referral) => {
    setSelectedReferral(referral);
  };

  const handleClose = () => {
    setSelectedReferral(undefined);
  };
  const [form] = useForm();

  return (
    <Form
      form={form}
      wrapperCol={{ span: 16 }}
      onFinish={fetchReferrals}
      disabled={disabled}
    >
      <AntdForm.Item
        label="Agent Id"
        name="agentId"
        rules={[{ required: true, message: 'Please input agentId!' }]}
      >
        <Input type="string" />
      </AntdForm.Item>
      <AntdForm.Item>
        <Button type="primary" htmlType="submit">
          Search
        </Button>
      </AntdForm.Item>
      {referrals.length > 0 && (
        <>
          Referrals:
          <Table
            dataSource={referrals}
            columns={columns}
            onRow={(record) => ({
              onClick: () => {
                clickRow(record);
              },
            })}
            data-testid="admin:Referrals.Referrals:table"
          />
        </>
      )}
      {selectedReferral && (
        <ReferralModal
          disabled={disabled}
          selectedReferral={selectedReferral}
          onClose={handleClose}
          onUpdate={() => fetchReferrals(form.getFieldsValue())}
          data-testid="admin:Referrals.Referrals:selectedReferralModal"
        />
      )}
    </Form>
  );
};

import { atom } from 'jotai';

import { getCloudTasks, updateCloudTasks } from '../firebase/configs';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

type CloudTasks = Record<string, Array<string>>;

const cloudTasksAsyncValueAtom = atom<AsyncValue<CloudTasks>>({
  loading: true,
});

export const readOnlyCloudTasksAtom = asyncAtom(
  cloudTasksAsyncValueAtom,
  getCloudTasks,
);

export const updateCloudTasksAtom = asyncAtom<
  CloudTasks,
  { projectId: string; isAdding: boolean }
>(
  cloudTasksAsyncValueAtom,
  async ({ projectId, isAdding }, prev) => {
    const { data } = prev;

    if (!data) {
      throw new Error('Missing cloud task data');
    }

    const result = Object.keys(data).reduce((acc, cur) => {
      const newData = new Set(data[cur]);

      if (isAdding) {
        newData.add(projectId);
      } else {
        newData.delete(projectId);
      }

      return {
        ...acc,
        [cur]: [...newData],
      };
    }, {} as CloudTasks);

    await updateCloudTasks(result);

    return result;
  },
  true,
);

import { Form as AntdForm, FormInstance, FormProps } from 'antd';
import React, { ReactNode } from 'react';

type Props = FormProps & {
  children: ReactNode;
  innerRef?: React.Ref<FormInstance<any>> | undefined;
};

export const Form: React.FC<Props> = React.memo((props) => {
  const { disabled, onFinish, children, innerRef, ...rest } = props;

  return (
    <AntdForm
      {...rest}
      disabled={disabled}
      onFinish={!disabled ? onFinish : undefined}
      ref={innerRef}
    >
      {children}
    </AntdForm>
  );
});

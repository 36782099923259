import {
  Form as AntdForm,
  Input,
  Modal,
  notification,
  Select as AntSelect,
  Typography,
} from 'antd';

import { getAgentByEmail } from '../../../../firebase/agent';
import {
  Referral,
  ReferralStatus,
} from '../../../../firebase/models/interfaces';
import { updateReferrals } from '../../../../firebase/referrals';
import { Button } from '../../../../shared/ui/Button';
import { Form } from '../../../../shared/ui/form/Form';

const { Title } = Typography;

type Props = {
  selectedReferral: Referral;
  onClose: () => void;
  onUpdated: (referral: Referral) => void;
  disabled: boolean;
};

export const FixReferralModal = ({
  selectedReferral,
  onClose,
  onUpdated,
  disabled,
}: Props) => {
  const updateParent = async ({
    email,
    status,
  }: {
    email: string;
    status: ReferralStatus;
  }) => {
    const agent = await getAgentByEmail(email.trim());
    if (agent) {
      await updateReferrals(selectedReferral.id, {
        referrerId: agent.id,
        referrerEmail: agent.email,
        status,
      });
      onUpdated({
        ...selectedReferral,
        ...{
          referrerId: agent.id,
          referrerEmail: agent.email,
          status,
        },
      });
      notification.open({
        message: 'Success to update referrals',
        type: 'success',
      });
    } else {
      notification.open({
        message: 'Not found the email in referrals or found more than one',
        type: 'error',
      });
    }
    onClose();
  };

  return (
    <Modal
      title={`refereeEmail: ${selectedReferral?.refereeEmail}`}
      open={!!selectedReferral}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form onFinish={updateParent} preserve={false} disabled={disabled}>
        <Title level={4} style={{ color: 'black' }}>
          Updating Parent Email
        </Title>
        <AntdForm.Item
          label="Parent Email"
          name="email"
          initialValue={selectedReferral?.referrerEmail}
          preserve={false}
          rules={[
            {
              type: 'email',
            },
            {
              validator: async (_, email) => {
                if (email === selectedReferral.refereeEmail)
                  throw new Error(
                    'parent email should not be same as referee email',
                  );
              },
            },
          ]}
        >
          <Input type="string" disabled={disabled} />
        </AntdForm.Item>
        <AntdForm.Item
          label="Status"
          name="status"
          initialValue={selectedReferral?.status}
          preserve={false}
          rules={[
            {
              type: 'string',
            },
            {
              validator: async (_, status) => {
                if (Object.values(ReferralStatus).indexOf(status) < 0) {
                  throw new Error(
                    `Invalid. Status must be ${Object.values(
                      ReferralStatus,
                    ).join(', ')}`,
                  );
                }
              },
            },
          ]}
        >
          <AntSelect disabled={!!disabled}>
            {Object.values(ReferralStatus).map((option) => (
              <AntSelect.Option key={option} value={option}>
                {option}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </AntdForm.Item>
        <AntdForm.Item>
          <Button type="primary" htmlType="submit" disabled={disabled}>
            Update
          </Button>
        </AntdForm.Item>
      </Form>
    </Modal>
  );
};

import { Project } from '@agentsonly/models';
import { Col, Radio, Row, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import React, { useCallback, useEffect, useReducer } from 'react';

import { auth } from '../../../firebase';
import { CsvUploadItem } from '../../../firebase/models/Demo';
import { Button } from '../../../shared/ui/Button';
import { ImportCSVModal } from '../../../shared/ui/ImportCSVModal';
import {
  IconType,
  ShowNotification,
} from '../../../shared/ui/ShowNotification';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { parseCSV } from '../../../utils/csv';
import { createFileToDownload } from '../../../utils/file';
import { validateDemoClientCSV } from '../../../utils/validator';
import {
  getProjectStats,
  updateCSVUploadSubmissions,
  updateProjectStats,
} from '../helpers';
import { StyledHeading } from './styledComponents';
import { generateTemplate } from './utils';
import Timestamp = firebase.firestore.Timestamp;
import { CSVData } from './types';

interface SettingsProps {
  timePeriod: 'daily' | 'weekly' | 'monthly';
  data: CsvUploadItem[];
  isOpen: boolean;
  isLoading: boolean;
}

type Props = {
  project: Project;
  disabled: boolean;
};

const columns: ColumnType<CsvUploadItem>[] = [
  {
    title: 'Upload Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: Timestamp) => format(date.toDate(), 'P'),
  },
  {
    title: 'Time Period',
    dataIndex: 'timePeriod',
    key: 'timePeriod',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

export const ClientTweak: React.FC<Props> = ({ project, disabled }) => {
  const [settings, updateSettings] = useReducer(
    (prev: SettingsProps, next: Partial<SettingsProps>) => ({
      ...prev,
      ...next,
    }),
    {
      timePeriod: 'daily',
      data: [],
      isOpen: false,
      isLoading: false,
    },
  );
  const handleLoadData = useCallback(async () => {
    const data = await getProjectStats(project.id);
    updateSettings({ data });
  }, [project.id]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  const handleDownloadCSVTemplate = () => {
    createFileToDownload(
      `Demo-client-tweak-${settings.timePeriod}-template.csv`,
      generateTemplate(settings.timePeriod),
    );
  };

  const handleImportCSVFile = async (file: File | undefined) => {
    const createdBy = await auth().currentUser?.uid;
    if (file === undefined || !createdBy) {
      ShowNotification('File is empty or not authorized', IconType.Error);
      return;
    }

    try {
      const items = await parseCSV<CSVData>(file, {
        transformHeader: (header: string) => header.trim().split(' ')[0],
      });

      await validateDemoClientCSV(settings.timePeriod).validate(items, {
        strict: true,
      });
      await updateProjectStats(project.id, items, settings.timePeriod);
      await updateCSVUploadSubmissions(
        project.id,
        createdBy,
        settings.timePeriod,
      );
      handleLoadData();
    } catch (e) {
      await updateCSVUploadSubmissions(
        project.id,
        createdBy,
        settings.timePeriod,
        'failed',
      );
      ShowNotification((e as Error).message, IconType.Error);
    }
  };

  const openImportModal = () => {
    updateSettings({ isOpen: true });
  };

  const closeImportModal = () => {
    updateSettings({ isOpen: false });
  };

  return (
    <Row>
      <StyledHeading>
        <Col>
          <Typography>Dashboard Data</Typography>
        </Col>
        <Col>
          <Radio.Group
            onChange={(e) => updateSettings({ timePeriod: e.target.value })}
            value={settings.timePeriod}
          >
            <Radio value="daily">Daily</Radio>
            <Radio value="weekly">Weekly</Radio>
            <Radio value="monthly">Monthly</Radio>
          </Radio.Group>
          <Button ghost type="primary" onClick={handleDownloadCSVTemplate}>
            Download Template
          </Button>
          <Button onClick={openImportModal} type="primary" disabled={disabled}>
            Upload Data
          </Button>
        </Col>
      </StyledHeading>
      <Spacer size={24} axis="vertical" />
      <Col span={24}>
        <Row>
          <Spacer size={18} axis="vertical" />
          <Col span={24}>
            <Table
              pagination={{
                pageSize: 12,
                position: ['bottomLeft'],
              }}
              dataSource={settings.data}
              key="achievement"
              rowKey="id"
              columns={columns}
              loading={settings.isLoading}
            />
          </Col>
        </Row>
      </Col>
      <ImportCSVModal
        open={settings.isOpen}
        onUpload={handleImportCSVFile}
        onClose={closeImportModal}
      />
    </Row>
  );
};

import { AxiosResponse } from 'axios';
import { atom } from 'jotai';

import { asyncAtom } from '../utils/atoms';
import {
  AIAssistant,
  aiAssistantsAPI,
  GetAIAssistantsResponse,
} from '../utils/firebase/aiAssistantsAPI';
import { AsyncValue } from '../utils/types';

// OpenAI api cannot get all assistants at once
// They have paginate option however our ui uses select
// It needs to display all the assistants list
// For now, we don't think assistants will create over 100 soon
const DEFAULT_PAGE_SIZE = 100;

const fetchAIAssistants = async (projectId: string) => {
  try {
    const response: AxiosResponse<GetAIAssistantsResponse> =
      await aiAssistantsAPI.getAIAssistants(projectId, DEFAULT_PAGE_SIZE);

    const {
      data: { data: assistants },
    } = response;

    return assistants;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const aiAssistants = atom<AsyncValue<AIAssistant[]>>({ loading: false });

export const fetchAIAssistantsAtom = asyncAtom<AIAssistant[], string>(
  aiAssistants,
  fetchAIAssistants,
);

import { useState } from 'react';

import { getErrorMessage } from '../../../../shared/utils/error';
import { coachingAPI } from '../../../../utils/firebase/coachingAPI';

export const useDownloadFeedbackSubmission = (
  projectId: string,
  formattedDate: string,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const download = async () => {
    try {
      setLoading(true);
      await coachingAPI.downloadFeedbackSubmissionFile(
        projectId,
        formattedDate,
      );
    } catch (err) {
      console.error(getErrorMessage(err));
      setError('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, download };
};

import type { ContactRecord } from '@agentsonly/models';

import { db } from '..';
import { ProjectContactRecordsCollections, RootCollections } from '../consts';
import { CallRecordTemplate } from '../models/contactRecord';
import { generalConverter } from '../utils';

const getCTRCollectionRef = (instanceId: string) =>
  db
    .collection(RootCollections.ProjectContactRecords)
    .doc(instanceId)
    .collection(ProjectContactRecordsCollections.ContactRecords);

export const saveCallRecord = (
  instanceId: string,
  newCallRecord: CallRecordTemplate,
) => {
  const { ContactId } = newCallRecord.original;
  return getCTRCollectionRef(instanceId)
    .withConverter(generalConverter<CallRecordTemplate>())
    .doc(ContactId)
    .set(newCallRecord, { merge: true });
};

export const getMissingCLAInCTRs = async (
  startDate: Date,
  endDate: Date,
  instanceId?: string,
) => {
  if (!instanceId) return [];
  const ctrRefs = await getCTRCollectionRef(instanceId)
    .withConverter(generalConverter<ContactRecord>({ convertTimestamp: true }))
    .where('missingCLARecord', '==', true)
    .where('original.DisconnectTimestamp', '>=', startDate)
    .where('original.DisconnectTimestamp', '<=', endDate)
    .get();

  return ctrRefs.docs.map((d) => d.data());
};

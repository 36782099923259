import firebase from 'firebase/compat/app';

import { chunk } from '../../utils';
import { RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';
import { ClientUser } from './model';

export const getClientById = async (clientId: string) => {
  const snapshot = await db
    .collection(RootCollections.ClientUsers)
    .doc(clientId)
    .withConverter(generalConverter<ClientUser>())
    .get();
  return snapshot.data();
};

export const getClientsById = async (ids: string[]) => {
  const results = await Promise.all(
    chunk(ids, 10).map((chunkIds) =>
      db
        .collection(RootCollections.ClientUsers)
        .where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds)
        .withConverter(generalConverter<ClientUser>())
        .get(),
    ),
  );

  return results.map((result) => result.docs.map((doc) => doc.data())).flat();
};

export const getClientByEmail = async (email: string) => {
  const snapshots = await db
    .collection(RootCollections.ClientUsers)
    .where('email', '==', email)
    .get();

  if (!snapshots.empty) {
    return {
      ...(snapshots.docs[0].data() as ClientUser),
      id: snapshots.docs[0].id,
    } as ClientUser;
  }
  return undefined;
};

import axios from 'axios';

import { auth } from '../../firebase';
import { getCloudRunUrl } from '../cloudRun';

export const firebaseBaseAPI = axios.create({
  baseURL: getCloudRunUrl('ao-wallet'),
  headers: {
    'Content-Type': 'application/json',
  },
});

firebaseBaseAPI.interceptors.request.use(async (req) => {
  const token = await auth().currentUser?.getIdToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { FC } from 'react';

import type { AiProvider } from '../../../../../firebase/models/Prompts';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { PROVIDER_MODEL_MAP } from '../../../const';
import { StyledSelect } from '../styledComponents';

type Props = {
  fieldName: string;
  provider?: AiProvider;
};

const getModelOptions = (provider: AiProvider) =>
  PROVIDER_MODEL_MAP[provider]?.map((modelName) => ({
    label: modelName,
    value: modelName,
  })) ?? [];

const toolTipText: Partial<Record<AiProvider, string>> = {
  openai: `GPT-4: Limited beta	A set of models that improve on GPT-3.5 and can understand as well as generate natural language or code.
  GPT-3.5:	A set of models that improve on GPT-3 and can understand as well as generate natural language or code`,
};

export const Model: FC<Props> = ({ provider = 'openai', fieldName }) => {
  const options = getModelOptions(provider);
  return (
    <Col span={5}>
      <Row>
        Model
        <Spacer axis="horizontal" size={4} />
        <Tooltip title={toolTipText[provider]}>
          <InfoCircleFilled style={{ color: 'black' }} />
        </Tooltip>
      </Row>
      <Spacer size={4} axis="vertical" />
      <StyledSelect
        name={fieldName}
        placeholder="Choose a model"
        options={options}
      />
    </Col>
  );
};

import { Agent, BackgroundCheck, ISOCountryKeys } from '@agentsonly/models';
import React, { useState } from 'react';

import { StyledP4Text } from '../../../../shared/ui/text/styledComponents';
import { StyledXsTitle } from '../../../../shared/ui/title/styledComponents';
import { StyledButton, StyledSpace } from '../styledComponents';

type Props = {
  agent: Agent;
  backgroundCheck: BackgroundCheck;
  onCompleted: () => Promise<void>;
  disabled: boolean;
};

export const ReviewCompleted: React.FC<Props> = ({
  agent,
  backgroundCheck,
  onCompleted,
  disabled,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const isValid = Object.entries(backgroundCheck)
    .filter(([key]) => key !== 'allPassed')
    .every(([, value]) => !!value);

  const handleClick = async () => {
    try {
      setLoading(true);
      const { countryCode } = agent.address;
      if (
        countryCode === ISOCountryKeys.CA ||
        countryCode === ISOCountryKeys.PH
      ) {
        if (backgroundCheck.policeClearance === 'pending') {
          setError('Police Clearance status should not be pending');
          throw new Error(
            'CA and PH agent must review the police clearance in this step',
          );
        }
      }

      await onCompleted();
      setError(undefined);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledSpace direction="vertical" size="middle">
      <StyledP4Text>*Please review all required fields to proceed</StyledP4Text>
      {error && <StyledP4Text type="danger">{error}</StyledP4Text>}
      <StyledButton
        disabled={!isValid || disabled}
        loading={isLoading}
        onClick={handleClick}
      >
        <StyledXsTitle
          color="inherit"
          style={{ lineHeight: '18px', padding: '10px 0' }}
          disabled={!isValid}
        >
          Review Completed
        </StyledXsTitle>
      </StyledButton>
    </StyledSpace>
  );
};

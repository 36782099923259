import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export const useQueryInput = () => {
  const { control, setValue } = useFormContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const insertText = (name: string, textToInsert: string) => {
    if (!textareaRef.current) return; // Check if the reference is null

    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart || 0;
    const endPos = textarea.selectionEnd || 0;

    // Insert the text at the cursor position
    const currentValue = textarea.value;
    const newValue =
      currentValue.substring(0, startPos) +
      textToInsert +
      currentValue.substring(endPos);

    // Update the textarea with the new value and move the cursor after the inserted text
    textarea.value = newValue;
    textarea.setSelectionRange(
      startPos + textToInsert.length,
      startPos + textToInsert.length,
    );
    textarea.focus();

    setValue(name, newValue);
  };

  return { insertText, textareaRef, control };
};

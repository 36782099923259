import { PlatformRate } from '@agentsonly/models';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { App, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { memo } from 'react';

import { deletePlatformRate } from '../../firebase/platformRates';
import { BaseIcon } from '../../shared/ui/BaseIcon';
import { Chip } from '../../shared/ui/Chip';
import { BaseColors } from '../../shared/ui/colors';
import { formatNumber } from '../../utils/number';
import { PLATFORM_RATE_MAPPING } from '../consts';
import { TileContainer } from './styledComponents';

const { Title, Paragraph } = Typography;

type Props = {
  platformRate: PlatformRate;
  deletable?: boolean;
  onPostSuccessDelete: () => void;
};

const { confirm } = Modal;

export const PlatformRateTile: React.FC<Props> = memo(
  ({ platformRate, deletable, onPostSuccessDelete }) => {
    const { notification } = App.useApp();
    const { id, name, rateInUSD, description, rateType } = platformRate;

    const handleDeletePlatformRate = async (rate: PlatformRate) => {
      confirm({
        title: `Do you want to delete rate: ${rate.name}?`,
        content: "This action can't be undone",
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          try {
            await deletePlatformRate(rate.id);
            notification.success({
              message: 'Rate deleted successfully',
            });
            onPostSuccessDelete();
          } catch (error) {
            notification.error({
              message: 'Rate deleted failed',
            });
            console.error(error);
          }
        },
      });
    };

    return (
      <TileContainer className="PlatformRateTile" data-testid={id}>
        <Row align="top" justify="space-between">
          <Col span={22}>
            <Title
              level={4}
              className="Title"
              style={{ margin: '0 0 33px 0' }}
              ellipsis={{ tooltip: name, rows: 1 }}
            >
              {name}
            </Title>
          </Col>
          <Col span={2}>
            <Tooltip
              title={
                !deletable && 'Platform Rate currently in use so not deletable'
              }
            >
              <div data-testid={`platformRates:tile:deleteIcon-${name}`}>
                <BaseIcon
                  icon="trashCan"
                  stroke={deletable ? BaseColors.primary : BaseColors.inactive}
                  onClick={() =>
                    deletable && handleDeletePlatformRate(platformRate)
                  }
                  disabled={!deletable}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Title
              level={3}
              className="RateAmount"
              style={{ margin: '0 0 8px 0' }}
            >
              ${formatNumber(rateInUSD, 0, 10)}
            </Title>

            <Paragraph
              className="Description"
              ellipsis={{ tooltip: description, rows: 2 }}
              style={{ height: 44 }}
            >
              {description}
            </Paragraph>
          </Col>
        </Row>
        <Row align="bottom">
          <Chip className="RateType">{PLATFORM_RATE_MAPPING[rateType]}</Chip>
        </Row>
      </TileContainer>
    );
  },
);

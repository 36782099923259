import axios from 'axios';

import { auth } from '../../firebase';
import { FIREBASE_FUNCTIONS_BASE_URL } from '../../shared/api/consts';

const aiPlaceholdersAxios = axios.create({
  baseURL: `${FIREBASE_FUNCTIONS_BASE_URL}/aiPlaceholdersAPI`,
  headers: {
    'Content-Type': 'application/json',
  },
});

aiPlaceholdersAxios.interceptors.request.use(async (req) => {
  const token = await auth().currentUser?.getIdToken();
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  return req;
});

const getAiPlaceholders = async () =>
  aiPlaceholdersAxios.get<Array<string>>('/placeholders');

const getAiAgentChatPlaceholders = async () =>
  aiPlaceholdersAxios.get<Array<string>>('/placeholders/agent-chat');
const getAiClientChatPlaceholders = async () =>
  aiPlaceholdersAxios.get<Array<string>>('/placeholders/client-chat');

export const aiPlaceholdersAPI = {
  getAiPlaceholders,
  getAiAgentChatPlaceholders,
  getAiClientChatPlaceholders,
};

import { Col, Row } from 'antd';
import styled from 'styled-components';

import { Button } from '../../../../shared/ui/Button';

export const InputCol = styled(Col)`
  max-width: 360px;
  width: 100%;
`;

export const InputButton = styled(Button)`
  margin-top: 22px;
`;

export const TableRow = styled(Row)`
  margin-top: 24px;
`;

import { Row, Typography } from 'antd';
import React from 'react';

import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { AddPlatformRateModal } from './AddPlatformRateModal';

const { Text } = Typography;

type Props = {
  disabled: boolean;
};

export const Header: React.FC<Props> = React.memo(({ disabled }) => {
  const { isOpen, closeModal, openModal } = useModal(false);

  return (
    <Row
      justify="space-between"
      data-testid="PlatformRates:PlatformRatesPage:HeaderContainer"
    >
      <Text strong data-testid="PlatformRates:PlatformRatesPage:Title">
        Platform Rates
      </Text>
      <Button
        data-testid="PlatformRates:PlatformRatesPage:CreateNewButton"
        type="primary"
        ghost
        onClick={openModal}
        disabled={disabled}
      >
        Create New
      </Button>
      <AddPlatformRateModal open={isOpen} onClose={closeModal} />
    </Row>
  );
});

import { atom, PrimitiveAtom } from 'jotai';

import { AsyncValue } from './types';

/**
 * Creates an asyncAtom.
 *
 * @template T - The type of data that the asyncAtom will hold.
 * @template D - The type of additional data passed to the async function.
 *
 * @param {PrimitiveAtom<AsyncValue<T>>} asyncValueAtom - The atom holding the async value.
 * @param {(data: D, value: AsyncValue<T>) => Promise<T>} func - The async function to be executed.
 * @param {boolean} [enableErrorThrow] - Optional flag to enable throwing errors.
 *
 * @returns {Atom<T>} - The created asyncAtom.
 */
export const asyncAtom = <T, D = void>(
  asyncValueAtom: PrimitiveAtom<AsyncValue<T>>,
  func: (data: D, value: AsyncValue<T>) => Promise<T>,
  enableErrorThrow?: boolean,
) =>
  atom(
    (get) => get(asyncValueAtom),
    async (get, set, data: D) => {
      set(asyncValueAtom, (prev: AsyncValue<T>) => ({
        loading: true,
        data: prev.data,
      }));
      try {
        const dataValue = await func(data, get(asyncValueAtom));
        set(asyncValueAtom, { loading: false, data: dataValue });
      } catch (error) {
        set(asyncValueAtom, (prev: AsyncValue<T>) => ({
          ...prev,
          loading: false,
          error,
        }));
        if (enableErrorThrow) {
          throw error;
        }
      }
    },
  );

import { Project } from '@agentsonly/models';
import { useEffect, useState } from 'react';

import { getAgentProjects } from '../../../firebase/agent';
import { ProjectStatus } from '../../../firebase/models/AgentsProject';
import { getProject } from '../../../firebase/project';

type CurrentProjectsType = Record<ProjectStatus, Array<string>>;

export const useAgentProjects = (agentId: string) => {
  const [projects, setProjects] = useState<CurrentProjectsType | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const agentProjects = await getAgentProjects(agentId);
        const projectIdsFromAgentProjects = agentProjects.map(
          (project) => project.id,
        );
        const projectDocsFromIds = await Promise.all(
          projectIdsFromAgentProjects.map(getProject),
        );
        const projectData = projectDocsFromIds.reduce<Record<string, Project>>(
          (acc, cur) => {
            const data = cur.data();
            if (data) {
              acc[data.id] = data;
            }

            return acc;
          },
          {},
        );

        const groupingProjectsByStatus = agentProjects.reduce((acc, cur) => {
          const name = projectData[cur.id]?.name;
          if (name) {
            acc[cur.status] = [...(acc[cur.status] || []), name];
          }

          return acc;
        }, {} as CurrentProjectsType);

        setProjects(groupingProjectsByStatus);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [agentId]);

  return { projects, loading };
};

import React from 'react';

import { SpeedTestResultData } from '../../../../shared/models/SpeedTest';
import { ResultContent } from './ResultContent';

type Props = {
  result?: SpeedTestResultData;
};

export const SpeedTestResult: React.FC<Props> = ({ result }) => {
  const contents = [
    {
      id: 'download',
      label: 'Download',
      value: result?.download ? `${result.download} Mbps` : '-',
    },
    {
      id: 'upload',
      label: 'Upload',
      value: result?.upload ? `${result.upload} Mbps` : '-',
    },
    {
      id: 'latency',
      label: 'Latency',
      value: result?.latency ? `${result.latency} ms` : '-',
    },
    {
      id: 'jitter',
      label: 'Jitter',
      value: result?.jitter ? `${result.jitter} ms` : '-',
      highlight: true,
    },
    {
      id: 'test-server',
      label: 'Test Server',
      value: result?.testServer ? result.testServer : '-',
      highlight: true,
    },
    {
      id: 'ip',
      label: 'IP',
      value: result?.ipAddress ? result.ipAddress : '-',
      highlight: true,
    },
    {
      id: 'hostname',
      label: 'Hostname',
      value: result?.hostname ? result.hostname : '-',
      highlight: true,
    },
  ];

  return <ResultContent title="Test Result" contents={contents} />;
};

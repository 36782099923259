import { Col, Space, Typography } from 'antd';
import React from 'react';

import { ImageFolderEmpty } from '../../../../shared/assets';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { ColorfulTitle } from '../styledComponents';
import { StyledCol, StyledRow } from './styledComponents';

const { Paragraph } = Typography;

type Props = {
  openCreateModal: () => void;
};

export const NoResourceItem: React.FC<Props> = ({ openCreateModal }) => (
  <StyledRow align="middle">
    <Col span={12}>
      <ImageFolderEmpty />
    </Col>
    <Space size={100} direction="horizontal" />
    <StyledCol span={12}>
      <ColorfulTitle color={BaseColors.neutral100} level={4}>
        No Resource
      </ColorfulTitle>
      <Paragraph>
        You don’t have any resources set up. Please start creating one!
      </Paragraph>
      <Button onClick={openCreateModal} ghost type="primary">
        Link Resource
      </Button>
    </StyledCol>
  </StyledRow>
);

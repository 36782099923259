import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Space, Typography } from 'antd';
import { useAtom } from 'jotai';
import { FC, memo } from 'react';

import { removeAdminUserRoleAtom } from '../../atoms/adminUsers';
import { AdminRole } from '../../firebase/adminRoles/model';
import { AdminUser } from '../../firebase/adminUsers/model';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';

type Props = {
  user: AdminUser;
  role: AdminRole;
  disabled: boolean;
};

const { confirm } = Modal;

export const UserItem: FC<Props> = memo(({ user, role, disabled }) => {
  const [removeState, removeAdminUserRole] = useAtom(removeAdminUserRoleAtom);
  const { closeModal } = useModal();

  const handleDelete = async () => {
    await removeAdminUserRole({ id: user.id, roleId: role.id });
  };

  const showConfirm = () => {
    confirm({
      title: `Do you want to remove ${user.email} from ${role.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: handleDelete,
      onCancel: closeModal,
    });
  };

  return (
    <Space>
      <Typography>
        {user?.firstName && user?.lastName
          ? `${user?.firstName} ${user?.lastName}`
          : user.email}
      </Typography>
      &nbsp;|&nbsp;
      <Typography>{user?.email}</Typography>
      <Button
        disabled={removeState.loading || disabled}
        icon={<CloseOutlined />}
        size="small"
        danger
        onClick={showConfirm}
      />
    </Space>
  );
});

import { Row } from 'antd';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { fetchAiPlaceholders } from '../../../../atoms/aiPlaceholders';
import { AdminTab } from '../../../../firebase/adminRoles/model';
import { AI_PROVIDERS, AiProvider } from '../../../../firebase/models/Prompts';
import { useFeatureViewOnly } from '../../../../shared/hooks/useFeatureViewOnly';
import { QueryInput } from '../../../AITemplateEditor/components/fileld/QueryInput';
import { promptSectionMapping } from '../../../AITemplateEditor/components/form/consts';

type Props = {
  fetchPlaceholders?: () => Promise<string[]>;
};

export const PromptSection: FC<Props> = ({
  fetchPlaceholders = fetchAiPlaceholders,
}) => {
  const isViewOnly = useFeatureViewOnly(AdminTab.Projects);
  const { watch } = useFormContext();

  const provider = watch('settings.provider') as AiProvider;
  const isNoneProvider = provider === AI_PROVIDERS.NONE;

  if (isNoneProvider) {
    return null;
  }

  return (
    <Row>
      {promptSectionMapping[provider]?.map((config) => (
        <QueryInput
          key={config.name}
          name={config.name}
          title={config.title}
          disabled={isViewOnly}
          fetchPlaceholders={fetchPlaceholders}
          disablePlaceholder={config.disablePlaceholder}
        />
      ))}
    </Row>
  );
};

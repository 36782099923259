export const TEMPLATE_NAME = 'batch_fix_referrals_template.csv';

export const TEMPLATE_DATA = [
  ['full_name', 'email', 'platform_id', 'parent', 'parent_id'],
  [
    'Agent Name',
    'example@example.com',
    'US2ccYb0KiUMuoe3HY8wRmv5xxxx',
    'Referrer Agent Name',
    'US2ccYb0KiUMuoe3HY8wRmv5yyyy',
  ],
];

import firebase from 'firebase/compat';

export enum PaymentStatus {
  none = '',
  pending = 'pending',
  complete = 'complete',
  error = 'error',
}

export enum Provider {
  wise = 'wise',
  ofx = 'ofx',
  payoneer = 'payoneer',
}

type AgentInvoiceStatusData = {
  event_type: string;
  schema_version: string;
  sent_at: string;
  subscription_id: string;
  data: {
    current_state: string;
    occurred_at: string;
    previous_state: string;
    resource: {
      account_id: number;
      id: number;
      profile_id: number;
      type: string;
    };
  };
};

export type AgentInvoiceStatus = {
  paymentStatus: PaymentStatus;
  provider: Provider;
  updatedAt: firebase.firestore.Timestamp;
  data?: AgentInvoiceStatusData;
};

import { Table } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../shared/ui/colors';

export const StyledTable: typeof Table = styled(Table)`
  background-color: ${BaseColors.missingDataBg};

  tr {
    background-color: ${BaseColors.missingDataBg};
  }
`;

import { db } from '../../firebase';
import { ProjectStats } from '../../firebase/consts';
import { getUploadedCSVRef } from '../../firebase/demo';
import { Status, TimePeriod } from '../../firebase/models/Demo';
import { getProjectStatsByPeriodRef } from '../../firebase/project';
import { CSVData } from './ClientTweak/types';

export const getProjectStats = async (projectId: string) => {
  const snapshot = await getUploadedCSVRef(projectId)
    .orderBy('createdAt', 'desc')
    .get();
  return snapshot.docs.map((d) => d.data());
};

export const updateCSVUploadSubmissions = async (
  projectId: string,
  createdBy: string,
  timePeriod = 'daily' as TimePeriod,
  status = 'success' as Status,
) => {
  try {
    await getUploadedCSVRef(projectId).doc().set({
      status,
      createdAt: new Date(),
      timePeriod,
      createdBy,
    });
  } catch (e) {
    if (e instanceof Error) {
      console.error('error during update demo csv submission: ', e);
    }
  }
};

export const updateProjectStats = async (
  projectId: string,
  items: CSVData[],
  timePeriod: TimePeriod,
) => {
  const batch = db.batch();
  items.forEach((item) => {
    const {
      date,
      totalAvailableShiftSeconds,
      totalProductiveTimeSeconds,
      platformSpend,
    } = item;

    // we need to parse to the string format
    const projectStatsRef = getProjectStatsByPeriodRef(projectId, timePeriod);

    batch.set(
      projectStatsRef.collection(ProjectStats.Schedules).doc(`${date}`),
      {
        totalAvailableShiftSeconds,
        totalProductiveTimeSeconds,
      },
      { merge: true },
    );

    batch.set(
      projectStatsRef.collection(ProjectStats.Project).doc(`${date}`),
      {
        platformSpend,
      },
      { merge: true },
    );
  });
  batch.commit();
};

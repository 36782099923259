import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  createReferralPlanAtom,
  referralPlanAtom,
} from '../../../../atoms/payout';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { payoutSchema } from '../../../../utils/validator';
import { Form } from './Form';
import {
  convertReferralPlanToFormParam,
  mapValuesToReferralPlan,
} from './helpers';
import { MilestoneFormParam } from './types';

type Props = {
  disabled: boolean;
};

export const Payout: React.FC<Props> = ({ disabled }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [{ data: referralPlan, loading, error }] = useAtom(referralPlanAtom);
  const [, createReferralPlan] = useAtom(createReferralPlanAtom);
  const form = useForm<MilestoneFormParam>({
    resolver: yupResolver(payoutSchema),
    mode: 'all',
  });

  const isFormDirty = form.formState.isDirty;

  useEffect(() => {
    if (referralPlan) {
      form.reset(convertReferralPlanToFormParam(referralPlan));
    }
  }, [referralPlan, form]);

  useEffect(() => {
    if (showMessage && !loading) {
      ShowNotification(error ? 'Error' : 'Payout updated', IconType.Success);
      setShowMessage(false);
    }
  }, [showMessage, loading, error]);

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isFormDirty]);

  return (
    <FormProvider {...form}>
      <Form
        disabled={disabled}
        onSubmit={form.handleSubmit(async (data) => {
          await createReferralPlan(mapValuesToReferralPlan(data, referralPlan));
          setShowMessage(true);
        })}
      />
    </FormProvider>
  );
};

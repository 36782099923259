import { Project } from '@agentsonly/models';
import { App, Col, Row, Spin, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';

import { getProjectApprovedAgents } from '../../firebase/project';
import { Button } from '../../shared/ui/Button';
import { isProd } from '../../utils/consts';
import { AddCallDataModal } from './addCallDateModal';
import { PlatformRates } from './PlatformRates';
import { ProjectDetails } from './ProjectDetails';
import { StyledDivider } from './styledComponents';

interface Props {
  project: Project;
  disabled: boolean;
}

export const EditProjectDetails: React.FC<Props> = ({ project, disabled }) => {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [addDataModalVisible, setAddDataModalVisible] = useState<
    'callData' | 'connectFailure' | 'paymentFailure' | 'none'
  >('none');
  const [approvedAgents, setApprovedAgents] = useState<string[]>([]);
  // TODO: Remove once we migrate all project to ccassProjectProps
  const projectInstanceId =
    project?.ccaasProjectProps?.instanceIdentifier ??
    project?.connectDetails?.instanceId;

  useEffect(() => {
    if (isProd()) return;

    const getApprovedAgentsIds = async () => {
      setLoading(true);
      const agents = await getProjectApprovedAgents(project.id);

      const approvedAgentsIds = agents.map((agent) => agent.id);
      setApprovedAgents(approvedAgentsIds);
      setLoading(false);
    };

    getApprovedAgentsIds();
  }, [project]);

  const onResult = ({ err, isCancel }: { err?: Error; isCancel?: boolean }) => {
    if (err) {
      console.log(`error creating call data: ${err.message}`);
      message.error(`Error creating data: ${err.message}`, 5);
    }

    if (!isCancel) {
      console.log('call data created successfully!');
      message.success('Contact Records created successfully!', 3);
    }

    setAddDataModalVisible('none');
  };

  let validTelephonyDetails: boolean = false;

  if (projectInstanceId && projectInstanceId !== 'none') {
    validTelephonyDetails = true;
  }

  const addCallDataButton = (
    <Button
      onClick={() => {
        setAddDataModalVisible('callData');
      }}
      disabled={!validTelephonyDetails || disabled}
    >
      Add Call Data
    </Button>
  );

  if (loading) {
    <Spin />;
  }

  return (
    <>
      <AddCallDataModal
        disabled={disabled}
        open={addDataModalVisible === 'callData'}
        onResult={onResult}
        approvedAgentIds={approvedAgents}
        connectInstanceId={projectInstanceId}
      />

      <ProjectDetails project={project} disabled={disabled} />

      <Col span={22}>
        <StyledDivider dashed />
      </Col>

      <PlatformRates key={`platformRate-${project.id}`} project={project} />

      <Col span={22}>
        <StyledDivider dashed />
      </Col>

      <Row>
        <Title level={2}>Actions</Title>
      </Row>
      <Row gutter={[0, 24]}>
        {!isProd() && (
          <Col span={24}>
            {validTelephonyDetails ? (
              addCallDataButton
            ) : (
              <Tooltip title="Invalid Connect Details">
                {addCallDataButton}
              </Tooltip>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

import { ISOCountryKeys } from '@agentsonly/models';

export const COUNTRY_TITLE_MAPPING = {
  [ISOCountryKeys.US]: 'United States',
  [ISOCountryKeys.PH]: 'Philippines',
  [ISOCountryKeys.MX]: 'Mexico',
  [ISOCountryKeys.CA]: 'Canada',
};

export const COUNTRY_UNIT_MAPPING = {
  [ISOCountryKeys.US]: 'hrs',
  [ISOCountryKeys.PH]: 'mins',
  [ISOCountryKeys.MX]: 'mins',
  [ISOCountryKeys.CA]: 'mins',
};

export const COUNTRY_LABEL_MAPPING = {
  [ISOCountryKeys.US]: 'Gate Hours',
  [ISOCountryKeys.PH]: 'Gate Minutes',
  [ISOCountryKeys.MX]: 'Gate Minutes',
  [ISOCountryKeys.CA]: 'Gate Minutes',
};

import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';
import { clabe } from 'clabe-validator';

import { BANK_DISPLAY_ELEMENTS, BankKey } from '../firebase/models/Bank';
import {
  PHILIPPINES_BANK_CODES,
  PHILIPPINES_BANK_INFO_BY_BANK_CODES,
} from './bankCode';
import {
  LengthRule,
  validateBeginWith,
  validateCURP,
  validateIntString,
  validateLength,
} from './validator';

export type Range = [number] | [number, number];
export type Prefix = string | number;

type BankValue = {
  name: BankKey;
  title: string;
  validation: (value: string) => Promise<void>;
};

const createAccountData = (
  range: Range | undefined = [7, 18],
  prefix: Prefix | undefined = '',
): BankValue => ({
  name: 'account',
  title: 'Account Number',
  validation(value: string) {
    const error =
      validateIntString(this.title, value) ??
      validateLength(this.title, value, {
        range,
        rule: range.length === 2 ? LengthRule.between : LengthRule.equal,
      }) ??
      validateBeginWith(this.title, value, prefix);
    return error ? Promise.reject(new Error(error)) : Promise.resolve();
  },
});

const BANK_FIELDS: Record<BankKey, BankValue> = {
  account: createAccountData(),
  routing: {
    name: 'routing',
    title: 'Routing Number',
    validation(value: string) {
      const error =
        validateIntString(this.title, value) ??
        validateLength(this.title, value, {
          range: [5, 9],
          rule: LengthRule.between,
        });
      return error ? Promise.reject(new Error(error)) : Promise.resolve();
    },
  },
  institution: {
    name: 'institution',
    title: 'Institution Number',
    validation(value: string) {
      const error =
        validateIntString(this.title, value) ??
        validateLength(this.title, value, {
          range: [3],
          rule: LengthRule.equal,
        });
      return error ? Promise.reject(new Error(error)) : Promise.resolve();
    },
  },
  clabe: {
    name: 'clabe',
    title: 'CLABE',
    validation(value: string) {
      const result = clabe.validate(value);
      return result.ok
        ? Promise.resolve()
        : Promise.reject(new Error(result.message));
    },
  },
  curp: {
    name: 'curp',
    title: 'CURP',
    validation(value: string) {
      const error = validateCURP(value);
      return error ? Promise.reject(new Error(error)) : Promise.resolve();
    },
  },
  abartn: {
    name: 'abartn',
    title: 'Routing Number',
    validation(value: string) {
      const error =
        validateIntString(this.title, value) ??
        validateLength(this.title, value, {
          range: [9],
          rule: LengthRule.equal,
        });
      return error ? Promise.reject(new Error(error)) : Promise.resolve();
    },
  },
  bankCode: {
    name: 'bankCode',
    title: 'Bank Code',
    validation(value: string) {
      const error = validateLength(this.title, value, {
        range: [2, 5],
        rule: LengthRule.between,
      });
      return error ? Promise.reject(new Error(error)) : Promise.resolve();
    },
  },
  legalName: {
    name: 'legalName',
    title: 'Account holder name',
    validation(value: string) {
      const isValid = value?.length > 0;
      return isValid
        ? Promise.resolve()
        : Promise.reject(new Error('Account holder name is a required field'));
    },
  },
};

const MAP_COUNTRY_BANK_FIELDS: Record<
  ISOCountry,
  ({ key: BankKey } & BankValue)[]
> = (Object.keys(BANK_DISPLAY_ELEMENTS) as ISOCountry[]).reduce(
  (acc: any, cur: ISOCountry) => {
    acc[cur] = BANK_DISPLAY_ELEMENTS[cur]?.map((key) => ({
      key,
      ...BANK_FIELDS[key],
    }));
    return acc;
  },
  {},
);

export const OVERRIDE_BANK_FIELD: Record<
  string,
  Record<BankKey, BankValue>
> = PHILIPPINES_BANK_CODES.reduce((acc, cur) => {
  const info = PHILIPPINES_BANK_INFO_BY_BANK_CODES[cur];
  return {
    ...acc,
    [cur]: { account: createAccountData(info.range, info.prefix) },
  };
}, {});

const getOriginalBankFields = (countryCode: ISOCountry) =>
  MAP_COUNTRY_BANK_FIELDS[countryCode] || [];

export const OVERRIDE_BANK_FIELDS: Partial<
  Record<ISOCountry, Partial<Record<BankKey, Partial<BankValue>>>>
> = {
  [ISOCountryKeys.US]: {
    account: {
      ...createAccountData([4, 17]),
    },
  },
};

export const getBankFields = (countryCode: ISOCountry) => {
  const bankInfo = getOriginalBankFields(countryCode);
  if (!OVERRIDE_BANK_FIELDS[countryCode]) {
    return bankInfo;
  }

  return bankInfo.map((info) => {
    if (OVERRIDE_BANK_FIELDS[countryCode]?.[info.key]) {
      return {
        key: info.key,
        ...OVERRIDE_BANK_FIELDS[countryCode]?.[info.key],
      };
    }

    return info;
  });
};

import styled from 'styled-components';

import { Button } from '../../../../../shared/ui/Button';

export const StyledButton = styled(Button)`
  && {
    border-radius: 4px;
    width: 180px;
  }
`;

import { ISOCountryKeys } from '@agentsonly/models';
import { Col, Row, Typography } from 'antd';
import React, { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';

import { InformationIcon } from '../../../../shared/assets';
import { Button } from '../../../../shared/ui/Button';
import { Form as FormCmp } from '../../../../shared/ui/form/Form';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { Gray } from '../../../../shared/ui/themes/colors/gray';
import { CountryMilestone } from './CountryMilestone';
import { InformationContainer } from './styledComponents';

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  disabled: boolean;
};

const { Text } = Typography;

export const Form: React.FC<Props> = ({ onSubmit, disabled }) => {
  const { formState } = useFormContext();

  return (
    <FormCmp onFinish={onSubmit} disabled={disabled}>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row gutter={[48, 0]}>
                {Object.values(ISOCountryKeys).map((country) => (
                  <CountryMilestone
                    key={`countryMilestone-${country}`}
                    country={country}
                  />
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Spacer size={8} axis="vertical" />
        <Col span={24}>
          <InformationContainer align="top" wrap={false}>
            <InformationIcon stroke={Gray[600]} />
            <Spacer size={12} axis="horizontal" />
            <Text strong>
              Gate refers to the milestone the child needs to achieve in order
              for the parent to receive the payout. For the edge case where
              parent is PH, the child is US, the child needs to pass the minimum
              gate hours so that the parent can get the PHP payout.
            </Text>
          </InformationContainer>
        </Col>
        <Spacer size={24} axis="vertical" />
        <Col span={24}>
          <Row justify="end">
            <Button
              htmlType="submit"
              type="primary"
              disabled={
                formState.isSubmitting || !formState.isValid || disabled
              }
              loading={formState.isSubmitting}
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </FormCmp>
  );
};

import { ISOCountry } from '@agentsonly/models';
import { Row, Space, Table, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useEffect } from 'react';

import { PlatformPayment } from '../../../firebase/models/interfaces';
import { Button } from '../../../shared/ui/Button';
import {
  currencyIdentifiers,
  currencySymbolFromCountry,
  formatAmount,
} from '../../../utils/currency';
import { toVancouverTime } from '../../../utils/timezone';
import { useInvoiceDownloader } from './invoiceGenerator/useInvoiceDownloader';

type Props = {
  date: Date;
};

// Date	Invoice ID	Parent Name	No of Payments	Total Amount
export const PlatformInvoicesTable = ({ date }: Props) => {
  const { data, invoices, referrals, agents, updateData, handleDownloadClick } =
    useInvoiceDownloader({
      invoiceDate: date,
    });

  useEffect(() => {
    updateData(date);
  }, [date, updateData]);

  const columns = [
    {
      title: 'Date',
      key: 'createdAt',
      render: (_: undefined, row: PlatformPayment) => {
        const invoice = row.invoiceId ? invoices[row.invoiceId] : undefined;
        return (
          invoice && (
            <Typography>
              {format(toVancouverTime(invoice.createdAt), 'P')}
            </Typography>
          )
        );
      },
    },
    {
      title: 'Invoice ID',
      dataIndex: 'invoiceId',
      key: 'id',
      render: (invoiceId: string) => <Typography>{invoiceId}</Typography>,
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentId',
      key: 'agentId',
      render: (agentId: string) =>
        agents[agentId] && (
          <div>
            {agents[agentId].firstName} {agents[agentId].lastName}
          </div>
        ),
    },
    {
      title: 'Child Referral',
      key: 'childRef',
      render: (_: undefined, row: PlatformPayment) => {
        const referral = referrals[row.reference];
        const { refereeFirstName, refereeLastName } = referral;
        return (
          <Typography>{`${refereeFirstName} ${refereeLastName}`}</Typography>
        );
      },
    },
    {
      title: 'Level',
      key: 'level',
      render: (_: undefined, row: PlatformPayment) => (
        <Typography>{`L${row.data?.referralLevel}`}</Typography>
      ),
    },
    {
      title: 'Gate',
      key: 'gate',
      render: (_: undefined, row: PlatformPayment) => (
        <Typography>{`Gate ${row.data?.referralGate}`}</Typography>
      ),
    },
    {
      title: 'Payment Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, row: PlatformPayment) => {
        if (!row.agentId) {
          return null;
        }

        const countryCode: ISOCountry | undefined = row.agentId
          ? agents[row.agentId].address.countryCode
          : undefined;

        return (
          countryCode && (
            <div>
              {currencySymbolFromCountry(countryCode)} {formatAmount(amount)}{' '}
              {currencyIdentifiers[countryCode]}
            </div>
          )
        );
      },
    },
    {
      title: 'Payment Status',
      key: 'status',
      render: (_: undefined, row: PlatformPayment) => {
        const invoice = row.invoiceId ? invoices[row.invoiceId] : undefined;
        return invoice && <Typography>{invoice.status}</Typography>;
      },
    },
  ];

  const footer = () => (
    <Row>
      <Space size="large" direction="horizontal">
        <Button type="primary" onClick={handleDownloadClick}>
          Download All
        </Button>
      </Space>
    </Row>
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      footer={footer}
      pagination={{ pageSize: 25 }}
      rowKey="id"
    />
  );
};

import { SearchOutlined } from '@ant-design/icons';
import { Input, InputRef, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useState } from 'react';

import { Referral } from '../../../../firebase/models/interfaces';
import { Button } from '../../../../shared/ui/Button';
import { FixReferralModal } from './modal';

const getColumnSearchProps = (fieldNameToSearch: string) => {
  let searchInput: InputRef | null;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${fieldNameToSearch}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean): React.ReactNode => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (valueToFilter: string | number | boolean, record: any) =>
      record[fieldNameToSearch]
        ? record[fieldNameToSearch]
            .toString()
            .toLowerCase()
            .includes(valueToFilter.toString().toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
  };
};

const columns: ColumnType<Referral>[] = [
  {
    title: 'Referee Email',
    dataIndex: 'refereeEmail',
    key: 'refereeEmail',
    width: '20%',
    ...getColumnSearchProps('refereeEmail'),
  },
  {
    title: 'Referee Name',
    dataIndex: 'refereeName',
    key: 'refereeName',
    ...getColumnSearchProps('refereeName'),
  },
  {
    title: 'Parent (Referrer) Email',
    dataIndex: 'referrerEmail',
    key: 'referrerEmail',
    ...getColumnSearchProps('referrerEmail'),
    render: (email: string, referral: Referral) => (
      <Tooltip title={referral.referrerId}>
        <Typography.Text>{email}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (status: string, referral: Referral) => (
      <Tooltip title={referral.id}>
        <Typography.Text data-referralid={referral.id}>
          {status}
        </Typography.Text>
      </Tooltip>
    ),
  },
];

type SingleFixReferralsProps = {
  referrals: Referral[];
  fetchReferral: (pageIndex: number, pageSize: number) => void;
  disabled: boolean;
  onUpdated: (referral: Referral) => void;
  loading: boolean;
};

export const SingleFixReferrals = ({
  referrals,
  onUpdated,
  disabled,
  fetchReferral,
  loading,
}: SingleFixReferralsProps) => {
  const [selectedReferral, setSelectedReferral] = useState<Referral>();
  const clickRow = (referral: Referral) => {
    setSelectedReferral(referral);
  };

  const handleClose = () => {
    setSelectedReferral(undefined);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={referrals}
        rowKey={(record: Referral) => record.id}
        onRow={(record: Referral) => ({
          onClick: () => {
            clickRow(record);
          },
        })}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          onChange: fetchReferral,
          total: referrals.length + 1,
        }}
        data-testid="admin:FixReferralRelationships.SingleFixReferrals:table"
      />

      {selectedReferral && (
        <FixReferralModal
          disabled={disabled}
          selectedReferral={selectedReferral}
          onClose={handleClose}
          onUpdated={onUpdated}
          data-testid="admin:FixReferralRelationships.SingleFixReferrals:FixReferralModal"
        />
      )}
    </>
  );
};

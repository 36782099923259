import { ISOCountryKeys, Milestone, ReferralPlan } from '@agentsonly/models';

import { MilestoneFormParam } from './types';

const UPSERT_MILESTONE_INDEX = 0;

export const mapValuesToReferralPlan = (
  values: MilestoneFormParam,
  referralPlan?: ReferralPlan,
): Omit<ReferralPlan, 'id'> => {
  const originalMilestones = [...(referralPlan?.milestones ?? [])];
  const updateMilestone: Milestone =
    referralPlan?.milestones[UPSERT_MILESTONE_INDEX] ?? ({} as Milestone);
  originalMilestones[UPSERT_MILESTONE_INDEX] = Object.values(
    ISOCountryKeys,
  ).reduce<Milestone>(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        ...(updateMilestone[curr] ?? {}),
        ...values[curr],
        milestone:
          (values[curr].milestone ?? 0) *
          (curr === ISOCountryKeys.US ? 3600 : 60),
        milestoneType:
          curr === ISOCountryKeys.US ? 'productiveHours' : 'handleTime',
      },
    }),
    {} as Milestone,
  );

  return {
    type: 'starMultiply',
    createdAt: new Date(),
    milestones: originalMilestones,
  };
};

export const convertReferralPlanToFormParam = (referralPlan: ReferralPlan) => {
  const milestone = referralPlan.milestones[UPSERT_MILESTONE_INDEX];

  if (!milestone) {
    return undefined;
  }

  return Object.values(ISOCountryKeys).reduce<MilestoneFormParam>(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        ...milestone[curr],
        milestone:
          (milestone[curr].milestone ?? 0) /
          (ISOCountryKeys.US === curr ? 3600 : 60),
      },
    }),
    {} as MilestoneFormParam,
  );
};

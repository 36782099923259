import { Agent } from '@agentsonly/models';
import { useAtom } from 'jotai';

import {
  agentByIdAtom,
  agentsTableAtom,
  fetchAgentsLoadingAtom,
} from '../../atoms/agent';
import { tableDataAtom, TableFilterType } from '../../atoms/agentTable';
import { getPaginatedAgentsQuery } from '../../firebase/agent';

export const Order = {
  Descend: 'descend',
  Ascend: 'ascend',
} as const;

type Props = Partial<Omit<TableFilterType, 'lastItem'>> & {
  pageIndex?: number;
  pageSize?: number;
  isAgentsDataRest?: boolean;
  isKeepCurrentPageIndex?: boolean;
};

const FIRST_PAGE_INDEX = 1;

export const useAgentTable = () => {
  const [agents, setAgents] = useAtom(agentsTableAtom);
  const [, setAgentById] = useAtom(agentByIdAtom);
  const [, setLoading] = useAtom(fetchAgentsLoadingAtom);
  const [tableData, setTableData] = useAtom(tableDataAtom);

  const updatePage = (pageIndex: number) => {
    setTableData({
      ...tableData,
      currentPageIndex: pageIndex,
    });
  };

  const getCurrentPageIndex = (
    pageIndex: number,
    isAgentsDataRest?: boolean,
    isKeepCurrentPageIndex?: boolean,
  ) => {
    if (isAgentsDataRest) {
      return FIRST_PAGE_INDEX;
    }
    if (isKeepCurrentPageIndex) {
      return tableData.currentPageIndex;
    }
    return pageIndex;
  };

  const fetchAgents = async ({
    isAgentsDataRest,
    isKeepCurrentPageIndex,
    sort = tableData.sort,
    filters = tableData.filters,
    pageIndex = tableData.currentPageIndex,
    pageSize = tableData.pageSize,
  }: Props) => {
    try {
      setLoading(true);
      const query = await getPaginatedAgentsQuery({
        sort,
        filters,
        pageSize,
        lastItem: isAgentsDataRest ? undefined : tableData.lastItem,
      });

      const excludeInvalidAgents = query.docs
        .map((q) => q.data())
        .filter((q) => q.createdAt !== undefined);

      const transformToAgentById = excludeInvalidAgents.reduce<
        Record<string, Agent>
      >(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {},
      );

      setAgents((prev) =>
        isAgentsDataRest
          ? excludeInvalidAgents
          : [...prev, ...excludeInvalidAgents],
      );
      setAgentById((prev) =>
        isAgentsDataRest
          ? { ...transformToAgentById }
          : { ...prev, ...transformToAgentById },
      );
      setTableData({
        sort,
        filters,
        pageSize,
        lastItem: query.docs[query.docs.length - 1],
        currentPageIndex: getCurrentPageIndex(
          pageIndex,
          isAgentsDataRest,
          isKeepCurrentPageIndex,
        ),
        fetchedPageIndex: isAgentsDataRest ? FIRST_PAGE_INDEX : pageIndex,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    agents,
    tableData,
    fetchAgents,
    updatePage,
  };
};

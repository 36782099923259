import { db } from '../firebase';
import { AgentCollections, RootCollections } from '../firebase/consts';
import { Document } from '../firebase/models/Document';
import { generalConverter } from '../firebase/utils';

const CONTRACTOR_AGREEMENT_TYPE = 'contractorAgreement';
const CONTRACTOR_AGREEMENT_VERSION = 2;

export const getAgentAgreements = async (agentId: string) => {
  const query = db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .collection(AgentCollections.Documents)
    .withConverter(generalConverter<Document>({ convertTimestamp: true }))
    .where('type', '==', CONTRACTOR_AGREEMENT_TYPE)
    .where('version', '==', CONTRACTOR_AGREEMENT_VERSION)
    .where('agreement', '==', true);
  const result = await query.get();
  return result.docs
    .map((doc) => doc.data())
    .sort((a, b) => b.dateSigned.getTime() - a.dateSigned.getTime())
    .slice(0, 1);
};

import { Col, Row, Typography } from 'antd';
import { useState } from 'react';

import { Behavior } from '../../../../firebase/models/Coaching';
import { IconEdit } from '../../../../shared/assets';
import iconCheck from '../../../../shared/assets/ic-check.svg';
import { BaseIcon } from '../../../../shared/ui/BaseIcon';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { Gray } from '../../../../shared/ui/themes/colors/gray';
import {
  LiveBehaviorContent,
  LiveBehaviorStatus,
  RecommendedItem,
  RecommendedList,
} from '../../styledComponents';
import {
  BehaviorDescriptionText,
  LiveBehaviorContainer,
  PointerWrapper,
  StyledLink,
  TitleContainer,
} from './styledComponents';

const { Title, Text } = Typography;

type Props = {
  behavior: Behavior;
  onClick?: () => void;
};

export const LiveBehavior = ({ behavior, onClick }: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <LiveBehaviorContainer>
      <Row
        align="top"
        justify="space-between"
        style={{ alignItems: 'self-start', justifyContent: 'space-between' }}
      >
        <TitleContainer>
          <Title
            style={{
              color: '#202629',
              fontSize: '24px',
              marginBottom: '0px',
              marginTop: '0',
            }}
          >
            {behavior.name}
          </Title>
          <Spacer axis="horizontal" size={16} />
          <PointerWrapper>
            <IconEdit width={42} onClick={onClick} />
          </PointerWrapper>
        </TitleContainer>
        <Col>
          <Row justify="center" align="middle" gutter={12}>
            <Col>
              <LiveBehaviorStatus onClick={onClick}>
                <Row justify="space-around" align="middle">
                  Live{' '}
                  <img src={iconCheck} alt="" style={{ marginLeft: '5px' }} />
                </Row>
              </LiveBehaviorStatus>
            </Col>
            <Col style={{ transform: `rotate(${collapsed ? 0 : 180}deg)` }}>
              <BaseIcon
                icon="chevronDown"
                stroke={Gray[500]}
                onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Text copyable>{behavior.id}</Text>
      </Row>
      <Spacer axis="vertical" size={32} />
      <LiveBehaviorContent>
        <BehaviorDescriptionText>
          {behavior.description}
        </BehaviorDescriptionText>
        {!collapsed && (
          <>
            <Title style={{ color: '#666666', fontSize: '18px' }}>
              Recommended Actions
            </Title>
            <RecommendedList>
              {behavior.actions?.map((action, idx) => (
                <RecommendedItem key={`recommendation-item-${idx + 1}`}>
                  {action.link ? (
                    <StyledLink
                      href={action.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {action.description}
                    </StyledLink>
                  ) : (
                    action.description
                  )}
                </RecommendedItem>
              ))}
            </RecommendedList>
          </>
        )}
      </LiveBehaviorContent>
    </LiveBehaviorContainer>
  );
};

import firebase from 'firebase/compat/app';

import { ProcessingStatus } from '../../Project/consts';
import Timestamp = firebase.firestore.Timestamp;

export type ClientManualData = {
  id: string;
  totalContactsHandled: number;
  totalUniqueAgent: number;
  totalMissingDataRows: number;
  processingStatus: ProcessingStatus;
  filePath: string;
  uploadedAt: Timestamp;
};

type CTRMissingData = {
  ContactId: string;
};

export enum ErrorType {
  MISSING = 'missing',
  FAILED = 'failed',
}

export type ClientErrorData = {
  id: string;
  type: ErrorType;
  row: { CTR: CTRMissingData };
};

import { add } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

const PROJECT_TZ = 'America/Vancouver';

const getVancouverOffset = (date: Date) => {
  const localOffset = date.getTimezoneOffset() * 60; // offset between local and UTC
  const vancouverOffset = getTimezoneOffset(PROJECT_TZ) / 1000; // offset between projectTZ and UTC

  return vancouverOffset + localOffset;
};

// firestore timestamp is saved as UTC
// we have to query with UTC which need to subtract offset
export const toVancouverTime = (date: Date, fsQuery?: boolean) =>
  add(date, { seconds: getVancouverOffset(date) * (fsQuery ? -1 : 1) });

export const getProjectTZLabel = (date: Date) =>
  formatInTimeZone(date, PROJECT_TZ, 'zzz');

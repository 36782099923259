import { BACKGROUND_STATUS, BackgroundCheckStatus } from '@agentsonly/models';

import { getAppDomain } from '../../../../../shared/utils/urls';

type MessageObject = {
  title: string;
  content: string;
  buttonText: string;
  link: string;
};

const incorrectMessage: MessageObject = {
  title: 'Almost there!',
  content: `Before you can get started with a project, we need you to provide additional information. 
  <br />
    Kindly check your Profile Information in Account Settings, to view which document(s) you need to upload.`,
  buttonText: 'Go to Profile',
  link: `${getAppDomain()}/account-settings`,
};

const failedMessage: MessageObject = {
  title: 'Thank you for your interest in Agents Only.',
  content: `Unfortunately, you did not qualify for our available projects.`,
  buttonText: '',
  link: ``,
};

const passedMessage: MessageObject = {
  title: 'Congratulations!',
  content: `This is to confirm that you have passed the background verification. 
  <br />
  This means you can now start applying to available projects by logging back in to your Agent Portal.`,
  buttonText: 'Apply to Projects',
  link: `${getAppDomain()}`,
};

const MessageByStatus: Record<
  BackgroundCheckStatus,
  MessageObject | undefined
> = {
  [BACKGROUND_STATUS.INCORRECT]: incorrectMessage,
  [BACKGROUND_STATUS.MISSING]: incorrectMessage,
  [BACKGROUND_STATUS.FAILED]: failedMessage,
  [BACKGROUND_STATUS.PASSED]: passedMessage,
  [BACKGROUND_STATUS.PENDING_REVIEW]: undefined,
};

const getTemplateButton = ({
  link,
  buttonText,
}: Partial<MessageObject>): string => `
<div style="text-align: center; margin-top: 56px">
    <a style="background: #078768; color: #fff; font-size: 16px; font-weight: 700; text-decoration: none; border-radius: 32px; padding: 16px 67px;" 
href="${link}"> ${buttonText} 
    </a>
</div>`;

export const buildBackgroundCheckEmail = (
  name: string,
  status: BackgroundCheckStatus,
): string => {
  const message = MessageByStatus[status];
  if (!message) {
    return '';
  }

  return `
    <div>
        <h1 style="font-size: 32px">
            Hi <span style="color: #078768">${name}</span>!
        </h1>
    </div>
    <div>
        <p style="font-size: 24px;">${message.title}</p>
            <p>${message.content}</p>
            <br />
            Thanks, <br />
            Your Agents Only team
        </p>
        ${message.link === '' ? '<div />' : getTemplateButton(message)}
    </div>`;
};

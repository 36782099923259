import { Row } from 'antd';
import React from 'react';

import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { StyledButton } from './styledComponents';

type Props = {
  resetDisabled: boolean;
  saveDisabled: boolean;
};

export const Submit: React.FC<Props> = ({ resetDisabled, saveDisabled }) => (
  <Row justify="end">
    <StyledButton htmlType="reset" disabled={resetDisabled} data-testid="reset">
      Reset
    </StyledButton>
    <Spacer size={24} axis="horizontal" />
    <StyledButton
      htmlType="submit"
      type="primary"
      disabled={saveDisabled}
      data-testid="submit"
    >
      Save
    </StyledButton>
  </Row>
);

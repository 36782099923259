import { BACKGROUND_STATUS } from '@agentsonly/models';
import { Select } from 'antd';
import React, { useCallback } from 'react';

import {
  StyledP2Text,
  StyledP4Text,
} from '../../../../shared/ui/text/styledComponents';
import { BackgroundCheckStatusLabels } from '../consts';
import { StyledSelect, StyledSpace } from '../styledComponents';

const { Option } = Select;

export type ItemProps = {
  id: string;
  title?: string;
  value?: string;
  onChange: (key: string, newValue: string) => void;
  disabled?: boolean;
};

export const CheckItem: React.FC<ItemProps> = ({
  id,
  title,
  value = '',
  onChange,
  disabled,
}) => {
  const onChangeValue = useCallback(
    (selectedValue: unknown) => {
      if (typeof selectedValue !== 'string') {
        console.log(`value is ${typeof selectedValue}`);
        return;
      }

      onChange(id, selectedValue ?? '');
    },
    [id, onChange],
  );

  return (
    <StyledSpace direction="vertical">
      {title && <StyledP4Text type="secondary">{`${title}`}</StyledP4Text>}
      <StyledSelect
        disabled={disabled}
        defaultValue={BACKGROUND_STATUS.PENDING_REVIEW}
        value={value}
        onChange={onChangeValue}
      >
        {Object.values(BACKGROUND_STATUS).map((status) => (
          <Option key={status} value={status}>
            <StyledP2Text>{BackgroundCheckStatusLabels[status]}</StyledP2Text>
          </Option>
        ))}
      </StyledSelect>
    </StyledSpace>
  );
};

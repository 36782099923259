import { ColumnsType } from 'antd/es/table';

import {
  CSV_UPLOAD_TYPE,
  CsvUploadError,
  CsvUploadType,
} from '../../../../firebase/models/CsvUpload';
import { ERROR_TYPE } from '../../../CsvUploadErrorModal/types';

export const ERROR_TYPE_MAPPING = {
  [ERROR_TYPE.MISSING]: 'Missing',
  [ERROR_TYPE.FAILED]: 'Failed',
};

export const COLUMNS: ColumnsType<CsvUploadError> = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: string) => ERROR_TYPE_MAPPING[type],
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error',
  },
];

export const HEADERS = ['Type', 'Error'];

export const TITLES: Partial<Record<CsvUploadType, string>> = {
  [CSV_UPLOAD_TYPE.FEEDBACK]: 'Feedback - Error Upload',
};

import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Progress, Typography } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../../shared/ui/colors';

const { Paragraph: AntParagraph } = Typography;

export const ProgressBar = styled(Progress)`
  margin: 0 16px;
  width: 90%;
`;

export const Paragraph = styled(AntParagraph)`
  color: ${BaseColors.neutral100};
`;

export const PauseIcon = styled(PauseOutlined)`
  color: ${BaseColors.neutral00};
  font-size: 16px;
`;

export const PlayIcon = styled(CaretRightOutlined)`
  color: ${BaseColors.neutral00};
  font-size: 16px;
`;

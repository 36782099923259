import { BackgroundCheck } from '@agentsonly/models';
import { useImmerAtom } from 'jotai-immer';

import { agentByIdAtom, agentsTableAtom } from '../../../../atoms/agent';
import { updateAgent } from '../../../../firebase/agent';
import {
  getBackgroundCheckFinalStatus,
  getBackgroundCheckStatuses,
  getReviewStatus,
} from '../services/backgroundCheckStatus';

export const useBackgroundCheck = (agentId: string) => {
  const [agents, setAgents] = useImmerAtom(agentByIdAtom);
  const [, setAgentsTable] = useImmerAtom(agentsTableAtom);
  const agent = agents[agentId];

  const saveBackgroundCheck = async (backgroundCheck: BackgroundCheck) => {
    const backgroundCheckStatuses = getBackgroundCheckStatuses(
      agent.address.countryCode,
      backgroundCheck,
    );
    await updateAgent(agentId, {
      backgroundCheck,
    });
    setAgents((draftAgents) => {
      const draftAgent = draftAgents[agentId];
      draftAgent.backgroundCheck = {
        ...backgroundCheck,
        status: getBackgroundCheckFinalStatus(backgroundCheckStatuses),
        reviewStatus: getReviewStatus(backgroundCheckStatuses),
      };
    });
    setAgentsTable((draftAgentsTable) => {
      const agentIndex = draftAgentsTable.findIndex((a) => a.id === agentId);
      if (agentIndex !== -1) {
        const tableAgent = draftAgentsTable[agentIndex];
        tableAgent.backgroundCheck = {
          ...backgroundCheck,
          status: getBackgroundCheckFinalStatus(backgroundCheckStatuses),
          reviewStatus: getReviewStatus(backgroundCheckStatuses),
        };
      }
    });
  };

  return {
    agent,
    saveBackgroundCheck,
  };
};

import { Col, Row, Typography } from 'antd';

import { FormInput } from '../../../../../../shared/ui/form/FormInput';
import { Container, PaddingContainer, StyledText } from './styledComponents';

const { Title, Text } = Typography;

const CRITERIA = {
  adherence: 'Adherence %',
  cancellation: 'Cancellation %',
  surgeMins: 'Surge Mins',
  starRating: 'Star Rating',
} as const;

const CriteriaKeys: (keyof typeof CRITERIA)[] = [
  'adherence',
  'cancellation',
  'surgeMins',
  'starRating',
];

type Props = {
  tierKey: string;
  label: string;
  disabled: boolean;
  parentFormKey?: string;
};
export const TierCriteria = ({
  tierKey,
  label,
  parentFormKey = 'preferential',
  disabled,
}: Props) => (
  <Container>
    <PaddingContainer gutter={[2, 2]}>
      <Col>
        <Row>
          <Title level={4} color="black">
            {label}
          </Title>
        </Row>
        {CriteriaKeys.map((criteriaKey) => {
          const title = CRITERIA[criteriaKey];
          const criteriaInputKey = `${parentFormKey}.${tierKey}.weeklyEligibilityCriteria.${criteriaKey}`;
          return (
            <Row key={criteriaKey} gutter={[0, 8]}>
              <Col>
                <Text>{title}</Text>
              </Col>
              <Col span={24}>
                <Row wrap={false} align="middle">
                  <FormInput
                    data-testid={`${criteriaInputKey}.min`}
                    type="number"
                    name={`${criteriaInputKey}.min`}
                    placeholder="Enter value"
                    disabled={disabled}
                  />
                  <StyledText>to</StyledText>
                  <FormInput
                    data-testid={`${criteriaInputKey}.max`}
                    type="number"
                    name={`${criteriaInputKey}.max`}
                    placeholder="Enter value"
                    disabled={disabled}
                  />
                </Row>
              </Col>
            </Row>
          );
        })}
      </Col>
    </PaddingContainer>
  </Container>
);

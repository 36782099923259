import get from 'lodash.get';

import {
  AIChatType,
  AiPrompt,
  AiPromptConfig,
  AiTemplateType,
  BaseAiSettings,
} from '../../../../firebase/models/Prompts';
import { getCryptoPublicKey } from '../../../../utils/consts';
import { cryptoEncrypt } from '../../../../utils/crypto';

export const CREDENTIALS_PATH = 'settings.apiCredentials';

const doEncryptKey = async (
  apiKey: string | undefined,
  existingEncryptedKey: string,
  existingMaskedKey: string,
) => {
  if (!apiKey?.trim()) {
    return '';
  }

  // API key is not changed so returning the existing encryptedKey
  if (existingMaskedKey === apiKey) {
    return existingEncryptedKey;
  }

  const encryptedKey = await cryptoEncrypt(getCryptoPublicKey(), apiKey);
  return encryptedKey;
};

export const encryptAPIKeyFromAPICredential = (
  apiCredential: BaseAiSettings['apiCredentials'],
  apiKey?: string,
) => {
  const { maskedKey: existingMaskedKey, encryptedKey: existingEncryptedKey } =
    apiCredential ?? {};

  return doEncryptKey(
    apiKey,
    existingEncryptedKey ?? '',
    existingMaskedKey ?? '',
  );
};

export const encryptAPIKey = async (
  aiPrompt: AiPrompt | AiPromptConfig | undefined,
  apiKey: string,
  type: AiTemplateType | AIChatType,
) => {
  const existingMaskedKey = get(
    aiPrompt,
    `${type}.${CREDENTIALS_PATH}.maskedKey`,
    '',
  );

  const existingEncryptedKey = get(
    aiPrompt,
    `${type}.${CREDENTIALS_PATH}.encryptedKey`,
    '',
  );

  return doEncryptKey(apiKey, existingEncryptedKey, existingMaskedKey);
};

export const maskKey = (key: string) => `${key.slice(0, 3)}***${key.slice(-3)}`;

import { isDev, isStage } from '../../utils/consts';

export const getEnvironmentDomain = (
  isDevEnvironment: boolean,
  isStageEnvironment: boolean,
) => {
  let env = 'agents';

  if (isDevEnvironment) {
    env = 'agents-dev';
  } else if (isStageEnvironment) {
    env = 'agents-stage';
  }

  return `https://${env}.agentsonly.com`;
};

export const getAppDomain = () => getEnvironmentDomain(isDev(), isStage());

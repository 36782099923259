import { Agent, AgentsProject, PcCheck, Security } from '@agentsonly/models';
import firebase from 'firebase/compat/app';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { TableFilterType } from '../../atoms/agentTable';
import { SpeedTestResultData } from '../../shared/models/SpeedTest';
import { chunk } from '../../utils';
import { DeepPartial } from '../../utils/types';
import {
  AgentCollections,
  AgentEnvironmentDocuments,
  AgentProjectCollections,
  AgentProjectStatsCollections,
  RootCollections,
} from '../consts';
import { db } from '../firebaseEntity';
import { ProductiveScheduleStats } from '../models/ProductiveScheduleStats';
import { generalConverter } from '../utils';
import { WorkingExperienceDetail, WorkingExperienceType } from './model';
import { AgentStats } from './stats';

export const getAgentById = async (agentId: string) => {
  const snapshot = await db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
    .get();

  return snapshot.data();
};

export const getAgentsById = async (ids: string[]) => {
  const results = await Promise.all<firebase.firestore.QuerySnapshot<Agent>>(
    chunk(ids, 10).map((chunkIds) =>
      db
        .collection(RootCollections.Agents)
        .where(firebase.firestore.FieldPath.documentId(), 'in', chunkIds)
        .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
        .get(),
    ),
  );

  return results.map((result) => result.docs.map((d) => d.data())).flat();
};

export const getAgentRefByEmailRef = (email: string) =>
  db
    .collection(RootCollections.Agents)
    .where('email', '==', email)
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }));

export const getAgentPcCheckRef = (id: string) =>
  db
    .collection(RootCollections.Agents)
    .doc(id)
    .collection(AgentCollections.AgentEnvironment)
    .doc(AgentEnvironmentDocuments.PcCheck)
    .withConverter(generalConverter<PcCheck>({ convertTimestamp: true }));

export const getAgentSecurityRef = (agentId: string) =>
  doc(
    collection(
      db,
      RootCollections.Agents,
      agentId,
      AgentCollections.AgentEnvironment,
    ),
    AgentEnvironmentDocuments.Security,
  ).withConverter(generalConverter<Security>({ convertTimestamp: true }));

export const getAgentSecurity = async (agentId: string) => {
  const agentSecurity = await getDoc(getAgentSecurityRef(agentId));
  return agentSecurity.data();
};

export const getAgentSpeedTestRef = (id: string) =>
  db
    .collection(RootCollections.Agents)
    .doc(id)
    .collection(AgentCollections.AgentEnvironment)
    .doc(AgentEnvironmentDocuments.SpeedTest)
    .withConverter(generalConverter<SpeedTestResultData>());

export const getAgentByEmail = async (email: string) => {
  const snapshots = await db
    .collection(RootCollections.Agents)
    .where('email', '==', email)
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
    .get();

  if (!snapshots.empty) {
    const firstDoc = snapshots.docs[0];
    return {
      ...firstDoc.data(),
      id: firstDoc.id,
    } as Agent;
  }
  return undefined;
};

export const updateAgent = async (
  agentId: string,
  agent: DeepPartial<Agent>,
) => {
  await db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .set(agent, { merge: true });
};

export const updateHTSeconds = async (agentId: string, seconds: number) => {
  await db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .collection(AgentCollections.AgentStats)
    .doc('default')
    .withConverter(generalConverter<AgentStats>())
    .set(
      { ctr: { totalHTSeconds: seconds }, migrated: { totalHTSeconds: 0 } },
      { merge: true },
    );
};

export const getAgentStats = async (agentId: string) => {
  const data = await db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .collection(AgentCollections.AgentStats)
    .doc('default')
    .withConverter(generalConverter<AgentStats>())
    .get();

  return data.data();
};

const orderTypeMapping = {
  ascend: 'asc',
  descend: 'desc',
};

export const getPaginatedAgentsQuery = async ({
  sort,
  pageSize,
  filters,
  lastItem,
}: TableFilterType) => {
  let query = db.collection(RootCollections.Agents).limit(pageSize);

  if (sort) {
    const sortKey = orderTypeMapping[sort] as 'asc' | 'desc';
    query = query.orderBy('createdAt', sortKey);
  }

  const { status, reviewStatus } = filters;
  // if the status is more than 10 items, we have to update this condition
  if (status !== null) {
    query = query.where('backgroundCheck.status', 'in', status);
  }

  if (reviewStatus !== null) {
    query = query.where('backgroundCheck.reviewStatus', 'in', reviewStatus);
  }

  query = lastItem ? query.startAfter(lastItem) : query;

  return query
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
    .get();
};

// NOTE: If demo agents will be increased over 100, it should fetch with pagination
export const getDemoAgents = async () => {
  // Fetch all demo agents
  const snapshot = await db
    .collection(RootCollections.Agents)
    .where('demo', '==', true)
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
    .get();

  return snapshot.docs.map((agent) => agent.data());
};

export const getAgentProjects = async (agentId: string) => {
  const agentProjects = await getDocs(
    collection(
      db,
      RootCollections.Agents,
      agentId,
      AgentCollections.Projects,
    ).withConverter(generalConverter<AgentsProject>()),
  );

  return agentProjects.docs.map((d) => d.data());
};

export const getAgentProjectStatsRef = (agentId: string, projectId: string) =>
  db
    .collection(RootCollections.Agents)
    .doc(agentId)
    .collection(AgentCollections.Projects)
    .doc(projectId)
    .collection(AgentProjectCollections.AgentProjectStats);

export const getAgentProjectReliabilityRef = (
  agentId: string,
  projectId: string,
) =>
  getAgentProjectStatsRef(agentId, projectId)
    .doc('weekly')
    .collection(AgentProjectStatsCollections.Schedules)
    .withConverter(generalConverter<ProductiveScheduleStats>());

export const getAgentWorkingExperience = async (
  id: string,
  type: WorkingExperienceType,
) => {
  const snapshot = await db
    .collection(RootCollections.Agents)
    .doc(id)
    .collection(AgentCollections.WorkingExperience)
    .doc(type)
    .withConverter(generalConverter<WorkingExperienceDetail>())
    .get();

  return snapshot.data();
};

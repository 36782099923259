import { InitiationMethod } from '@agentsonly/models';
import { setHours, setMinutes } from 'date-fns';
import { v4 as uuid } from 'uuid';

import { getAgentById } from '../../firebase/agent';
import { saveAnalysisRecord } from '../../firebase/analysis';
import { saveCallRecord } from '../../firebase/contactRecords';
import { saveExternalDataRecord } from '../../firebase/externalData';
import { CallRecordTemplate } from '../../firebase/models/contactRecord';
import { convertFSTimestamp } from '../../firebase/utils';
import { randomIntFromRange } from '../../utils/dataUtils';
import { getExternalDataKeysByInstanceId } from '../../utils/externalData';

export const generateParsedCallRecord = (
  callStartDateTime: Date,
  callEndDateTime: Date,
  acwTime: number,
  handleTime: number,
  callACWStartDateTime: Date,
  agentUsername: string,
  newCallRecordId: string,
  initiationMethod: InitiationMethod,
) => {
  const newCallRecord: CallRecordTemplate = {
    original: {
      ConnectedToSystemTimestamp: convertFSTimestamp(callStartDateTime),
      DisconnectTimestamp: convertFSTimestamp(callEndDateTime),
      Agent: {
        AfterContactWorkDuration: Math.round(acwTime),
        AgentInteractionDuration: Math.round(handleTime),
        ConnectedToAgentTimestamp: callStartDateTime,
        AfterContactWorkStartTimestamp: callACWStartDateTime,
        AfterContactWorkEndTimestamp: callEndDateTime,
        Username: agentUsername,
      },
      ContactId: newCallRecordId,
      InitiationTimestamp: callStartDateTime,
      LastUpdateTimestamp: callEndDateTime,
      InitiationMethod: initiationMethod,
    },
  };

  return newCallRecord;
};

/**
 * Creates a call record for the given agent, project and call details
 * May need to move this to Firebase Functions for security reasons
 *
 * Location: projectContactRecords/{instanceId}/contactRecords/{contactRecordId}
 *
 * @returns
 */
export const generateCallRecord = (
  agentUsername: string,
  callStartDateTime: Date,
  handleTime: number,
  acwTime: number,
  newCallRecordId: string,
  initiationMethod: InitiationMethod,
): CallRecordTemplate => {
  const callEndDateTime = new Date(callStartDateTime.getTime());
  callEndDateTime.setSeconds(
    callEndDateTime.getSeconds() + (handleTime + acwTime),
  );

  const callACWStartDateTime = new Date(callStartDateTime.getTime());
  callACWStartDateTime.setSeconds(
    callACWStartDateTime.getSeconds() + handleTime,
  );

  return generateParsedCallRecord(
    callStartDateTime,
    callEndDateTime,
    acwTime,
    handleTime,
    callACWStartDateTime,
    agentUsername,
    newCallRecordId,
    initiationMethod,
  );
};

const generateRandomCallRecord = (
  userName: string,
  tempStartDate: Date,
  handleTimeMins: number,
  acwMins: number,
  initiationMethod: InitiationMethod,
): CallRecordTemplate => {
  const randomPercentage = 1 + randomIntFromRange(1, 30) / 100;
  const randomHandleTimeMins = randomIntFromRange(1, handleTimeMins);
  const randomAcwMins = acwMins === 0 ? 0 : randomIntFromRange(1, acwMins);
  return generateCallRecord(
    userName,
    tempStartDate,
    randomHandleTimeMins * 60 * randomPercentage,
    randomAcwMins * 60 * randomPercentage,
    uuid(),
    initiationMethod,
  );
};

// Data generation example:
// https://docs.google.com/document/d/1ThZnbT_b1u1jiGj5B1nz0OPWYUeH7opzpdFLS9XirGI/edit
export const generateCallRecords = (
  date: Date,
  time: Date,
  userNames: string[],
  callsPerAgent: number,
  handleTimeMins: number,
  acwMins: number,
  initiationMethod: InitiationMethod,
) =>
  userNames.flatMap((name) =>
    Array.from(new Array(callsPerAgent)).flatMap(() => {
      console.log(name);
      const randomStartMinutes = randomIntFromRange(
        time.getMinutes(),
        time.getMinutes() + 29,
      );
      const hours = setHours(date, time.getHours());
      const startTime = setMinutes(hours, randomStartMinutes);
      return generateRandomCallRecord(
        name,
        startTime,
        handleTimeMins,
        acwMins,
        initiationMethod,
      );
    }),
  );

export const fetchUserNames = (approvedAgentIds: string[]): Promise<string[]> =>
  Promise.all(
    approvedAgentIds
      .map(async (agentId) => {
        const agent = await getAgentById(agentId);
        return agent?.userName;
      })
      .filter((userName) => !!userName) as unknown as string[],
  );

export const saveCallRecords = async (
  callRecords: CallRecordTemplate[],
  connectInstanceId?: string,
) => {
  if (!connectInstanceId) {
    throw new Error(`invalid instance id ${connectInstanceId}`);
  }

  const externalDataKeys =
    await getExternalDataKeysByInstanceId(connectInstanceId);

  for (let i = 0; i < callRecords.length; i += 1) {
    const record = callRecords[i];
    const { original } = record;

    console.log(`saving record ${original.ContactId}`);

    /* eslint-disable no-await-in-loop */
    await saveExternalDataRecord(
      connectInstanceId,
      original.ContactId,
      externalDataKeys,
    );
    await saveCallRecord(connectInstanceId, record);
    await saveAnalysisRecord(connectInstanceId, original.ContactId);
    /* eslint-enable no-await-in-loop */
  }

  return callRecords.length;
};

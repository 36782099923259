import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Radio, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import { Behavior } from '../../../../firebase/models/Coaching';
import { MinusIcon } from '../../../../shared/assets';
import { FormInput } from '../../../../shared/ui/form/FormInput';
import { Modal } from '../../../../shared/ui/Modal';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { DEFAULT_ACTION } from '../../consts';
import { coachingBehaviorSchema } from './consts';
import {
  StyledAddNewActionButton,
  StyledDeleteButton,
  StyledRemoveButton,
  StyledSaveButton,
} from './styledComponents';

const { Text } = Typography;

type BehaviorModalProps = {
  header: string;
  isLoading: boolean;
  initialValue: Behavior;
  isEdit?: boolean;
  onSubmit: (value: Behavior) => void;
  onDismiss: () => void;
  onDelete?: () => void;
  projectId: string;
};

enum BehaviorStatus {
  LIVE = 'live',
  OFF = 'off',
}

const ACTIONS_FIELD_NAME = 'actions';
const BEHAVIOR_FIELD_NAME = 'name';

export const BehaviorModal = ({
  header,
  isLoading,
  initialValue,
  isEdit = false,
  onSubmit,
  onDismiss,
  onDelete,
  projectId,
}: BehaviorModalProps) => {
  const form = useForm<Behavior>({
    mode: 'all',
    defaultValues: initialValue,
    resolver: yupResolver(coachingBehaviorSchema),
    context: {
      projectId,
      behaviorId: initialValue.id,
    },
  });

  const {
    setFocus,
    formState: { isDirty, isValid },
  } = form;

  useEffect(() => setFocus(BEHAVIOR_FIELD_NAME), [setFocus]);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: ACTIONS_FIELD_NAME,
  });

  const onAddNewAction = async () => {
    append(DEFAULT_ACTION);
    await form.trigger(ACTIONS_FIELD_NAME, { shouldFocus: true });
  };

  const onRemoveAction = async (index: number) => {
    remove(index);
    await form.trigger(ACTIONS_FIELD_NAME, { shouldFocus: true });
  };

  const disabledBtnSave = !isValid || isLoading || !isDirty;

  return (
    <Modal title={header} open footer={null} width={800} onCancel={onDismiss}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Row>
            <Col span={24}>
              <FormInput
                name={BEHAVIOR_FIELD_NAME}
                label="Behavior Name"
                placeholder="Name"
              />
            </Col>
            <Col span={24}>
              <FormInput
                name="description"
                type="textarea"
                label="Behavior Description"
                placeholder="Enter text"
                height={200}
              />
            </Col>
          </Row>
          {fields.map((item, index) => (
            <Row
              justify="space-between"
              align="middle"
              key={`ActionContainer${index + 1}`}
            >
              <Col span={23}>
                <Row key={item.id} gutter={12}>
                  <Col span={12} style={{ paddingRight: '8px' }}>
                    <FormInput
                      name={`${ACTIONS_FIELD_NAME}.${index}.description`}
                      type="textarea"
                      label={`Recommended Action ${index + 1}`}
                      placeholder="Enter text"
                      height={92}
                    />
                  </Col>
                  <Col span={11}>
                    <FormInput
                      name={`${ACTIONS_FIELD_NAME}.${index}.link`}
                      type="textarea"
                      label={`Action ${index + 1} Link (Optional)`}
                      placeholder="https://www.sample.com"
                      height={92}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={1}>
                {index > 0 && (
                  <StyledRemoveButton
                    danger
                    onClick={() => onRemoveAction(index)}
                  >
                    <MinusIcon />
                  </StyledRemoveButton>
                )}
              </Col>
            </Row>
          ))}
          <Row>
            <Col span={24}>
              {fields.length < 5 && (
                <StyledAddNewActionButton onClick={onAddNewAction}>
                  Add New Action
                </StyledAddNewActionButton>
              )}
            </Col>
          </Row>
          <Spacer axis="vertical" size={30} />
          <Row>
            <Col span={24}>
              <Text>Status</Text>
            </Col>
            <Spacer axis="vertical" size={16} />
            <Col span={24}>
              <Controller
                control={form.control}
                name="status"
                render={({ field }) => (
                  <Radio.Group
                    name={field.name}
                    disabled={field.disabled}
                    value={field.value}
                    ref={field.ref}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    <Radio value={BehaviorStatus.LIVE}>Live</Radio>
                    <Radio value={BehaviorStatus.OFF}>Off</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>
          <Controller
            control={form.control}
            name="displayOrder"
            render={({ field }: any) => (
              <input
                {...field}
                type="hidden"
                value={initialValue.displayOrder}
              />
            )}
          />
          <Spacer axis="vertical" size={24} />
          <Row justify="end">
            {isEdit && (
              <>
                <StyledDeleteButton loading={isLoading} onClick={onDelete}>
                  Delete
                </StyledDeleteButton>
                <Spacer axis="horizontal" size={10} />
              </>
            )}
            <StyledSaveButton
              loading={form.formState.isSubmitting}
              disabled={disabledBtnSave}
              htmlType="submit"
            >
              Save
            </StyledSaveButton>
          </Row>
        </form>
      </FormProvider>
    </Modal>
  );
};

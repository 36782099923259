import { Checkbox, Col, notification, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { db } from '../firebase';
import { RootCollections } from '../firebase/consts';
import { Button } from '../shared/ui/Button';

const { Title } = Typography;

const options = [
  { label: 'CTR', value: 'CTR' },
  { label: 'CLA', value: 'CLA' },
  { label: 'External', value: 'External' },
];

const transferRef = db.collection(RootCollections.Configs).doc('transferData');

type Props = {
  disabled: boolean;
};

export const TransferData: React.FC<Props> = ({ disabled }) => {
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    transferRef.onSnapshot((snapshot) => {
      const d = snapshot.data() || {};
      setData(Object.keys(d).filter((key) => d[key]));
    });
  }, []);

  return (
    <>
      <Row justify="start">
        <Title level={3} style={{ color: 'black' }}>
          Transfer Data
        </Title>
      </Row>
      <Row justify="start">
        <Title level={5} style={{ color: 'red' }}>
          After testing is done, make sure toggle off all checkboxes, otherwise
          keep transfer data and would cost us, <br />
          double check with staging firebase console
        </Title>
      </Row>
      <Col style={{ margin: '16px' }}>
        <Checkbox.Group
          disabled={disabled}
          options={options}
          value={data}
          onChange={(values) => {
            setData(values as string[]);
          }}
        />
      </Col>
      <Button
        disabled={disabled}
        onClick={async () => {
          const update = options.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.value]: data?.includes(cur.value) ?? false,
            }),
            {},
          );
          await transferRef.set(update, { merge: true });

          notification.success({ message: `transferData is updated` });
        }}
      >
        Update
      </Button>
    </>
  );
};

import { Agent } from '@agentsonly/models';
import { Row, Table, TableProps } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchAgentsLoadingAtom } from '../atoms/agent';
import { Button } from '../shared/ui/Button';
import { createFileToDownload } from '../utils/file';
import { getColumns } from './columns';
import { AgentSearch } from './components/AgentSearch';
import { useAgentTable } from './hooks/useAgentTable';
import { AgentScreenContainer } from './styledComponents';

function createFileBody(agents: Array<Agent>): string {
  let body = '';

  agents.forEach((agent) => {
    body += `${agent.id},${agent.email},${agent.firstName} ${agent.lastName},${agent.phoneNumber}\n`;
  });
  return body;
}

export const Agents: React.FC = () => {
  const navigate = useNavigate();
  const [loading] = useAtom(fetchAgentsLoadingAtom);
  const { agents, tableData, fetchAgents, updatePage } = useAgentTable();
  const columns = getColumns();

  const isNeedToFetchNextPage = (pageIndex: number) =>
    pageIndex > tableData.fetchedPageIndex;

  const handleTableChanged: TableProps<Agent>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    // Typescript user type guard. Currently sorter is date field only
    const singleSorter = !Array.isArray(sorter) ? sorter : sorter[0];
    const { pageSize, current } = pagination;

    if (extra.action !== 'paginate') {
      fetchAgents({
        isAgentsDataRest: true,
        filters,
        sort: singleSorter.order ?? 'descend',
        pageIndex: 1,
        pageSize: tableData.pageSize,
      });
      return;
    }

    // Pagination changes
    // If the current page is greater than the fetched page index, fetch the next page
    if (current && isNeedToFetchNextPage(current)) {
      fetchAgents({
        filters,
        sort: singleSorter.order ?? 'descend',
        pageIndex: current,
        pageSize: tableData.pageSize,
      });
      return;
    }

    // If the page size changes, flush the current data and fetch the first page
    if (pageSize !== tableData.pageSize) {
      fetchAgents({
        isAgentsDataRest: true,
        filters,
        sort: singleSorter.order ?? 'descend',
        pageIndex: 1,
        pageSize: pageSize ?? tableData.pageSize,
      });
    }

    // When user change to the previous page
    // Just update pageIndex
    updatePage(current ?? 1);
  };

  // Runs only once
  useEffect(() => {
    if (agents.length === 0) {
      fetchAgents({});
    }
  }, [agents]);

  return (
    <AgentScreenContainer>
      <AgentSearch />
      <div style={{ overflowY: 'scroll' }}>
        <Table
          rowKey="id"
          dataSource={agents}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: tableData.pageSize,
            position: ['bottomLeft'],
            showSizeChanger: true,
            total: agents.length + (tableData.lastItem !== null ? 1 : 0),
            current: tableData.currentPageIndex,
          }}
          onChange={handleTableChanged}
          onRow={(agent: Agent) => ({
            onClick: () => {
              navigate(`/agents/${agent.id}`);
            },
          })}
          data-testid="admin:Agents.list:table"
        />
        <Row justify="end">
          <Button
            style={{ width: '160px' }}
            onClick={() => {
              const title = 'users.csv';
              const body = createFileBody(agents);

              createFileToDownload(title, body);
            }}
            data-testid="admin:Agents.list.button:downloadcsv"
          >
            Download .csv
          </Button>
        </Row>
      </div>
    </AgentScreenContainer>
  );
};

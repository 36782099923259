import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

export const ColorfulTitle = styled(Title)<{ color: string }>`
  color: ${({ color }) => color} !important;
`;

export const RestrictableDiv = styled.div<{ $restricted?: boolean }>`
  opacity: ${({ $restricted }) => ($restricted ? '0.6' : '1')};
  pointer-events: ${({ $restricted }) => ($restricted ? 'none' : 'auto')};
`;

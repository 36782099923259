import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';

import { EmptyBehavior } from '../../types';
import { ImageEmpty } from './ImageEmpty';
import {
  EmptyBehaviorContent,
  LiveBehaviorContainer,
} from './styledComponents';

const { Title, Paragraph } = Typography;

type Props = {
  emptyBehaviors: EmptyBehavior[];
  onOpenCreateBehaviorModal: () => void;
};

export const EmptyLiveBehaviorUI = ({
  emptyBehaviors,
  onOpenCreateBehaviorModal,
}: Props) => (
  <div>
    {emptyBehaviors.map((item, index) => (
      <LiveBehaviorContainer
        $hoverPointer={index === 0 ? 'pointer' : 'arrow'}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        <Row
          align="middle"
          justify="space-between"
          wrap={false}
          onClick={index === 0 ? onOpenCreateBehaviorModal : undefined}
        >
          <Col
            flex="120px"
            style={{
              height: '120px',
            }}
          >
            <ImageEmpty disable={index !== 0} number={item.displayOrder} />
          </Col>
          <Col flex="auto">
            <EmptyBehaviorContent>
              <Title
                style={{
                  color: '#202629',
                  marginBottom: '8px',
                  marginTop: '0',
                  fontSize: '24px',
                }}
              >
                Live Behavior {item.displayOrder}
              </Title>
              <Paragraph
                style={{
                  color: '#5A6369',
                  fontSize: '16px',
                  marginBottom: 0,
                }}
              >
                You don&lsquo;t have any behaviour created.
              </Paragraph>
              <Paragraph
                style={{ color: '#5A6369', fontSize: '16px', marginBottom: 0 }}
              >
                Please drag and drop or start creating one!
              </Paragraph>
            </EmptyBehaviorContent>
          </Col>
          <Col flex="33px">
            <PlusOutlined
              style={{
                fontSize: '32px',
                color: `${index === 0 ? '#078768' : '#A5B1B9'}`,
              }}
            />
          </Col>
        </Row>
      </LiveBehaviorContainer>
    ))}
  </div>
);

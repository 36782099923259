import { v4 as uuid } from 'uuid';

import { useLocalStorage } from './useLocalStorage';

const MFA_BROWSER_ID_KEY = 'browserId';

export const useBrowserId = () => {
  const [browserId, setBrowserId] = useLocalStorage(MFA_BROWSER_ID_KEY, '');

  const getBrowserId = () => {
    if (browserId) {
      return browserId;
    }

    const uniqueKey = uuid();
    setBrowserId(uniqueKey);
    return uniqueKey;
  };

  const clearBrowserId = () => setBrowserId('');

  return { getBrowserId, clearBrowserId };
};

import styled from 'styled-components';

export const StyledHeading = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  width: 100%;
  height: 112px;
  border: 1px solid #dae4ea;
  box-shadow:
    0px 0px 4px rgba(38, 110, 95, 0.05),
    0px 12px 24px rgba(29, 173, 142, 0.05);

  button {
    margin-left: 24px;
  }
`;

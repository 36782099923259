import { atom } from 'jotai';

import {
  createAdminRole,
  deleteAdminRole,
  getAdminPermissions,
  getAdminRoles,
  updateAdminRole,
} from '../firebase/adminRoles';
import {
  AdminPermission,
  AdminPermissions,
  AdminRole,
  AdminTab,
} from '../firebase/adminRoles/model';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const adminRolesAsyncValueAtom = atom<AsyncValue<AdminRole[]>>({
  loading: true,
});

export const adminRolesAtom = asyncAtom(
  adminRolesAsyncValueAtom,
  getAdminRoles,
);

adminRolesAtom.onMount = (run) => {
  run();
};
export const currentAdminRoleAtom = atom<string[] | undefined>(undefined);

export const isCurrentUserAdmin = atom<boolean>((get) => {
  const roles = get(currentAdminRoleAtom);
  return roles?.includes('admin') ?? false;
});

export const currentAdminPermissionsAtom = atom<Partial<AdminPermissions>>(
  (get) => {
    const currentRoles = get(currentAdminRoleAtom);
    const { data: roles } = get(adminRolesAtom);

    const permissions = currentRoles?.map((cr) => {
      if (cr === 'admin') {
        return getAdminPermissions();
      }
      return roles?.find((role) => role.id === cr)?.permissions ?? {};
    });

    const permission = permissions?.reduce<Partial<AdminPermissions>>(
      (obj, p) => {
        Object.entries(p).forEach((value) => {
          const tab = value[0] as AdminTab;
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const permission = value[1];
          if (obj[tab] === undefined || obj[tab] === AdminPermission.View) {
            // eslint-disable-next-line no-param-reassign
            obj[tab] = permission;
          }
        });

        return obj;
      },
      {},
    );
    return permission ?? {};
  },
);

export const createAdminRoleAtom = asyncAtom<
  AdminRole[],
  Omit<AdminRole, 'id'>
>(
  adminRolesAsyncValueAtom,
  async (data, prev) => {
    const role = await createAdminRole(data);
    const newRoles = prev.data ?? [];
    return [...newRoles, role];
  },
  true,
);

export const updateAdminRoleAtom = asyncAtom<AdminRole[], AdminRole>(
  adminRolesAsyncValueAtom,
  async (data, prev) => {
    const role = await updateAdminRole(data);
    return prev?.data?.map((r) => (r.id === role.id ? role : r)) ?? [];
  },
  true,
);

export const deleteAdminRoleAtom = asyncAtom<AdminRole[], string>(
  adminRolesAsyncValueAtom,
  async (id, prev) => {
    await deleteAdminRole(id);
    return prev?.data?.filter((r) => r.id !== id) ?? [];
  },
  true,
);

import firebase from 'firebase/compat/app';

import {
  getCsvFileCollectionRef,
  getCsvFileRef,
} from '../../firebase/csvUpload';
import { CsvUpload } from '../../firebase/models/CsvUpload';
import { ProcessingStatus } from '../../Project/consts';

export const createCsvFile = async (
  projectId: string,
  templateType: string,
  filterConditions?: Record<string, any>,
) => {
  const fileColRef = getCsvFileCollectionRef(projectId);
  return fileColRef.add({
    templateType,
    processingStatus: ProcessingStatus.UPLOADING,
    uploadedAt: firebase.firestore.Timestamp.now(),
    filterConditions,
    totalRows: 0,
    metadata: {},
  } as CsvUpload);
};

export const updateCsvFile = async (
  projectId: string,
  fileId: string,
  filePath: string,
  status: ProcessingStatus,
) => {
  const fileRef = getCsvFileRef(projectId, fileId);
  return fileRef.set({ filePath, processingStatus: status }, { merge: true });
};

export const getCsvFile = async (projectId: string, fileId: string) => {
  const snapshot = await getCsvFileRef(projectId, fileId).get();
  return snapshot.data();
};

import { Project } from '@agentsonly/models';
import type { TabsProps } from 'antd';
import { App, Tabs } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useAtom } from 'jotai';
import React, { useCallback } from 'react';

import { projectByIdAtom, updateProjectAtom } from '../../atoms/project';
import { Switch } from '../../shared/ui/form/FormSwitch/Switch';
import { AgentTweak } from './AgentTweak';
import { ClientTweak } from './ClientTweak';
import { CronJob } from './CronJob';

const DEFAULT_KEY = 'cronJob';

const tabItems = (project: Project, disabled: boolean): TabsProps['items'] => [
  {
    key: DEFAULT_KEY,
    label: 'CRON Job',
    children: <CronJob project={project} disabled={disabled} />,
    disabled: !project.demo || disabled,
  },
  {
    key: 'clientTweak',
    label: 'Client Tweak',
    children: <ClientTweak project={project} disabled={disabled} />,
    disabled: !project.demo || disabled,
  },
  {
    key: 'agentTweak',
    label: 'Agent Tweak',
    children: <AgentTweak project={project} disabled={disabled} />,
    disabled: !project.demo || disabled,
  },
];

type Props = {
  project: Project;
  disabled: boolean;
};

export const Demo: React.FC<Props> = ({ project, disabled }) => {
  const { message } = App.useApp();
  const [currenctProject] = useAtom(projectByIdAtom);
  const [, updateProject] = useAtom(updateProjectAtom);

  const handleDemoChange: SwitchChangeEventHandler = useCallback(
    async (value) => {
      try {
        await updateProject({ id: project.id, demo: value });
        message.success('Project has been updated');
      } catch (e) {
        if (e instanceof Error) {
          message.error(e.message);
        }
      }
    },
    [project, updateProject, message],
  );

  return (
    <>
      <Switch
        label="Demo Project"
        checked={project.demo}
        onChange={handleDemoChange}
        disabled={disabled}
      />
      {currenctProject[project.id].demo && (
        <Tabs
          defaultActiveKey={DEFAULT_KEY}
          items={tabItems(project, disabled)}
        />
      )}
    </>
  );
};

import { AgentSkill, QuizSkill, Skill, TypingSkill } from '@agentsonly/models';
import firebase from 'firebase/compat/app';

import { chunk } from '../../utils';
import { AgentCollections, RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';

export enum SkillType {
  TYPING = 'typing',
  QUIZ = 'quiz',
}

const getAgentTestingSkills = async <T extends AgentSkill>(
  agentId: string,
  skillIds: string[],
) => {
  const snapshotPromises = chunk(skillIds, 10).map((ids) =>
    db
      .collection(RootCollections.Agents)
      .doc(agentId)
      .collection(AgentCollections.Skills)
      .withConverter(generalConverter<T>())
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .get(),
  );

  const snapshots = await Promise.all(snapshotPromises);
  return snapshots.map((s) => s.docs.map((d) => d.data())).flat();
};

export const getTypingTests = async (agentId: string, skillIds: string[]) =>
  getAgentTestingSkills<TypingSkill>(agentId, skillIds);

export const getQuizSkills = (agentId: string, skillIds: string[]) =>
  getAgentTestingSkills<QuizSkill>(agentId, skillIds);

export const getSkills = async () => {
  const snapshot = await db
    .collection(RootCollections.SKills)
    .withConverter(generalConverter<Skill>())
    .get();

  return snapshot.docs.map((d) => d.data());
};

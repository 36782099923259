import { PlatformRate, PlatformRateType } from '@agentsonly/models';

export const PLATFORM_RATE_MAPPING: Record<PlatformRateType, string> = {
  perCall: 'Per Minute',
  scheduled: 'Hourly Productive',
  transactional: 'Transactional',
};

export const PLATFORM_RATE_DROPDOWN: Array<{
  value: PlatformRateType;
  label: string;
}> = [
  {
    value: 'perCall',
    label: PLATFORM_RATE_MAPPING.perCall,
  },
  {
    value: 'scheduled',
    label: PLATFORM_RATE_MAPPING.scheduled,
  },
  {
    value: 'transactional',
    label: PLATFORM_RATE_MAPPING.transactional,
  },
];

export type PlatformRateField = Omit<
  PlatformRate,
  'id' | 'updatedAt' | 'createdAt'
>;

import { Security } from '@agentsonly/models';
import React, { useEffect, useState } from 'react';

import { getAgentSecurity } from '../../../../firebase/agent';
import { ResultContent } from './ResultContent';
import { getColorAndBgColor, NEEDS_VALIDATION, PASSED } from './utils';

type Props = {
  agentId: string;
};

const useSecurity = (agentId: string) => {
  const [security, setSecurity] = useState<Security | undefined>();
  useEffect(() => {
    (async () => {
      const result = await getAgentSecurity(agentId);
      setSecurity(result);
    })();
  }, [agentId]);
  return security;
};

export const SecurityCheckResult: React.FC<Props> = ({ agentId }) => {
  const security = useSecurity(agentId);

  const isAntivirusPassed = security?.antivirus?.continuousEnabled || false;
  const antivirusStatus = isAntivirusPassed ? PASSED : NEEDS_VALIDATION;
  const antivirusColorAndBgColor = getColorAndBgColor(antivirusStatus);
  const isFirewallPassed = security?.firewall?.enabled || false;
  const firewallStatus = isFirewallPassed ? PASSED : NEEDS_VALIDATION;
  const firewallColorAndBgColor = getColorAndBgColor(firewallStatus);

  const contents = [
    {
      id: 'antivirus',
      label: 'Antivirus Software Check',
      value: antivirusStatus,
      badge: true,
      badgeColor: antivirusColorAndBgColor.color,
      badgeBgColor: antivirusColorAndBgColor.bgColor,
    },
    {
      id: 'firewall',
      label: 'Firewall Status Check',
      value: isFirewallPassed ? PASSED : NEEDS_VALIDATION,
      badge: true,
      badgeColor: firewallColorAndBgColor.color,
      badgeBgColor: firewallColorAndBgColor.bgColor,
    },
  ];

  return <ResultContent contents={contents} />;
};

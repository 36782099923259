import { atom } from 'jotai';

import {
  AGENT_REFERRAL_WEEKLY_LIMIT,
  getConfigReferrals,
  getWeeklyLimits,
  removeWeeklyLimit,
  replaceWeeklyLimits,
  updateReferral,
} from '../firebase/configs';
import { AgentReferralWeeklyLimit } from '../firebase/configs/model';
import { chunk } from '../utils';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const globalQuotaAsyncValueAtom = atom<AsyncValue<number | undefined>>({
  loading: true,
});

export const globalQuotaAtom = asyncAtom(
  globalQuotaAsyncValueAtom,
  async () => {
    const referrals = await getConfigReferrals();
    return referrals?.weeklyReferralLimit;
  },
);

globalQuotaAtom.onMount = (run) => {
  run();
};

export const updateWeeklyReferralLimitAtom = asyncAtom<
  number | undefined,
  number | undefined
>(globalQuotaAsyncValueAtom, async (weeklyReferralLimit) => {
  await updateReferral({ weeklyReferralLimit });
  return weeklyReferralLimit;
});

const agentWeeklyLimitsAsyncValueAtom = atom<
  AsyncValue<AgentReferralWeeklyLimit[][]>
>({
  loading: true,
  data: [],
});

export const agentWeeklyLimitsAtom = asyncAtom<
  AgentReferralWeeklyLimit[][],
  void
>(agentWeeklyLimitsAsyncValueAtom, async (_, value) => {
  const data = value?.data ?? [];

  const lastPage = data[data.length - 1] ?? [];
  const lastPageItem = lastPage[lastPage.length - 1];

  if (data.length > 0 && lastPage.length < AGENT_REFERRAL_WEEKLY_LIMIT) {
    return value.data ?? [];
  }

  const list = await getWeeklyLimits(lastPageItem);
  return [...data, list];
});

agentWeeklyLimitsAtom.onMount = (run) => {
  run();
};

export const replaceWeeklyLimitsAtom = asyncAtom<
  AgentReferralWeeklyLimit[][],
  AgentReferralWeeklyLimit[]
>(agentWeeklyLimitsAsyncValueAtom, async (limits) => {
  const newLimits = await replaceWeeklyLimits(limits);
  return chunk(newLimits, AGENT_REFERRAL_WEEKLY_LIMIT);
});

export const removeWeeklyLimitAtom = asyncAtom<
  AgentReferralWeeklyLimit[][],
  AgentReferralWeeklyLimit
>(agentWeeklyLimitsAsyncValueAtom, async (limit, value) => {
  await removeWeeklyLimit(limit);
  const flatData =
    value.data?.flat().filter((item) => item.id !== limit.id) ?? [];
  return chunk(flatData, AGENT_REFERRAL_WEEKLY_LIMIT);
});

import type { ContactRecord, Project } from '@agentsonly/models';
import { Table, Typography } from 'antd';
import { endOfDay, format, startOfYesterday } from 'date-fns';
import { useEffect, useState } from 'react';

import { getMissingCLAInCTRs } from '../../firebase/contactRecords';
import { DatePicker } from '../../utils/DatePicker';
import { getProjectTZLabel, toVancouverTime } from '../../utils/timezone';

const { Title } = Typography;

type Props = {
  project: Project;
};

const columns = [
  {
    title: 'Contact ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Agent ID',
    dataIndex: 'agentId',
    key: 'agentId',
  },
  {
    title: 'DisconnectTimestamp',
    dataIndex: 'original',
    key: 'original',
    render: (original: ContactRecord['original']) => {
      const date = toVancouverTime(original.DisconnectTimestamp ?? new Date());
      return (
        <span key={original.ContactId}>
          {`${format(date, 'Pp')} (${getProjectTZLabel(date)})`}
        </span>
      );
    },
  },
];

export const MissingCLA = ({ project }: Props) => {
  const [selectedDate, setSelectedDate] = useState(startOfYesterday());
  const [ctrs, setCTRs] = useState<ContactRecord[]>([]);
  useEffect(() => {
    (async () => {
      // TODO: Remove once we migrate all project to ccassProjectProps
      const instanceId =
        project.ccaasProjectProps?.instanceIdentifier ??
        project.connectDetails?.instanceId;

      const results = await getMissingCLAInCTRs(
        toVancouverTime(selectedDate, true),
        toVancouverTime(endOfDay(selectedDate), true),
        instanceId,
      );

      setCTRs(results);
    })();
  }, [selectedDate, project]);

  return (
    <>
      <DatePicker
        key="datePicker"
        defaultValue={selectedDate}
        onChange={(date: Date | null) => setSelectedDate(date ?? new Date())}
      />
      <Title
        key="title"
        level={2}
      >{`Missing CLA in CTRs: ${ctrs.length}`}</Title>
      <Table
        key="table"
        rowKey="id"
        showHeader
        dataSource={ctrs}
        columns={columns}
        bordered
      />
    </>
  );
};

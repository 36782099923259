import { Typography } from 'antd';
import { memo } from 'react';

import { IconEmptyResult } from '../../shared/assets';
import { StyledEmptyState } from './styledComponents';

const { Text, Title } = Typography;

export const EmptyState = memo(() => (
  <StyledEmptyState>
    <IconEmptyResult />
    <Title level={2}>No Platform Rates Available</Title>
    <Text type="secondary">
      Platform Pay Rates will appear in this section after <br />
      Platform Admin creates them
    </Text>
  </StyledEmptyState>
));

import { DeleteFilled } from '@ant-design/icons';
import { App, Col, Row, Table, Typography } from 'antd';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';

import {
  agentWeeklyLimitsAtom,
  removeWeeklyLimitAtom,
  replaceWeeklyLimitsAtom,
} from '../../../../atoms/quota';
import { AGENT_REFERRAL_WEEKLY_LIMIT } from '../../../../firebase/configs';
import { AgentReferralWeeklyLimit } from '../../../../firebase/configs/model';
import { useModal } from '../../../../shared/hooks/useModal';
import { Button } from '../../../../shared/ui/Button';
import { Switch } from '../../../../shared/ui/form/FormSwitch/Switch';
import { ImportCSVModal } from '../../../../shared/ui/ImportCSVModal';
import { parseCSV } from '../../../../utils/csv';
import { createFileToDownload } from '../../../../utils/file';
import { TableRow } from './styledComponents';
import { TEMPLATE_DATA, TEMPLATE_NAME } from './template';

const { Text } = Typography;

type Props = {
  disabled: boolean;
};

type CSVRow = {
  agentId: string;
  agentEmail: string;
  weeklyQuota: number;
};

const csvToLimitMapper = (rows: CSVRow[]) =>
  rows
    .map<AgentReferralWeeklyLimit>((row) => ({
      id: row.agentId,
      email: row.agentEmail,
      weeklyReferralLimit: row.weeklyQuota,
    }))
    .filter(
      ({ id, email, weeklyReferralLimit }) =>
        typeof id === 'string' &&
        typeof email === 'string' &&
        typeof weeklyReferralLimit === 'number',
    );

const handleDownloadTemplate = () => {
  const template = TEMPLATE_DATA.map((data) => data.join(',')).join('\n');
  createFileToDownload(TEMPLATE_NAME, template);
};

export const CustomQuota: React.FC<Props> = ({ disabled }) => {
  const { notification } = App.useApp();
  const [custom, setCustom] = useState(false);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [{ data: agentWeeklyLimits }, updateAgentWeeklyLimits] = useAtom(
    agentWeeklyLimitsAtom,
  );
  const [, replaceWeeklyLimits] = useAtom(replaceWeeklyLimitsAtom);
  const [, removeWeeklyLimit] = useAtom(removeWeeklyLimitAtom);
  const [page, setPage] = useState(1);

  const flatAgentWeeklyLimits = agentWeeklyLimits?.flat();
  const currentDatasource = agentWeeklyLimits?.[page - 1] ?? [];
  const total = flatAgentWeeklyLimits?.length ?? 0;
  const paginationTotal =
    currentDatasource.length === AGENT_REFERRAL_WEEKLY_LIMIT
      ? total + 1
      : total;

  const renderWeeklyQuota = useCallback(
    (value: number, limit: AgentReferralWeeklyLimit) => (
      <Row justify="space-between" align="middle">
        <Col>
          <Text>{value}</Text>
        </Col>
        <Col>
          <Button
            disabled={disabled}
            type="text"
            shape="circle"
            icon={<DeleteFilled />}
            onClick={() => removeWeeklyLimit(limit)}
          />
        </Col>
      </Row>
    ),
    [removeWeeklyLimit, disabled],
  );

  const handleCustomChange = useCallback(
    (checked: boolean) => {
      if (!checked) {
        replaceWeeklyLimits([]);
      }
      setCustom(checked);
    },
    [replaceWeeklyLimits],
  );

  const handlePageChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);
      updateAgentWeeklyLimits();
    },
    [updateAgentWeeklyLimits],
  );

  const onUpload = useCallback(
    async (file?: File) => {
      try {
        if (!file) {
          notification.error({ message: 'Something went wrong' });
          return;
        }
        const csvData = await parseCSV<CSVRow>(file);
        const normalizedData = csvToLimitMapper(csvData);

        if (normalizedData.length === 0) {
          notification.error({ message: 'Invalid CSV data' });
          return;
        }

        replaceWeeklyLimits(normalizedData);
        notification.success({ message: 'Updated Referral Quota Settings' });
      } catch (e) {
        if (e instanceof Error) {
          notification.error({ message: e.message });
        }
      }
    },
    [replaceWeeklyLimits, notification],
  );

  useEffect(() => {
    if (total > 0) {
      setCustom(true);
      setPage((prev) =>
        Math.min(prev, Math.ceil(total / AGENT_REFERRAL_WEEKLY_LIMIT)),
      );
    }
  }, [total]);

  return (
    <Row>
      <Col span={24}>
        <Switch
          disabled={disabled}
          label="Custom"
          checkedChildren="Live"
          unCheckedChildren="Off"
          checked={custom}
          onChange={handleCustomChange}
        />
      </Col>
      <Col span={24}>
        <Row gutter={[12, 0]}>
          <Col>
            <Button
              disabled={!custom || disabled}
              onClick={handleDownloadTemplate}
            >
              Download Import Template
            </Button>
          </Col>
          <Col>
            <Button
              disabled={!custom || disabled}
              onClick={openModal}
              ghost
              type="primary"
            >
              Import a CSV File
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <TableRow>
          <Col span={24}>
            <Text>Custom Settings</Text>
          </Col>
          <Col span={24}>
            <Table
              pagination={{
                hideOnSinglePage: true,
                pageSize: AGENT_REFERRAL_WEEKLY_LIMIT,
                total: paginationTotal,
                current: page,
                onChange: handlePageChange,
              }}
              rowKey="id"
              dataSource={currentDatasource}
              columns={[
                { title: 'Agent ID', dataIndex: 'id', key: 'id' },
                { title: 'Agent Email', dataIndex: 'email', key: 'email' },
                {
                  title: 'Weekly Quota',
                  dataIndex: 'weeklyReferralLimit',
                  key: 'weeklyReferralLimit',
                  render: renderWeeklyQuota,
                },
              ]}
            />
          </Col>
        </TableRow>
      </Col>
      <ImportCSVModal open={isOpen} onClose={closeModal} onUpload={onUpload} />
    </Row>
  );
};

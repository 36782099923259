import { endOfDay, startOfDay } from 'date-fns';
import firebase from 'firebase/compat/app';

import { toVancouverTime } from '../../utils/timezone';
import { db } from '..';
import {
  AgentInvoiceStatusesCollections,
  PlatformInvoicesCollections,
  RootCollections,
} from '../consts';
import {
  AgentInvoiceStatus,
  PaymentStatus,
} from '../models/AgentInvoiceStatus';
import { FirebasePlatformInvoice, PlatformInvoice } from '../models/interfaces';
import { generalConverter } from '../utils';

const platformInvoiceConverter = {
  toFirestore(data: PlatformInvoice): firebase.firestore.DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): PlatformInvoice {
    const data = snapshot.data(options) as FirebasePlatformInvoice;
    return { ...data, createdAt: data.createdAt.toDate() };
  },
};

const getLatestAgentInvoiceStatusData = async (
  doc: firebase.firestore.QueryDocumentSnapshot<PlatformInvoice>,
) => {
  const invoiceStatuses = db.collection(RootCollections.AgentInvoiceStatuses);

  const queryRef = await invoiceStatuses
    .doc(doc.id)
    .collection(AgentInvoiceStatusesCollections.Statuses)
    .orderBy('updatedAt', 'desc')
    .limit(1)
    .withConverter(generalConverter<AgentInvoiceStatus>())
    .get();

  return queryRef.docs.pop()?.data();
};

export const getPlatformInvoicesByDate = async (date: Date) => {
  const startTime = toVancouverTime(startOfDay(date), true);
  const endTime = toVancouverTime(endOfDay(date), true);

  // CollectionGroup searches all `invoices` collections which means it searchs from `invoices` and `platformInvoices/{agentId}/invoices`
  // If sets the composite indexies ('createdAt', 'paymentAmount'), it happens this error.
  // Unhandled Rejection (FirebaseError): Invalid query.
  // All where filters with an inequality (<, <=, !=, not-in, >, or >=) must be on the same field.
  // But you have inequality filters on 'paymentAmount' and 'createdAt'
  //
  // Therefore, we sets exemptions for invoices with createdAt (ASC)
  //
  // Return data includes `/invoices` data because where filter matchs (only 1 record for now)
  // after that, it filters it contains items (because `platformInvoice/**/invoices` contains items field)
  const invoices = await db
    .collectionGroup(PlatformInvoicesCollections.Invoices)
    .where('createdAt', '>=', startTime)
    .where('createdAt', '<=', endTime)
    .withConverter(platformInvoiceConverter)
    .get();

  const filteredInvoices = invoices.docs.filter(
    (invoice) => invoice.data().items,
  );

  return Promise.all(
    filteredInvoices.map(async (p) => {
      // Get AgentId
      // platformInvoices/{agentId}/invoices/{filteredInvoices}
      const agentId = p.ref.path.split('/')[1];
      const latestInvoiceStatusData = await getLatestAgentInvoiceStatusData(p);
      const status =
        latestInvoiceStatusData?.paymentStatus ?? PaymentStatus.pending;
      return {
        ...p.data(),
        agentId,
        id: p.id,
        key: p.id,
        status,
      };
    }),
  );
};

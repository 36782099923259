import { CCaaSType } from '@agentsonly/models';

import { MenuItem } from './styledComponents';
import { TranscriptDownloader } from './TranscriptDownloader';

export const getIntegrationDetails = (projectId: string, type?: CCaaSType) => {
  switch (type) {
    case 'gorgias':
      return {
        key: 'gorgiasTicket',
        label: <MenuItem>Gorgias Ticket</MenuItem>,
        children: <TranscriptDownloader projectId={projectId} />,
      };
    case 'intercom':
      return {
        key: 'intercomConversation',
        label: <MenuItem>Intercom Conversation</MenuItem>,
        children: (
          <TranscriptDownloader projectId={projectId} type="Conversation" />
        ),
      };
    default:
      return undefined;
  }
};

import { EditOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { updateAdminRoleAtom } from '../../atoms/adminRoles';
import { AdminRole } from '../../firebase/adminRoles/model';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { Modal } from '../../shared/ui/Modal';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { adminRoleSchema } from '../../utils/validator';
import { AdminRoleForm } from './AdminRoleForm';

type Props = {
  role: AdminRole;
  disabled: boolean;
};

export const EditRoleModal: FC<Props> = memo(({ role, disabled }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [, updateRole] = useAtom(updateAdminRoleAtom);
  const form = useForm({
    resolver: yupResolver(adminRoleSchema),
    mode: 'all',
    defaultValues: {
      name: role.name,
      permissions: role.permissions,
    },
  });

  const handleUpdateClose = (newValues?: Omit<AdminRole, 'id'>) => {
    form.reset(newValues);
    closeModal();
  };

  const handleClose = () => {
    form.reset();
    closeModal();
  };

  return (
    <>
      <Button
        disabled={disabled}
        icon={<EditOutlined />}
        size="small"
        onClick={openModal}
      />
      <Modal
        title="Edit Role"
        open={isOpen}
        onCancel={handleClose}
        footer={null}
      >
        <FormProvider {...form}>
          <AdminRoleForm
            disabled={disabled}
            edit
            onSubmit={form.handleSubmit(async (data) => {
              try {
                await updateRole({ ...data, id: role.id });
                handleUpdateClose(data);
              } catch (e: any) {
                if (e instanceof Error) {
                  ShowNotification(e.message, IconType.Error);
                }
              }
            })}
          />
        </FormProvider>
      </Modal>
    </>
  );
});

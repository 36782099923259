const ALGORITHM = 'RSA-OAEP';
const HASH_TYPE = 'SHA-256';

const importPublicFromJwk = async (keyData: JsonWebKey) =>
  window.crypto.subtle.importKey(
    'jwk',
    keyData,
    {
      name: ALGORITHM,
      hash: HASH_TYPE,
    },
    true,
    ['encrypt'],
  );

const arrayBufferToBase64: (buffer: ArrayBuffer | undefined) => string = (
  buffer,
) => {
  if (!buffer) {
    return '';
  }

  const str = String.fromCharCode.apply(
    null,
    new Uint8Array(buffer) as unknown as number[],
  );

  return window.btoa(str);
};

export const cryptoEncrypt = async (key: JsonWebKey, payload: string) => {
  try {
    const publicKey = await importPublicFromJwk(key);
    const encodeMessage = new TextEncoder().encode(payload);
    const encryptedPayload = await window.crypto.subtle.encrypt(
      {
        name: ALGORITHM,
      },
      publicKey,
      encodeMessage,
    );

    return arrayBufferToBase64(encryptedPayload);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

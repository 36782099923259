import type { ISOCountry } from '@agentsonly/models';

import { ISOCurrency } from '../../utils/currency';

export const WalletTxType = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type WalletTxType = keyof typeof WalletTxType;

export const WalletTxStatus = {
  Pending: 0,
  InProgress: 10,
  Completed: 20,
  Error: 30,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WalletTxStatus =
  (typeof WalletTxStatus)[keyof typeof WalletTxStatus];

export type WalletTxAdmin = {
  account: string;
  amount: number;
  bankCode: string;
  currency: ISOCurrency;
  date: Date;
  description: string;
  referenceNumber: string;
  txStatus: string;
  txStatusId: WalletTxStatus;
  txType: WalletTxType;
  txTypeId: number;
  abartn: string;
  institutionNumber: string;
  transitNumber: string;
  city: string;
  state: string;
  clabe: string;
  countryCode: ISOCountry;
  email: string;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  legalName?: string;
};

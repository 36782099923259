import * as yup from 'yup';

const criteriaSchema = yup
  .mixed()
  .transform((value) =>
    Number.isNaN(value) || value === null ? undefined : value,
  );

const criteriaObjSchema = yup.object({
  min: criteriaSchema,
  max: criteriaSchema,
});

const tierSchema = yup.object({
  durationSeconds: yup.number(),
  weeklyEligibilityCriteria: yup.object({
    adherence: criteriaObjSchema,
    cancellation: criteriaObjSchema,
    surgeMins: criteriaObjSchema,
    starRating: criteriaObjSchema,
  }),
});

export const schema = yup
  .object({
    preferential: yup.object({
      'tier-1': tierSchema,
      'tier-2': tierSchema,
      'tier-3': tierSchema,
    }),
  })
  .test({
    name: 'durationSeconds',
    test: (values, option) => {
      const { preferential: value } = values;

      const t1Value = value['tier-1'].durationSeconds;
      const t2Value = value['tier-2'].durationSeconds;
      const t3Value = value['tier-3'].durationSeconds;

      if (!t1Value && !t2Value && !t3Value) return true;

      if ((t1Value || 0) <= (t2Value || Number.MAX_SAFE_INTEGER)) {
        return option.createError({
          path: 'preferential.tier-1.durationSeconds',
          message: 'Tier 1 interval should be greater than Tier 2',
        });
      }

      if ((t2Value || 0) <= (t3Value ?? Number.MAX_SAFE_INTEGER)) {
        return option.createError({
          path: 'preferential.tier-2.durationSeconds',
          message: 'Tier 2 interval should be greater than Tier 3',
        });
      }

      return true;
    },
  });

import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';
import { atom } from 'jotai';

import { getCoachingStatsFeedbackColRef } from '../firebase/coaching';
import { FeedbackGroup } from '../firebase/models/Coaching';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

type FeedbackGroupPagination = {
  data: FeedbackGroup[];
  latestRef?: QueryDocumentSnapshot<FeedbackGroup>;
  total: number;
};

type FeedbackGroupPerProject = Record<string, FeedbackGroupPagination>;

type FetchFeedbackGroupPayload = {
  projectId: string;
  offset?: number;
  pageSize?: number;
};

const feedbackGroupAsyncValueAtom = atom<AsyncValue<FeedbackGroupPerProject>>({
  loading: false,
});

export const feedbackGroupAtom = asyncAtom<
  FeedbackGroupPerProject,
  FetchFeedbackGroupPayload
>(
  feedbackGroupAsyncValueAtom,
  async ({ projectId, pageSize = 10, offset = 0 }, prevState) => {
    const prevData = prevState.data ?? {};
    const feedbackData = { ...(prevData[projectId] ?? {}) };
    const endOffset = offset + pageSize;
    // if data does not have next page(offset) index data, we need to fetch
    // First pass, feedbackData.data is undefined
    const needToFetchData =
      feedbackData.data === undefined || !feedbackData.data?.[offset];

    // Initially, atom does not store any data
    // First pass, we need to set inital values
    if (feedbackData.data === undefined) {
      feedbackData.total = (
        await getCountFromServer(
          query(getCoachingStatsFeedbackColRef(projectId)),
        )
      ).data().count;

      feedbackData.data = [];
    }

    if (needToFetchData) {
      const baseQuery = query(
        getCoachingStatsFeedbackColRef(projectId),
        orderBy('feedbackAt', 'desc'),
        limit(pageSize),
      );
      const extendQuery = feedbackData.latestRef
        ? query(baseQuery, startAfter(feedbackData.latestRef))
        : baseQuery;

      const snapshots = await getDocs(extendQuery);

      const snapshotData = snapshots.docs.map((snapshot) => snapshot.data());
      feedbackData.data = [...feedbackData.data, ...snapshotData];

      // For pagination
      if (endOffset < feedbackData.total) {
        feedbackData.latestRef = snapshots.docs[snapshots.size - 1];
      }
    }

    return {
      ...prevData,
      [projectId]: {
        ...(prevData[projectId] ?? {}),
        ...feedbackData,
      },
    };
  },
  false,
);

import {
  BACKGROUND_FINAL_STATUS,
  BACKGROUND_STATUS,
  BackgroundCheck,
  BackgroundCheckStatus,
  ISOCountry,
  ISOCountryKeys,
  REVIEW_STATUS,
} from '@agentsonly/models';

const PENDING_STATUSES: BackgroundCheckStatus[] = [
  BACKGROUND_STATUS.INCORRECT,
  BACKGROUND_STATUS.MISSING,
  BACKGROUND_STATUS.PENDING_REVIEW,
];

export const getBackgroundCheckStatuses = (
  agentCountryCode: ISOCountry,
  background: BackgroundCheck,
): BackgroundCheckStatus[] => {
  const ignoreKeys = ['allPassed', 'status', 'reviewStatus'];

  return Object.entries(background)
    .filter(([key]) => !ignoreKeys.includes(key))
    .map(([key, value]) => {
      // US/MX agent's policeClearance will check later
      // Gig Requirement can be passed if policeClearance is pending
      if (
        (agentCountryCode === ISOCountryKeys.US ||
          agentCountryCode === ISOCountryKeys.MX) &&
        key === 'policeClearance' &&
        value === 'pending'
      ) {
        return BACKGROUND_STATUS.PASSED;
      }

      return value;
    });
};

export const getBackgroundCheckFinalStatus = (
  backgroundCheckStatus: BackgroundCheckStatus[],
) => {
  const havingFailed = backgroundCheckStatus.some(
    (status) => status === BACKGROUND_STATUS.FAILED,
  );
  if (havingFailed) {
    return BACKGROUND_FINAL_STATUS.FAILED;
  }

  const havingPending = backgroundCheckStatus.some((status) =>
    PENDING_STATUSES.includes(status),
  );

  if (havingPending) {
    return BACKGROUND_FINAL_STATUS.PENDING;
  }

  return BACKGROUND_STATUS.PASSED;
};

export const getReviewStatus = (
  backgroundCheckStatuses: BackgroundCheckStatus[],
) => {
  const havingPending = backgroundCheckStatuses.some((s) =>
    PENDING_STATUSES.includes(s),
  );
  if (havingPending) {
    return REVIEW_STATUS.PENDING;
  }

  return REVIEW_STATUS.REVIEWED;
};

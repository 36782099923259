import { Row } from 'antd';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

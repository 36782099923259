import styled from 'styled-components';

import { BaseColors } from '../../shared/ui/colors';

export const StyledEmptyState = styled.div`
  padding: 48px;
  border: solid 1px ${BaseColors.dividers};
  box-shadow:
    0px 12px 24px 0px rgba(29, 173, 142, 0.05),
    0px 0px 4px 0px rgba(38, 110, 95, 0.05);
  border-radius: 8px;
  text-align: center;
`;

export const TileContainer = styled.div`
  background: rgba(218, 228, 234, 0.3);
  border-radius: 8px;
  box-shadow:
    0px 4px 4px 0px rgba(110, 129, 141, 0.05),
    0px 0px 2px 0px rgba(110, 129, 141, 0.1);
  padding: 24px;
`;

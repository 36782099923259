import { Typography } from 'antd';
import styled from 'styled-components';

import {
  CronJobStatuses,
  CronJobStatusesType,
} from '../../../firebase/demoCronJob';
import { BaseColors } from '../../../shared/ui/colors';

const { Text } = Typography;

export const StatusLabel = styled(Text)<{ $type: CronJobStatusesType }>`
  text-transform: capitalize;
  ${({ $type }) => {
    switch ($type) {
      case CronJobStatuses.FAILED:
        return `color: ${BaseColors.errors}`;
      case CronJobStatuses.DONE:
        return `color: ${BaseColors.primaryDark}`;
      default:
        return undefined;
    }
  }};
`;

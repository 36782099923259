import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';

import { firebaseConfig } from './firebaseConfig';

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
firebase.analytics();

db.settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

export const fs = getFirestore();

export const dbNamespace = firebase.firestore;

export const { auth } = firebase;

export const auth2 = getAuth();

export const functions = firebase.functions();

export const authNamespace = firebase.auth;

export const storage = firebase.storage();

export const fbNow = () => firebase.firestore.Timestamp.now();

import { InputNumber } from 'antd';
import React from 'react';

import { FormItem } from '../../styledComponents';

type Props = {
  name: string;
  label: string;
  disabled: boolean;
  min?: number;
  max?: number;
  step?: number;
  addonAfter?: string;
};

export const IntegerInput: React.FC<Props> = ({
  name,
  label,
  disabled,
  min = 0,
  max = 100,
  step = 1,
  addonAfter,
}) => (
  <FormItem key={name} name={name} label={label}>
    <InputNumber
      min={min}
      max={max}
      step={step}
      addonAfter={addonAfter}
      disabled={disabled}
      precision={0} // Parse to integer
    />
  </FormItem>
);

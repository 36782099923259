import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { MANAGEMENT_API_URL } from '../shared/api/base';
import { useBrowserId } from '../shared/hooks/useBrowserId';
import { getFirebaseAuthFromAuth0Token } from '../shared/utils/auth';
import { auth0Consts } from './Auth0/consts';
import { LoadingContainer } from './styleComponents';

export const LoginForm = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();
  const { getBrowserId } = useBrowserId();

  const handleSubmit = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await getFirebaseAuthFromAuth0Token(token);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(err.message);
      }
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const param = {
        managementAPI: MANAGEMENT_API_URL,
        platform: auth0Consts.WEB,
        app: auth0Consts.ADMIN,
      };
      const searchParam = new URLSearchParams(param).toString();
      loginWithRedirect({
        redirectUri: `${window.location.origin}?${searchParam}`,
        browserId: getBrowserId(),
        managementAPI: MANAGEMENT_API_URL,
        portal: auth0Consts.ADMIN,
      });
    }

    if (!isLoading && isAuthenticated) handleSubmit();
  }, [isLoading, isAuthenticated, loginWithRedirect, handleSubmit]);

  return (
    <LoadingContainer>
      <Spin />
    </LoadingContainer>
  );
};

import { parse, ParseLocalConfig } from 'papaparse';

export const parseCSV = <T>(file: File, options?: Partial<ParseLocalConfig>) =>
  new Promise<T[]>((resolve, reject) => {
    parse(file as any, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => resolve(results.data as T[]),
      error: (err) => reject(err),
      dynamicTyping: true,
      ...options,
    });
  });

export const DEFAULT_PAGE_SIZE = 25;

export const csvTransform = (header: string[], body: string[][]) =>
  [header]
    .concat(body)
    .map((row) => row.map((cell) => `"${cell}"`).join(','))
    .join('\n');

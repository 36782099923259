import { atom } from 'jotai';

import {
  createAdminUser,
  getAdminUserByEmail,
  getAdminUsers,
  removeAdminUserRole,
} from '../firebase/adminUsers';
import { AdminUser, AdminUserRole } from '../firebase/adminUsers/model';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

type CreateAdminUser = {
  email: string;
  role: Omit<AdminUserRole, 'id'>;
};

type RemoveAdminUserRole = {
  id: string;
  roleId: string;
};

const adminUsersAsyncValueAtom = atom<AsyncValue<AdminUser[]>>({
  loading: true,
  data: [],
});

export const adminUsersAtom = asyncAtom(
  adminUsersAsyncValueAtom,
  getAdminUsers,
);

adminUsersAtom.onMount = (run) => {
  run();
};

export const createAdminUserAtom = asyncAtom(
  adminUsersAsyncValueAtom,
  async (data: CreateAdminUser, prev) => {
    const { email, role } = data;
    const admin = await getAdminUserByEmail(email);
    const adminUserRole = await createAdminUser({ id: admin.id, ...role });
    const { id: roleId, ...rest } = adminUserRole;

    const newValue = prev.data?.filter((u) => u.id !== admin?.id) ?? [];

    if (admin) {
      newValue.push({ ...admin, ...rest });
    }

    return newValue;
  },
);

export const removeAdminUserRoleAtom = asyncAtom<
  AdminUser[],
  RemoveAdminUserRole
>(adminUsersAsyncValueAtom, async ({ id, roleId }, prev) => {
  await removeAdminUserRole(id, roleId);

  const user = prev.data?.find((u) => u.id === id);

  const roles = user?.roles ?? [];

  const numOfRolesLeft =
    roles && roles.length > 1 ? roles.filter((r) => r !== roleId).length : 0;

  const isStillAdmin = numOfRolesLeft > 0;

  const newValue = prev.data?.filter((u) => u.id !== id) ?? [];

  return isStillAdmin && user
    ? [
        ...newValue,
        {
          ...user,
          roles: user?.roles?.filter((r) => r !== roleId) ?? [],
        },
      ]
    : newValue;
});

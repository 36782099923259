import { FC } from 'react';

import { StyledButton } from './styledComponents';

type Props = { disabled?: boolean; loading?: boolean };

export const Submit: FC<Props> = ({ disabled = false, loading }) => (
  <StyledButton
    htmlType="submit"
    type="primary"
    disabled={disabled}
    loading={loading}
  >
    Save
  </StyledButton>
);

import { Cpu, Display, PcCheck } from '@agentsonly/models';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { getConfigBackgroundCheck } from '../../../../firebase/configs';
import { ResultContent } from './ResultContent';
import { FAILED, getColorAndBgColor, NEEDS_VALIDATION, PASSED } from './utils';

type Props = {
  title: string;
  pcCheck?: PcCheck;
};

type MinSpec = {
  minWidth: number;
  minHeight: number;
  minRam: number;
  minCores: number;
  supportedBrowser: string[];
};

type Spec = {
  display: Display | undefined;
  ram: number | undefined;
  cpus: Cpu[] | undefined;
};

const fetchMinSpec = async () => {
  const configBackground = await getConfigBackgroundCheck();
  const minWidth = configBackground?.pcCheck.minMonitorResolution.width ?? 0;
  const minHeight = configBackground?.pcCheck.minMonitorResolution.height ?? 0;
  const minRam = configBackground?.pcCheck.minRAM ?? 0;
  const minCores = configBackground?.pcCheck.minCores ?? 0;
  const supportedBrowser = configBackground?.pcCheck.supportedBrowsers ?? [''];

  return {
    minWidth,
    minHeight,
    minRam,
    minCores,
    supportedBrowser,
  };
};

const getStatus = (
  { display, ram, cpus }: Spec,
  minSpec: MinSpec | undefined,
) => {
  if (!minSpec) return FAILED;
  const { minWidth, minHeight, minRam, minCores } = minSpec;

  if (display && (display.width < minWidth || display.height < minHeight)) {
    return FAILED;
  }
  if (ram && ram < minRam) {
    return FAILED;
  }
  if (cpus && cpus.length < minCores) {
    return FAILED;
  }
  return PASSED;
};

const useResult = ({ pcCheck }: Props) => {
  const [minSpec, setMinSpec] = useState<MinSpec>();

  const display = pcCheck?.displays
    .concat([])
    .sort((a, b) => b.width - a.width)[0];

  useEffect(() => {
    (async () => {
      const fetchedMinSpec = await fetchMinSpec();
      setMinSpec(fetchedMinSpec);
    })();
  }, []);

  const spec = {
    display,
    ram: pcCheck?.ram,
    cpus: pcCheck?.cpus,
  };

  const status = getStatus(spec, minSpec);

  const cpu = pcCheck?.cpus?.concat([]).sort((a, b) => b.speed - a.speed)[0];

  return { display, cpu, pcCheck, status };
};

export const PCCheckResult: React.FC<Props> = (props) => {
  const { display, cpu, status } = useResult(props);
  const { pcCheck, title } = props;

  const pcCheckStatus = pcCheck ? status : NEEDS_VALIDATION;
  const pcCheckColorAndBgColor = getColorAndBgColor(pcCheckStatus);

  const contents = [
    {
      id: 'test-date',
      label: 'Test date',
      value: pcCheck ? format(pcCheck.testedAt, 'PP') : '-',
    },
    {
      id: 'test-time',
      label: 'Test time',
      value: pcCheck ? format(pcCheck.testedAt, 'pp') : '-',
    },
    {
      id: 'processor',
      label: 'Processor',
      value: cpu?.model ?? '-',
      highlight: true,
    },
    {
      id: 'ram',
      label: 'RAM',
      value: pcCheck ? `${pcCheck.ram}GB` : '-',
      highlight: true,
    },
    {
      id: 'monitor',
      label: 'Monitor',
      value: display ? `${display.width} x ${display.height}` : '-',
      highlight: true,
    },
    {
      id: 'status',
      label: 'PC Check',
      value: pcCheckStatus,
      badge: true,
      badgeColor: pcCheckColorAndBgColor.color,
      badgeBgColor: pcCheckColorAndBgColor.bgColor,
    },
  ];

  return <ResultContent title={title} contents={contents} />;
};

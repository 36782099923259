import styled from 'styled-components';

export const StyledBadgeContainer = styled.div`
  max-width: 110px;
  margin: auto;
`;

export const AgentScreenContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const AgentDetailsContainer = styled(AgentScreenContainer)`
  overflow-y: scroll;

  && .ant-collapse-header {
    padding: 16px;
  }

  && .ant-collapse-content-box {
    padding: 0;
  }
`;

import { Col, Row } from 'antd';
import { FC } from 'react';

import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { StyledSelect } from '../styledComponents';
import { anthropicModalOptions } from './consts';

type Props = {
  fieldName: string;
  disabled?: boolean;
};

export const AnthropicModel: FC<Props> = ({ fieldName, disabled }) => (
  <Col span={5}>
    <Row>Model</Row>
    <Spacer size={4} axis="vertical" />
    <StyledSelect
      name={fieldName}
      placeholder="Select Model"
      options={anthropicModalOptions}
      disabled={disabled}
    />
  </Col>
);

// eslint-disable-next-line import/no-extraneous-dependencies
import 'antd/lib/date-picker/style/index';

import generatePicker, {
  RangePickerProps as BaseRangePickerProps,
} from 'antd/lib/date-picker/generatePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

export type RangePickerProps = BaseRangePickerProps<Date>;

export const DateFnsPicker = generatePicker<Date>(dateFnsGenerateConfig);

import { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AI_PROVIDERS, AiProvider } from '../../../../firebase/models/Prompts';
import { AnthropicModel } from '../../../AITemplateEditor/components/fileld/AnthropicModel';
import { AssistantId } from '../../../AITemplateEditor/components/fileld/AssistantId';
import { APIKeyInput } from '../../../AITemplateEditor/components/fileld/AssistantId/APIKeyInput/APIKeyInput';
import { Model } from '../../../AITemplateEditor/components/fileld/Model';
import { Provider } from '../../../AITemplateEditor/components/fileld/Provider';
import { Temperature } from '../../../AITemplateEditor/components/fileld/Temperature';

type Props = {
  supportedAIProviders: AiProvider[];
  showComponent?: boolean;
};

const OpenAiConfig = () => (
  <>
    <Temperature fieldName="settings.temperature" />
    <Model fieldName="settings.preferredModel" />
    <APIKeyInput
      label="API Key(Optional)"
      fieldName="settings.apiCredentials.maskedKey"
    />
  </>
);

const COMPONENT_MAP = {
  [AI_PROVIDERS.OPEN_AI]: <OpenAiConfig />,
  [AI_PROVIDERS.OPEN_AI_ASSISTANT]: (
    <AssistantId fieldName="settings.assistantId" />
  ),
  [AI_PROVIDERS.ANTHROPIC]: <AnthropicModel fieldName="settings.model" />,
  [AI_PROVIDERS.NONE]: null,
} as const;

export const AIConfigs: FC<Props> = ({
  supportedAIProviders,
  showComponent = true,
}) => {
  const providerWatch: AiProvider = useWatch({ name: 'settings.provider' });

  return (
    <>
      <Provider
        supportedAIProviders={supportedAIProviders}
        fieldName="settings.provider"
      />
      {showComponent && COMPONENT_MAP[providerWatch]}
    </>
  );
};

import { App } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import {
  chatConfigAtom,
  updateChatConfigAtom,
} from '../../../atoms/chatConfig';
import { currentProjectIdAtom } from '../../../atoms/project';
import { AdminTab } from '../../../firebase/adminRoles/model';
import {
  AI_PROVIDERS,
  AIChatType,
  AiPrompt,
} from '../../../firebase/models/Prompts';
import { useFeatureViewOnly } from '../../../shared/hooks/useFeatureViewOnly';
import {
  encryptAPIKey,
  maskKey,
} from '../../AITemplateEditor/components/form/helpers';
import { DEFAULT_TEMPLATE_VALUES } from '../../consts';
import { useTemplateFormValidator } from '../../hooks/useTemplateFormValidator';

const NESTED_PROMPT_CONFIG_KEYS = {
  agents: 'dashboard',
  clients: 'contacts',
};

const AI_TEMPLATE_TYPES_MAPPING: Record<string, AIChatType> = {
  agents: 'agentChat',
  clients: 'clientContact',
};

export const useChatConfig = (type: 'agents' | 'clients') => {
  const { notification } = App.useApp();

  const [projectId] = useAtom(currentProjectIdAtom);
  const [, fetchChatConfig] = useAtom(chatConfigAtom);
  const [chatConfig, updateChatConfig] = useAtom(updateChatConfigAtom);
  const { form } = useTemplateFormValidator(
    chatConfig?.data?.[projectId ?? '']?.[type]?.[
      NESTED_PROMPT_CONFIG_KEYS[type]
    ] || DEFAULT_TEMPLATE_VALUES,
  );
  const providerWatch = useWatch({
    name: 'settings.provider',
    control: form.control,
  });

  const isNoneProvider = providerWatch === AI_PROVIDERS.NONE;
  const isViewOnly = useFeatureViewOnly(AdminTab.Projects);
  const disabled = isViewOnly || !form.formState.isValid || isNoneProvider;

  useEffect(() => {
    if (projectId) {
      fetchChatConfig(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    const aiPrompt =
      chatConfig?.data?.[projectId ?? '']?.[type]?.[
        NESTED_PROMPT_CONFIG_KEYS[type]
      ];

    if (!aiPrompt) {
      return;
    }
    form.reset(aiPrompt);
  }, [form, chatConfig.data]);

  const handleSubmit = async (data: AiPrompt) => {
    if (!projectId || !data?.settings) {
      return;
    }

    try {
      const encryptedKey = await encryptAPIKey(
        data,
        data.settings.apiCredentials?.maskedKey ?? '',
        AI_TEMPLATE_TYPES_MAPPING[type],
      );

      await updateChatConfig({
        projectId,
        data: {
          [NESTED_PROMPT_CONFIG_KEYS[type]]: {
            ...data,
            settings: {
              ...data.settings,
              apiCredentials: {
                maskedKey:
                  encryptedKey === ''
                    ? ''
                    : maskKey(data?.settings?.apiCredentials?.maskedKey ?? ''),
                encryptedKey,
              },
            },
          },
        },
        type,
      });
      notification.success({ message: 'Config updated successfully' });
    } catch (e) {
      notification.error({ message: 'Something went wrong' });
      console.error(e);
    }
  };

  return { handleSubmit, chatConfig, disabled, updateChatConfig, form };
};

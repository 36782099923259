import { Col, Row, Typography } from 'antd';
import { secondsToMinutes } from 'date-fns';
import { FC, useCallback } from 'react';

import { useVoiceProgressPlayer } from '../../../../../shared/hooks/useVoicePlayer';
import { Button } from '../../../../../shared/ui/Button';
import { BaseColors } from '../../../../../shared/ui/colors';
import { PauseIcon, PlayIcon, ProgressBar } from './styledComponents';

const { Text } = Typography;

type Props = {
  agentId?: string;
};

export const VoicePlayer: FC<Props> = ({ agentId }) => {
  const [toggle, isPlaying, remaining, progress] = useVoiceProgressPlayer();
  const min = secondsToMinutes(remaining).toString().padStart(2, '0');
  const sec = (remaining % 60).toFixed(0).toString().padStart(2, '0');
  const handleClick = useCallback(() => toggle(agentId), [toggle, agentId]);
  return (
    <Row wrap={false} align="middle">
      <Col span={2}>
        <Button
          type="primary"
          shape="circle"
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
          size="middle"
          onClick={handleClick}
        />
      </Col>
      <Col span={19}>
        <ProgressBar
          percent={progress}
          showInfo={false}
          strokeColor={BaseColors.primary}
        />
      </Col>
      <Col span={3}>
        <Text strong>{`${min}:${sec}`}</Text>
      </Col>
    </Row>
  );
};

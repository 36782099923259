import { Row, Typography } from 'antd';
import React from 'react';

import { Behavior } from '../../../../firebase/models/Coaching';
import { IconEdit, IconTrash } from '../../../../shared/assets';
import {
  LiveBehaviorContent,
  OfflineBehaviorContainer,
} from '../../styledComponents';
import {
  BehaviorDescriptionText,
  StyledIconContainer,
} from './styledComponents';

const { Title } = Typography;
type Props = {
  behavior: Behavior;
  onOpenEditBehaviorModal: () => void;
  onOpenConfirmDeleteModal: () => void;
};
export const OfflineBehavior = ({
  behavior,
  onOpenEditBehaviorModal,
  onOpenConfirmDeleteModal,
}: Props) => (
  <OfflineBehaviorContainer style={{ padding: '1rem' }}>
    <Row justify="space-between">
      <Title
        style={{
          color: '#202629',
          marginBottom: '13px',
          fontSize: '24px',
        }}
      >
        {behavior?.name}
      </Title>
    </Row>
    <LiveBehaviorContent>
      <BehaviorDescriptionText
        ellipsis={{
          rows: 3,
          tooltip: true,
        }}
      >
        {behavior.description}
      </BehaviorDescriptionText>
      <Row justify="end">
        <StyledIconContainer>
          <IconEdit onClick={onOpenEditBehaviorModal} />
        </StyledIconContainer>
        <StyledIconContainer>
          <IconTrash onClick={onOpenConfirmDeleteModal} />
        </StyledIconContainer>
      </Row>
    </LiveBehaviorContent>
  </OfflineBehaviorContainer>
);

import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  padding: 84px 64px;
  h4 {
    text-align: left;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

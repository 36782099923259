import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable: typeof Table = styled(Table)`
  tr.clickable {
    &:hover {
      cursor: pointer;
    }
  }
  tr.not-clickable {
    &:hover {
      cursor: not-allowed;
    }
  }
`;

import { Tabs, TabsProps } from 'antd';
import React from 'react';

import { AgentList } from './AgentList';
import { Settings } from './Settings';

type Props = {
  projectId: string;
  disabled: boolean;
};

const getTabItems = ({ projectId, disabled }: Props): TabsProps['items'] => [
  {
    key: 'settings',
    label: `Settings`,
    children: (
      <Settings
        data-testid={`setting_${projectId}`}
        key={`setting_${projectId}`}
        projectId={projectId}
        disabled={disabled}
      />
    ),
  },
  {
    key: 'agentList',
    label: `Agent List`,
    children: (
      <AgentList
        data-testid={`agentList_${projectId}`}
        key={`agentList_${projectId}`}
        projectId={projectId}
        disabled={disabled}
      />
    ),
  },
];

export const PreferredAgents: React.FC<Props> = ({ projectId, disabled }) => (
  <Tabs
    defaultActiveKey="settings"
    items={getTabItems({ projectId, disabled })}
    destroyInactiveTabPane
  />
);

import { ISOCountry, ISOCountryKeys } from '@agentsonly/models';

import { LengthRule, validateIntString, validateLength } from './validator';

type Keys = 'ssn';

type Value = {
  name: Keys;
  title: string;
  validation: (value: string) => Promise<void>;
};

const PERSONAL_IDENTIFICATION_FIELD: Value = {
  name: 'ssn',
  title: 'Social security system number',
  async validation(value: string) {
    const err =
      validateIntString(this.title, value) ??
      validateLength(this.title, value, {
        range: [10],
        rule: LengthRule.equal,
      });
    return err ? Promise.reject(new Error(err)) : Promise.resolve();
  },
};

const US_SOCIAL_SECURITY_NUMBER: Value = {
  name: 'ssn',
  title: 'Social Security Number',
  async validation(value: string) {
    const err =
      validateIntString(this.title, value) ??
      validateLength(this.title, value, {
        range: [9],
        rule: LengthRule.equal,
      });
    return err ? Promise.reject(new Error(err)) : Promise.resolve();
  },
};

// TODO: Add CURP in this area?
const IDENTIFICATION_FIELD_BY_COUNTRY: Record<ISOCountry, Value | undefined> = {
  [ISOCountryKeys.PH]: PERSONAL_IDENTIFICATION_FIELD,
  [ISOCountryKeys.US]: US_SOCIAL_SECURITY_NUMBER,
  [ISOCountryKeys.MX]: undefined,
  [ISOCountryKeys.CA]: undefined,
};

const MAP_PERSONAL_IDENTIFICATION_FIELD = Object.keys(ISOCountryKeys).reduce<
  Record<string, Value>
>((obj, key) => {
  const field = IDENTIFICATION_FIELD_BY_COUNTRY[key as ISOCountry];
  if (field) {
    return {
      ...obj,
      [key]: field,
    };
  }

  return obj;
}, {});

export const getPersonalIdentificationField = (code: ISOCountry) =>
  MAP_PERSONAL_IDENTIFICATION_FIELD[code];

import React from 'react';

import { WalletTxStatus } from '../../../shared/models/WalletTx';
import { getStatusFromStatusId } from '../../Withdrawals/utils';
import { StatusText } from './styledComponents';

type Props = {
  status: WalletTxStatus;
};

export const WalletStatusText: React.FC<Props> = ({ status }) => (
  <StatusText $status={status}>{getStatusFromStatusId(status)}</StatusText>
);

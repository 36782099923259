import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CarouselContainer, StyledCarousel } from '../../styledComponents';
import { ThumbnailList } from './list/list';

type CarouselProps = {
  imageList: string[];
};

export const ImageCarousel: React.FC<CarouselProps> = ({ imageList }) => {
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [isFromCarousel, setFromCarousel] = useState(false);
  const [isFromList, setFromList] = useState(false);
  const carousel = useRef<CarouselRef>(null);

  const isSameImage = (element: string) => element === selectedImage;

  useEffect(() => {
    if (carousel.current && isFromList) {
      carousel.current.goTo(imageList.findIndex(isSameImage), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageList, selectedImage, isFromList]);

  const onListChange = useCallback((newSelection: string) => {
    setFromCarousel(false);
    setFromList(true);
    setSelectedImage(newSelection);
  }, []);

  const onCarouselChange = useCallback(
    (currentSlide: number) => {
      setFromCarousel(true);
      setFromList(false);
      setSelectedImage(imageList[currentSlide]);
    },
    [imageList],
  );

  return (
    <CarouselContainer direction="vertical" size="middle">
      <StyledCarousel
        ref={carousel}
        arrows
        dotPosition="bottom"
        slidesToShow={1}
        speed={400}
        easing="ease-in-out"
        centerMode
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
        afterChange={onCarouselChange}
      >
        {imageList.map((item) => (
          <Image key={item} preview src={item} />
        ))}
      </StyledCarousel>
      <ThumbnailList
        imageList={imageList}
        selectedImage={selectedImage}
        setSelectedImage={onListChange}
        changeFromCarousel={isFromCarousel}
      />
    </CarouselContainer>
  );
};

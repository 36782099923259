import { Agent } from '@agentsonly/models';
import {
  endOfWeek,
  format,
  minutesToSeconds,
  startOfWeek,
  sub,
} from 'date-fns';

import {
  getAgentProjectReliabilityRef,
  getAgentProjectStatsRef,
} from '../../../firebase/agent';
import { ProductiveScheduleStats } from '../../../firebase/models/ProductiveScheduleStats';
import { getProjectReliabilityRef } from '../../../firebase/project';
import { getYearWeekFromDate } from '../../../utils/date';
import { FormValues } from './type';

const BASE_DENOMINATOR = 100;

const BASE_ADHERENCE_DATA = {
  totalAvailableShiftSeconds: undefined,
  totalProductiveTimeSeconds: undefined,
};

const BASE_CANCELLATION_DATA = {
  cancelRate: {
    numCommitted: undefined,
    numCanceled: undefined,
  },
};

export const formatWeekly = (value: Date) => {
  const startWeekDay = startOfWeek(value);
  const endWeekDay = endOfWeek(value);
  return `${format(startWeekDay, 'yyyy-MM-dd')} - ${format(
    endWeekDay,
    'yyy-MM-dd',
  )}`;
};

export const generateDemoAgentOption = (
  demoAgents: Agent[],
): { value: string; label: string }[] =>
  demoAgents.map((agent) => ({
    value: agent.id,
    label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
  }));

const generateAdherenceTweakData = (
  percentage: number | undefined | null,
): Partial<ProductiveScheduleStats> => {
  if (percentage === undefined || percentage === null) {
    return BASE_ADHERENCE_DATA;
  }

  return {
    totalAvailableShiftSeconds: BASE_DENOMINATOR,
    totalProductiveTimeSeconds: percentage,
  };
};

const generateCancellationTweakData = (
  percentage: number | undefined | null,
): Partial<ProductiveScheduleStats> => {
  if (percentage === undefined || percentage === null) {
    return BASE_CANCELLATION_DATA;
  }

  return {
    cancelRate: {
      numCommitted: BASE_DENOMINATOR - percentage,
      numCanceled: percentage,
    },
  };
};

const saveAveragePreferential = async (
  projectId: string,
  dateId: string,
  data: Partial<ProductiveScheduleStats>,
) => {
  try {
    const payload = {
      preferential: data,
    };

    const basePath = getProjectReliabilityRef(projectId);
    await basePath.doc(dateId).set(payload, { merge: true });
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`Error: ${e.message}`);
    }
  }
};

const saveDemoAgentScheduleStats = async (
  projectId: string,
  dateId: string,
  payload: Partial<ProductiveScheduleStats>,
  agentId?: string,
) => {
  try {
    if (!agentId) {
      return;
    }

    const basePath = getAgentProjectReliabilityRef(agentId, projectId);
    await basePath.doc(dateId).set(payload, { merge: true });
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`Error: ${e.message}`);
    }
  }
};

const saveDemoAgentTotalSurgeSeconds = async (
  projectId: string,
  tweakSurgeMins: number | undefined | null,
  agentId?: string,
) => {
  try {
    if (!agentId || tweakSurgeMins === undefined || tweakSurgeMins === null) {
      return;
    }

    const payload = {
      surge: {
        totalHTSeconds: minutesToSeconds(tweakSurgeMins),
      },
    };

    const basePath = getAgentProjectStatsRef(agentId, projectId);
    await basePath.doc('default').set(payload, { merge: true });
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`Error: ${e.message}`);
    }
  }
};

export const saveAgentTweakData = async (
  projectId: string,
  value: FormValues,
) => {
  const {
    selectWeek,
    preferredAgentAdherence,
    preferredAgentCancellation,
    demoAgentAdherence,
    demoAgentCancellation,
    selectedDemoAgentId,
    demoAgentTotalSurgeMins,
  } = value;
  const previousWeek = sub(selectWeek, { weeks: 1 });
  const dateId = getYearWeekFromDate(previousWeek);

  await Promise.all([
    saveAveragePreferential(
      projectId,
      dateId,
      generateAdherenceTweakData(preferredAgentAdherence),
    ),
    saveAveragePreferential(
      projectId,
      dateId,
      generateCancellationTweakData(preferredAgentCancellation),
    ),
    saveDemoAgentScheduleStats(
      projectId,
      dateId,
      generateAdherenceTweakData(demoAgentAdherence),
      selectedDemoAgentId,
    ),
    saveDemoAgentScheduleStats(
      projectId,
      dateId,
      generateCancellationTweakData(demoAgentCancellation),
      selectedDemoAgentId,
    ),
    saveDemoAgentTotalSurgeSeconds(
      projectId,
      demoAgentTotalSurgeMins,
      selectedDemoAgentId,
    ),
  ]);
};

import { Typography } from 'antd';
import { FC } from 'react';

import { AgentAccount } from './AgentAccount';
import { AgentSearch } from './components/AgentSearch';
import { AgentDetailsContainer } from './styledComponents';

const { Title } = Typography;

type Props = {
  disabled: boolean;
};

export const AgentDetails: FC<Props> = ({ disabled }) => (
  <AgentDetailsContainer>
    <AgentSearch />
    <Title level={3} style={{ color: '#000', textAlign: 'left' }}>
      Agent Account Details
    </Title>

    <AgentAccount disabled={disabled} />
  </AgentDetailsContainer>
);

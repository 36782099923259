import { Agent, ReferralPlan } from '@agentsonly/models';
import {
  Button,
  Col,
  Form as AntdForm,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
} from 'antd';
import { useEffect, useState } from 'react';

import {
  getAgentByEmail,
  getAgentStats,
  updateHTSeconds,
} from '../../../firebase/agent';
import { AgentStats } from '../../../firebase/agent/stats';
import { Referral } from '../../../firebase/models/interfaces';
import {
  getReferralPlan,
  isExistPlan,
  updateReferrals,
} from '../../../firebase/referrals';
import { Form } from '../../../shared/ui/form/Form';
import { isProd } from '../../../utils/consts';

type Props = {
  selectedReferral: Referral;
  onClose: () => void;
  onUpdate: () => void;
  disabled: boolean;
};

const useFetchData = (selectedReferral: Props['selectedReferral']) => {
  const [agent, setAgent] = useState<Agent>();
  const [plan, setPlan] = useState<ReferralPlan>();
  const [stats, setStats] = useState<AgentStats>();

  useEffect(() => {
    (async () => {
      try {
        const data = await getAgentByEmail(
          selectedReferral?.refereeEmail ?? '',
        );
        const agentStats = data && (await getAgentStats(data?.id));
        setStats(agentStats);
        setAgent(data);
      } catch (err) {
        console.error(err);
      }
    })();

    (async () => {
      if (selectedReferral?.planId) {
        const data = await getReferralPlan(selectedReferral?.planId ?? '');
        setPlan(data);
      }
    })();
  }, [selectedReferral]);

  return [agent, plan, stats] as const;
};
export const ReferralModal = ({
  selectedReferral,
  onClose,
  onUpdate,
  disabled,
}: Props) => {
  const [agent, plan, stats] = useFetchData(selectedReferral);

  const updatePlanId = async ({ planId }: { planId: string }) => {
    if (planId && (await isExistPlan(planId ?? selectedReferral?.planId))) {
      await updateReferrals(selectedReferral.id, { planId });
      onUpdate();
      notification.open({
        message: 'success to update plan id in referrals',
      });
    } else {
      notification.open({
        message: 'not found the plan id',
      });
    }
    onClose();
  };

  const updateCallMins = async ({ callMins }: { callMins: number }) => {
    if (!agent) return;
    await updateHTSeconds(agent.id, callMins * 60);
    onUpdate();
    onClose();
    notification.open({
      message: 'success to update call mins',
    });
  };
  const resetMilestone = async () => {
    await updateReferrals(selectedReferral.id, { achievedMilestone: 0 });
    onClose();
    notification.open({
      message: 'Reset achievedMilestone successfully',
    });
  };

  const totalHTSeconds =
    stats?.ctr?.totalHTSeconds ?? 0 + (stats?.migrated?.totalHTSeconds ?? 0);
  return (
    <Modal
      title={`refereeEmail: ${selectedReferral?.refereeEmail}`}
      open={!!selectedReferral}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {plan && (
        <Form onFinish={updatePlanId} preserve={false} disabled={disabled}>
          <AntdForm.Item
            label="Plan Id"
            name="planId"
            initialValue={selectedReferral?.planId}
            preserve={false}
          >
            <Input type="string" disabled={isProd() || disabled} />
          </AntdForm.Item>
          <AntdForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isProd() || disabled}
            >
              Update Plan Id
            </Button>
          </AntdForm.Item>
        </Form>
      )}
      <Col>
        Plan Milestones:
        <Col>
          {plan?.milestones.map((m, i) => (
            <Row>{`Gates ${i}: ${m.milestone ?? 0 / 60} mins`}</Row>
          ))}
        </Col>
      </Col>
      {agent && (
        <Form onFinish={updateCallMins} preserve={false} disabled={disabled}>
          <AntdForm.Item
            label="Call mins"
            name="callMins"
            preserve={false}
            initialValue={totalHTSeconds / 60}
          >
            <InputNumber disabled={isProd() || disabled} />
          </AntdForm.Item>
          <AntdForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isProd() || disabled}
            >
              Update Call Mins
            </Button>
          </AntdForm.Item>
          can&lsquo;t process payments for all gates at once. need to
          increase/update call mins for each gate
        </Form>
      )}
      <Button type="primary" onClick={resetMilestone} disabled={isProd()}>
        Reset milestones
      </Button>
    </Modal>
  );
};

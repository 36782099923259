import { firebaseConfig, functions, storage } from '../firebase';

const baseAgentDirectory = 'agents';

export enum Directories {
  SampleVoices = 'voice/',
  ProjectInfo = 'info/',
  TrainingDocuments = 'training/',
  LegalDocuments = 'documents/',
  Verifications = 'verifications/',
  Temp = 'temp/',
  DescriptionImages = 'descriptionImages/',
}

const DEFAULT_VOICE_FILENAME = 'onboarding.mp3';

const getAgentVoiceSampleDirectory = (agentId: string): string =>
  `${baseAgentDirectory}/${agentId}/${Directories.SampleVoices}`;

export const getAbsoluteVoiceRecordPath = (agentId: string) => {
  const voicePath = getAgentVoiceSampleDirectory(agentId);
  return `gs://${firebaseConfig.storageBucket}/${voicePath}${DEFAULT_VOICE_FILENAME}`;
};

const urlToFilePath = (url: string): string => {
  let filePath = url;
  const urlPrefix = `gs://${firebaseConfig.storageBucket}/`;

  if (filePath.startsWith(urlPrefix)) {
    filePath = filePath.substring(urlPrefix.length);
  }

  return filePath;
};

export const getDownloadURL = async (url: string): Promise<string> => {
  try {
    const documentRef = storage.refFromURL(url);

    return await documentRef.getDownloadURL();
  } catch {
    const filePath = urlToFilePath(url);

    const getDownloadURLCallable = functions.httpsCallable('getDownloadURL');

    const result = await getDownloadURLCallable({ path: filePath });

    return result.data;
  }
};

import styled from 'styled-components';

import { Modal } from '../../../shared/ui/Modal';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 56px 64px 32px 64px;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

import { Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  color?: string;
};

const BaseTitleStyle = styled(Typography.Title)<Props>`
  && {
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : '#5A6369')};
    margin-top: 0;
  }

  &.ant-typography-disabled {
    opacity: 0.35;
  }
`;

export const StyledHeroTitle = styled(BaseTitleStyle)`
  && {
    font-size: 48px;
  }
`;

export const StyledBigTitle = styled(BaseTitleStyle)<Props>`
  && {
    font-size: 32px;
  }
`;

export const StyledTitle = styled(BaseTitleStyle)`
  && {
    font-size: 24px;
  }
`;

export const StyledSubTitle = styled(BaseTitleStyle)`
  && {
    font-size: 21px;
  }
`;

export const StyledSmallTitle = styled(BaseTitleStyle)`
  && {
    font-size: 18px;
  }
`;

export const StyledLabelTitle = styled(BaseTitleStyle)<Props>`
  && {
    font-size: 16px;
  }
`;

export const StyledXsTitle = styled(BaseTitleStyle)`
  && {
    font-size: 14px;
  }
`;

import { Col, Row } from 'antd';
import { FC } from 'react';

import { Button } from '../Button';

type Props = {
  isLoading: boolean;
  disabled: boolean;
  onReplaceButtonClick: () => void;
  onUploadButtonClick: () => Promise<void>;
};

export const Footer: FC<Props> = ({
  isLoading,
  disabled,
  onReplaceButtonClick,
  onUploadButtonClick,
}) => (
  <Row>
    <Col span={24}>
      <Row justify="end">
        <Button
          type="text"
          size="large"
          disabled={disabled}
          onClick={onReplaceButtonClick}
        >
          Replace File
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          disabled={disabled}
          onClick={onUploadButtonClick}
          loading={isLoading}
        >
          Upload File
        </Button>
      </Row>
    </Col>
  </Row>
);

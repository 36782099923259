import { format } from 'date-fns';

import { WalletTxAdmin, WalletTxStatus } from '../../shared/models/WalletTx';
import { csvTransform } from '../../utils/csv';

export const getStatusFromStatusId = (statusId: WalletTxStatus) => {
  switch (statusId) {
    case WalletTxStatus.Completed:
      return 'Paid';
    case WalletTxStatus.InProgress:
      return 'In Progress';
    case WalletTxStatus.Pending:
      return 'Pending';
    case WalletTxStatus.Error:
      return 'Error';
    default:
      return 'Unknown';
  }
};

const WITHDRAWALS_TEMPLATE = [
  'date',
  'name',
  'agentName',
  'paymentReference',
  'receiverType', // PERSON
  'amountCurrency', // target
  'sourceCurrency', // USD
  'targetCurrency',
  'accountType', // CHECKING
  'bankCode',
  'accountNumber',
  'institutionNumber',
  'transitNumber',
  'addressCountryCode',
  'addressCity',
  'addressFirstLine',
  'addressState',
  'addressPostCode',
  'clabe',
  'amount',
  'recipientEmail',
  'paymentStatus',
];

// withdrawals is the withdraw lists
export const generateTemplate = (withdrawals: WalletTxAdmin[]) => {
  const header = WITHDRAWALS_TEMPLATE;

  const body = withdrawals.map((withdrawal: WalletTxAdmin) => [
    `${format(new Date(withdrawal.date), 'P')}`,
    withdrawal.legalName ?? '',
    `${withdrawal.firstName} ${withdrawal.lastName}`,
    withdrawal.referenceNumber,
    'PERSON', // receiverType -> PERSON
    'target', // amountCurrency -> target
    'USD', // sourceCurrency -> USD
    withdrawal.currency,
    'CHECKING', // accountType -> CHECKING
    withdrawal.bankCode ?? '',
    withdrawal.account,
    withdrawal.institutionNumber ?? '',
    withdrawal.transitNumber ?? '',
    withdrawal.countryCode,
    withdrawal.city,
    withdrawal.street,
    withdrawal.state ?? '',
    withdrawal.zip,
    withdrawal.clabe ?? '',
    `${withdrawal.amount}`,
    withdrawal.email, // recipientEmail
    getStatusFromStatusId(withdrawal.txStatusId),
  ]);

  const template = csvTransform(header, body);

  return template;
};

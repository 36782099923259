import { User } from '@auth0/auth0-react';

import { isProd, isStage } from '../../utils/consts';

export const Auth0ManagementApiPath = {
  GET_USER_BY_EMAIL: '/users',
  UPDATE_USER: 'users/:id',
};

type UserMetadata = {
  userId?: string;
  phoneNumber?: string;
};

export type Auth0User = User & {
  userMetadata?: UserMetadata;
};

export const FIREBASE_FUNCTIONS_BASE_URL = (() => {
  if (isProd()) {
    return 'https://us-central1-agents-only.cloudfunctions.net';
  }

  if (isStage()) {
    return 'https://us-central1-agents-only-stage.cloudfunctions.net';
  }

  return 'https://us-central1-agents-only-dev.cloudfunctions.net';
})();

export const AO_API_BASE_URL = (() => {
  if (isProd()) {
    return 'https://api.agentsonly.com';
  }

  if (isStage()) {
    return 'https://api-stage.agentsonly.com';
  }

  return 'https://api-dev.agentsonly.com';
})();

import { notification, Row, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useState } from 'react';

import { Button } from '../../../../shared/ui/Button';
import { createFileToDownload } from '../../../../utils/file';
import { walletAPI } from '../../../../utils/firebase/walletAPI';
import { generateTemplate } from '../../utils';

const { Text } = Typography;

const TEMPLATE_FILE_NAME = 'wise-withdrawals-list.csv';

export const Header: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleDownloadTemplate = async () => {
    setLoading(true);
    try {
      const fetchAllWithdrawals = await walletAPI.getWithdrawals();
      const { txs: allWithdrawals } = fetchAllWithdrawals.data;
      const template = generateTemplate(allWithdrawals);
      const currentDate = format(new Date(), 'yyyy-MM-dd-HH-mm');
      createFileToDownload(`${currentDate}-${TEMPLATE_FILE_NAME}`, template);
    } catch (err) {
      if (err instanceof Error) {
        console.log('Error', err.message);
      }

      notification.error({
        message: 'Failed to get Withdrawals data',
      });
    }
    setLoading(false);
  };

  return (
    <Row justify="space-between">
      <Text strong>Pending Withdrawals List</Text>
      <Button
        type="primary"
        ghost
        loading={loading}
        onClick={handleDownloadTemplate}
      >
        Download Withdrawal List
      </Button>
    </Row>
  );
};

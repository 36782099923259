import { Modal } from 'antd';
import React, { useEffect } from 'react';

import { getWebAdminConfigRef } from '../../../firebase/configs';
import { useModal } from '../../hooks/useModal';
import { Button } from '../Button';

const parseVersion = (version: string) => {
  const [major, minor, patch] = version.split('.');

  return {
    major: parseInt(major, 10),
    minor: parseInt(minor, 10),
    patch: parseInt(patch, 10),
  };
};

const requireRefresh = (
  currentVersion: string | undefined,
  minVersion: string | undefined,
) => {
  if (!currentVersion || !minVersion) {
    return false;
  }

  const current = parseVersion(currentVersion);
  const min = parseVersion(minVersion);

  if (current.major < min.major) {
    return true;
  }
  if (current.minor < min.minor) {
    return true;
  }
  return current.patch < min.patch;
};

export const VersionCheck: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModal(false);

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(
    () =>
      getWebAdminConfigRef().onSnapshot((snapshot) => {
        const currentVersion = process.env.REACT_APP_VERSION;
        const minVersion = snapshot.data()?.minVersion;
        if (requireRefresh(currentVersion, minVersion)) {
          openModal();
        } else {
          closeModal();
        }
      }),
    [openModal, closeModal],
  );

  return (
    <Modal
      closable={false}
      title="Unsupported version"
      open={isOpen}
      footer={
        <Button type="primary" onClick={handleRefresh}>
          Refresh Browser
        </Button>
      }
    />
  );
};

import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Resource } from '../../../../firebase/models/Coaching';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { getResources } from '../../helpers';
import { NoResourceItem } from '../NoResourceItem';
import { ResourceForm } from '../ResourceForm';
import { ResourceItem } from '../ResourceItem';
import { RestrictableDiv } from '../styledComponents';
import {
  ButtonAddContainer,
  ColorfulText,
  Container,
  InlineCol,
  ResourcesSection,
  StyledTitle,
} from './styledComponents';

const MAX_RESOURCES = 4;
type Props = {
  projectId: string;
  disabled: boolean;
};
export const Resources: React.FC<Props> = ({ projectId, disabled }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [editingResource, setEditingResource] = useState<Resource>();
  const [isOpenResourceModal, toggleResourceModal] = useState(false);
  const handleFetchResources = useCallback(async () => {
    const result = await getResources(projectId);
    setResources(result);
  }, [projectId]);

  useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);

  const openResourceModal = () => {
    toggleResourceModal(true);
    setEditingResource(undefined);
  };

  const closeResourceModal = () => toggleResourceModal(false);

  const handleResourceUpsert = () => {
    if (editingResource) {
      setEditingResource(undefined);
    }
    closeResourceModal();
    handleFetchResources();
  };

  const handleOpenResourceEditForm = (resource: Resource) => {
    openResourceModal();
    setEditingResource(resource);
  };

  return (
    <Container>
      <InlineCol span={24}>
        <Row align="middle">
          <Col>
            <StyledTitle level={5}>Resources</StyledTitle>
          </Col>
          <Spacer size={5} axis="horizontal" />
          <Col>
            <ColorfulText
              color={BaseColors.primaryMedium}
            >{`${resources.length}/${MAX_RESOURCES}*`}</ColorfulText>
          </Col>
          <Col flex={1}>
            <ButtonAddContainer>
              <Button
                disabled={disabled || resources.length === MAX_RESOURCES}
                onClick={openResourceModal}
                ghost
                type="primary"
              >
                Link Resource
              </Button>
            </ButtonAddContainer>
          </Col>
        </Row>
      </InlineCol>
      <Spacer size={20} axis="vertical" />
      <Col span={24}>
        <ResourcesSection>
          {resources.length === 0 && (
            <RestrictableDiv $restricted={disabled}>
              <NoResourceItem openCreateModal={openResourceModal} />
            </RestrictableDiv>
          )}
          {resources.length > 0 &&
            resources.map((r, idx) => (
              <RestrictableDiv
                key={`resource-item-${r.id}`}
                $restricted={disabled}
              >
                <ResourceItem
                  openResourceForm={handleOpenResourceEditForm}
                  isLast={idx === resources.length - 1}
                  resource={r}
                />
              </RestrictableDiv>
            ))}
        </ResourcesSection>
      </Col>
      {isOpenResourceModal && (
        <ResourceForm
          resources={resources}
          onOk={handleResourceUpsert}
          onClose={closeResourceModal}
          projectId={projectId}
          selectedResource={editingResource}
        />
      )}
    </Container>
  );
};

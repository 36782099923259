import { AxiosResponse } from 'axios';
import { atom } from 'jotai';

import { asyncAtom } from '../utils/atoms';
import { aiPlaceholdersAPI } from '../utils/firebase/aiPlaceholdersAPI';
import { AsyncValue } from '../utils/types';

export const fetchAiPlaceholders = async () => {
  try {
    const response: AxiosResponse<string[]> =
      await aiPlaceholdersAPI.getAiPlaceholders();

    const { data } = response;

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchAiAgentChatPlaceholders = async () => {
  try {
    const response: AxiosResponse<string[]> =
      await aiPlaceholdersAPI.getAiAgentChatPlaceholders();

    const { data } = response;

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchAiClientChatPlaceholders = async () => {
  try {
    const response: AxiosResponse<string[]> =
      await aiPlaceholdersAPI.getAiClientChatPlaceholders();

    const { data } = response;

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const aiPlaceholders = atom<AsyncValue<string[]>>({
  loading: false,
});

export const AiPlaceholdersAtom = (fetchFunc: () => Promise<string[]>) =>
  asyncAtom<string[], void>(aiPlaceholders, fetchFunc);

import { ISOCountry } from '@agentsonly/models';

export const countryNames: Record<ISOCountry, string> = {
  US: 'USA',
  CA: 'Canada',
  MX: 'Mexico',
  PH: 'Phillipines',
};

export const DEFAULT_CURRENCY_SYMBOL = '$';

import styled from 'styled-components';

import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import { hexToRGB } from '../../../../utils/color';

export const StyledSaveButton = styled(Button)`
  background: ${BaseColors.primaryDark};
  border-radius: 4px;
  width: 180px;
  height: 45px;
  color: white;
  border: none;

  &&:not([disabled]):hover {
    color: white;
    background-color: ${hexToRGB(BaseColors.primaryDark, 0.8)};
    border: none;
  }
`;

export const StyledRemoveButton = styled(Button)`
  background-color: ${BaseColors.alertWarning};
  height: 92px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: white;

    svg > rect {
      fill: ${BaseColors.alertWarning};
    }
  }
`;

export const StyledAddNewActionButton = styled(Button)`
  color: #078768;
  border-color: #078768;
  width: 100%;
  border-radius: 4px;

  &&&:hover {
    border-color: #078768;
    background: #078768;
    color: white;
  }
`;

export const StyledDeleteButton = styled(Button)`
  background-color: ${BaseColors.alertWarning};
  width: 180px;
  height: 45px;
  color: white;
  border: none;

  &&&:hover {
    color: white;
    border: none;
    background-color: ${hexToRGB(BaseColors.alertWarning, 0.8)};
  }
`;

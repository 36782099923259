import { ISOCountry } from '@agentsonly/models';

export enum ISOCurrency {
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  PHP = 'PHP',
}

export const currencyIdentifiers: Record<ISOCountry, ISOCurrency> = {
  US: ISOCurrency.USD,
  CA: ISOCurrency.CAD,
  MX: ISOCurrency.MXN,
  PH: ISOCurrency.PHP,
};

export const currencySymbols: Record<ISOCurrency, string> = {
  USD: '$',
  CAD: '$',
  MXN: '$',
  PHP: '₱',
};

export const currencySymbolFromCountry = (country: ISOCountry) =>
  currencySymbols[currencyIdentifiers[country]];

export const formatAmount = (amount: string | number): string => {
  if (!amount) return '';
  if (typeof amount !== 'number') return amount;
  return amount.toFixed(2);
};

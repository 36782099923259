import { randomIntFromRange } from '../../utils/dataUtils';
import { db } from '..';
import { ExternalDataCollections, RootCollections } from '../consts';

export const saveExternalDataRecord = async (
  instanceId: string,
  contactId: string,
  externalDataKeys: string[],
) => {
  if (externalDataKeys.length === 0) {
    return undefined;
  }

  return db
    .collection(RootCollections.ExternalData)
    .doc(instanceId)
    .collection(ExternalDataCollections.ContactIds)
    .doc(contactId)
    .set(
      externalDataKeys.reduce(
        (acc, key) => ({ ...acc, [key]: randomIntFromRange(5, 10) }),
        {},
      ),
    );
};

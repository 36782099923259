import { BACKGROUND_FINAL_STATUS } from '@agentsonly/models';
import React from 'react';

import {
  CompleteIcon,
  InCompleteIcon,
  PendingIcon,
} from '../../../shared/assets';
import { Badge } from '../../../shared/ui/Badge';
import { BaseColors } from '../../../shared/ui/colors';
import { hexToRGB } from '../../../utils/color';
import { StyledBadgeContainer } from '../../styledComponents';

type BackgroundFinalStatus =
  (typeof BACKGROUND_FINAL_STATUS)[keyof typeof BACKGROUND_FINAL_STATUS];

type Props = {
  status?: BackgroundFinalStatus;
};

const STATUS_INFOS = {
  [BACKGROUND_FINAL_STATUS.FAILED]: {
    icon: <InCompleteIcon />,
    label: 'Failed',
    color: BaseColors.errors,
  },
  [BACKGROUND_FINAL_STATUS.PASSED]: {
    icon: <CompleteIcon />,
    label: 'Passed',
    color: BaseColors.primaryDark,
  },
  [BACKGROUND_FINAL_STATUS.PENDING]: {
    icon: <PendingIcon />,
    label: 'Pending',
    color: BaseColors.alertAdvice,
  },
};

export const BackgroundCheckStatusCell: React.FC<Props> = ({ status }) => {
  const info = STATUS_INFOS[status ?? BACKGROUND_FINAL_STATUS.PASSED];

  return (
    <StyledBadgeContainer>
      <Badge
        backgroundColor={hexToRGB(info.color, 0.1)}
        color={info.color}
        icon={info.icon}
        spacer={9}
        content={info.label}
      />
    </StyledBadgeContainer>
  );
};

import { ISOCountryKeys } from '@agentsonly/models';
import { notification, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import {
  WalletTxAdmin,
  WalletTxStatus,
} from '../../../../shared/models/WalletTx';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/csv';
import { walletAPI } from '../../../../utils/firebase/walletAPI';
import { WalletStatusText } from '../../../components/WalletStatusText';

type FetchData = {
  count: number;
  txs: WalletTxAdmin[];
};

const columns: ColumnsType<WalletTxAdmin> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '9%',
    render: (value: string) => format(new Date(value), 'P'),
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Reference#',
    dataIndex: 'referenceNumber',
    key: 'referenceNumber',
  },
  {
    title: 'Agent Name',
    key: 'agentName',
    width: '8%',
    render: (_, record: WalletTxAdmin) =>
      `${record.firstName} ${record.lastName}`,
  },
  {
    title: 'Recipient Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'BankCode/CLABE',
    key: 'code',
    width: '8%',
    render: (_, record: WalletTxAdmin) => {
      const { countryCode } = record;
      if (
        countryCode === ISOCountryKeys.CA ||
        countryCode === ISOCountryKeys.US
      ) {
        return '--';
      }

      return record.clabe ?? record.bankCode;
    },
  },
  {
    title: 'Account Details',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: 'Target Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '7%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
  },
  {
    title: 'Payment Status',
    dataIndex: 'txStatusId',
    key: 'txStatusId',
    width: '8%',
    render: (value: WalletTxStatus) => <WalletStatusText status={value} />,
  },
];

export const WithdrawalsList: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FetchData>();

  const getWithdrawals = useCallback(async (pageNumber?: number) => {
    setLoading(true);
    try {
      const fetchWithdrawals = await walletAPI.getWithdrawals(pageNumber);
      setData(fetchWithdrawals.data);
    } catch (err) {
      if (err instanceof Error) {
        console.log('Error', err.message);
      }

      notification.error({
        message: 'Failed to get Withdrawals data',
      });
    }
    setLoading(false);
  }, []);

  const onPageChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);
      getWithdrawals(currentPage);
    },
    [getWithdrawals],
  );

  useEffect(() => {
    getWithdrawals(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      dataSource={data?.txs}
      rowKey="referenceNumber"
      loading={loading}
      pagination={{
        current: page,
        total: data?.count,
        position: ['bottomLeft'],
        showSizeChanger: false,
        hideOnSinglePage: false,
        pageSize: DEFAULT_PAGE_SIZE,
        onChange: onPageChange,
      }}
    />
  );
};

import { Agent, BACKGROUND_KEYS, BackgroundCheck } from '@agentsonly/models';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';

import {
  BorderedRow,
  StyledEndRow,
} from '../../AgentInformation/styledComponents';
import { SectionContainer } from '../../SectionContainer';
import { IDCheck } from './IDCheck';
import { PCCheck } from './PCCheck';
import { ReviewCompleted } from './ReviewCompleted';
import { SpeedTest } from './SpeedTest';
import { VoiceRecording } from './VoiceRecording';

const INITIAL_BACKGROUND_CHECK = {
  [BACKGROUND_KEYS.GOVERNMENT_ID]: undefined,
  [BACKGROUND_KEYS.POLICE_CLEARANCE]: undefined,
  [BACKGROUND_KEYS.SPEED_TEST]: undefined,
  [BACKGROUND_KEYS.PC_CHECK]: undefined,
  [BACKGROUND_KEYS.VOICE_RECORDING]: undefined,
};

type OnReviewCompleted = (backgroundCheck: BackgroundCheck) => Promise<void>;

type Props = {
  agent: Agent;
  disabled: boolean;
  onReviewCompleted: OnReviewCompleted;
};
export const BackgroundCheckSection: FC<Props> = ({
  agent,
  disabled,
  onReviewCompleted,
}) => {
  const [backgroundCheck, setBackgroundCheck] = useState<BackgroundCheck>({
    ...INITIAL_BACKGROUND_CHECK,
    ...(agent.backgroundCheck ?? {}),
  });
  return (
    <>
      <BorderedRow>
        <Col span={24}>
          <VoiceRecording
            agentId={agent.id}
            backgroundCheck={backgroundCheck}
            onBackgroundCheckChange={setBackgroundCheck}
            disabled={disabled}
          />
        </Col>
      </BorderedRow>
      <BorderedRow>
        <Col span={24}>
          <SectionContainer title="Background Check">
            <IDCheck
              agentId={agent.id}
              backgroundCheck={backgroundCheck}
              onBackgroundCheckChange={setBackgroundCheck}
              disabled={disabled}
            />
          </SectionContainer>
        </Col>
      </BorderedRow>
      <BorderedRow>
        <Col span={24}>
          <SectionContainer title="Speed Test">
            <SpeedTest
              agentId={agent.id}
              backgroundCheck={backgroundCheck}
              onBackgroundCheckChange={setBackgroundCheck}
              disabled={disabled}
            />
          </SectionContainer>
        </Col>
      </BorderedRow>
      <StyledEndRow>
        <Col span={24}>
          <SectionContainer title="PC Check">
            <PCCheck
              agentId={agent.id}
              backgroundCheck={backgroundCheck}
              onBackgroundCheckChange={setBackgroundCheck}
              disabled={disabled}
            />
          </SectionContainer>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col span={8}>
              <ReviewCompleted
                agent={agent}
                backgroundCheck={backgroundCheck}
                onCompleted={() => onReviewCompleted(backgroundCheck)}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </StyledEndRow>
    </>
  );
};

import { TypingSkill } from '@agentsonly/models';
import { Col, Row, Typography } from 'antd';
import React from 'react';

import { CompleteIcon, InCompleteIcon } from '../../../shared/assets';
import { BaseColors } from '../../../shared/ui/colors';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { AVERAGE_ACC, AVERAGE_WPM, NA_VALUE } from '../../consts';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { StyledParagraph, StyledRow } from './styledComponents';

const { Paragraph } = Typography;

type Props = {
  typingTest?: TypingSkill;
};

export const TypingTestSection: React.FC<Props> = ({ typingTest }) => {
  const renderStatus = (wpm?: number) => {
    if (wpm === undefined) {
      return (
        <StyledParagraph strong $color={BaseColors.inactive}>
          {NA_VALUE}
        </StyledParagraph>
      );
    }

    return wpm < AVERAGE_WPM ? (
      <Row align="middle">
        <InCompleteIcon />
        <Spacer axis="horizontal" size={4} />
        <StyledParagraph strong $color={BaseColors.errors}>
          Failed
        </StyledParagraph>
      </Row>
    ) : (
      <Row align="middle">
        <CompleteIcon />
        <Spacer axis="horizontal" size={4} />
        <StyledParagraph strong $color={BaseColors.primaryDark}>
          Passed
        </StyledParagraph>
      </Row>
    );
  };

  return (
    <BorderedRow>
      <Col span={24}>
        <SectionContainer title="Typing Test">
          <StyledRow>
            <Col span={8}>
              <Paragraph>Words per minutes</Paragraph>
              {typingTest?.wpm !== undefined ? (
                <StyledParagraph
                  strong
                  $color={
                    typingTest.wpm < AVERAGE_WPM
                      ? BaseColors.errors
                      : BaseColors.primaryDark
                  }
                >
                  {typingTest.wpm}
                </StyledParagraph>
              ) : (
                <StyledParagraph strong $color={BaseColors.inactive}>
                  {NA_VALUE}
                </StyledParagraph>
              )}
            </Col>
            <Col span={8}>
              <Paragraph>Accuracy</Paragraph>
              <StyledParagraph strong $color={BaseColors.primaryDark}>
                {typingTest?.accuracy !== undefined ? (
                  <StyledParagraph
                    strong
                    $color={
                      typingTest.accuracy < AVERAGE_ACC
                        ? BaseColors.errors
                        : BaseColors.primaryDark
                    }
                  >
                    {Math.round(typingTest.accuracy * 100)}%
                  </StyledParagraph>
                ) : (
                  <StyledParagraph strong $color={BaseColors.inactive}>
                    {NA_VALUE}
                  </StyledParagraph>
                )}
              </StyledParagraph>
            </Col>
            <Col span={8}>
              <Paragraph>Status</Paragraph>
              {renderStatus(typingTest?.wpm)}
            </Col>
          </StyledRow>
        </SectionContainer>
      </Col>
    </BorderedRow>
  );
};

import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

export const useField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Omit<UseControllerProps<TFieldValues, TName>, 'control'>,
) => {
  const { control, formState } = useFormContext<TFieldValues>();
  const controller = useController({ control, ...props });
  const { fieldState } = controller;

  const error =
    fieldState.isTouched || formState.isSubmitted
      ? fieldState.error?.message
      : undefined;

  return { controller, error };
};

import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Title, Text } = Typography;

export const Container = styled(Row)`
  margin: 59px 0;
`;

export const InlineCol = styled(Col)`
  display: inline-block;
  padding: 0 32px;
`;

export const StyledTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 0 !important;
`;

export const ColorfulText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
`;

export const ResourcesSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: white;
  padding: 32px;
  flex: 1;
  overflow-x: auto;
`;

export const ButtonAddContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

import { Typography } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../shared/ui/colors';

const { Paragraph } = Typography;

export const StyledIconContainer = styled.div`
  cursor: pointer;
`;

export const BehaviorDescriptionText = styled(Paragraph)`
  color: ${BaseColors.neutral100};
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
`;

import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const StyledCol = styled(Col)`
  margin-bottom: 3px;
`;

export const DropdownContainer = styled(Col)`
  max-width: 360px;
`;

import { Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';

import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { StyledParagraph } from './styledComponents';

type Props = {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
};

export const SectionContainer: React.FC<Props> = ({
  title,
  subTitle,
  children,
}) => (
  <>
    <Spacer axis="vertical" size={24} />
    <StyledParagraph strong>{title}</StyledParagraph>
    <Paragraph>{subTitle}</Paragraph>
    <Row justify="space-between">{children}</Row>
    <Spacer axis="vertical" size={24} />
  </>
);

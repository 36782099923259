import { Typography, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';

import { isCSV } from '../../../utils/file';
import { Modal } from '../Modal';
import { IconType, ShowNotification } from '../ShowNotification';
import { Footer } from './Footer';

const { Text } = Typography;
const { Dragger } = Upload;

type Props = {
  open: boolean;
  onClose: () => void;
  onUpload: (file?: File) => Promise<void>;
};

const MAX_FILE_UPLOAD = 1;

export const ImportCSVModal: React.FC<Props> = ({
  open,
  onClose,
  onUpload,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);
    await onUpload(fileList?.[0]?.originFileObj);
    setLoading(false);
    onClose();
  };

  // Antd Uploads tries to upload, if handleBeforeUpload return true
  // if thie returns true, handleChange will fire twice
  const handleBeforeUpload = (): boolean => false;

  const handleChange = (info: UploadChangeParam<UploadFile<File>>) => {
    if (!isCSV(info.file)) {
      ShowNotification('File Type is not .csv', IconType.Error);
      return;
    }

    if (info.fileList.length > MAX_FILE_UPLOAD) {
      ShowNotification('Reached max file upload limit', IconType.Error);
      return;
    }

    setFileList([...info.fileList]);
  };

  useEffect(() => {
    setFileList([]);
  }, [open]);

  return (
    <Modal
      title="Import File Confirmation"
      open={open}
      onCancel={onClose}
      footer={
        <Footer
          isLoading={isLoading}
          disabled={!fileList.length}
          onReplaceButtonClick={() => setFileList([])}
          onUploadButtonClick={handleUpload}
        />
      }
    >
      <>
        <Text type="secondary">Imported File Name</Text>
        <Dragger
          name="file"
          fileList={fileList}
          multiple={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
          onRemove={() => setFileList([])}
        >
          <p className="ant-upload-text">
            Click or drag .csv file to this area to upload (Max:
            {MAX_FILE_UPLOAD})
          </p>
        </Dragger>
      </>
    </Modal>
  );
};

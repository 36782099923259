import { ScheduleConfig } from '../../../firebase/models/ScheduleConfig';
import { getSchedulePreferentialConfigRef } from '../../../firebase/project';

export const usePrefConfig = (projectId?: string) => {
  const errMsg = 'project id is missing';
  const fetchPrefConfig = async () => {
    if (!projectId) throw new Error(errMsg);
    const ref = await getSchedulePreferentialConfigRef(projectId).get();
    return ref.data();
  };

  const deletePrefConfig = async () => {
    if (!projectId) throw new Error(errMsg);
    await getSchedulePreferentialConfigRef(projectId).delete();
  };

  const savePrefConfig = async (data: ScheduleConfig) => {
    if (!projectId) throw new Error(errMsg);
    await getSchedulePreferentialConfigRef(projectId).set(data);
  };

  return {
    fetchPrefConfig,
    deletePrefConfig,
    savePrefConfig,
  };
};

import { Project } from '@agentsonly/models';
import { MenuProps } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { useCallback, useState } from 'react';

import { Achievement } from './components/Achievement';
import { AgentFeedback } from './components/AgentFeedback';
import { Feedback } from './components/Feedback';
import { Settings } from './components/Settings';
import { Submissions } from './components/Submissions';
import { StyledCol, StyledMenu } from './styledComponents';

type Props = {
  project: Project;
  disabled: boolean;
};

const TABS = [
  {
    key: 'settings',
    title: 'Settings',
    element: Settings,
  },
  {
    key: 'feedback',
    title: 'Feedback',
    element: Feedback,
  },
  {
    key: 'agentFeedback',
    title: 'Agent Feedback',
    element: AgentFeedback,
  },
  {
    key: 'achievements',
    title: 'Achievements',
    element: Achievement,
  },
  {
    key: 'submissions',
    title: 'Submissions',
    element: Submissions,
  },
];
export const Coaching = ({ project, disabled }: Props) => {
  const [selectedTab, selectTab] = useState(TABS[0]);
  const MenuItems: MenuProps['items'] = TABS.map((tab) => ({
    label: tab.title,
    key: tab.key,
  }));
  const Comp = selectedTab.element;

  const handleClick: MenuProps['onClick'] = useCallback((e: MenuInfo) => {
    selectTab(TABS.find(({ key }) => e.key === key) ?? TABS[0]);
  }, []);

  return (
    <>
      <StyledMenu
        onClick={handleClick}
        selectedKeys={[selectedTab.key]}
        mode="horizontal"
        items={MenuItems}
      />
      <StyledCol span={24}>
        <Comp project={project} disabled={disabled} />
      </StyledCol>
    </>
  );
};

import type { MenuProps } from 'antd';
import { Col, Menu, Row } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useState } from 'react';

import { FixReferralRelationships } from './contents/FixReferralRelationships';
import { PlatformInvoices } from './contents/PlatformInvoices';
import { PlatformPayments } from './contents/PlatformPayments';
import { Referrals } from './contents/Referrals';
import { Settings } from './contents/Settings';

const TABS = [
  {
    key: 'settings',
    title: 'Settings',
    Cmp: Settings,
  },
  {
    key: 'referrals',
    title: 'Referrals',
    Cmp: Referrals,
  },
  {
    key: 'platformPayments',
    title: 'Platform Payments',
    Cmp: PlatformPayments,
  },
  {
    key: 'platformInvoices',
    title: 'Platform Invoices',
    Cmp: PlatformInvoices,
  },
  {
    key: 'fixReferralRelationships',
    title: 'Fix Referral Relationships',
    Cmp: FixReferralRelationships,
  },
];

const MenuItems: MenuProps['items'] = TABS.map((tab) => ({
  label: tab.title,
  key: tab.key,
}));

type Props = {
  disabled: boolean;
};

export const ReferralPage: React.FC<Props> = ({ disabled }) => {
  const [selectedTab, selectTab] = useState(TABS[0]);
  const { Cmp } = selectedTab;

  const handleClick: MenuProps['onClick'] = useCallback((e: MenuInfo) => {
    selectTab(TABS.find(({ key }) => e.key === key) ?? TABS[0]);
  }, []);

  return (
    <Row
      style={{
        height: '100%',
        flexDirection: 'column',
        gap: '32px',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <Col span={24}>
        <Menu
          onClick={handleClick}
          selectedKeys={[selectedTab.key]}
          mode="horizontal"
          style={{ marginBottom: 16 }}
          items={MenuItems}
        />
        <Cmp disabled={disabled} />
      </Col>
    </Row>
  );
};

import { Modal } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { formatCSVData } from '../../../../referrals/contents/PlatformInvoicesTable/invoiceGenerator/invoices';
import { Button } from '../../../../shared/ui/Button';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { StyledLabelTitle } from '../../../../shared/ui/title/styledComponents';
import { createFileToDownload } from '../../../../utils/file';
import { getClientMissingData } from '../../helpers';
import { StyledTable } from './styledComponents';

type Props = {
  docId: string;
  projectId: string;
  onClose: () => void;
  uploadedAt: Date;
};

const columns = [
  {
    title: 'Contact ID List',
    key: 'contactId',
    dataIndex: 'contactId',
    render: (value: string) =>
      value && value !== '' ? value : 'No Contact Id',
  },
];

export const MissingDataModal: React.FC<Props> = ({
  docId,
  uploadedAt,
  projectId,
  onClose,
}) => {
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (projectId) {
        try {
          const missingData = await getClientMissingData(projectId, docId);
          setContactIds(missingData.map((m) => m.contactId));
        } catch (e) {
          ShowNotification((e as Error).message, IconType.Error);
        }
        setLoaded(true);
      }
    };
    loadData();
  }, [docId, projectId]);

  const handleDownloadFile = () => {
    const finalData = [['Contact Id'], ...contactIds.map((id) => [id])];
    createFileToDownload(
      `Missing-Data-${format(uploadedAt, 'yyyy-MM-dd')}.csv`,
      finalData
        .map((r) => formatCSVData(r))
        .reduce((prev, curr) => prev + curr, ''),
    );
    onClose();
  };

  return (
    <Modal
      footer={
        <Button disabled={!loaded} onClick={handleDownloadFile} type="primary">
          Download CSV File
        </Button>
      }
      onCancel={onClose}
      title="Data Contact ID"
      visible
    >
      <StyledLabelTitle>{`Missing Data: ${contactIds.length}`}</StyledLabelTitle>
      <StyledTable
        dataSource={contactIds.map((id) => ({ contactId: id }))}
        columns={columns}
        pagination={{
          pageSize: 5,
          position: ['bottomLeft'],
        }}
      />
    </Modal>
  );
};

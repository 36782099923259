import { hoursToSeconds } from 'date-fns';

import { TierEnum } from '../../../firebase/models/ScheduleConfig';

export const TIER_OPTIONS: Record<number, string> = {
  [hoursToSeconds(24)]: '24 hours',
  [hoursToSeconds(36)]: '36 hours',
  [hoursToSeconds(48)]: '48 hours',
  [hoursToSeconds(60)]: '60 hours',
  [hoursToSeconds(72)]: '72 hours',
};

export const TIERS: Record<TierEnum, string> = {
  'tier-1': 'Tier 1',
  'tier-2': 'Tier 2',
  'tier-3': 'Tier 3',
};

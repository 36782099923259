import { Layout } from 'antd';
import styled from 'styled-components';

export const MenuWrapper = styled.div`
  background-color: white;
  height: 100vh;
  max-height: 100vh;
  display: 'flex';
  width: 200px;
`;

export const StyledSider = styled(Layout.Sider)`
  border-right: 0;
`;

export const LogoContainer = styled.div`
  background-color: black;
  text-align: center;
`;

export const BottomContainer = styled.div`
  padding: 16px;
`;

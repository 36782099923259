import React, { PropsWithChildren } from 'react';

import { StyledChip } from './styledComponents';

type ChipProps = { className?: string | undefined } & PropsWithChildren;

export const Chip: React.FC<ChipProps> = React.memo(
  ({ className, children }) => (
    <StyledChip className={className}>{children}</StyledChip>
  ),
);

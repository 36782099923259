import { Col, Row } from 'antd';
import { FC, FormEventHandler, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '../../shared/ui/Button';
import { Form } from '../../shared/ui/form/Form';
import { FormInput } from '../../shared/ui/form/FormInput';

type Props = {
  disabled: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

export const AddUserForm: FC<Props> = memo(({ disabled, onSubmit }) => {
  const { formState } = useFormContext();

  return (
    <Form onFinish={onSubmit} disabled={disabled}>
      <Row>
        <Col span={24}>
          <FormInput name="email" label="User email" />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button
            htmlType="submit"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

import { useEffect, useState } from 'react';

import { Document } from '../../firebase/models/Document';
import { getAgentAgreements } from '../helper';

type Props = {
  agentId: string;
};

export const useFetchSignedDocuments = ({ agentId }: Props) => {
  const [fetchingDocument, setFetchingDocument] = useState<boolean>(false);
  const [legalDocuments, setLegalDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const fetchSignedDocuments = async () => {
      setFetchingDocument(true);
      try {
        const signedDocuments = await getAgentAgreements(agentId);
        setLegalDocuments(signedDocuments);
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message);
        }
      }
      setFetchingDocument(false);
    };

    fetchSignedDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  return { legalDocuments, fetchingDocument };
};

import firebase from 'firebase/compat/app';
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  getDocs,
  Query,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

// obj violates no-params-reassign but solution is not good for performance https://stackoverflow.com/a/45700941
/* eslint-disable no-param-reassign */
const convertTimestampToDate = <T>(data: Record<string, any>): T => {
  if (typeof data !== 'object') return data;
  return Object.entries(data).reduce<Record<string, unknown>>(
    (obj, [key, value]) => {
      if (value instanceof firebase.firestore.Timestamp) {
        obj[key] = value.toDate();
      } else if (Array.isArray(value)) {
        obj[key] = value.map(convertTimestampToDate);
      } else if (typeof value === 'object' && value !== null) {
        obj[key] = convertTimestampToDate(value);
      } else {
        obj[key] = value;
      }
      return obj;
    },
    {},
  ) as T;
};
/* eslint-enable no-param-reassign */

export const generalConverter = <T>({
  convertTimestamp,
}: { convertTimestamp?: boolean } = {}) => ({
  toFirestore(data: T): firebase.firestore.DocumentData {
    return { ...data } as firebase.firestore.DocumentData;
  },
  fromFirestore(
    snapshot:
      | firebase.firestore.QueryDocumentSnapshot<DocumentData> // for fs v8
      | QueryDocumentSnapshot<DocumentData>, // for fs v9,
    options: firebase.firestore.SnapshotOptions,
  ): T {
    const data = { ...snapshot.data(options), id: snapshot.id } as any;
    if (convertTimestamp) {
      return convertTimestampToDate<T>(data);
    }
    return data as T;
  },
});

export const convertFSTimestamp = (date: Date) =>
  firebase.firestore.Timestamp.fromDate(date);

/**
 * Using v8 API, execute query and return array of document. Each document object is decorated with new field `id: document.id`
 * @param query query to execute against DB
 * @param defaultEmptyValue if result is empty, return this value
 * @param convertTimestamp set to true to convert firebase.firestore.Timestamp value to Date
 * @returns
 */
export const getCollectionData = async <T>(
  query: firebase.firestore.Query,
  defaultEmptyValue = [],
  convertTimestamp = false,
) => {
  const querySnapshot = await query
    .withConverter(generalConverter<T>({ convertTimestamp }))
    .get();

  return querySnapshot.empty
    ? defaultEmptyValue
    : querySnapshot.docs.map((r) => r.data());
};

/**
 * Using v9 API, return array of document data. Each document object is decorated with new field `id: document.id`
 * @param queryOrCollectionRef accept Query or CollectionReference
 * @param defaultEmptyValue if result is empty, return this value
 * @returns
 */
export async function loadCollectionData<T>(
  queryOrCollectionRef: Query<T>,
  defaultEmptyValue = [],
) {
  const querySnapshot = await getDocs(queryOrCollectionRef);

  return querySnapshot.empty
    ? defaultEmptyValue
    : querySnapshot.docs.map((r) => r.data());
}

/**
 * convert path to an object of key:value. a/1/b/2/c ==> {a:1, b:2, c: undefined}
 * @param path
 * @returns
 */
export const convertPathToObject = (path: string) => {
  const c = path.split('/');
  const obj: Record<string, string> = {};
  for (let i = 0; i < c.length; i += 2) {
    obj[c[i]] = c[i + 1];
  }
  return obj;
};

/**
 * having data structure of /coaching/111/agents/{agentId}/feedbacks
 * rootRef = "/coaching/111"
 * childrenCollectionPath = "agents"
 * return array of children { id, ref } where id = ${agentId}, ref = ref to each "/coaching/111/agents/{agentId}"
 * @param rootRef
 * @param childrenCollectionPath
 * @returns
 */
export const getSubCollectionRefs = async (
  rootRef: DocumentReference,
  childrenCollectionPath: string,
): Promise<
  {
    id: string;
    ref: DocumentReference;
  }[]
> => {
  const queryChildrenSnapshot = await getDocs(
    collection(rootRef, childrenCollectionPath),
  );

  const childrenRefs = queryChildrenSnapshot.empty
    ? []
    : queryChildrenSnapshot.docs.map((r) => r.ref);

  const childrenDocsRefs = childrenRefs.map((ref) => ({
    id: convertPathToObject(ref.path)[childrenCollectionPath],
    ref,
  }));

  return childrenDocsRefs;
};

/**
 * from rootRef, load {childrenId => grandChildren} objects array
 * @example load /coaching/111/agents/{agentId}/feedbacks/{feedbackId} to {id: agentId, grandChildrenDocs: arrFeedbacks }[]
 * @param rootRef
 * @param childrenCollectionPath
 * @param fnConvertRefToCollection
 * @returns
 */
export const loadDataOfAllSubCollectionRefs = async <T>(
  rootRef: DocumentReference,
  childrenCollectionPath: string,

  fnConvertRefToCollection: (
    ref: DocumentReference<DocumentData>,
  ) => CollectionReference,
) => {
  collection(rootRef, childrenCollectionPath).withConverter(
    generalConverter<T>(),
  );

  const childrenDocsRefs = await getSubCollectionRefs(
    rootRef,
    childrenCollectionPath,
  );

  const arrRet = await Promise.all(
    childrenDocsRefs.map(async ({ id, ref }) => {
      const grandChildrenDocs = await loadCollectionData<T>(
        fnConvertRefToCollection(ref) as CollectionReference<T>,
      );

      return {
        id,
        grandChildrenDocs,
      };
    }),
  );

  return arrRet;
};

import { DemoCollections, RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { CsvUploadItem } from '../models/Demo';
import { generalConverter } from '../utils';

export const getUploadedCSVRef = (projectId: string) =>
  db
    .collection(RootCollections.Demo)
    .doc(projectId)
    .collection(DemoCollections.ProjectStats)
    .withConverter(generalConverter<CsvUploadItem>());

import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import * as React from 'react';

import { DatePicker } from '../../../utils/DatePicker';

export interface TimePickerProps
  extends Omit<PickerTimeProps<Date>, 'picker'> {}

export const TimePicker = React.forwardRef<any, TimePickerProps>(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props, ref) => <DatePicker {...props} picker="time" ref={ref} />,
);

TimePicker.displayName = 'TimePicker';

import React from 'react';

type Props = {
  disable: boolean;
  number: number;
  style?: object;
};

export const ImageEmpty = ({ disable, number, style }: Props) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="60" cy="60" r="60" fill="#ECF1F5" />
    <path
      d="M17.2951 40.7548L17.0905 41.5112C17.0434 41.6841 16.9023 41.8252 16.7282 41.8735L15.9731 42.0781C15.8625 42.1086 15.8625 42.251 15.9731 42.2828L16.7282 42.4874C16.9023 42.5345 17.0434 42.6756 17.0905 42.8497L17.2951 43.6061C17.3269 43.7154 17.4693 43.7154 17.4998 43.6061L17.7057 42.8497C17.7527 42.6756 17.8938 42.5345 18.068 42.4874L18.8231 42.2828C18.9337 42.251 18.9337 42.1086 18.8231 42.0781L18.068 41.8735C17.8938 41.8252 17.7527 41.6841 17.7057 41.5112L17.4998 40.7548C17.4693 40.6442 17.3269 40.6442 17.2951 40.7548Z"
      fill="#A5B1B9"
    />
    <path
      d="M86.273 34.8135L85.9895 35.8851C85.926 36.1368 85.7213 36.3415 85.4696 36.4038L84.398 36.6885C84.2404 36.7356 84.2404 36.9402 84.398 36.9873L85.4696 37.2707C85.7213 37.3343 85.926 37.539 85.9895 37.7906L86.273 38.8623C86.32 39.0186 86.5247 39.0186 86.5717 38.8623L86.8552 37.7906C86.9188 37.539 87.1234 37.3343 87.3751 37.2707L88.4467 36.9873C88.6044 36.9402 88.6044 36.7356 88.4467 36.6885L87.3751 36.4038C87.1234 36.3415 86.9188 36.1368 86.8552 35.8851L86.5717 34.8135C86.5412 34.6559 86.32 34.6559 86.273 34.8135Z"
      fill="#A5B1B9"
    />
    <path
      d="M78.4873 70.344L78.2827 71.1003C78.2356 71.2732 78.0945 71.4156 77.9204 71.4626L77.164 71.6673C77.0547 71.6991 77.0547 71.8402 77.164 71.872L77.9204 72.0766C78.0945 72.1249 78.2356 72.266 78.2827 72.4389L78.4873 73.1953C78.5191 73.3059 78.6615 73.3059 78.692 73.1953L78.8967 72.4389C78.945 72.266 79.0861 72.1249 79.2589 72.0766L80.0153 71.872C80.1259 71.8402 80.1259 71.6991 80.0153 71.6673L79.2589 71.4626C79.0861 71.4156 78.945 71.2732 78.8967 71.1003L78.692 70.344C78.6767 70.2499 78.5191 70.2499 78.4873 70.344Z"
      fill="#35383D"
    />
    <path
      d="M76.6332 13.6417L76.065 15.7532C75.9392 16.2566 75.5464 16.6354 75.0417 16.7765L72.9315 17.3434C72.6315 17.4222 72.6315 17.8481 72.9315 17.9422L75.0417 18.5091C75.5464 18.635 75.9239 19.029 76.065 19.5337L76.6332 21.6439C76.7108 21.9439 77.1366 21.9439 77.2307 21.6439L77.7976 19.5337C77.9248 19.029 78.3176 18.6515 78.8222 18.5091L80.9324 17.9422C81.2324 17.8633 81.2324 17.4388 80.9324 17.3434L78.8222 16.7765C78.3176 16.6506 77.94 16.2566 77.7976 15.7532L77.2307 13.6417C77.1519 13.3278 76.7108 13.3278 76.6332 13.6417Z"
      fill="#6E818D"
    />
    <path
      d="M32.4189 90.5449L31.9155 92.4186C31.8049 92.8597 31.4579 93.2068 31.0168 93.3174L29.1431 93.8208C28.8748 93.8996 28.8748 94.2784 29.1431 94.3407L31.0168 94.8453C31.4579 94.9547 31.8049 95.3017 31.9155 95.7428L32.4189 97.6178C32.4977 97.8847 32.8753 97.8847 32.9388 97.6178L33.4435 95.7428C33.5528 95.3017 33.8998 94.9547 34.3409 94.8453L36.2147 94.3407C36.4829 94.2619 36.4829 93.8843 36.2147 93.8208L34.3409 93.3174C33.8998 93.2068 33.5528 92.8597 33.4435 92.4186L32.9388 90.5449C32.86 90.2767 32.4825 90.2767 32.4189 90.5449Z"
      fill="#6E818D"
    />
    <path
      d="M99.0051 82.7985L98.4686 84.7994C98.3428 85.2723 97.9805 85.6346 97.5076 85.7604L95.508 86.2956C95.2245 86.3744 95.2245 86.7837 95.508 86.8625L97.5076 87.3977C97.9805 87.5248 98.3428 87.8871 98.4686 88.3587L99.0051 90.3596C99.0826 90.643 99.4932 90.643 99.572 90.3596L100.107 88.3587C100.233 87.8871 100.595 87.5248 101.068 87.3977L103.068 86.8625C103.353 86.7837 103.353 86.3744 103.068 86.2956L101.068 85.7604C100.595 85.6346 100.233 85.2723 100.107 84.7994L99.572 82.7985C99.4767 82.5151 99.0826 82.5151 99.0051 82.7985Z"
      fill="#6E818D"
    />
    <path
      d="M41.6731 104.781L41.5625 105.175C41.532 105.269 41.4684 105.333 41.3744 105.365L40.9803 105.474C40.9167 105.49 40.9167 105.569 40.9803 105.584L41.3744 105.695C41.4684 105.727 41.532 105.789 41.5625 105.883L41.6731 106.277C41.6896 106.341 41.7672 106.341 41.7837 106.277L41.8943 105.883C41.9248 105.789 41.9883 105.727 42.0824 105.695L42.4765 105.584C42.54 105.569 42.54 105.49 42.4765 105.474L42.0824 105.365C41.9883 105.333 41.9248 105.269 41.8943 105.175L41.7837 104.781C41.7672 104.719 41.6896 104.719 41.6731 104.781Z"
      fill="#A5B1B9"
    />
    <path
      d="M33.1244 28.0863L33.0608 28.291C33.0456 28.338 33.0138 28.3851 32.9668 28.3851L32.7621 28.4486C32.7303 28.4639 32.7303 28.4957 32.7621 28.5109L32.9668 28.5745C33.0138 28.5897 33.0608 28.6215 33.0608 28.6685L33.1244 28.8732C33.1397 28.905 33.1714 28.905 33.1867 28.8732L33.2503 28.6685C33.2655 28.6215 33.2973 28.5745 33.3443 28.5745L33.549 28.5109C33.5808 28.4957 33.5808 28.4639 33.549 28.4486L33.3443 28.3851C33.2973 28.3698 33.2503 28.338 33.2503 28.291L33.1867 28.0863C33.1714 28.0546 33.1397 28.0546 33.1244 28.0863Z"
      fill="#A5B1B9"
    />
    <path
      d="M62.5951 79.6645L62.5315 79.8691C62.5163 79.9162 62.4845 79.9632 62.4375 79.9632L62.2328 80.0268C62.201 80.042 62.201 80.0738 62.2328 80.0903L62.4375 80.1526C62.4845 80.1679 62.5315 80.1996 62.5315 80.2467L62.5951 80.4526C62.6104 80.4831 62.6421 80.4831 62.6574 80.4526L62.721 80.2467C62.7362 80.1996 62.768 80.1526 62.815 80.1526L63.0197 80.0903C63.0515 80.0738 63.0515 80.042 63.0197 80.0268L62.815 79.9632C62.768 79.9479 62.721 79.9162 62.721 79.8691L62.6574 79.6645C62.6574 79.6327 62.6104 79.6327 62.5951 79.6645Z"
      fill="#35383D"
    />
    <path
      d="M80.4097 56.328L80.2686 56.8326C80.2368 56.9585 80.1415 57.0525 80.0156 57.0843L79.5122 57.2254C79.4334 57.2419 79.4334 57.3525 79.5122 57.3678L80.0156 57.5089C80.1415 57.5407 80.2368 57.636 80.2686 57.7619L80.4097 58.2653C80.425 58.3441 80.5355 58.3441 80.5521 58.2653L80.6932 57.7619C80.725 57.636 80.819 57.5407 80.9449 57.5089L81.4495 57.3678C81.5283 57.3525 81.5283 57.2419 81.4495 57.2254L80.9449 57.0843C80.819 57.0525 80.725 56.9585 80.6932 56.8326L80.5521 56.328C80.5355 56.2657 80.425 56.2657 80.4097 56.328Z"
      fill="#35383D"
    />
    <path
      d="M93.674 52.4152L35.0514 33.6987C32.4761 32.9072 29.7035 34.2929 28.9109 36.8682L16.335 76.1801L84.2664 97.8664L96.8423 58.5546C97.635 55.9793 96.2481 53.3065 93.674 52.4152Z"
      fill={disable ? '#B8B8B8' : '#078768'}
    />
    <path
      d="M100.407 50.7299L78.3239 43.6991C76.641 43.1049 74.7598 43.6005 73.4715 44.7889L67.6292 50.3341C66.3422 51.5225 64.4609 52.0169 62.7769 51.4227L37.9222 43.5007C35.644 42.8078 33.2683 43.7978 32.0799 45.8775L16.2358 76.0801L84.1662 97.7665L103.278 57.3648C104.665 54.7895 103.278 51.6212 100.407 50.7299Z"
      fill={disable ? '#B8B8B8' : '#078768'}
    />
    <circle cx="60.7062" cy="69.8727" r="13.4118" fill="white" />
    <text
      id="GT"
      fontFamily="BrandonText-BoldItalic, Brandon Text"
      fontSize="18"
      fontStyle="normal"
      fontWeight="700"
      fill={disable ? '#B8B8B8' : '#08A17C'}
    >
      <tspan x="56" y="76">
        {number}
      </tspan>
    </text>
  </svg>
);

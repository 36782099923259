import { InitiationMethod } from '@agentsonly/models';
import {
  Col,
  Form as AntdForm,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
} from 'antd';
import { startOfDay } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { Form } from '../../../shared/ui/form/Form';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { TimePicker } from '../../../shared/ui/TimePicker';
import { DatePicker } from '../../../utils/DatePicker';
import { toVancouverTime } from '../../../utils/timezone';
import { useGenerateCallData } from './hooks';

interface Props {
  disabled: boolean;
  open: boolean;
  onResult: ({ err, isCancel }: { err?: Error; isCancel?: boolean }) => void;
  approvedAgentIds: Array<string>;
  connectInstanceId?: string;
}

export const AddCallDataModal: React.FC<Props> = ({
  disabled,
  open,
  onResult,
  approvedAgentIds,
  connectInstanceId,
}) => {
  const currentDate = new Date();
  const initialTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  const convertVancouverTime = startOfDay(toVancouverTime(currentDate));
  const [form] = AntdForm.useForm();
  const [date, setDate] = useState(convertVancouverTime);
  const [time, setTime] = useState(initialTime);
  const [callType, setCallType] = useState<InitiationMethod>('INBOUND');
  const [loading, handleGenerateCallData] = useGenerateCallData(
    approvedAgentIds,
    date,
    time,
    callType,
    onResult,
    connectInstanceId,
  );

  const handleDate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (date: Date | null) => {
      if (date) {
        setDate(date);
      }
    },
    [setDate],
  );

  const handleTime = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (time: Date | null) => {
      if (time) {
        setTime(time);
      }
    },
    [setTime],
  );

  const cancelClicked = useCallback(() => {
    onResult({ isCancel: true });
  }, [onResult]);

  return (
    <Modal
      title={`Generate Call Data (Instance ID: ${connectInstanceId})`}
      open={open}
      okText="Generate"
      onCancel={cancelClicked}
      onOk={form.submit}
      width="75%"
      confirmLoading={loading}
      getContainer={false}
    >
      <Form
        form={form}
        initialValues={{
          agentIds: '',
          callsPerAgent: 2,
          minsPerCall: 5,
          acwMinsPerCall: 2,
        }}
        onFinish={handleGenerateCallData}
        disabled={disabled}
      >
        <Row>
          <Col span={24}>Total Approved Agents: {approvedAgentIds.length}</Col>
          <Col span={24}>
            <Spacer axis="vertical" size={18} />
          </Col>
          <Col span={8} pull={16} offset={16}>
            Approved Agent IDs (optional, comma separated):
            <AntdForm.Item name="agentIds">
              <Input />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            Calls Per Agent:
            <AntdForm.Item name="callsPerAgent">
              <InputNumber min={1} max={1000} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            Handle Time Mins:
            <AntdForm.Item name="minsPerCall">
              <InputNumber min={1} max={10} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            ACW Mins:
            <AntdForm.Item name="acwMinsPerCall">
              <InputNumber min={0} max={10} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            Start Date:
            <br />
            <DatePicker
              key="startDatePicker"
              defaultValue={date}
              onChange={handleDate}
            />
          </Col>
          <Col span={8}>
            Start Time:
            <br />
            <TimePicker
              key="timePicker"
              format="HH:mm"
              minuteStep={30}
              defaultValue={initialTime}
              onChange={handleTime}
            />
          </Col>
          <Col span={8}>
            Call Type:
            <br />
            <Radio.Group
              onChange={(e: RadioChangeEvent) => setCallType(e.target.value)}
              value={callType}
            >
              <Radio value="INBOUND">INBOUND</Radio>
              <Radio value="OUTBOUND">OUTBOUND</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import { PlatformRate, PlatformRateType, Project } from '@agentsonly/models';

import { ProjectPlatformRate, ProjectPlatformRatesFormParam } from './types';

export const sortingProjectPlatformRates = (
  projectPlatformRates: ProjectPlatformRate[],
) =>
  projectPlatformRates.sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  );

export const convertPlatformRatesToProjectPlatformRates = (
  platformRates: PlatformRate[],
  project: Project,
) =>
  platformRates.map<ProjectPlatformRate>((platformRate) => ({
    ...platformRate,
    rateId: platformRate.id,
    disabled: false,
    active: !!project.platformRates?.rates.find(
      ({ rateId }) => rateId === platformRate.id,
    ),
  }));

export const disableOtherPlatformRateTypes = (
  projectPlatformRates: ProjectPlatformRate[],
  interactRateId?: string,
) => {
  const interactRate = projectPlatformRates.find(
    (rate) => rate.id === interactRateId,
  );

  if (!interactRate || interactRate.disabled) {
    // bad data, return the original project platform rates. Disabled interaction rate is not allowed to interact
    return projectPlatformRates;
  }

  const groupRatesByType = projectPlatformRates.reduce<
    Partial<Record<PlatformRateType, ProjectPlatformRate[]>>
  >(
    (prev, curr) => ({
      ...prev,
      [curr.rateType]: [...(prev[curr.rateType] ?? []), curr],
    }),
    {},
  );

  return Object.values(groupRatesByType).flatMap((rates) => {
    const groupActive = rates.some((r) => r.active);
    const activeRate = rates.find((r) => r.active);
    return rates.map((rate) => ({
      ...rate,
      disabled: groupActive && rate.id !== activeRate?.id,
    }));
  });
};

export const getDefaultPlatformRatesFormParam = (
  platformRates: PlatformRate[],
  project: Project,
): ProjectPlatformRatesFormParam => {
  const firstActiveRate = project.platformRates?.rates[0];
  const sortedRates = sortingProjectPlatformRates(
    disableOtherPlatformRateTypes(
      convertPlatformRatesToProjectPlatformRates(platformRates, project),
      firstActiveRate?.rateId,
    ),
  );

  return {
    platformRates: [
      ...sortedRates.filter((r) => !r.disabled),
      ...sortedRates.filter((r) => r.disabled),
    ],
  };
};

import { Agent } from '@agentsonly/models';
import React, { FC, useEffect, useState } from 'react';

import { getAgentWorkingExperience } from '../../../../../firebase/agent';
import {
  WorkExperienceDetail,
  WorkingExperienceType,
} from '../../../../../firebase/agent/model';
import { CallCenterExperienceSection } from '../../../CallCenterExperience';
import { ExperienceSection } from '../../../Experience';
import { FreelanceSection } from '../../../FreelanceSection';
import { ProjectsSection } from '../../../ProjectsSection';
import { QualificationsSection } from '../../../QualificationsSection';
import { WorkPreferenceSection } from '../../../WorkPreferenceSection';

type Props = {
  agent: Agent;
};

export const WorkExperienceAndPreference: FC<Props> = ({ agent }) => {
  const [workExperience, setWorkExperience] = useState<WorkExperienceDetail>();
  const getAgentWorkingExperiences = async () => {
    const [callCenter, freelancer, others] = await Promise.all([
      getAgentWorkingExperience(agent.id, WorkingExperienceType.CALL_CENTER),
      getAgentWorkingExperience(
        agent.id,
        WorkingExperienceType.ONLINE_FREELANCER,
      ),
      getAgentWorkingExperience(agent.id, WorkingExperienceType.OTHERS),
    ]);

    setWorkExperience({
      ...(callCenter && { [WorkingExperienceType.CALL_CENTER]: callCenter }),
      ...(freelancer && {
        [WorkingExperienceType.ONLINE_FREELANCER]: freelancer,
      }),
      ...(others && { [WorkingExperienceType.OTHERS]: others }),
    });
  };

  useEffect(() => {
    getAgentWorkingExperiences();
  }, []);
  return (
    <>
      <ProjectsSection agentId={agent.id} />
      <ExperienceSection workExperience={workExperience} />
      <CallCenterExperienceSection workExperience={workExperience} />
      <FreelanceSection workExperience={workExperience} />
      <QualificationsSection qualifications={agent.qualifications} />
      <WorkPreferenceSection workPreference={agent.workPreference} />
    </>
  );
};

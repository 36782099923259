import type { AiProvider, ChatGptModel } from '../../firebase/models/Prompts';
import { AI_PROVIDERS, CHAT_GPT_MODEL } from '../../firebase/models/Prompts';

export const PROVIDER_MODEL_MAP: Partial<
  Record<AiProvider, Readonly<ChatGptModel[]>>
> = {
  openai: CHAT_GPT_MODEL,
};

export const OPENAI_TEMPERATURE_RANGE = [0, 1] as const;

export const AI_PROVIDERS_LABEL_MAPPING: Record<AiProvider, string> = {
  [AI_PROVIDERS.NONE]: 'None',
  [AI_PROVIDERS.OPEN_AI]: 'Open AI',
  [AI_PROVIDERS.OPEN_AI_ASSISTANT]: 'Open AI Assistant',
  [AI_PROVIDERS.ANTHROPIC]: 'Anthropic',
};

import { Agent } from '@agentsonly/models';
import { FC } from 'react';

import { BackgroundCheck } from '../../../BackgroundCheck';
import { ComprehensiveAndTypingTest } from '../../../ComprehensionAndTypingTest';

type Props = {
  agent: Agent;
  disabled: boolean;
};

export const GigReguirements: FC<Props> = ({ agent, disabled }) => (
  <>
    <ComprehensiveAndTypingTest agentId={agent.id} />
    <BackgroundCheck agentId={agent.id} disabled={disabled} />
  </>
);

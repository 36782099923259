import { Col, Row } from 'antd';
import React from 'react';

import { IconEmptyResult } from '../../../../shared/assets';
import { BaseColors } from '../../../../shared/ui/colors';
import { Spacer } from '../../../../shared/ui/Spacer/styledComponents';
import { StyledP2Text } from '../../../../shared/ui/text/styledComponents';
import { StyledBigTitle } from '../../../../shared/ui/title/styledComponents';

export const NoResultFound = () => (
  <Row>
    <Col span={24}>
      <Spacer axis="vertical" size={100} />
      <Row>
        <Col span={24}>
          <IconEmptyResult />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spacer axis="vertical" size={50} />
          <StyledBigTitle color={BaseColors.neutral100}>
            No Result Found
          </StyledBigTitle>
          <StyledP2Text color={BaseColors.neutral60}>
            You don&lsquo;t have any client manual data on that day. <br />
            Please search another date.
          </StyledP2Text>
        </Col>
      </Row>
      <Spacer axis="vertical" size={100} />
    </Col>
  </Row>
);

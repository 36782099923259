import { getDocs } from 'firebase/firestore';
import { atom } from 'jotai';

import {
  getCsvFileCollectionRef,
  getCsvFileErrorCollectionRef,
} from '../firebase/csvUpload';
import {
  CsvUpload,
  CsvUploadError,
  CsvUploadType,
} from '../firebase/models/CsvUpload';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

type CsvUploadPerProject = Record<string, Record<string, CsvUpload[]>>;
type CsvUploadErrorPerProject = Record<
  string,
  Record<string, CsvUploadError[]>
>;

type FetchCSVUploadPayload = {
  projectId: string;
  type: CsvUploadType;
};

type FetchCSVUploadErrorPayload = {
  projectId: string;
  fileId: string;
};

const csvUploadAsyncValueAtom = atom<AsyncValue<CsvUploadPerProject>>({
  loading: false,
});

type AddCSVFilePayload = {
  csvUpload: CsvUpload;
  projectId: string;
  type: CsvUploadType;
};

export const addCSVFileAtom = atom(
  null,
  (_, set, { csvUpload, projectId, type }: AddCSVFilePayload) => {
    set(csvUploadAsyncValueAtom, (prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [projectId]: {
          ...(prev.data?.[projectId] ?? {}),
          [type]: [csvUpload, ...(prev.data?.[projectId]?.[type] ?? [])],
        },
      },
    }));
  },
);

export const csvUploadAtom = asyncAtom<
  CsvUploadPerProject,
  FetchCSVUploadPayload
>(csvUploadAsyncValueAtom, async ({ projectId, type }, prev) => {
  const prevData = prev.data ?? {};
  try {
    const snapshot = await getCsvFileCollectionRef(projectId)
      .orderBy('uploadedAt', 'desc')
      .get();
    const data = snapshot.docs
      .map((s) => s.data())
      .filter((d) => d.templateType === type);

    return {
      ...prevData,
      [projectId]: { ...(prevData[projectId] ?? {}), [type]: data },
    };
  } catch (e) {
    console.error(e);
    return prevData;
  }
});

const csvUploadErrorAsyncValueAtom = atom<AsyncValue<CsvUploadErrorPerProject>>(
  {
    loading: false,
  },
);

export const csvUploadErrorAtom = asyncAtom<
  CsvUploadErrorPerProject,
  FetchCSVUploadErrorPayload
>(csvUploadErrorAsyncValueAtom, async ({ projectId, fileId }, prev) => {
  const prevData = prev.data ?? {};
  try {
    const savedData = prevData?.[projectId]?.[fileId];
    if (savedData && savedData.length > 0) {
      return { ...prevData };
    }

    const data = (
      await getDocs(getCsvFileErrorCollectionRef(projectId, fileId))
    ).docs.map((d) => d.data());

    return {
      ...prevData,
      [projectId]: {
        ...(prevData[projectId] ?? {}),
        [fileId]: data,
      },
    };
  } catch (e) {
    console.error(e);
    return prevData;
  }
});

import { UserAddOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Space } from 'antd';
import { useAtom } from 'jotai';
import { FC, memo, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { createAdminUserAtom } from '../../atoms/adminUsers';
import { AdminRole } from '../../firebase/adminRoles/model';
import { AdminUser } from '../../firebase/adminUsers/model';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { addAdminUserSchema } from '../../utils/validator';
import { AddUserForm } from './AddUserForm';
import { UserItem } from './UserItem';

type Props = {
  users: AdminUser[];
  role: AdminRole;
  disabled: boolean;
};

type FormValues = {
  email: string;
};

export const UsersColumn: FC<Props> = memo(({ users, role, disabled }) => {
  const renderUser = (user: AdminUser, index: number) => (
    <UserItem key={index} user={user} role={role} disabled={disabled} />
  );

  const [, createAdminUser] = useAtom(createAdminUserAtom);
  const { isOpen, openModal, closeModal } = useModal();
  const form = useForm<FormValues>({
    resolver: yupResolver(addAdminUserSchema),
    mode: 'all',
    defaultValues: { email: '' },
  });

  const sortedUsers = useMemo(() => {
    const concatFullName = (user: AdminUser) =>
      `${user.firstName} ${user.lastName}`.toLowerCase();

    return [...users].sort((user1, user2) =>
      concatFullName(user1).localeCompare(concatFullName(user2)),
    );
  }, [users]);

  const handleCloseModal = () => {
    closeModal();
    form.reset();
  };

  return (
    <Space direction="vertical">
      {sortedUsers.map(renderUser)}
      <Button
        disabled={disabled}
        icon={<UserAddOutlined />}
        size="small"
        onClick={openModal}
      />
      <Modal
        title={`Add user to ${role.name}`}
        open={isOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <FormProvider {...form}>
          <AddUserForm
            disabled={disabled}
            onSubmit={form.handleSubmit(async (data) => {
              await createAdminUser({
                role: {
                  roles: [role.id],
                },
                email: data.email,
              });
              handleCloseModal();
            })}
          />
        </FormProvider>
      </Modal>
    </Space>
  );
});

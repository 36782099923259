import { yupResolver } from '@hookform/resolvers/yup';
import { App, Col, Row, Typography } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { platformRatesAtom } from '../../../atoms/platformRates';
import { createPlatformRate } from '../../../firebase/platformRates';
import { Button } from '../../../shared/ui/Button';
import { BaseColors } from '../../../shared/ui/colors';
import { FormInput } from '../../../shared/ui/form/FormInput';
import { FormSelect } from '../../../shared/ui/form/FormSelect';
import { PLATFORM_RATE_DROPDOWN, PlatformRateField } from '../../consts';
import { checkExistedInArray } from '../../helpers';
import { platformRateValidator } from '../../schema';
import { FormLabel } from './FormLabel';
import { StyledModal } from './styledComponents';

const { Text } = Typography;

type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddPlatformRateModal: React.FC<Props> = ({ open, onClose }) => {
  const [platformRates, fetchPlatformRates] = useAtom(platformRatesAtom);
  const { notification } = App.useApp();

  const { data } = platformRates;
  const existingNames = data?.map((platformRate) => platformRate.name) || [];

  const form = useForm<PlatformRateField>({
    mode: 'onChange',
    resolver: yupResolver(platformRateValidator),
  });

  const handleSubmit = async (values: PlatformRateField) => {
    if (checkExistedInArray(values.name, existingNames)) {
      notification.error({
        message:
          'Duplicated platform rate name, please enter a different name.',
      });
      return;
    }

    try {
      await createPlatformRate(values);
      notification.success({ message: 'Created Platform Rate Successfully!' });
      onClose();
      form.reset();
      await fetchPlatformRates();
    } catch (err) {
      notification.error({ message: 'Failed to create platform rate.' });
    }
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <StyledModal
      title="Platform Rate"
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Row gutter={[0, 32]}>
        <Text type="secondary" style={{ color: BaseColors.neutral60 }}>
          Please fill all fields below to create a platform rate.
        </Text>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Row justify="end">
              <Col span={24}>
                <FormInput
                  label={<FormLabel title="Name" />}
                  name="name"
                  type="string"
                  placeholder="Rate Name"
                  data-testid="platformRate:AddPlatformRateModal:name"
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label={<FormLabel title="Description" />}
                  name="description"
                  type="textarea"
                  placeholder="Enter here"
                  height={100}
                  data-testid="platformRate:AddPlatformRateModal:description"
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label={<FormLabel title="Rate (in USD)" />}
                  name="rateInUSD"
                  type="number"
                  placeholder="Enter here"
                  data-testid="platformRate:AddPlatformRateModal:rateInUSD"
                />
              </Col>
              <Col span={24}>
                <FormSelect
                  label={<FormLabel title="Rate Type" />}
                  name="rateType"
                  options={PLATFORM_RATE_DROPDOWN}
                  placeholder="Please select"
                  data-testid="platformRate:AddPlatformRateModal:rateType"
                />
              </Col>
              <Col span={24}>
                <Text type="danger">*Not editable after creation</Text>
              </Col>

              <Button
                type="primary"
                htmlType="submit"
                loading={form.formState.isSubmitting}
                disabled={!form.formState.isValid}
                data-testid="platformRate:AddPlatformRateModal:save"
              >
                Save
              </Button>
            </Row>
          </form>
        </FormProvider>
      </Row>
    </StyledModal>
  );
};

import { Col, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { agentByIdAtom, fetchAgentsLoadingAtom } from '../../atoms/agent';
import { getAgentByEmail } from '../../firebase/agent';
import { isEmailValid } from '../../utils/emailValidator';

export const AgentSearch: React.FC = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useAtom(agentByIdAtom);
  const [isFetching] = useAtom(fetchAgentsLoadingAtom);
  const [errorMessage, setErrorMessage] = useState('');

  const onSearch = async (searchValue: string) => {
    setErrorMessage('Searching...');
    const standardizeEmail = searchValue
      ? searchValue.trim().toLowerCase()
      : searchValue;
    if (!isEmailValid(standardizeEmail)) {
      setErrorMessage('Email is invalid');
      return;
    }

    try {
      let foundAgent = Object.values(agents).find(
        (a) => a.email === standardizeEmail,
      );
      if (!foundAgent) {
        foundAgent = await getAgentByEmail(standardizeEmail);
        if (foundAgent) {
          setAgents({ ...agents, [foundAgent.id]: foundAgent });
        }
      }

      setErrorMessage('');
      navigate(`/agents/${foundAgent?.id}`);
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage('There is an unexpected error');
      }
    }
  };

  return (
    <Row>
      <Col>
        Search for an Agent
        <Search
          placeholder="email"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearch}
          disabled={isFetching}
        />
        {errorMessage}
      </Col>
    </Row>
  );
};

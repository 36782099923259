import { useAuth0 } from '@auth0/auth0-react';
import { App as AntdApp, Layout } from 'antd';
import firebase from 'firebase/compat/app';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Agents } from './Agents';
import { AgentDetails } from './Agents/AgentDetails';
import { adminRolesAtom, currentAdminRoleAtom } from './atoms/adminRoles';
import { LoginForm } from './auth';
import { AdminTab } from './firebase/adminRoles/model';
import { getUserRoles } from './firebase/adminUsers';
import { firebaseConfig } from './firebase/firebaseConfig';
import { PlatformRatesPage } from './PlatformRates';
import { EditProject } from './Project';
import { UnsavedPrefScheduleConfigModal } from './Project/PreferredAgents/Settings/components/UnsavedPrefScheduleConfigModal';
import { ReferralPage } from './referrals';
import { RolesPage } from './Roles';
import { AdminPermissionRoute } from './shared/ui/AdminPermissionRoute';
import { NavigationMenu } from './shared/ui/NavigationMenu';
import { VersionCheck } from './shared/ui/VersionCheck';
import { TransferData } from './TransferData';
import { Wallet } from './Wallet';

const { Content } = Layout;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const getRoles = async (user: firebase.User | null) => {
  try {
    return await getUserRoles(user?.uid);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  return [];
};

// should use global state later

export const App = () => {
  const { logout } = useAuth0();
  const { notification } = AntdApp.useApp();
  const [, getAdminRoles] = useAtom(adminRolesAtom);
  const [, setCurrentAdminRole] = useAtom(currentAdminRoleAtom);

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const roles = await getRoles(user);
      setCurrentAdminRole(roles);
      if (user && roles.length === 0) {
        firebase.auth().signOut();
        logout({ returnTo: window.location.origin, federated: true });
        notification.error({
          message: 'you do not have permission to login',
        });
      }
      setIsSignedIn(roles.length > 0);
    });

    return () => unsubscribe();
  }, [setCurrentAdminRole, logout, notification]);

  useEffect(() => {
    if (isSignedIn) {
      getAdminRoles();
    }
  }, [isSignedIn, getAdminRoles]);

  if (!isSignedIn) {
    return <LoginForm />;
  }

  return (
    <Router>
      <VersionCheck />
      <Layout style={{ height: '100vh', minHeight: '600px' }}>
        <NavigationMenu />
        <Layout style={{ padding: '24px 24px 24px' }}>
          <Content
            style={{
              backgroundColor: 'white',
              padding: '24px',
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Agents}
                    component={Agents}
                  />
                }
              />
              <Route
                path="/agents"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Agents}
                    component={Agents}
                  />
                }
              />
              <Route
                path="/agents/:agentId"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Agents}
                    component={AgentDetails}
                  />
                }
              />
              <Route
                path="/project"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Projects}
                    component={EditProject}
                  />
                }
              />
              <Route
                path="/wallet"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Wallet}
                    component={Wallet}
                  />
                }
              />
              <Route
                path="/referrals"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Referrals}
                    component={ReferralPage}
                  />
                }
              />
              <Route
                path="/transferData"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.TransferData}
                    component={TransferData}
                  />
                }
              />
              <Route
                path="/platform-rates"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.PlatformRates}
                    component={PlatformRatesPage}
                  />
                }
              />

              <Route
                path="/admin-users"
                element={
                  <AdminPermissionRoute
                    tab={AdminTab.Roles}
                    component={RolesPage}
                  />
                }
              />
            </Routes>
          </Content>
          <UnsavedPrefScheduleConfigModal />
        </Layout>
      </Layout>
    </Router>
  );
};

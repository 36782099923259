import { InputProps, Typography } from 'antd';
import React, { ReactNode } from 'react';

import { useField } from '../../../../hooks/useField';
import {
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledTextArea,
} from './styledComponents';

const { Text } = Typography;

type Props = {
  name: string;
  label?: ReactNode;
  type?: 'string' | 'number' | 'textarea';
  disabled?: boolean;
  placeholder?: string;
  height?: number;
  step?: number;
  onPaste?: InputProps['onPaste'];
  suffix?: InputProps['suffix'];
};

export const FormInput: React.FC<Props> = ({
  name,
  label,
  type = 'string',
  height,
  disabled,
  placeholder,
  step,
  onPaste,
  ...rest
}) => {
  const { controller, error } = useField({ name });

  return (
    <StyledFormItem
      labelAlign="left"
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      {label && <Text>{label}</Text>}
      {type === 'string' && (
        <StyledInput
          {...rest}
          name={controller.field.name}
          value={controller.field.value}
          ref={controller.field.ref}
          onChange={controller.field.onChange}
          onBlur={controller.field.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          height={height}
          onPaste={onPaste}
        />
      )}
      {type === 'number' && (
        <StyledInputNumber
          {...rest}
          name={controller.field.name}
          value={controller.field.value}
          ref={controller.field.ref}
          onChange={controller.field.onChange}
          onBlur={controller.field.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          height={height}
          step={step}
        />
      )}
      {type === 'textarea' && (
        <StyledTextArea
          {...rest}
          name={controller.field.name}
          disabled={controller.field.disabled}
          value={controller.field.value}
          ref={controller.field.ref}
          onChange={controller.field.onChange}
          onBlur={controller.field.onBlur}
          placeholder={placeholder}
          height={height}
          autoSize
        />
      )}
    </StyledFormItem>
  );
};

import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { addCSVFileAtom, csvUploadAtom } from '../atoms/csvUpload';
import { functions } from '../firebase';
import { CSV_UPLOAD_TYPE, CsvUploadType } from '../firebase/models/CsvUpload';
import { IconType, ShowNotification } from '../shared/ui/ShowNotification';
import { CSV_UPLOAD_FUNCTION } from '../shared/utils/consts';
import {
  createCsvFile,
  getCsvFile,
  updateCsvFile,
} from '../shared/utils/helpers';
import { uploadFile } from '../utils/file';
import {
  ACHIEVEMENT_BUCKET,
  COACHING_BUCKET,
  FEEDBACK_BUCKET,
} from './Coaching/consts';
import { ProcessingStatus } from './consts';
import { PRODUCTIVE_PAY_BUCKET } from './ProductivePay/consts';

const getBucket = (uploadType: CsvUploadType) => {
  switch (uploadType) {
    case CSV_UPLOAD_TYPE.PRODUCTIVE_PAY:
      return `${PRODUCTIVE_PAY_BUCKET}/${format(new Date(), 'yyyy-MM-dd')}`;
    case CSV_UPLOAD_TYPE.ACHIEVEMENT:
      return `${COACHING_BUCKET}/${ACHIEVEMENT_BUCKET}/${format(
        new Date(),
        'yyyy-MM-dd',
      )}`;
    case CSV_UPLOAD_TYPE.FEEDBACK:
      return `${COACHING_BUCKET}/${FEEDBACK_BUCKET}/${format(
        new Date(),
        'yyyy-MM-dd',
      )}`;
    default:
      return undefined;
  }
};

export const useCSVUpload = (uploadType: CsvUploadType, projectId: string) => {
  const [{ data, loading }, fetchCSVUpload] = useAtom(csvUploadAtom);
  const [, addCSVUploadToAtom] = useAtom(addCSVFileAtom);
  useEffect(() => {
    fetchCSVUpload({ projectId, type: uploadType });
  }, [projectId, uploadType, fetchCSVUpload]);
  const handleImportCSVFile = async (
    file: File | Blob | undefined,
    filterConditions?: Record<string, any>,
  ) => {
    try {
      if (file === undefined) {
        ShowNotification('File is empty', IconType.Error);
        return;
      }

      const bucket = getBucket(uploadType);
      if (!bucket) {
        ShowNotification('Cannot determine bucket', IconType.Error);
        return;
      }

      const csvFile = await createCsvFile(
        projectId,
        uploadType,
        filterConditions,
      );
      const filePath = await uploadFile(bucket, file, `${uuid()}.csv`);
      if (!filePath) {
        ShowNotification('Upload file was failed', IconType.Error);
        return;
      }
      await updateCsvFile(
        projectId,
        csvFile.id,
        filePath,
        ProcessingStatus.UPLOADED,
      );
      const csvUploadFunc = functions.httpsCallable(CSV_UPLOAD_FUNCTION);
      await csvUploadFunc({ projectId, documentId: csvFile.id });
      ShowNotification('File is upload successfully!', IconType.Success);
      const currentCSVUploadFile = await getCsvFile(projectId, csvFile.id);
      if (!currentCSVUploadFile) {
        ShowNotification('CSV File Upload is not found', IconType.Error);
        return;
      }
      addCSVUploadToAtom({
        type: uploadType,
        csvUpload: currentCSVUploadFile,
        projectId,
      });
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    }
  };

  const refreshData = () => fetchCSVUpload({ projectId, type: uploadType });

  return {
    handleImportCSVFile,
    refreshData,
    data: data?.[projectId]?.[uploadType] ?? [],
    loading,
  };
};

import React from 'react';

import {
  CSV_UPLOAD_TYPE,
  CsvUploadError,
  CsvUploadType,
} from '../../../../firebase/models/CsvUpload';
import { CsvUploadErrorModal } from '../../../CsvUploadErrorModal';
import { COLUMNS, ERROR_TYPE_MAPPING, HEADERS, TITLES } from './consts';

type Props = {
  projectId: string;
  fileId: string;
  onClose: () => void;
  type: CsvUploadType;
};

const getColumns = (type: CsvUploadType) => {
  if (type !== CSV_UPLOAD_TYPE.FEEDBACK) {
    return COLUMNS;
  }

  return [
    {
      title: 'Contact Id',
      dataIndex: 'row',
      render: (row: Record<string, string>) => row?.contactId,
    },
    {
      title: 'Agent Id',
      dataIndex: 'row',
      render: (row: Record<string, string>) => row?.agentId,
    },
    ...COLUMNS,
  ];
};

const getHeader = (type: CsvUploadType) => {
  if (type !== CSV_UPLOAD_TYPE.FEEDBACK) {
    return HEADERS;
  }

  return ['Contact Id', 'Agent Id', ...HEADERS];
};

const getContent = (record: CsvUploadError, type: CsvUploadType) => {
  const commonContent = [ERROR_TYPE_MAPPING[record.type], record.error];
  if (type !== CSV_UPLOAD_TYPE.FEEDBACK) {
    return commonContent;
  }

  return [record.row?.contactId, record.row?.agentId, ...commonContent];
};

export const CoachingCsvUploadErrorModal: React.FC<Props> = ({
  projectId,
  fileId,
  onClose,
  type,
}) => {
  const transformToDownloadRow = (record: CsvUploadError) =>
    getContent(record, type);

  return (
    <CsvUploadErrorModal
      projectId={projectId}
      fileId={fileId}
      onClose={onClose}
      headers={getHeader(type)}
      columns={getColumns(type)}
      title={TITLES[type]}
      getDownloadRow={transformToDownloadRow}
    />
  );
};

import {
  addDays,
  endOfWeek,
  format,
  isValid,
  parse,
  startOfWeek,
  weeksToDays,
} from 'date-fns';

import { functions } from '../../firebase';
import { PreferentialSchedules } from '../../firebase/models/PreferentialSchedules';
import {
  SUPPORTED_TIERS,
  TierEnum,
} from '../../firebase/models/ScheduleConfig';
import { parseCSV } from '../../utils/csv';
import { DAY_FORMAT, MONTH_FORMAT, YEAR_FORMAT } from '../../utils/date';

export type PreferentialAgentTableType = {
  weekStart: string;
  weekEnd: string;
  tiers: Record<TierEnum, number>;
  id: string;
};

const convertToDateFromNumberOfYearWeek = (yearWeek: string) => {
  const yearDigits = 4;
  const numWeeks = yearWeek.slice(yearDigits);
  const numDays = weeksToDays(Number(numWeeks) - 1);
  const year = yearWeek.slice(0, yearDigits);
  return addDays(parse(`${year}-01-01`, 'yyyy-MM-dd', new Date()), numDays);
};

const convertLegacySchedulesToPreferentialAgent = (
  schedule: PreferentialSchedules,
) => {
  const startDate = schedule.preferential?.startDate;
  if (!startDate) {
    return undefined;
  }
  const agentIds = schedule?.preferential?.agents ?? [];

  return {
    id: schedule.id,
    tiers: {
      'tier-1': agentIds.length,
      'tier-2': 0,
      'tier-3': 0,
    },
  };
};

const getNumberOfAgentInTier = (
  schedule: PreferentialSchedules,
  tier: TierEnum,
) => new Set(schedule.preferential?.tiers?.[tier]?.agents ?? []).size;

const convertNewSchedulesToPreferentialAgent = (
  schedule: PreferentialSchedules,
): PreferentialAgentTableType => {
  const tiers: Record<TierEnum, number> = {
    'tier-1': getNumberOfAgentInTier(schedule, 'tier-1'),
    'tier-2': getNumberOfAgentInTier(schedule, 'tier-2'),
    'tier-3': getNumberOfAgentInTier(schedule, 'tier-3'),
  };
  return {
    tiers,
    id: schedule.id,
  } as PreferentialAgentTableType;
};

const isLegacySchedule = (schedule: PreferentialSchedules) =>
  Object.keys(schedule.preferential?.tiers ?? {}).length === 0;

export const convertSchedulesTableData = (
  schedule: PreferentialSchedules,
): PreferentialAgentTableType | undefined => {
  const selectDateFromNumberOfWeek = convertToDateFromNumberOfYearWeek(
    schedule.id,
  );
  const startWeekDay = startOfWeek(selectDateFromNumberOfWeek);
  const endWeekDay = endOfWeek(selectDateFromNumberOfWeek);

  if (!isValid(startWeekDay) || !isValid(endWeekDay)) {
    return undefined;
  }

  const item: Partial<PreferentialAgentTableType> | undefined =
    isLegacySchedule(schedule)
      ? convertLegacySchedulesToPreferentialAgent(schedule)
      : convertNewSchedulesToPreferentialAgent(schedule);

  if (!item) {
    return undefined;
  }

  item.weekEnd = format(endWeekDay, 'P');
  item.weekStart = format(startWeekDay, 'P');

  return item as PreferentialAgentTableType;
};

export const parseCSVFile = async (file?: File | Blob) => {
  if (!file) {
    throw new Error('Something went wrong');
  }
  if (!(file instanceof File)) {
    throw new Error('Not CSV File Type');
  }
  const csvData = await parseCSV<PreferentialAgentsCSVRaw>(file);

  const row = csvData?.[0];
  if (csvData.length === 0) {
    throw new Error('The uploaded CSV is empty');
  } else if (!row?.date || !row.agent_id) {
    throw new Error('The uploaded CSV is invalid');
  }

  return csvData;
};

export const fullfillAllTiersPerWeek = (
  csvData: PreferentialAgentsCSVRaw[],
) => {
  const now = new Date();
  const tiersByDate: Record<string, Set<number>> = {};
  const DATE_FORMAT = `${YEAR_FORMAT}-${MONTH_FORMAT}-${DAY_FORMAT}`;

  csvData.forEach((r) => {
    const startOfWeekDate = startOfWeek(parse(r.date, DATE_FORMAT, now));
    const formattedDate = format(startOfWeekDate, DATE_FORMAT);

    if (!tiersByDate[formattedDate]) {
      tiersByDate[formattedDate] = new Set();
    }

    tiersByDate[formattedDate].add(r.tier);
  });

  Object.values(tiersByDate).forEach((tiers) => {
    if (!SUPPORTED_TIERS.every((t) => tiers.has(t))) {
      throw new Error(
        'File should contain at least one user per tier for each week',
      );
    }
  });

  return csvData;
};

export type PreferentialAgentsCSVRaw = {
  date: string;
  agent_id: string;
  agent_name: string;
  tier: number;
};

export const uploadCSVData = async (
  projectId: string,
  data: PreferentialAgentsCSVRaw[],
) => {
  const result = await functions.httpsCallable('importPreferredAgents')({
    data,
    projectId,
  });
  return result;
};

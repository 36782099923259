import { collection, doc } from 'firebase/firestore';

import {
  CoachingCollections,
  CoachingStatsCollections,
  RootCollections,
} from '../consts';
import { db } from '../firebaseEntity';
import { Achievement } from '../models/Achievement';
import {
  Behavior,
  FeedbackGroup,
  Resource,
  SubmissionGroup,
} from '../models/Coaching';
import { generalConverter } from '../utils';

export const getAgentsAchievementColRef = (projectId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Achievements)
    .withConverter(generalConverter<Achievement>());

export const getProjectBehaviorsColRef = (projectId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Behaviors)
    .withConverter(generalConverter<Behavior>());

export const getCoachingBehaviorByNameRef = (
  projectId: string,
  name: string,
  limit = 1,
) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Behaviors)
    .where('name', '==', name)
    .withConverter(generalConverter<Behavior>())
    .limit(limit);

export const getCoachingResourcesColRef = (projectId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Resources)
    .withConverter(generalConverter<Resource>());

export const getCoachingResourcesColRefByTitle = (
  projectId: string,
  title: string,
) => getCoachingResourcesColRef(projectId).where('title', '==', title);

export const getCoachingResourceRef = (projectId: string, resourceId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Resources)
    .doc(resourceId)
    .withConverter(generalConverter<Resource>());

export const getCoachingStatsQuizColRef = (projectId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Stats)
    .doc('quiz')
    .collection(CoachingStatsCollections.TotalSubmissions)
    .withConverter(generalConverter<SubmissionGroup>());

const getCoachingRef = (projectId: string) =>
  doc(collection(db, RootCollections.Coaching), projectId);

export const getCoachingStatsFeedbackColRef = (projectId: string) =>
  collection(
    doc(
      collection(getCoachingRef(projectId), CoachingCollections.Stats),
      'feedback',
    ),
    CoachingStatsCollections.TotalAgentFeedback,
  ).withConverter(generalConverter<FeedbackGroup>({ convertTimestamp: true }));

export const getCoachingBehaviorRef = (projectId: string, behaviorId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Behaviors)
    .doc(behaviorId);

export const getCoachingLiveBehaviorColRef = (projectId: string) =>
  db
    .collection(RootCollections.Coaching)
    .doc(projectId)
    .collection(CoachingCollections.Behaviors)
    .withConverter(generalConverter<Behavior>())
    .where('status', '==', 'live');

import {
  BACKGROUND_KEYS,
  BACKGROUND_STATUS,
  BackgroundCheck,
} from '@agentsonly/models';
import { Col, Row } from 'antd';
import { useCallback } from 'react';

import { SectionContainer } from '../../../SectionContainer';
import { BackgroundCheckStatusLabels } from '../../consts';
import { CheckItem } from '../CheckItem';
import { VoicePlayer } from './VoicePlayer';

type Props = {
  agentId?: string;
  backgroundCheck: BackgroundCheck;
  onBackgroundCheckChange: (backgroundCheck: BackgroundCheck) => void;
  disabled: boolean;
};

export const VoiceRecording = ({
  agentId,
  backgroundCheck,
  onBackgroundCheckChange,
  disabled,
}: Props) => {
  const voiceRecordingKey = BACKGROUND_KEYS.VOICE_RECORDING;
  const value =
    backgroundCheck[voiceRecordingKey] ?? BACKGROUND_STATUS.PENDING_REVIEW;

  const onChange = useCallback(
    (key: string, newValue: string) => {
      onBackgroundCheckChange({ ...backgroundCheck, [key]: newValue });
    },
    [backgroundCheck, onBackgroundCheckChange],
  );

  return (
    <SectionContainer title="Voice Recording">
      <Col span={24}>
        <Row justify="space-between">
          <Col span={12}>
            <VoicePlayer agentId={agentId} />
          </Col>
          <Col span={8}>
            <CheckItem
              key={voiceRecordingKey}
              id={voiceRecordingKey}
              value={BackgroundCheckStatusLabels[value]}
              onChange={onChange}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>
    </SectionContainer>
  );
};

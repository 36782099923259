import { isProd, isStage } from '../../utils/consts';

export const REACT_APP_AUTH0_DOMAIN = (() => {
  if (isProd()) return process.env.REACT_APP_AUTH0_DOMAIN_PROD || '';
  if (isStage()) return process.env.REACT_APP_AUTH0_DOMAIN_STAGE || '';
  return process.env.REACT_APP_AUTH0_DOMAIN_DEV || '';
})();

export const REACT_APP_AUTH0_CLIENT_ID = (() => {
  if (isProd()) return process.env.REACT_APP_AUTH0_CLIENT_ID_PROD || '';
  if (isStage()) return process.env.REACT_APP_AUTH0_CLIENT_ID_STAGE || '';
  return process.env.REACT_APP_AUTH0_CLIENT_ID_DEV || '';
})();

export const REACT_APP_AUTH0_API_AUDIENCE = (() => {
  if (isProd()) return process.env.REACT_APP_AUTH0_API_AUDIENCE_PROD || '';
  if (isStage()) return process.env.REACT_APP_AUTH0_API_AUDIENCE_STAGE || '';
  return process.env.REACT_APP_AUTH0_API_AUDIENCE_DEV || '';
})();

export const CSV_UPLOAD_FUNCTION = 'csvUpload';

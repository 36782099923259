import { Project } from '@agentsonly/models';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

import { functions } from '../../firebase';
import {
  createClientManualData,
  getClientManualDataRef,
} from '../../firebase/clientManualData';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { uploadFile } from '../../utils/file';
import { toVancouverTime } from '../../utils/timezone';
import { MAPPING_PROCESSING_STATUS, ProcessingStatus } from '../consts';
import {
  CLIENT_MANUAL_DATA_BUCKET_NAME,
  DATE_FORMAT,
  MANUAL_DATA_UPLOAD_FUNC,
} from './consts';
import { getClientManualData, updateClientManualData } from './helpers';
import { RowItem } from './types';

type HookType = [
  RowItem[],
  (from: Date, to: Date) => Promise<void>,
  boolean,
  (file: Blob | File | undefined, fileName: string) => Promise<void>,
];

export const useClientManualData = (project: Project | undefined): HookType => {
  const [data, setData] = useState<RowItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [docId, setDocId] = useState<string>();

  useEffect(() => {
    if (!docId || !project?.id) {
      return undefined;
    }

    const ref = getClientManualDataRef(project?.id, docId);
    const unsubscribe = ref.onSnapshot((snapshot) => {
      const updatedClientManualData = snapshot.data();
      if (updatedClientManualData) {
        const {
          totalContactsHandled,
          totalMissingDataRows,
          totalUniqueAgent,
          processingStatus,
          id,
        } = updatedClientManualData;
        const foundIndex = data.findIndex((c) => c.id === id);
        if (foundIndex > -1) {
          data[foundIndex].totalMissingDataRows = totalMissingDataRows;
          data[foundIndex].totalContactsHandled = totalContactsHandled;
          data[foundIndex].totalUniqueAgent = totalUniqueAgent;
          data[foundIndex].status = processingStatus
            ? MAPPING_PROCESSING_STATUS[processingStatus]
            : ProcessingStatus.UPLOADED;
          setData([...data]);
        }
      }
    });

    return () => unsubscribe();

    // eslint-disable-next-line
  }, [project?.id, docId]);

  const loadData = async (from: Date, to: Date) => {
    if (!project) {
      return;
    }
    setLoading(true);
    try {
      const result = await getClientManualData(project.id, from, to);
      setData(
        result.map(
          (r) =>
            ({
              id: r.id,
              totalContactsHandled: r.totalContactsHandled ?? '--',
              totalUniqueAgent: r.totalUniqueAgent ?? '--',
              totalMissingDataRows: r.totalMissingDataRows ?? '--',
              uploadDate: format(
                toVancouverTime(r.uploadedAt.toDate()),
                DATE_FORMAT,
              ),
              status: MAPPING_PROCESSING_STATUS[r.processingStatus],
            }) as RowItem,
        ),
      );
    } catch (e) {
      ShowNotification((e as Error).message, IconType.Error);
    }
    setLoading(false);
  };

  const uploadClientFile = async (
    file: Blob | File | undefined,
    fileName: string,
  ): Promise<void> => {
    if (file === undefined) {
      ShowNotification('File is empty', IconType.Error);
      return;
    }

    if (!project) {
      ShowNotification('Cannot find instance or id of project', IconType.Error);
      return;
    }

    const projectId = project.id;

    const clientManualDataRef = await createClientManualData(
      projectId,
      ProcessingStatus.UPLOADING,
    );
    const result = await uploadFile(
      `${CLIENT_MANUAL_DATA_BUCKET_NAME}/${projectId}/${format(
        new Date(),
        'yyyy-MM-dd',
      )}`,
      file,
      fileName,
    );

    if (!result) {
      await updateClientManualData(projectId, clientManualDataRef.id, {
        processingStatus: ProcessingStatus.FAILED,
      });
      ShowNotification('Failed to upload file', IconType.Error);

      return;
    }

    const now = firebase.firestore.Timestamp.now();

    await updateClientManualData(projectId, clientManualDataRef.id, {
      processingStatus: ProcessingStatus.UPLOADED,
      uploadedAt: now,
      filePath: result,
    });

    const manualUploadDataFnc = functions.httpsCallable(
      MANUAL_DATA_UPLOAD_FUNC,
    );

    try {
      await manualUploadDataFnc({
        projectId,
        documentId: clientManualDataRef.id,
      });
    } catch (e) {
      if (e instanceof Error) {
        console.log('Error:', e.message);
      }

      ShowNotification('Temporary server error, please retry.', IconType.Error);

      await updateClientManualData(projectId, clientManualDataRef.id, {
        processingStatus: ProcessingStatus.FAILED,
      });
    }

    const tmpFile: RowItem = {
      id: clientManualDataRef.id,
      uploadDate: format(toVancouverTime(now.toDate()), DATE_FORMAT),
      status: MAPPING_PROCESSING_STATUS[ProcessingStatus.UPLOADED],
    };

    setData((prev) => [...prev, tmpFile]);

    ShowNotification('File Uploaded.', IconType.Success);
    setDocId(clientManualDataRef.id);
  };

  return [data, loadData, loading, uploadClientFile];
};

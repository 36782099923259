import { Typography } from 'antd';
import React from 'react';

import { Spacer } from '../Spacer/styledComponents';
import { StyledBadge } from './styledComponents';

const { Text } = Typography;

type Props = {
  backgroundColor: string;
  color: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  spacer?: number;
};

export const Badge: React.FC<Props> = ({
  color,
  content,
  backgroundColor,
  spacer,
  icon,
}) => (
  <StyledBadge $backgroundColor={backgroundColor}>
    <Text strong style={{ color }}>
      {content}
    </Text>
    <Spacer axis="horizontal" size={spacer ?? 0} />
    {icon}
  </StyledBadge>
);

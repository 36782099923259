import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { FC } from 'react';

import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { StyledInputNumber } from './styledComponents';

type Props = {
  fieldName: string;
};

export const Temperature: FC<Props> = ({ fieldName }) => (
  <Col span={5}>
    <Row>
      Temperature
      <Spacer axis="horizontal" size={4} />
      <Tooltip title="What sampling temperature to use, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.">
        <InfoCircleFilled style={{ color: 'black' }} />
      </Tooltip>
    </Row>
    <Spacer size={4} axis="vertical" />
    <StyledInputNumber name={fieldName} type="number" step={0.1} />
  </Col>
);

import './index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { App as AntdApp, ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import {
  REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
} from './shared/utils/consts';
import { theme } from './theme';
import { DEBUG_DATA } from './utils/debug';

// Ref: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById('root');
const root = createRoot(container!);

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-underscore-dangle
  window.__DEBUG_DATA__ = DEBUG_DATA;
}

root.render(
  <Auth0Provider
    cacheLocation="localstorage"
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}`}
    useRefreshTokens
    scope="offline_access"
    audience={REACT_APP_AUTH0_API_AUDIENCE}
  >
    <ConfigProvider theme={theme}>
      <AntdApp>
        <App />
      </AntdApp>
    </ConfigProvider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { QuizSkill, TypingSkill } from '@agentsonly/models';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';

import { fetchSkillsAtom } from '../../../atoms/skill';
import {
  getQuizSkills,
  getTypingTests,
  SkillType,
} from '../../../firebase/skills';
import { ComprehensiveTestSection } from '../ComprehensiveTestSection';
import { TypingTestSection } from '../TypingTestSection';

type Props = {
  agentId: string;
};

export const ComprehensiveAndTypingTest: FC<Props> = ({ agentId }) => {
  const [skills, fetchSkills] = useAtom(fetchSkillsAtom);
  const [typingTest, setTypingTest] = useState<TypingSkill>();
  const [comprehensiveTest, setComprehensiveTest] = useState<QuizSkill>();

  useEffect(() => {
    if (!skills?.data || skills?.data?.length === 0) {
      fetchSkills();
    }
  }, [skills?.data, fetchSkills]);

  useEffect(() => {
    const getTypingTest = async () => {
      const typingSkills =
        skills?.data?.filter((s) => s.type === SkillType.TYPING) ?? [];
      const result = await getTypingTests(
        agentId,
        typingSkills.map((t) => t.id),
      );
      if (result && result.length > 0) {
        setTypingTest(result[0]);
      }
    };

    const getComprehensiveTest = async () => {
      const comprehensiveSkills =
        skills?.data?.filter((s) => s.type === SkillType.QUIZ) ?? [];
      const result = await getQuizSkills(
        agentId,
        comprehensiveSkills.map((t) => t.id),
      );
      if (result && result.length > 0) {
        setComprehensiveTest(result[0]);
      }
    };

    Promise.all([getTypingTest(), getComprehensiveTest()]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, skills]);

  return (
    <>
      {comprehensiveTest && (
        <ComprehensiveTestSection skill={comprehensiveTest} />
      )}
      {typingTest && <TypingTestSection typingTest={typingTest} />}
    </>
  );
};

import { Col, Row, Spin } from 'antd';
import { useAtom } from 'jotai';
import { memo } from 'react';

import { isCurrentUserAdmin } from '../atoms/adminRoles';
import { platformRatesAtom } from '../atoms/platformRates';
import { Spacer } from '../shared/ui/Spacer/styledComponents';
import { EmptyState } from './components/EmptyState';
import { Header } from './components/Header';
import { PlatformRateTile } from './components/PlatformRateTile';
import { FullContainer, PlatformRateContainer } from './styledComponents';

export const PlatformRatesPage: React.FC = memo(() => {
  const [platformRates, fetchPlatformRates] = useAtom(platformRatesAtom);
  // Only admin has access to edit / delete is this page
  const [isAdmin] = useAtom(isCurrentUserAdmin);
  const { data = [], loading } = platformRates;

  if (loading && data?.length === 0) {
    return (
      <FullContainer>
        <Spin />
      </FullContainer>
    );
  }

  return (
    <PlatformRateContainer data-testid="PlatformRates:PlatformRatesPage:Container">
      <Header disabled={!isAdmin} />

      <Spacer axis="vertical" size={48} />

      <div>
        {data?.length > 0 ? (
          <Row gutter={[33, 33]}>
            {data?.map((rate) => (
              <Col span={8} key={rate.id}>
                <PlatformRateTile
                  key={`PlatformRateTile${rate.id}`}
                  platformRate={rate}
                  deletable={!rate.projectIds}
                  onPostSuccessDelete={fetchPlatformRates}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <EmptyState />
        )}
      </div>
    </PlatformRateContainer>
  );
});

import { Typography } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../shared/ui/colors';

const { Paragraph } = Typography;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LiveBehaviorContainer = styled.div`
  width: 100%;
  background-color: rgba(220, 236, 232, 0.3);
  border: 1px dashed #a5b1b9;
  border-radius: 8px;
  margin-top: 24px;
  padding: 1rem;
`;

export const BehaviorDescriptionText = styled(Paragraph)`
  font-weight: 400;
  font-size: 16px;
  color: ${BaseColors.neutral60};
  margin-bottom: 0;
`;

export const PointerWrapper = styled.div`
  cursor: pointer;
`;

export const StyledLink = styled.a`
  color: #666666;
  text-decoration: underline;

  &:hover {
    color: #666666;
    text-decoration: none;
  }
`;

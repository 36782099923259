import { Project } from '@agentsonly/models';
import { startOfWeek } from 'date-fns';
import { DocumentReference } from 'firebase/firestore';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import { preferentialWeeksAtom } from '../../atoms/prefAgents';
import { PreferentialSchedules } from '../../firebase/models/PreferentialSchedules';
import {
  ScheduleConfig,
  TierConfig,
  TierEnum,
} from '../../firebase/models/ScheduleConfig';
import { getSchedulePreferentialConfigRef } from '../../firebase/project';
import { IconType, ShowNotification } from '../../shared/ui/ShowNotification';
import { createFileToDownload } from '../../utils/file';
import {
  convertSchedulesTableData,
  fullfillAllTiersPerWeek,
  parseCSVFile,
  PreferentialAgentTableType,
  uploadCSVData,
} from './helper';
import { TEMPLATE_DATA } from './template';

const TEMPLATE_FILE_NAME = 'preferred_agents_template.csv';
const sortData = (data: PreferentialAgentTableType[] | undefined) =>
  data ? data.sort((a, b) => b.id.localeCompare(a.id)) : data;
export const usePreferredAgents = (project: Project) => {
  useState<DocumentReference<PreferentialSchedules>>();
  const [data, setData] = useState<PreferentialAgentTableType[] | undefined>();
  const [{ data: preferentialWeeks }, fetchPreferentialWeeks] = useAtom(
    preferentialWeeksAtom,
  );
  const [scheduleConfig, setScheduleConfig] = useState<
    ScheduleConfig | undefined
  >();
  const [selectedDate, setSelectedDate] = useState(startOfWeek(new Date()));

  const fetchSchedule = useCallback(async () => {
    if (preferentialWeeks?.[project.id]) {
      const preferentialAgent = preferentialWeeks[project.id]
        .map((p) => convertSchedulesTableData(p))
        .filter((d) => !!d);
      setData(sortData(preferentialAgent as PreferentialAgentTableType[]));
    } else {
      fetchPreferentialWeeks(project.id);
    }
  }, [project, fetchPreferentialWeeks, preferentialWeeks]);

  const fetchScheduleConfig = useCallback(async () => {
    const scheduleConfigRef = getSchedulePreferentialConfigRef(project.id);
    const response = await scheduleConfigRef.get();
    setScheduleConfig(response.data());
  }, [project]);

  useEffect(() => {
    fetchSchedule();
    fetchScheduleConfig();
  }, [fetchSchedule, fetchScheduleConfig]);

  const handleDownloadTemplate = () => {
    const template = TEMPLATE_DATA.map((templateData) =>
      templateData.join(','),
    ).join('\n');
    createFileToDownload(TEMPLATE_FILE_NAME, template);
  };

  const handleDatePickerChange = (date: Date | null) => {
    setSelectedDate(date ? startOfWeek(date) : new Date());
  };

  const handleUpload = async (file?: File | Blob) => {
    try {
      const csvData = await parseCSVFile(file);
      const validCSVData = fullfillAllTiersPerWeek(csvData);
      await uploadCSVData(project.id, validCSVData);
      ShowNotification('File uploaded successfully', IconType.Success);
    } catch (err) {
      const error: any = err;
      if (error?.code) {
        ShowNotification('File upload failed', IconType.Error);
      } else {
        ShowNotification(error?.message, IconType.Error);
      }
    }
    await fetchSchedule();
  };

  const validateTierConfig = (config: Record<TierEnum, TierConfig>) => {
    if (
      config['tier-1'].durationSeconds <= config['tier-2'].durationSeconds ||
      config['tier-1'].durationSeconds <= config['tier-3'].durationSeconds ||
      config['tier-2'].durationSeconds <= config['tier-3'].durationSeconds
    ) {
      return 'Tier Interval should be descending by tier level';
    }

    return undefined;
  };

  const handleUpdateTierConfig = async (
    config: Record<TierEnum, TierConfig>,
  ) => {
    try {
      const errorMessage = validateTierConfig(config);
      if (errorMessage) {
        ShowNotification(errorMessage, IconType.Error);
        return;
      }
      const ref = getSchedulePreferentialConfigRef(project.id);
      await ref.set(
        {
          preferential: config,
        },
        { merge: true },
      );
      ShowNotification(
        'Updated Tier Configuration successfully',
        IconType.Success,
      );

      if (scheduleConfig) {
        setScheduleConfig({
          ...scheduleConfig,
          preferential: config,
        });
      }
    } catch (e) {
      ShowNotification('Failed to update Tier Configuration', IconType.Error);
    }
  };

  return {
    data,
    scheduleConfig,
    selectedDate,
    handleDownloadTemplate,
    handleUpload,
    handleDatePickerChange,
    handleUpdateTierConfig,
  };
};

export enum BaseColors {
  promotedBrand = '#EEEEF3',
  promotedHover = '#49D174',
  primaryButton = '#02BA91',
  primaryAction = '#203D50',
  primaryActionHover = '#215B81',
  primaryActionHoverWithOpacity = 'rgba(33, 91, 129, 0.1)',
  secondaryAction = '#6E818D',
  text = '#202629',
  secondaryText = '#5A6369',
  greenText = '#02BA91',
  dividers = '#DAE4EA',
  background = '#F2F6F8',
  errors = '#B64E2D',
  errorWithOpacity = 'rgba(182,78,45, 0.1)',
  white = '#FFFFFF',
  red = '#FF0000',
  alertSuccess = '#2593FC',
  alertWarning = '#B64E2D',
  alertWarningHover = '#CE5934',
  alertAdvice = '#EC9C00',
  purple = '#C97DFF',
  lightBlue = '#14AEF0',
  primary = '#2593FC',
  primaryMedium = '#08A17C',
  primaryLight = '#00D0A3',
  primaryDark = '#078768',
  secondary = '#203D50',
  secondaryMedium = '#6E818D',
  secondaryLight = '#A5B1B9',
  linkHover = '#2A5069',
  lightPrimary = '#A6C9C3',
  inputField = '#8F9DA7',
  missingDataBg = '#E7F7FF',
  neutral00 = '#FFFFFF',
  neutral10 = '#F2F6F8',
  neutral20 = '#ECF4F5',
  neutral30 = '#F5FCFB',
  neutral40 = '#B8B8B8',
  neutral60 = '#5A6369',
  neutral80 = '#666666',
  neutral100 = '#202629',
  black = '#000000',
  coachingIconBg = '#EDFBF9',
  inactive = '#C7C8C9',
}

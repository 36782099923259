import { Qualifications } from '@agentsonly/models/types/Qualifications';
import { Col, Typography } from 'antd';
import React from 'react';

import {
  EducationLevel,
  EducationLevelLabels,
  LANGUAGE,
  LANGUAGE_LABELS,
} from '../../../firebase/agent/model';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { StyledRow } from './styledComponents';

const { Paragraph } = Typography;

type Props = {
  qualifications?: Qualifications;
};

export const QualificationsSection: React.FC<Props> = ({ qualifications }) => {
  const languages = qualifications?.languages.reduce((accum, language) => {
    const lang = Object.values(language).join('');
    accum.push(LANGUAGE_LABELS[lang as LANGUAGE]);
    return accum;
  }, [] as string[]);

  return (
    <BorderedRow>
      <Col span={24}>
        <SectionContainer title="Qualifications">
          <StyledRow gutter={5}>
            <Col span={8}>
              <Paragraph>Highest Qualification</Paragraph>
              <Paragraph strong>
                {qualifications?.educationLevel
                  ? EducationLevelLabels[
                      qualifications.educationLevel as EducationLevel
                    ]
                  : '-'}
              </Paragraph>
            </Col>
            <Col span={8}>
              <Paragraph>Language</Paragraph>
              <Paragraph strong>{languages?.join('; ') ?? '-'}</Paragraph>
            </Col>
            {qualifications?.educationLevel ===
              EducationLevel.COLLEGE_GRADUATE && (
              <Col span={8}>
                <Paragraph>College Major</Paragraph>
                <Paragraph strong>{qualifications.collegeMajor}</Paragraph>
              </Col>
            )}
          </StyledRow>
        </SectionContainer>
      </Col>
    </BorderedRow>
  );
};

import { Carousel, Col, Select, Space } from 'antd';
import styled from 'styled-components';

import { Button } from '../../../shared/ui/Button';
import { BaseColors } from '../../../shared/ui/colors';

export const CarouselContainer = styled(Space)`
  width: 100%;
  padding-left: 20px;
`;

export const StyledCarousel = styled(Carousel)`
  background-color: #fff;
  padding: 27px 0 50px;

  .slick-slide {
    & > div > div {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        transition: background-color 400ms ease-in;
        background-color: rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.slick-active > div > div {
      &:after {
        background: transparent;
      }
    }
  }

  .slick-slide > div {
    padding-left: 40px;
    padding-right: 40px;
  }

  .slick-dots-bottom {
    bottom: 0;
  }

  .slick-dots > li {
    background-color: #6e818d;

    &.slick-active > button {
      background-color: #078768;
    }
  }

  .slick-prev,
  .slick-next,
  .slick-prev:hover,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-next:focus {
    font-size: 18px;
    color: #515355;
  }
`;

export const StyledSpace = styled(Space)`
  display: grid;
`;

export const StyledSelect = styled(Select)`
  flex-shrink: 1;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  && {
    width: 100%;
    height: auto;
    text-align: center;

    &:not(:disabled) {
      color: ${BaseColors.white};
      border-radius: 4px;
      background-color: #2593fc;

      &:hover {
        color: ${BaseColors.white};
        background-color: rgba(37, 147, 252, 0.8);
      }
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.5);
    }

    & > * {
      display: inline-block;
      margin-bottom: 0;
    }
  }
`;

export const BorderedCol = styled(Col)`
  border-radius: 8px;
  background-color: #dcece8;
  padding: 48px;
`;

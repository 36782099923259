import { AI_PROVIDERS, AiProvider } from '../../../../firebase/models/Prompts';

type ConfigType = {
  name: string;
  title: string;
  disablePlaceholder: boolean;
};

export const queryConfig: ConfigType = {
  name: 'query',
  title: 'Query',
  disablePlaceholder: false,
};

export const instructionsConfig: ConfigType = {
  name: 'settings.systemMessage',
  title: 'Instructions',
  disablePlaceholder: true,
};

type PromptSectionType = {
  [key in AiProvider]: Array<ConfigType>;
};

export const promptSectionMapping: PromptSectionType = {
  [AI_PROVIDERS.ANTHROPIC]: [instructionsConfig, queryConfig],
  [AI_PROVIDERS.OPEN_AI]: [queryConfig],
  [AI_PROVIDERS.OPEN_AI_ASSISTANT]: [queryConfig],
  [AI_PROVIDERS.NONE]: [],
};

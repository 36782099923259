import { Typography } from 'antd';
import React from 'react';

import { ImageFolderEmpty } from '../../../../shared/assets';
import { StyledAddButton } from './styledComponents';

const { Title, Paragraph } = Typography;

interface IProps {
  onClickAddOfflineBehavior: () => void;
  disableBtn?: boolean;
}

export const EmptyOfflineProjectBehavior = ({
  onClickAddOfflineBehavior,
  disableBtn,
}: IProps) => (
  <div style={{ textAlign: 'center' }}>
    <ImageFolderEmpty style={{ marginTop: '70px' }} />
    <Title
      style={{
        color: '#202629',
        marginBottom: '18px',
        fontSize: '32px',
      }}
    >
      No Offline Behaviors
    </Title>
    <Paragraph>
      You don&lsquo;t have any offline behaviour created. Please start creating
      one!
    </Paragraph>
    <StyledAddButton
      disabled={!!disableBtn}
      onClick={onClickAddOfflineBehavior}
    >
      Add Offline New Behavior
    </StyledAddButton>
  </div>
);

import axios from 'axios';

import { getCloudRunUrl } from '../../utils/cloudRun';

export const MANAGEMENT_API_URL = getCloudRunUrl('management-api');
export const auth0ManagementAPI = axios.create({
  baseURL: MANAGEMENT_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const API_FIREBASE_CUSTOM_TOKEN = getCloudRunUrl(
  'get-firebase-custom-token',
);

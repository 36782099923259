import { Agent, BackgroundCheck, REVIEW_STATUS } from '@agentsonly/models';
import { Tooltip, Typography } from 'antd';
import { ColumnFilterItem, ColumnsType } from 'antd/lib/table/interface';
import { format } from 'date-fns';
import { useAtom } from 'jotai';

import { tableDataAtom } from '../atoms/agentTable';
import { BaseColors } from '../shared/ui/colors';
import { BackgroundCheckStatusCell } from './components/BackgroundCheckStatusCell';
import {
  BACKGROUND_CHECK_STATUS_FILTER_VALUE_LABEL,
  BACKGROUND_CHECK_STATUS_FILTER_VALUES,
  REVIEW_STATUS_FILTER_VALUE_LABEL,
  REVIEW_STATUS_FILTER_VALUES,
} from './consts';
import { Order } from './hooks/useAgentTable';

const backgroundCheckFilters: ColumnFilterItem[] =
  BACKGROUND_CHECK_STATUS_FILTER_VALUES.map((v) => ({
    text: BACKGROUND_CHECK_STATUS_FILTER_VALUE_LABEL[v],
    value: v,
  }));

const reviewStatusFilters = REVIEW_STATUS_FILTER_VALUES.map((v) => ({
  text: REVIEW_STATUS_FILTER_VALUE_LABEL[v],
  value: v,
}));

export const getColumns = () => {
  const [tableData] = useAtom(tableDataAtom);

  const columns: ColumnsType<Agent> = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '15%',
      render: (firstName: string, agent) => `${firstName} ${agent.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, agent) => (
        <Tooltip title={agent.id}>
          <Typography.Text data-agentid={agent.id}>{email}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: Order.Descend,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '19%',
      render: (createdAt: Date) => format(createdAt, 'Pp'),
    },
    {
      title: 'Background Check',
      dataIndex: 'backgroundCheck',
      key: 'status',
      width: '13%',
      filters: backgroundCheckFilters,
      filterMode: 'tree',
      filteredValue: tableData.filters.status,
      onFilter: () => true,
      render: (backgroundCheck: BackgroundCheck) => (
        <BackgroundCheckStatusCell status={backgroundCheck?.status} />
      ),
    },
    {
      title: 'Review Status',
      dataIndex: 'backgroundCheck',
      key: 'reviewStatus',
      filters: reviewStatusFilters,
      filterMode: 'tree',
      filteredValue: tableData.filters.reviewStatus,
      onFilter: () => true,
      render: (backgroundCheck: BackgroundCheck) =>
        backgroundCheck?.reviewStatus === REVIEW_STATUS.PENDING ? (
          <Typography.Text style={{ color: BaseColors.errors }}>
            Pending
          </Typography.Text>
        ) : (
          <Typography.Text style={{ color: BaseColors.primaryDark }}>
            Reviewed
          </Typography.Text>
        ),
    },
  ];

  return columns;
};

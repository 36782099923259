import { Agent } from '@agentsonly/models';
import { Col, Input, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useState } from 'react';

import { getBankFields, OVERRIDE_BANK_FIELD } from '../../../utils/bank';
import { BANK_CODES_BY_COUNTRY } from '../../../utils/bankCode';
import { FormItem } from './styledComponents';

type Props = {
  agent: Agent;
  disabled: boolean;
};

export const BankFields: React.FC<Props> = ({ agent, disabled }) => {
  const [bankCode, setBankCode] = useState(agent.legal?.bank?.bankCode);
  const bankFieldsData = getBankFields(agent.address?.countryCode);
  const countryBanks = BANK_CODES_BY_COUNTRY[agent.address?.countryCode];

  return (
    <>
      {bankFieldsData.map((field) => {
        if (field.name === 'bankCode') {
          return (
            <Col span={12} key={field.key}>
              <FormItem
                initialValue={agent.legal?.bank[field.name]}
                label={field.title}
                name={field.name}
              >
                <Select
                  disabled={disabled}
                  onChange={(value: SelectValue) =>
                    setBankCode(value as string)
                  }
                >
                  {countryBanks.map((countryBank) => (
                    <Select.Option
                      key={countryBank.code}
                      value={countryBank.code}
                    >
                      {countryBank.title}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          );
        }

        const overrideField = bankCode
          ? OVERRIDE_BANK_FIELD[bankCode]?.[field.key]
          : undefined;
        if (overrideField?.name === field.name) {
          return (
            <Col span={12} key={field.key}>
              <FormItem
                label={overrideField?.title}
                name={overrideField?.name}
                initialValue={agent.legal?.bank[field.key]}
                rules={[
                  () => ({
                    validator(_, value: string) {
                      return overrideField?.validation(value);
                    },
                  }),
                ]}
              >
                <Input disabled={disabled} />
              </FormItem>
            </Col>
          );
        }

        return (
          <Col span={12} key={field.key}>
            <FormItem
              label={field.title}
              name={field.name}
              initialValue={agent.legal?.bank[field.key]}
              rules={[
                () => ({
                  validator(_, value: string) {
                    return field.validation?.(value);
                  },
                }),
              ]}
            >
              <Input disabled={disabled} />
            </FormItem>
          </Col>
        );
      })}
    </>
  );
};

import { Button, Form, Row } from 'antd';
import styled from 'styled-components';

export const BorderedRow = styled(Row)<{ $disabled?: boolean }>`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem;
  ${({ $disabled }) => $disabled && `opacity: 0.6;`};
`;

export const FormItem = styled(Form.Item)`
  flex: 1;
  padding-right: 0rem;

  .ant-input-number-group-wrapper {
    width: 100%;
  }
`;

export const SaveButton = styled(Button)`
  && {
    width: 200px;
    height: auto;
    text-align: center;
    padding-top: 11px;
    padding-bottom: 11px;

    border-radius: 4px;
    background-color: #2593fc;

    &:not(:disabled) {
    }

    & > * {
      display: inline-block;
      margin-bottom: 0;
    }

    &[disabled] {
      color: white;
      opacity: 0.5;

      &:hover {
        color: white;
        background-color: #2593fc;
      }
    }
  }
`;

import { Row } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../../shared/ui/colors';
import { Gray } from '../../../../shared/ui/themes/colors/gray';
import { hexToRGB } from '../../../../utils/color';

export const CountryMilestoneContainer = styled(Row)`
  background-color: ${hexToRGB(BaseColors.dividers, 0.3)};
  border-radius: 8px;
  padding: 0 24px;
  box-shadow:
    0 0 2px 0 rgba(110, 129, 141, 0.1),
    0 4px 4px 0 rgba(110, 129, 141, 0.05);
  margin-bottom: 8px;
`;

export const InformationContainer = styled(Row)`
  background-color: ${Gray[25]};
  border: 1px solid ${Gray[300]};
  padding: 16px;
  border-radius: 8px;
`;

import { Project } from '@agentsonly/models';
import { Col, Form, Row, Select, Typography } from 'antd';
import React, { useState } from 'react';

import {
  IconType,
  ShowNotification,
} from '../../../shared/ui/ShowNotification';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { DatePicker } from '../../../utils/DatePicker';
import { IntegerInput } from './components/IntegerInput';
import { useAgentTweak } from './hooks';
import { BorderedRow, FormItem, SaveButton } from './styledComponents';
import { FormValues } from './type';
import { formatWeekly, saveAgentTweakData } from './utils';

const { Text } = Typography;

type Props = {
  project: Project;
  disabled: boolean;
};

export const AgentTweak: React.FC<Props> = ({ project, disabled }) => {
  const [loading, setLoading] = useState(false);
  const {
    demoAgentsOption,
    selectedDate,
    selectedOption,
    handleDatePickerChange,
    handleSelectValue,
  } = useAgentTweak();
  const [form] = Form.useForm();
  const isDemoAgentSelected = Form.useWatch('selectedDemoAgentId', form);

  const onFinish = async (data: FormValues) => {
    setLoading(true);
    try {
      await saveAgentTweakData(project.id, data);
      ShowNotification('Saved Tweak Data', IconType.Success);
    } catch (e) {
      if (e instanceof Error) {
        ShowNotification(`Error: ${e.message}`, IconType.Error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" disabled={disabled} onFinish={onFinish}>
      <FormItem
        key="selectWeek"
        name="selectWeek"
        label={<Spacer axis="horizontal" size={16} />}
        initialValue={selectedDate}
      >
        <DatePicker
          picker="week"
          disabled={disabled}
          value={selectedDate}
          format={formatWeekly}
          onChange={handleDatePickerChange}
        />
      </FormItem>
      <BorderedRow>
        <Col span={24}>
          <Text strong>Preferred Agents Previous Week Data</Text>
        </Col>
        <Spacer axis="vertical" size={25} />
        <Col span={24}>
          <Row gutter={48}>
            <Col span={8}>
              <IntegerInput
                addonAfter="%"
                name="preferredAgentAdherence"
                label="Adherence %"
                disabled={disabled}
              />
            </Col>
            <Col span={8}>
              <IntegerInput
                addonAfter="%"
                name="preferredAgentCancellation"
                label="Cancellation %"
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </BorderedRow>
      <Spacer axis="vertical" size={32} />
      <BorderedRow>
        <Col span={24}>
          <Text strong>Demo Agent&lsquo;s Previous Week Data</Text>
        </Col>
        <Spacer axis="vertical" size={25} />
        <Col span={24}>
          <Row gutter={48}>
            <Col span={8}>
              <FormItem
                key="selectedDemoAgentId"
                name="selectedDemoAgentId"
                label={<Spacer axis="horizontal" size={16} />}
              >
                <Select
                  placeholder="Select Demo Agent"
                  options={demoAgentsOption}
                  disabled={disabled}
                  onSelect={handleSelectValue}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <IntegerInput
                addonAfter="%"
                name="demoAgentAdherence"
                label="Adherence %"
                disabled={!isDemoAgentSelected}
              />
            </Col>
            <Col span={8}>
              <IntegerInput
                addonAfter="%"
                name="demoAgentCancellation"
                label="Cancellation %"
                disabled={!isDemoAgentSelected}
              />
            </Col>
          </Row>
        </Col>
      </BorderedRow>
      <Spacer axis="vertical" size={32} />
      <BorderedRow $disabled={!isDemoAgentSelected}>
        <Col span={24}>
          <Text strong>Selected Agent&lsquo;s Total Surge Mins Deliverd</Text>
        </Col>
        <Spacer axis="vertical" size={25} />
        <Col span={24}>
          <Row gutter={48}>
            <Col span={8}>
              <FormItem
                key="selectedDemoAgentId"
                name="selectedDemoAgentId"
                label={<Spacer axis="horizontal" size={16} />}
              >
                <Select
                  placeholder="Select Demo Agent"
                  options={demoAgentsOption}
                  value={selectedOption}
                  disabled
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <IntegerInput
                name="demoAgentTotalSurgeMins"
                label="Total Surge Mins Delievered"
                disabled={!isDemoAgentSelected}
                addonAfter="mins"
                max={5000} // Max 5000 mins
              />
            </Col>
          </Row>
        </Col>
      </BorderedRow>
      <Spacer axis="vertical" size={30} />
      <Row justify="end">
        <SaveButton
          loading={loading}
          type="primary"
          htmlType="submit"
          disabled={disabled}
        >
          Save
        </SaveButton>
      </Row>
      <Spacer axis="vertical" size={30} />
    </Form>
  );
};

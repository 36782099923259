import { isProd, isStage } from '../utils/consts';

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export const firebaseConfig = (() => {
  let config = process.env.REACT_APP_FIREBASE_DEV_CONFIG;
  if (isStage()) {
    config = process.env.REACT_APP_FIREBASE_STAGE_CONFIG;
  }
  if (isProd()) {
    config = process.env.REACT_APP_FIREBASE_PROD_CONFIG;
  }
  return JSON.parse(config ?? '{}') as FirebaseConfig;
})();

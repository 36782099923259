import { Agent } from '@agentsonly/models';
import { App, Col, notification, Row, Table, Typography } from 'antd';
import { useAtom } from 'jotai';
import React, { useCallback, useState } from 'react';

import { agentByIdAtom } from '../../../../atoms/agent';
import { getAgentsById } from '../../../../firebase/agent';
import { Referral } from '../../../../firebase/models/interfaces';
import { updateReferrals } from '../../../../firebase/referrals';
import { useModal } from '../../../../shared/hooks/useModal';
import { Button } from '../../../../shared/ui/Button';
import { ImportCSVModal } from '../../../../shared/ui/ImportCSVModal';
import { parseCSV } from '../../../../utils/csv';
import { createFileToDownload } from '../../../../utils/file';
import { TEMPLATE_DATA, TEMPLATE_NAME } from './template';

const { Text } = Typography;

type BatchReferralsTableProps = {
  referrals: Referral[];
  disabled: boolean;
};

type CSVType = {
  full_name: string;
  email: string;
  platform_id: string;
  parent: string;
  parent_id: string;
};

type DiffTable = {
  referralId: string;
  referee: {
    id: string;
    name: string;
    email: string;
  };
  referer: {
    id: string;
    name: string;
    email: string;
    fixId: string;
    fixName: string;
    fixEmail: string;
    csvName: string;
  };
};

const handleDownloadTemplate = () => {
  const template = TEMPLATE_DATA.map((data) => data.join(',')).join('\n');
  createFileToDownload(TEMPLATE_NAME, template);
};

const validateCSV = (data: CSVType[], agentById: Record<string, Agent>) => {
  const invalidPlatformIds = data.filter((d) => !agentById[d.platform_id]);
  const invalidParentIds = data.filter((d) => !agentById[d.parent_id]);
  const invalidData = [...invalidPlatformIds, ...invalidParentIds];
  return [
    invalidData.length === 0,
    invalidPlatformIds,
    invalidParentIds,
  ] as const;
};

const getReferralsDiff = async (
  csv: CSVType[],
  referrals: Referral[],
  agentById: Record<string, Agent>,
) =>
  Promise.all(
    csv.map(async (d) => {
      const referral = referrals.find((r) => r.refereeEmail === d.email);
      if (!referral) {
        console.error(`there is no refereeEmail ${d.email}`);
        return undefined;
      }
      const original = agentById[referral.referrerId];
      const agent = agentById[d.parent_id];

      return {
        referralId: referral.id,
        referee: {
          id: d.platform_id,
          name: d.full_name,
          email: d.email,
        },
        referer: {
          id: original.id,
          name: `${original.firstName} ${original.lastName}`,
          email: original.email,
          fixId: agent.id,
          fixName: `${agent.firstName} ${agent.lastName}`,
          fixEmail: agent.email,
          csvName: d.parent,
        },
      } as DiffTable;
    }),
  );

const DiffCell = ({
  original,
  update,
  csv,
}: {
  original: string;
  update: string;
  csv?: string;
}) => (
  <Col>
    {original === update ? (
      <Row>{original}</Row>
    ) : (
      <>
        <Row>
          <Text type="danger" delete>
            {original}
          </Text>
        </Row>
        <Row>
          <Text type="success">{update}</Text>
        </Row>
        {csv && (
          <Row>
            <Text>{csv}</Text>
          </Row>
        )}
      </>
    )}
  </Col>
);

const columns = [
  {
    title: 'Referee Info',
    dataIndex: 'referee',
    key: 'refereeEmail',
    render: (data: DiffTable['referee']) => (
      <Col>
        <Row>{data.id}</Row>
        <Row>{data.name}</Row>
        <Row>{data.email}</Row>
      </Col>
    ),
  },
  {
    title: 'Referer ID Diff',
    dataIndex: 'referer',
    key: 'refererIdDiff',
    render: (data: DiffTable['referer']) => (
      <DiffCell original={data.id} update={data.fixId} />
    ),
  },
  {
    title: 'Referer Name Diff',
    dataIndex: 'referer',
    key: 'refererNameDiff',
    render: (data: DiffTable['referer']) => (
      <DiffCell original={data.name} update={data.fixName} csv={data.csvName} />
    ),
  },
  {
    title: 'Updating Referer Email',
    dataIndex: 'referer',
    key: 'updatingRefererEmail',
    render: (data: DiffTable['referer']) => (
      <DiffCell original={data.email} update={data.fixEmail} />
    ),
  },
];

const batchUpdateReferrals = async (data: DiffTable[]) => {
  try {
    await Promise.all(
      data.map(({ referralId, referer }) =>
        updateReferrals(referralId, {
          referrerId: referer.fixId,
          referrerEmail: referer.fixEmail,
        }),
      ),
    );
    notification.success({
      message: 'referral batch updates successfully finished',
    });
  } catch (err) {
    notification.error({
      message: `${err}`,
    });
  }
};

export const BatchFixReferrals = ({
  referrals,
  disabled,
}: BatchReferralsTableProps) => {
  // TODO: Should divide above logic/UI to separate files for avoiding no-shadow error
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { notification } = App.useApp();
  const [agentById, setAgentById] = useAtom(agentByIdAtom);
  const [tableData, setTableData] = useState<DiffTable[]>([]);
  const { isOpen, closeModal, openModal } = useModal(false);

  const handleFileLoaded = useCallback(
    async (csvData: CSVType[]) => {
      const ids = [
        ...new Set(
          csvData
            .map((data) => [data.parent_id, data.platform_id])
            .flat()
            .concat(referrals.map((r) => r.referrerId)),
        ),
      ].filter((id) => !agentById[id]);
      const newAgents = await getAgentsById(ids);
      const newAgentById = newAgents.reduce<Record<string, Agent>>(
        (acc, agent) => {
          acc[agent.id] = agent;
          return acc;
        },
        {},
      );
      const agents = { ...agentById, ...newAgentById };
      const [isValidCSV, invalidatePlatformIds, invalidParentIds] = validateCSV(
        csvData,
        agents,
      );
      if (!isValidCSV) {
        console.error('there is invalid platformIds ', invalidatePlatformIds);
        console.error('there is invalid parentIds', invalidParentIds);
        notification.error({
          message: 'there is invalid data, please see the console log',
        });
        return;
      }

      const result = await getReferralsDiff(csvData, referrals, agents);
      const data = result.filter((d) => !!d) as DiffTable[];
      setTableData(data);
      setAgentById((prev) => ({ ...prev, ...newAgentById }));
    },
    [notification, referrals, agentById, setAgentById],
  );

  const onUpload = useCallback(
    async (file?: File) => {
      try {
        if (!file) {
          notification.error({ message: 'Something went wrong' });
          return;
        }
        const csvData = await parseCSV<CSVType>(file, {
          transformHeader: (header: string) =>
            header.toLowerCase().replace(/\W/g, '_'),
        });
        await handleFileLoaded(csvData);
      } catch (e) {
        if (e instanceof Error) {
          notification.error({ message: e.message });
        }
      }
    },
    [handleFileLoaded, notification],
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <Row gutter={[12, 0]}>
            <Col>
              <Button disabled={disabled} onClick={handleDownloadTemplate}>
                Download Import Template
              </Button>
            </Col>
            <Col>
              <Button
                disabled={disabled}
                onClick={openModal}
                ghost
                type="primary"
              >
                Import a CSV File
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ImportCSVModal open={isOpen} onClose={closeModal} onUpload={onUpload} />
      {tableData.length > 0 && (
        <>
          <Table columns={columns} dataSource={tableData} rowKey="referralId" />
          <Button
            onClick={() => batchUpdateReferrals(tableData)}
            disabled={disabled}
          >
            Update
          </Button>
        </>
      )}
    </>
  );
};

import { Col } from 'antd';
import { FC } from 'react';

import type { AiProvider } from '../../../../../firebase/models/Prompts';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { AI_PROVIDERS_LABEL_MAPPING } from '../../../const';
import { StyledSelect } from './styledComponents';

type Props = {
  fieldName: string;
  supportedAIProviders: AiProvider[];
};

export const Provider: FC<Props> = ({ supportedAIProviders, fieldName }) => (
  <Col span={5}>
    AI Provider
    <Spacer size={4} axis="vertical" />
    <StyledSelect
      name={fieldName}
      placeholder="Select"
      options={supportedAIProviders.map((aiProvider) => ({
        value: aiProvider,
        label: AI_PROVIDERS_LABEL_MAPPING[aiProvider],
      }))}
    />
  </Col>
);

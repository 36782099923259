import { MouseEventHandler } from 'react';

import { ReactComponent as chevronDownIcon } from '../../assets/base/chevron_down.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/base/trash_can.svg';
import { BaseIconType, BaseIconTypes } from './BaseIcon.types';

export type BaseIconProps = {
  icon: BaseIconType;
  color?: string;
  stroke?: string;
  size?: number;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
};

export const BaseIconComponentMaps = {
  [BaseIconTypes.trashCan]: TrashCanIcon,
  [BaseIconTypes.chevronDown]: chevronDownIcon,
};

import { Project } from '@agentsonly/models';
import { Col, Row, Table } from 'antd';
import { endOfMonth, parse, startOfMonth } from 'date-fns';
import type { RangeValue } from 'rc-picker/lib/interface';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '../../shared/ui/Button';
import { ImportCSVModal } from '../../shared/ui/ImportCSVModal';
import { Spacer } from '../../shared/ui/Spacer/styledComponents';
import { DatePicker } from '../../utils/DatePicker';
import { createFileToDownload } from '../../utils/file';
import { DatePickerFooter } from './components/DatePickerFooter';
import { MissingDataModal } from './components/MissingDataModal';
import { NoResultFound } from './components/NoResultFound';
import { DATE_FORMAT } from './consts';
import { getCsvTemplate, getTableColumns } from './helpers';
import { useClientManualData } from './hooks';
import {
  DatePickerWrapper,
  StyledFlexCol,
  TableWrapper,
} from './styledComponents';
import { RowItem } from './types';

const { RangePicker } = DatePicker;

type Props = {
  project: Project;
  disabled: boolean;
};

export const ClientManualData: React.FC<Props> = ({ project, disabled }) => {
  const [fromDate, setFromDate] = useState<Date>(startOfMonth(new Date()));
  const [toDate, setToDate] = useState<Date>(endOfMonth(new Date()));
  const [selectedRow, setSelectedRow] = useState<RowItem>();
  const [isOpenPicker, setOpenPickerStatus] = useState(false);
  const [isOpenUploadModal, setOpenUploadModalStatus] = useState(false);
  const [data, loadData, loading, uploadClientFile] =
    useClientManualData(project);

  const handleLoadData = () => loadData(fromDate, toDate);

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line
  }, []);

  const handleDownloadTemplateClick = () => {
    const fileName = 'template-client-manual-data.csv';
    // TODO: Remove once we migrate all project to ccassProjectProps
    const instanceId =
      project?.ccaasProjectProps?.instanceIdentifier ??
      project?.connectDetails?.instanceId;
    createFileToDownload(fileName, getCsvTemplate(instanceId ?? ''));
  };

  const handleCalendarChanged = (range: RangeValue<Date>) => {
    const [from, to] = range?.values() ?? [];
    if (!from || !to) {
      return;
    }

    setFromDate(from);
    setToDate(to);
  };

  const handleCancelDatePicker = () => {
    setOpenPickerStatus(false);
  };

  const handleApplyDatePicker = async () => {
    setOpenPickerStatus(false);
    await handleLoadData();
  };

  const handleOnRow = (row: RowItem) => ({
    onClick: () => {
      if (row?.totalMissingDataRows && row.totalMissingDataRows > 0) {
        setSelectedRow(row);
      }
    },
  });

  const handleFocusRangePicker = () => setOpenPickerStatus(true);

  const renderFooterPicker = () => (
    <DatePickerFooter
      onCancel={handleCancelDatePicker}
      onApply={handleApplyDatePicker}
    />
  );

  const handleUploadFile = (file: Blob | File | undefined) =>
    uploadClientFile(file, `${uuid()}.csv`);

  const handleUploadModalClose = () => setOpenUploadModalStatus(false);

  const handleUploadCSVButtonClick = () => setOpenUploadModalStatus(true);

  return (
    <Row>
      <Col span={22}>
        <Row>
          <StyledFlexCol span={16}>
            <Button
              ghost
              type="primary"
              onClick={handleDownloadTemplateClick}
              disabled={disabled}
            >
              Download a CSV Template
            </Button>
            <Spacer axis="horizontal" size={8} />
            <Button
              ghost
              type="primary"
              onClick={handleUploadCSVButtonClick}
              disabled={disabled}
            >
              Import a CSV file
            </Button>
          </StyledFlexCol>
          <Col span={8}>
            <DatePickerWrapper>
              <RangePicker
                placeholder={['Start Date', 'End Date']}
                onChange={handleCalendarChanged}
                onFocus={handleFocusRangePicker}
                renderExtraFooter={renderFooterPicker}
                value={[fromDate, toDate]}
                open={isOpenPicker}
              />
            </DatePickerWrapper>
          </Col>
        </Row>
        <Spacer axis="vertical" size={8} />
        <Row>
          <TableWrapper>
            <Table
              pagination={{
                pageSize: 12,
                position: ['bottomLeft'],
              }}
              rowKey={(record) => `${record.id}-${record.status}`}
              dataSource={data}
              columns={getTableColumns}
              onRow={handleOnRow}
              loading={loading}
              locale={{ emptyText: <NoResultFound /> }}
            />
          </TableWrapper>
        </Row>
      </Col>
      {selectedRow && (
        <MissingDataModal
          uploadedAt={parse(selectedRow.uploadDate, DATE_FORMAT, new Date())}
          onClose={() => setSelectedRow(undefined)}
          projectId={project.id}
          docId={selectedRow.id}
        />
      )}
      <ImportCSVModal
        open={isOpenUploadModal}
        onClose={handleUploadModalClose}
        onUpload={handleUploadFile}
      />
    </Row>
  );
};

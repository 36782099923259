import { Col, Typography } from 'antd';
import React from 'react';

import { Resource } from '../../../../firebase/models/Coaching';
import { EditIcon } from '../../../../shared/assets';
import { CoachingResourceIcon } from '../../consts';
import { getIcon } from '../../helpers';
import { Container, EditIconWrapper, IconWrapper } from './styledComponents';

const { Title } = Typography;

type Props = {
  resource: Resource;
  isLast: boolean;
  openResourceForm: (r: Resource) => void;
};

export const ResourceItem: React.FC<Props> = ({
  resource,
  isLast,
  openResourceForm,
}) => {
  const handleEdit = async () => {
    openResourceForm(resource);
  };

  return (
    <Container $marginRight={isLast ? 0 : 24} gutter={10} align="middle">
      <Col span={12}>
        <IconWrapper>
          {getIcon(resource.icon as CoachingResourceIcon)}
        </IconWrapper>
      </Col>
      <Col span={12}>
        <Title level={5}>{resource.title}</Title>
        <EditIconWrapper>
          <EditIcon onClick={handleEdit} />
        </EditIconWrapper>
      </Col>
    </Container>
  );
};

import { Col, Typography } from 'antd';
import React from 'react';

import {
  QuestionKey,
  WorkExperienceDetail,
  WorkingExperienceType,
} from '../../../firebase/agent/model';
import { BaseColors } from '../../../shared/ui/colors';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { capitalize } from '../../../utils/string';
import {
  NA_VALUE,
  NONE_VALUE,
  WORKING_POSITIONS,
  WORKING_YEARS,
} from '../../consts';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { StyledParagraph, StyledRow } from './styledComponents';

const { Paragraph } = Typography;

const convertWorkingPositionsLabel = (
  positions: string | string[] | undefined,
) => {
  if (!positions) {
    return undefined;
  }

  if (typeof positions === 'string') {
    return positions;
  }

  return positions.map((position) => WORKING_POSITIONS[position]);
};

type Props = {
  workExperience?: WorkExperienceDetail;
};

export const CallCenterExperienceSection: React.FC<Props> = ({
  workExperience,
}) => {
  const callCenterExp = workExperience?.[WorkingExperienceType.CALL_CENTER];

  if (!callCenterExp) {
    return null;
  }

  const { tenure, questions } = callCenterExp;
  const positions =
    questions?.[QuestionKey.POSITIONS]?.[0] !== NONE_VALUE
      ? [
          ...(convertWorkingPositionsLabel(
            questions?.[QuestionKey.POSITIONS],
          ) ?? []),
        ]
      : [...(questions?.[QuestionKey.OTHER_POSITIONS] ?? [])];
  const industries = questions?.[QuestionKey.INDUSTRIES] as string[];
  const works = questions?.[QuestionKey.EXPERIENCES] as string[];
  const certificates =
    questions?.[QuestionKey.CERTIFICATIONS]?.[0] !== NONE_VALUE
      ? [...(questions?.[QuestionKey.CERTIFICATIONS] ?? [])]
      : [...(questions?.[QuestionKey.OTHER_CERTIFICATIONS] ?? [])];

  return (
    <BorderedRow>
      <Col span={24}>
        <SectionContainer title="Call Center Experience">
          <StyledRow>
            <Col span={8}>
              <Paragraph>Years of Tenure</Paragraph>
              {tenure !== undefined ? (
                <Paragraph strong>{WORKING_YEARS[tenure]}</Paragraph>
              ) : (
                <StyledParagraph strong $color={BaseColors.inactive}>
                  {NA_VALUE}
                </StyledParagraph>
              )}
            </Col>
            <Col span={8}>
              <Paragraph>Positions</Paragraph>
              <ul>
                {positions?.map((position) => {
                  const key = position.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{position}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col span={8}>
              <Paragraph>Industries</Paragraph>
              <ul>
                {industries?.map((position) => {
                  const key = position.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{capitalize(position)}</Paragraph>
                    </li>
                  );
                }) ?? (
                  <li>
                    <StyledParagraph strong $color={BaseColors.inactive}>
                      {NA_VALUE}
                    </StyledParagraph>
                  </li>
                )}
              </ul>
            </Col>
          </StyledRow>
          <Spacer axis="vertical" size={32} />
          <StyledRow>
            <Col span={8}>
              <Paragraph>Work Experience</Paragraph>
              <ul>
                {works?.map((position) => {
                  const key = position.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{capitalize(position)}</Paragraph>
                    </li>
                  );
                }) ?? (
                  <li>
                    <StyledParagraph strong $color={BaseColors.inactive}>
                      {NA_VALUE}
                    </StyledParagraph>
                  </li>
                )}
              </ul>
            </Col>
            <Col span={8}>
              <Paragraph>Credentials and Certifications</Paragraph>
              <ul>
                {certificates?.map((certificate) => {
                  const key = certificate.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{capitalize(certificate)}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </StyledRow>
        </SectionContainer>
      </Col>
    </BorderedRow>
  );
};

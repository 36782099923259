import React, { RefObject, useCallback, useEffect } from 'react';

import {
  HorizontalListContainer,
  HorizontalListItem,
} from './styledComponents';
import { ThumbnailItem } from './thumbnailItem';

type Props = {
  imageList: string[];
  selectedImage: string;
  setSelectedImage: (newImage: string) => void;
  changeFromCarousel: boolean;
};

export const ThumbnailList: React.FC<Props> = ({
  imageList,
  selectedImage,
  setSelectedImage,
  changeFromCarousel = false,
}) => {
  const refs = imageList.reduce(
    (acc, curr) => {
      acc[curr] = React.createRef();
      return acc;
    },
    {} as Record<string, RefObject<any>>,
  );

  const onImageChange = useCallback(
    (image: string) => {
      setSelectedImage(image);
    },
    [setSelectedImage],
  );

  useEffect(() => {
    if (changeFromCarousel) {
      refs[selectedImage]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedImage, changeFromCarousel, refs]);

  return (
    <HorizontalListContainer>
      {imageList.map((item) => (
        <HorizontalListItem key={item}>
          <ThumbnailItem
            image={item}
            isSelected={item === selectedImage}
            onClick={onImageChange}
          />
        </HorizontalListItem>
      ))}
    </HorizontalListContainer>
  );
};

import { collection } from 'firebase/firestore';

import {
  CsvUploadCollections,
  CsvUploadFilesCollections,
  RootCollections,
} from '../consts';
import { db } from '../firebaseEntity';
import { CsvUpload, CsvUploadError } from '../models/CsvUpload';
import { generalConverter } from '../utils';

export const getCsvFileCollectionRef = (projectId: string) =>
  db
    .collection(RootCollections.CSVUpload)
    .doc(projectId)
    .collection(CsvUploadCollections.Files)
    .withConverter(generalConverter<CsvUpload>());

export const getCsvFileRef = (projectId: string, fileId: string) =>
  db
    .collection(RootCollections.CSVUpload)
    .doc(projectId)
    .collection(CsvUploadCollections.Files)
    .doc(fileId)
    .withConverter(generalConverter<CsvUpload>());

export const getCsvFileErrorCollectionRef = (
  projectId: string,
  fileId: string,
) =>
  collection(
    getCsvFileRef(projectId, fileId),
    CsvUploadFilesCollections.ErrorRows,
  ).withConverter(generalConverter<CsvUploadError>());

import { Project } from '@agentsonly/models';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { functions } from '../../../firebase';
import {
  DemoCronJobType,
  getDemoCronJobRef,
} from '../../../firebase/demoCronJob';
import {
  IconType,
  ShowNotification,
} from '../../../shared/ui/ShowNotification';

const CRON_JOB_FUNCTION = 'runCronJobForDemoProject';

type Props = {
  date: Date;
  project: Project;
};

export const useCrobJob = ({ date, project }: Props) => {
  const [loading, setLoading] = useState(false);
  const [cronJobData, setCronJobData] = useState<DemoCronJobType[]>([]);

  const getCronJobData = useCallback(async (projectId: string) => {
    try {
      const snapshot = await getDemoCronJobRef(projectId).get();
      const result = snapshot.docs.map((query) => query.data());

      setCronJobData(result);
    } catch (e) {
      if (e instanceof Error) {
        ShowNotification(e.message, IconType.Error);
      }
    }
  }, []);

  const onHandleCronJob = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        date: format(date, 'yyyy-MM-dd'),
        projectId: project.id,
      };

      await functions.httpsCallable(CRON_JOB_FUNCTION)(payload);
      const snapshot = await getDemoCronJobRef(project.id).limit(1).get();
      const result = snapshot.docs.map((query) => query.data());
      setCronJobData((prev) => [...result, ...prev]);
      ShowNotification('Started the cron job', IconType.Success);
    } catch (e) {
      if (e instanceof Error) {
        ShowNotification(e.message, IconType.Error);
      }
    } finally {
      setLoading(false);
    }
  }, [date, project]);

  useEffect(() => {
    getCronJobData(project.id);
  }, [project, getCronJobData]);

  return { data: cronJobData, onHandleCronJob, loading };
};

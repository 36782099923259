import { startOfWeek } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { getDemoAgents } from '../../../firebase/agent';
import { generateDemoAgentOption } from './utils';

export const useAgentTweak = () => {
  const [demoAgentsOption, setDemoAgentsOption] =
    useState<{ value: string; label: string }[]>();
  const [selectedDate, setSelectedDate] = useState(startOfWeek(new Date()));
  const [selectedOption, setSelectedOption] = useState<string>();

  const handleDatePickerChange = (date: Date | null) => {
    setSelectedDate(date ? startOfWeek(date) : startOfWeek(new Date()));
  };

  const handleSelectValue = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  const fetchDemoAgents = useCallback(async () => {
    const result = await getDemoAgents();
    setDemoAgentsOption(generateDemoAgentOption(result));
  }, []);

  useEffect(() => {
    fetchDemoAgents();
  }, [fetchDemoAgents]);

  return {
    demoAgentsOption,
    selectedDate,
    selectedOption,
    handleDatePickerChange,
    handleSelectValue,
  };
};

import { WorkPreference } from '@agentsonly/models/types/WorkPreference';
import { Col, Typography } from 'antd';
import React from 'react';

import { WorkType, WorkTypeLabels } from '../../../firebase/agent/model';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { GIG_COMMIT_HOURS } from '../../consts';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { CapitalizedText, StyledRow } from './styledComponents';

const { Paragraph } = Typography;

type Props = {
  workPreference?: WorkPreference;
};

export const WorkPreferenceSection: React.FC<Props> = ({ workPreference }) => (
  <BorderedRow $borderRadius={8}>
    <Col span={24}>
      <SectionContainer title="Work Preference">
        <StyledRow>
          <Col span={8}>
            <Paragraph>Current State of Employment</Paragraph>
            <Paragraph strong>
              {workPreference?.workType
                ? WorkTypeLabels[workPreference.workType as WorkType]
                : '-'}
            </Paragraph>
          </Col>
          <Col span={8}>
            <Paragraph>Gig Hours Plan to Work</Paragraph>
            {workPreference?.commitHours !== undefined ? (
              <Paragraph strong>
                {GIG_COMMIT_HOURS[workPreference?.commitHours]}
              </Paragraph>
            ) : (
              <Paragraph strong>-</Paragraph>
            )}
          </Col>
          <Col span={8}>
            <Paragraph>Preferred Contact Type(s)</Paragraph>
            <Paragraph strong>
              <CapitalizedText>
                {workPreference?.contactType
                  ? workPreference?.contactType
                  : '-'}
              </CapitalizedText>
            </Paragraph>
          </Col>
        </StyledRow>
        <Spacer axis="vertical" size={32} />
        <StyledRow>
          <Col span={8}>
            <Paragraph>CCaaS & CRM Software</Paragraph>
            {workPreference?.software === undefined ? (
              '-'
            ) : (
              <ul>
                {workPreference?.software?.map((software) => {
                  const key = software.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{software}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            )}
          </Col>
          <Col span={8}>
            <Paragraph>Core Skills Relevant to Call Center</Paragraph>
            {workPreference?.skills === undefined ? (
              '-'
            ) : (
              <ul>
                {workPreference?.skills?.map((skill) => {
                  const key = skill.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{skill}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            )}
          </Col>
          <Col span={8}>
            <Paragraph>Affiliated Brand or Companies</Paragraph>
            {workPreference?.companyBrands === undefined ? (
              '-'
            ) : (
              <ul>
                {workPreference?.companyBrands?.map((companyBrand) => {
                  const key = companyBrand.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{companyBrand}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            )}
          </Col>
        </StyledRow>
        <Spacer axis="vertical" size={32} />
        <StyledRow>
          <Col span={8}>
            <Paragraph>Hobbies</Paragraph>
            {workPreference?.hobbies === undefined ? (
              '-'
            ) : (
              <ul>
                {workPreference?.hobbies?.map((hobby) => {
                  const key = hobby.replace(/\s+/g, '');
                  return (
                    <li key={key}>
                      <Paragraph strong>{hobby}</Paragraph>
                    </li>
                  );
                })}
              </ul>
            )}
          </Col>
        </StyledRow>
      </SectionContainer>
    </Col>
  </BorderedRow>
);

export type WorkingExperienceDetail = {
  tenure: number;
  questions?: Record<QuestionKey, string[] | string>;
};

export type WorkExperienceDetail = Partial<
  Record<WorkingExperienceType, WorkingExperienceDetail | undefined>
>;

export enum WorkingExperienceType {
  CALL_CENTER = 'call-center',
  ONLINE_FREELANCER = 'online-freelancer',
  OTHERS = 'others',
}

export enum QuestionKey {
  OTHER_EXPERIENCE = 'question-1',
  POSITIONS = 'question-1',
  OTHER_POSITIONS = 'question-1-plus',
  INDUSTRIES = 'question-2',
  EXPERIENCES = 'question-3',
  CERTIFICATIONS = 'question-4',
  OTHER_CERTIFICATIONS = 'question-4-plus',
}

export enum EducationLevel {
  HIGH_SCHOOL = 'high-school',
  SENIOR_HIGH_SCHOOL = 'senior-high-school',
  SHORT_COURSE = 'short-course',
  COLLEGE_UNDERGRADUATE = 'college-undergraduate',
  COLLEGE_GRADUATE = 'college-graduate',
  POST_GRADUATE = 'post-graduate',
  DOCTORAL = 'doctoral',
}

export const EducationLevelLabels = {
  [EducationLevel.HIGH_SCHOOL]: 'High School',
  [EducationLevel.SENIOR_HIGH_SCHOOL]: 'Senior High School',
  [EducationLevel.SHORT_COURSE]: 'Vocational / Short course',
  [EducationLevel.COLLEGE_UNDERGRADUATE]: 'College Undergraduate',
  [EducationLevel.COLLEGE_GRADUATE]: 'College Graduate',
  [EducationLevel.POST_GRADUATE]: 'Post Graduate',
  [EducationLevel.DOCTORAL]: 'Doctoral',
};

export const WorkingExperienceTypeLabels = {
  [WorkingExperienceType.CALL_CENTER]: 'Call Center',
  [WorkingExperienceType.ONLINE_FREELANCER]: 'Online Freelance',
  [WorkingExperienceType.OTHERS]: 'Others',
};

export enum WorkType {
  FULL_TIME = 'full-time',
  PART_TIME = 'part-time',
  FREELANCE = 'freelance',
  UNEMPLOYED = 'unemployed',
}

export const WorkTypeLabels = {
  [WorkType.FULL_TIME]: 'Full-time',
  [WorkType.PART_TIME]: 'Part-time',
  [WorkType.FREELANCE]: 'Freelance',
  [WorkType.UNEMPLOYED]: 'Unemployed',
};

// see @https://en.wikipedia.org/wiki/Wikipedia:WikiProject_Languages/List_of_ISO_639-3_language_codes_(2019)
export enum LANGUAGE {
  ENGLISH = 'eng',
  FILIPINO = 'fil',
  SPANISH = 'spa',
  FRENCH = 'fra',
  PORTUGUESE = 'por',
  JAPANESE = 'jpn',
  MANDARIN = 'cmn',
  CANTONESE = 'yue',
  KOREAN = 'kor',
  VIETNAMESE = 'vie',
  GERMAN = 'deu',
  ITALIAN = 'ita',
  RUSSIAN = 'rus',
}

export const LANGUAGE_LABELS = {
  [LANGUAGE.ENGLISH]: 'English',
  [LANGUAGE.FILIPINO]: 'Filipino',
  [LANGUAGE.SPANISH]: 'Spanish',
  [LANGUAGE.FRENCH]: 'French',
  [LANGUAGE.PORTUGUESE]: 'Portuguese',
  [LANGUAGE.JAPANESE]: 'Japanese',
  [LANGUAGE.MANDARIN]: 'Mandarin',
  [LANGUAGE.CANTONESE]: 'Cantonese',
  [LANGUAGE.KOREAN]: 'Korean',
  [LANGUAGE.VIETNAMESE]: 'Vietnamese',
  [LANGUAGE.GERMAN]: 'German',
  [LANGUAGE.ITALIAN]: 'Italian',
  [LANGUAGE.RUSSIAN]: 'Russian',
};

import { Form as AntdForm, Input, notification, Row, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { compareDesc, format } from 'date-fns';
import React, { useState } from 'react';

import { PlatformPayment } from '../../firebase/models/interfaces';
import {
  deletePlatformPayments,
  getPlatformPayments,
  resetAchievedMilestones,
} from '../../firebase/referrals';
import { Button } from '../../shared/ui/Button';
import { Form } from '../../shared/ui/form/Form';
import { isProd } from '../../utils/consts';

type Props = {
  disabled: boolean;
};

type FormValues = {
  agentId: string;
  amount: number;
  description: string;
};

export const PlatformPayments: React.FC<Props> = ({ disabled }) => {
  const [payments, setPayments] = useState<PlatformPayment[]>([]);
  const [form] = useForm();

  const searchPayments = async ({ agentId }: FormValues) => {
    try {
      const data = await getPlatformPayments(agentId);
      setPayments(data.sort((a, b) => compareDesc(a.createdAt, b.createdAt)));
    } catch (err) {
      if (err instanceof Error) {
        notification.open({
          message: `${err?.message}`,
        });
      }
    }
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'reference(referral id)',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: Date) => <span>{format(value, 'yyyy/MM/dd')}</span>,
    },
  ];

  return (
    <>
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={searchPayments}
        form={form}
        disabled={disabled}
      >
        <Row>
          <AntdForm.Item
            label="Agent Id"
            name="agentId"
            rules={[{ required: true, message: 'Please input agentId!' }]}
          >
            <Input type="string" />
          </AntdForm.Item>

          <AntdForm.Item>
            <Button type="primary" htmlType="submit">
              Search Payment
            </Button>
          </AntdForm.Item>
        </Row>
      </Form>
      {payments.length > 0 && (
        <>
          <Button
            type="primary"
            onClick={async () => {
              const agentId = form.getFieldValue('agentId');
              await resetAchievedMilestones(agentId);
              await deletePlatformPayments(agentId);
              searchPayments(form.getFieldsValue());
            }}
            disabled={isProd() || disabled}
          >
            Remove Payments
          </Button>
          <Table dataSource={payments} columns={columns} />
        </>
      )}
    </>
  );
};

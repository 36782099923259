import { Col, Row } from 'antd';
import React from 'react';

import { Modal } from '../../../../shared/ui/Modal';

interface IConfirmationProps {
  title: string;
  content: string;
  actionButtons: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const ConfirmationModal = ({
  title,
  content,
  actionButtons,
  open,
  onClose,
}: IConfirmationProps) => (
  <Modal title={title} open={open} footer={null} onCancel={onClose} width={800}>
    <Row>
      <Col span={24}>{content}</Col>
    </Row>
    <Row justify="end">{actionButtons}</Row>
  </Modal>
);

import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Form } from 'antd';
import equal from 'fast-deep-equal';
import { useAtom } from 'jotai';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { unsavedScheduleConfigAtom } from '../../../atoms/prefAgents';
import { ScheduleConfig } from '../../../firebase/models/ScheduleConfig';
import {
  IconType,
  ShowNotification,
} from '../../../shared/ui/ShowNotification';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { strip } from '../../../utils';
import { LeadTime } from './components/LeadTime';
import { Submit } from './components/Submit';
import { WeeklyEligibilityCriteria } from './components/WeeklyEligibilityCriteria';
import { TIERS } from './consts';
import { usePrefConfig } from './hooks';
import { schema } from './schema';
import { Container } from './styledComponents';

type Props = {
  projectId: string;
  disabled: boolean;
};

const FORM_KEY = 'preferential';

const convertToScheduleConfig = (data?: object) => {
  const newData = schema.cast(data, {
    stripUnknown: true,
  });
  return strip<ScheduleConfig>(newData);
};

export const Settings: React.FC<Props> = ({ projectId, disabled }) => {
  const [, setUnsavedScheduleConfigData] = useAtom(unsavedScheduleConfigAtom);
  const { fetchPrefConfig, savePrefConfig } = usePrefConfig(projectId);
  const originalValues = useRef<object | undefined>(undefined);

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      const data = convertToScheduleConfig(await fetchPrefConfig());
      originalValues.current = data;
      form.reset(data);
    })();
    return () => {
      if (!form.formState.isValid) return;
      const fieldData = convertToScheduleConfig(form.getValues());
      const original = convertToScheduleConfig(originalValues.current);

      if (!equal(fieldData, original)) {
        setUnsavedScheduleConfigData({
          projectId,
          data: fieldData,
        });
      } else {
        setUnsavedScheduleConfigData(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const fieldData = convertToScheduleConfig(data);
      originalValues.current = fieldData;
      await savePrefConfig(fieldData);
      ShowNotification(
        'Saved Tier Configuration successfully',
        IconType.Success,
      );
    } catch (e) {
      console.error(e);
    }
  });

  const onReset = async () => {
    form.reset({ preferential: {} } as ScheduleConfig);
  };

  const handleLeadTimeChange = () => {
    form.trigger(
      Object.keys(TIERS).map((k) => `${FORM_KEY}.${k}.durationSeconds`),
    );
  };

  return (
    <FormProvider {...form}>
      <Form onFinish={onSubmit} onReset={onReset}>
        <Container>
          <LeadTime
            disabled={disabled}
            onChange={handleLeadTimeChange}
            parentFormKey={FORM_KEY}
          />
          <Divider />
          <WeeklyEligibilityCriteria
            disabled={disabled}
            parentFormKey={FORM_KEY}
          />
          <Spacer size={48} axis="vertical" />
          <Submit
            saveDisabled={disabled || !form.formState.isValid}
            resetDisabled={disabled}
          />
        </Container>
      </Form>
    </FormProvider>
  );
};

import { Agent, Project } from '@agentsonly/models';
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  or,
  Query,
  query,
  where,
} from 'firebase/firestore';

import {
  ProjectsCollections,
  ProjectStatsCollections,
  RootCollections,
} from '../consts';
import { db, fs } from '../firebaseEntity';
import { ProjectStatus } from '../models/AgentsProject';
import { TimePeriod } from '../models/Demo';
import { PreferentialSchedules } from '../models/PreferentialSchedules';
import { ScheduleStats } from '../models/ProductiveScheduleStats';
import { ScheduleConfig } from '../models/ScheduleConfig';
import { generalConverter, loadCollectionData } from '../utils';

// modular api
export const projectCol = collection(fs, RootCollections.Projects);
export const projectDoc = (projectId: string) => doc(projectCol, projectId);

/**
 * @deprecated use modular api instead
 */
export const getProjectRef = (projectId: string) =>
  db.collection(RootCollections.Projects).doc(projectId);

export const getProjectRefV9 = (projectId: string) =>
  doc(collection(db, RootCollections.Projects), projectId);

export const getProject = (projectId: string) =>
  getDoc(getProjectRefV9(projectId).withConverter(generalConverter<Project>()));

export const getProjectExternalNumberDimensionsKeys = async (
  projectId: string,
) => {
  const externalData = await db
    .collection(RootCollections.Projects)
    .doc(projectId)
    .collection(ProjectsCollections.ExternalData)
    .where('valueType', '==', 'number')
    .get();

  return externalData.docs.map((d) => d.id);
};
export const getProjectRefsByInstanceId = (instanceId?: string) =>
  // TODO: Remove once we migrate all project to ccassProjectProps
  getDocs(
    query(
      collection(fs, RootCollections.Projects),
      or(
        where('ccaasProjectProps.instanceIdentifier', '==', instanceId),
        where('connectDetails.instanceId', '==', instanceId),
      ),
    ),
  );

export const getProjectStatsByPeriodRef = (
  projectId: string,
  timePeriod: TimePeriod,
) =>
  db
    .collection(RootCollections.Projects)
    .doc(projectId)
    .collection(ProjectsCollections.ProjectStats)
    .doc(timePeriod);

export const getAllProjects = async () => {
  const snapshot = await db
    .collection(RootCollections.Projects)
    .withConverter(generalConverter<Project>({ convertTimestamp: true }))
    .get();

  return snapshot.docs.map((snap) => snap.data());
};

export const getProjectPreferentialScheduleColRef = (projectId: string) =>
  getProjectRef(projectId)
    .collection(ProjectsCollections.Schedules)
    .withConverter(generalConverter<PreferentialSchedules>());

export const getProjectApprovedAgents = async (projectId: string) => {
  const snapshot = await getProjectRef(projectId)
    .collection(ProjectsCollections.ProjectAgents)
    .where('status', '==', ProjectStatus.Approved)
    .withConverter(generalConverter<Agent>({ convertTimestamp: true }))
    .get();
  return snapshot.docs.map((docAgent) => docAgent.data());
};

export const getSchedulePreferentialConfigRef = (projectId: string) =>
  getProjectRef(projectId)
    .collection(ProjectsCollections.Schedules)
    .withConverter(generalConverter<ScheduleConfig>())
    .doc('config');

export const getProjectReliabilityRef = (projectId: string) =>
  getProjectRef(projectId)
    .collection(ProjectsCollections.ProjectStats)
    .doc('weekly')
    .collection(ProjectStatsCollections.Schedules)
    .withConverter(generalConverter<ScheduleStats>());

export const getProjectsWithPlatformRatesData = async () =>
  loadCollectionData<Project>(
    query(projectCol, where('platformRates', '!=', null)) as Query<
      Project,
      DocumentData
    >,
  );

import React from 'react';

import { Behavior } from '../../../../firebase/models/Coaching';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { INITIAL_BEHAVIOR_FROM_VALUE } from '../../consts';
import { useEditBehavior } from '../../hooks';
import { BehaviorModal } from '../BehaviorModal';

type Props = {
  projectId: string;
  behavior: Behavior;
  onEditSuccess: () => void;
  onDismiss: () => void;
  onShowConfirmDelete: () => void;
};

export const EditBehavior = ({
  projectId,
  behavior,
  onEditSuccess,
  onDismiss,
  onShowConfirmDelete,
}: Props) => {
  const initialValues = { ...INITIAL_BEHAVIOR_FROM_VALUE, ...behavior };
  const { edit, loading } = useEditBehavior({
    projectId,
    behaviorId: (behavior as any).id,
    initialValues,
    onSuccess: () => {
      onDismiss();
      ShowNotification('Edited behavior successfully!', IconType.Success);
      onEditSuccess();
    },
  });

  return (
    <BehaviorModal
      header="Behavior Details"
      isEdit
      isLoading={loading}
      initialValue={initialValues}
      onSubmit={edit}
      onDismiss={onDismiss}
      onDelete={onShowConfirmDelete}
      projectId={projectId}
    />
  );
};

export const countWords = (
  input: string,
  list: string[],
): Record<string, number> => {
  if (!input || !list) {
    return {};
  }

  const result = list.reduce<Record<string, number>>((acc, word) => {
    const regex = new RegExp(word, 'g');
    const matches = input.match(regex);

    acc[word] = matches ? matches.length : 0;
    return acc;
  }, {});

  return result;
};

import { Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledText = styled(Text)`
  font-size: 20px;
  font-weight: 700;
`;

import { BaseIconComponentMaps, BaseIconProps } from './BaseIcon';
import { BaseIconTypes } from './BaseIcon.types';
import { StyledIcon } from './styledComponents';

const DEFAULT_ICON_SIZE = 24;

// DEPRECATED
// Note: There isn’t a design system for the old admin layout
// I created this to boost up the process when we process the new Admin portal revamp
export const BaseIcon = ({
  icon = BaseIconTypes.trashCan,
  color = 'white',
  stroke = 'black',
  size = DEFAULT_ICON_SIZE,
  disabled = false,
  className,
  onClick,
}: BaseIconProps) => {
  const BaseIconComponent = BaseIconComponentMaps[icon];
  return (
    <StyledIcon
      className={className}
      component={BaseIconComponent}
      style={{
        color,
        fontSize: size,
      }}
      stroke={stroke}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

import { AI_PROVIDERS, AiPrompt } from '../firebase/models/Prompts';

export enum ProcessingStatus {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  PROCESSING = 'processing',
  DONE = 'done',
  FAILED = 'failed',
}

export const MAPPING_PROCESSING_STATUS: Record<ProcessingStatus, string> = {
  [ProcessingStatus.UPLOADING]: 'Uploading',
  [ProcessingStatus.UPLOADED]: 'Uploaded',
  [ProcessingStatus.PROCESSING]: 'Processing',
  [ProcessingStatus.DONE]: 'Done',
  [ProcessingStatus.FAILED]: 'Failed',
};

export const DEFAULT_TEMPLATE_VALUES: AiPrompt = {
  settings: {
    provider: AI_PROVIDERS.NONE,
  },
};

export const SUPPORTIVE_AI_PROVIDERS = [AI_PROVIDERS.OPEN_AI_ASSISTANT];

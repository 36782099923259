import { Project } from '@agentsonly/models';
import { Col, Row, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';

import { fetchAgentsLoadingAtom } from '../../atoms/agent';
import { CSV_UPLOAD_TYPE, CsvUpload } from '../../firebase/models/CsvUpload';
import { ProductivePaySubmission } from '../../firebase/models/Project';
import { useModal } from '../../shared/hooks/useModal';
import { Button } from '../../shared/ui/Button';
import { ImportCSVModal } from '../../shared/ui/ImportCSVModal';
import { Spacer } from '../../shared/ui/Spacer/styledComponents';
import { DatePicker } from '../../utils/DatePicker';
import { toVancouverTime } from '../../utils/timezone';
import { MAPPING_PROCESSING_STATUS, ProcessingStatus } from '../consts';
import { useCSVUpload } from '../hooks';
import { yesterdayInVancouverTime } from './utils';

const columns: ColumnType<ProductivePaySubmission>[] = [
  {
    title: 'Upload Date',
    dataIndex: 'uploadedAt',
    key: 'uploadedAt',
    render: (date: firebase.firestore.Timestamp) =>
      format(date.toDate(), 'MM-dd-yyyy'),
  },
  {
    title: 'Status',
    dataIndex: 'processingStatus',
    key: 'processingStatus',
    render: (status: ProcessingStatus) => MAPPING_PROCESSING_STATUS[status],
  },
  {
    title: 'Total Rows',
    dataIndex: 'totalRows',
    key: 'totalRows',
  },
  {
    title: 'Online time (Seconds)',
    dataIndex: 'onlineTimeSeconds',
    key: 'onlineTimeSeconds',
  },
  {
    title: 'ACW (Seconds)',
    dataIndex: 'acwSeconds',
    key: 'acwSeconds',
  },
  {
    title: 'Contacts handled',
    dataIndex: 'csvNumCalls',
    key: 'csvNumCalls',
  },
  {
    title: 'Pay Date',
    dataIndex: 'payDate',
    key: 'payDate',
    render: (date: firebase.firestore.Timestamp) =>
      date ? format(date.toDate(), 'MM-dd-yyyy') : 'N/A',
  },
];

type Props = {
  project: Project;
  disabled: boolean;
};

const convertCSVUploadToProductivePay = (
  csvUpload: CsvUpload,
): ProductivePaySubmission => {
  const {
    uploadedAt,
    filterConditions,
    totalRows,
    processingStatus,
    metadata,
    id,
  } = csvUpload;
  const { selectedDate } = filterConditions ?? {};
  const {
    acwSeconds = 0,
    csvNumCalls = 0,
    onlineTimeSeconds = 0,
  } = metadata ?? {};
  return {
    uploadedAt,
    totalRows,
    processingStatus,
    payDate: selectedDate,
    id,
    acwSeconds: Number(acwSeconds),
    csvNumCalls: Number(csvNumCalls),
    onlineTimeSeconds: Number(onlineTimeSeconds),
  };
};

const TABLE_ID = 'productivePayTable';

export const ProductivePay = ({ project, disabled }: Props) => {
  const [selectedDate, setSelectedDate] = useState(yesterdayInVancouverTime());
  const [loading] = useAtom(fetchAgentsLoadingAtom);
  const [tableId, setTableId] = useState<string>(TABLE_ID);
  const { isOpen, closeModal, openModal } = useModal(false);
  const {
    handleImportCSVFile,
    data: productivePays,
    loading: loadingProductivePays,
    refreshData,
  } = useCSVUpload(CSV_UPLOAD_TYPE.PRODUCTIVE_PAY, project.id);

  const productivePaySubmissions = useMemo(
    () => productivePays.map((p) => convertCSVUploadToProductivePay(p)),
    [productivePays],
  );

  const onUpload = async (file?: File) => {
    // TODO: move notifications to ui layer
    await handleImportCSVFile(file, {
      selectedDate,
    });
  };

  const handleRefreshData = async () => {
    await refreshData();
    setTableId(`${TABLE_ID}_${Date.now()}`);
  };

  return (
    <>
      <Row gutter={10}>
        <Col>
          <DatePicker
            key="datePicker"
            defaultValue={selectedDate}
            onChange={(date: Date | null) =>
              setSelectedDate(date ?? new Date())
            }
            disabledDate={(date: Date) =>
              toVancouverTime(date).getTime() >
              yesterdayInVancouverTime().getTime()
            }
          />
        </Col>
        <Col>
          <Button disabled={disabled} onClick={openModal} ghost type="primary">
            Import a CSV File
          </Button>
        </Col>
        <Col>
          <Button onClick={handleRefreshData} type="primary">
            Refresh
          </Button>
        </Col>
        <ImportCSVModal
          open={isOpen}
          onClose={closeModal}
          onUpload={onUpload}
        />
      </Row>
      <Spacer size={10} axis="vertical" />
      <Table
        key={tableId}
        rowKey="id"
        showHeader
        dataSource={productivePaySubmissions}
        columns={columns}
        loading={loading || loadingProductivePays}
        bordered
      />
    </>
  );
};

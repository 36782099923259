import React from 'react';

import {
  getAgentsAchievementColRef,
  getCoachingBehaviorRef,
  getCoachingLiveBehaviorColRef,
  getCoachingResourceRef,
  getCoachingResourcesColRef,
  getCoachingStatsQuizColRef,
  getProjectBehaviorsColRef,
} from '../../firebase/coaching';
import { Behavior, Resource } from '../../firebase/models/Coaching';
import {
  CoachingActivityIcon,
  CoachingAirPlayIcon,
  CoachingApertureIcon,
  CoachingArchiveIcon,
  CoachingBookOpenIcon,
  CoachingCircleIcon,
  CoachingDownCircleIcon,
  CoachingHeadPhoneIcon,
  CoachingTrendingUpIcon,
  CoachingTriangleIcon,
} from '../../shared/assets';
import { CoachingResourceIcon } from './consts';
import { AchievementItem } from './types';

export const getBehaviors = async (projectId: string): Promise<Behavior[]> => {
  const coaching = getProjectBehaviorsColRef(projectId);
  const snapshot = await coaching.get();
  return snapshot.docs.map((query) => query.data());
};

export const getLiveBehaviors = async (
  projectId: string,
): Promise<Behavior[]> => {
  const coaching = getCoachingLiveBehaviorColRef(projectId);
  const snapshots = await coaching.get();
  return snapshots.docs.map((doc) => doc.data());
};

export const createBehavior = async (
  projectId: string,
  data: Behavior,
): Promise<void> => {
  const { id, ...behavior } = data;
  await getProjectBehaviorsColRef(projectId).add(behavior);
};

export const editBehavior = async (
  projectId: string,
  behaviorId: string,
  data: Behavior,
): Promise<void> => {
  const { id, ...updatedBehavior } = data;
  return getCoachingBehaviorRef(projectId, behaviorId).update(updatedBehavior);
};

export const deleteBehavior = async (
  projectId: string,
  behaviorId: string,
): Promise<void> => getCoachingBehaviorRef(projectId, behaviorId).delete();

export const getResources = async (projectId: string) => {
  const snapshot = await getCoachingResourcesColRef(projectId).get();
  return snapshot.docs.map((doc) => doc.data());
};

export const createResource = async (projectId: string, resource: Resource) => {
  const ref = getCoachingResourcesColRef(projectId);
  const newlyResource = await ref.add({
    ...resource,
  });

  return newlyResource.id;
};

export const updateResource = async (projectId: string, resource: Resource) => {
  const ref = getCoachingResourceRef(projectId, resource.id ?? '');
  await ref.set(
    {
      title: resource.title,
      icon: resource.icon,
      link: resource.link,
    },
    { merge: true },
  );
};

export const deleteResource = async (projectId: string, resourceId: string) => {
  const ref = getCoachingResourceRef(projectId, resourceId);
  await ref.delete();
};

export const getIcon = (icon: CoachingResourceIcon): React.ReactNode => {
  switch (icon) {
    case CoachingResourceIcon.ACTIVITY:
      return <CoachingActivityIcon />;
    case CoachingResourceIcon.AIRPLAY:
      return <CoachingAirPlayIcon />;
    case CoachingResourceIcon.ARCHIVE:
      return <CoachingArchiveIcon />;
    case CoachingResourceIcon.BOOK_OPEN:
      return <CoachingBookOpenIcon />;
    case CoachingResourceIcon.CIRCLE:
      return <CoachingCircleIcon />;
    case CoachingResourceIcon.DOWN_CIRCLE:
      return <CoachingDownCircleIcon />;
    case CoachingResourceIcon.HEADPHONE:
      return <CoachingHeadPhoneIcon />;
    case CoachingResourceIcon.TRENDING_UP:
      return <CoachingTrendingUpIcon />;
    case CoachingResourceIcon.TRIANGLE:
      return <CoachingTriangleIcon />;
    case CoachingResourceIcon.APERTURE:
      return <CoachingApertureIcon />;
    default:
      return <>No Image</>;
  }
};

export const getSubmissionGroups = async (projectId: string) => {
  const snapshot = await getCoachingStatsQuizColRef(projectId).get();
  return snapshot.docs.map((d) => d.data());
};

export const getProjectAchievements = async (projectId: string) => {
  const snapshot = await getAgentsAchievementColRef(projectId).get();
  return snapshot.docs.map((doc) => {
    const {
      id,
      agentId,
      proficiencyAchievement,
      assessedBy,
      agentName,
      createdAt,
    } = doc.data();
    return {
      id,
      agentId,
      assessedBy,
      agentName,
      proficiency: proficiencyAchievement,
      uploadDate: createdAt?.toDate() ?? new Date(),
    } as AchievementItem;
  });
};

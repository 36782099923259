import {
  BACKGROUND_KEYS,
  BACKGROUND_STATUS,
  BackgroundCheck,
} from '@agentsonly/models';
import React, { useCallback, useEffect, useState } from 'react';

import { getAgentSpeedTestRef } from '../../../../firebase/agent';
import { SpeedTestResultData } from '../../../../shared/models/SpeedTest';
import { BackgroundCheckStatusLabels } from '../consts';
import { CheckItem } from './CheckItem';
import { CheckItemContainer, ResultContainer } from './Containers';
import { SpeedTestResult } from './SpeedTestResult';

type Props = {
  agentId: string;
  backgroundCheck: BackgroundCheck;
  onBackgroundCheckChange: (backgroundCheck: BackgroundCheck) => void;
  disabled: boolean;
};

const useSpeedTestResult = (agentId: string) => {
  const [speedTestResult, setSpeedTestResult] = useState<
    SpeedTestResultData | undefined
  >();
  useEffect(() => {
    (async () => {
      const result = await getAgentSpeedTestRef(agentId).get();
      setSpeedTestResult(result.data());
    })();
  }, [agentId]);
  return speedTestResult;
};

export const SpeedTest: React.FC<Props> = ({
  agentId,
  backgroundCheck,
  onBackgroundCheckChange,
  disabled,
}) => {
  const speedTestResult = useSpeedTestResult(agentId);

  const sppedTestKey = BACKGROUND_KEYS.SPEED_TEST;
  const value =
    backgroundCheck[sppedTestKey] ?? BACKGROUND_STATUS.PENDING_REVIEW;

  const onChange = useCallback(
    (key: string, newValue: string) => {
      onBackgroundCheckChange({ ...backgroundCheck, [key]: newValue });
    },
    [backgroundCheck, onBackgroundCheckChange],
  );

  return (
    <>
      <ResultContainer>
        <SpeedTestResult result={speedTestResult} />
      </ResultContainer>
      <CheckItemContainer>
        <CheckItem
          key={sppedTestKey}
          id={sppedTestKey}
          value={BackgroundCheckStatusLabels[value]}
          onChange={onChange}
          disabled={disabled}
        />
      </CheckItemContainer>
    </>
  );
};

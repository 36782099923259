import { Col, Row } from 'antd';
import styled from 'styled-components';

import { Button } from '../../../../../shared/ui/Button';

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const StyledCol = styled(Col)`
  margin-bottom: 3px;
`;

export const StyledButton = styled(Button)`
  && {
    border-radius: 4px;
    width: 150px;
  }
`;

import firebase from 'firebase/compat/app';

import { RootCollections } from '../../firebase/consts';
import { db } from '../../firebase/firebaseEntity';
import {
  ExtendedPlatformInvoice,
  FirebasePlatformPayment,
  PlatformPayment,
} from '../../firebase/models/interfaces';
import { chunk } from '../../utils';

const platformPaymentConverter = {
  toFirestore(data: PlatformPayment): firebase.firestore.DocumentData {
    return { ...data };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): PlatformPayment {
    const data = snapshot.data(options) as FirebasePlatformPayment;
    return { ...data, createdAt: data.createdAt.toDate() };
  },
};

const fetchPaymentsByInvoiceIds = async (ids: string[]) => {
  const result = await Promise.all(
    chunk(ids, 10).map((chunkIds) =>
      db
        .collection(RootCollections.PlatformPayments)
        .where('invoiceId', 'in', chunkIds)
        .withConverter(platformPaymentConverter)
        .get(),
    ),
  );

  return result
    .map((platformPayments) =>
      platformPayments.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    )
    .flat();
};

export const fetchAgentPayments = async (
  invoices: ExtendedPlatformInvoice[],
): Promise<{ payments: PlatformPayment[] }> => {
  if (invoices.length === 0) {
    return { payments: [] };
  }

  const payments = await fetchPaymentsByInvoiceIds(invoices.map((i) => i.id));

  return { payments };
};

import type { MenuProps } from 'antd';
import { Col, Menu, Row } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { useCallback, useState } from 'react';

import { Payments } from './Payments';
import { Withdrawals } from './Withdrawals';

const TABS = [
  {
    key: 'withdrawals',
    title: 'Withdrawals',
    element: <Withdrawals />,
  },
  {
    key: 'payments',
    title: 'Payments',
    element: <Payments />,
  },
];

const MenuItems: MenuProps['items'] = TABS.map((tab) => ({
  label: tab.title,
  key: tab.key,
}));

export const Wallet = () => {
  const [selectedTab, selectTab] = useState(TABS[0]);

  const handleClick: MenuProps['onClick'] = useCallback((e: MenuInfo) => {
    selectTab(TABS.find(({ key }) => e.key === key) ?? TABS[0]);
  }, []);

  return (
    <Row
      style={{
        height: '100%',
        flexDirection: 'column',
        gap: '32px',
        overflowY: 'scroll',
      }}
    >
      <Col span={24}>
        <Menu
          onClick={handleClick}
          selectedKeys={[selectedTab.key]}
          mode="horizontal"
          style={{ marginBottom: 16 }}
          items={MenuItems}
        />
        {selectedTab.element}
      </Col>
    </Row>
  );
};

import { QuizSkill } from '@agentsonly/models';
import { Col, Row, Typography } from 'antd';
import React from 'react';

import { CompleteIcon, InCompleteIcon } from '../../../shared/assets';
import { BaseColors } from '../../../shared/ui/colors';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { getCorrectAnswers, getTotalByResults } from './helpers';
import { StyledParagraph } from './styledComponents';

type Props = {
  skill: QuizSkill;
};

const { Paragraph } = Typography;
const PASSED_SCORED = 0.7;

export const ComprehensiveTestSection: React.FC<Props> = ({ skill }) => {
  const correctAnswers = getCorrectAnswers(
    Object.values(skill?.responses ?? {}),
  );
  const totalQuestions = getTotalByResults(
    Object.values(skill?.responses ?? {}),
  );
  const scoredPercentage = ((skill?.result ?? 0) * 100).toFixed(0);
  const isPassed = (skill?.result ?? 0) >= PASSED_SCORED;

  return (
    <BorderedRow>
      <Col span={24}>
        <SectionContainer title="Comprehension Test">
          <Col span={8}>
            <Paragraph>Agent scored</Paragraph>
            <StyledParagraph strong color={BaseColors.primaryDark}>
              {`${scoredPercentage}% of 100%`}
            </StyledParagraph>
          </Col>
          <Col span={8}>
            <Paragraph>Correct answers</Paragraph>
            <StyledParagraph strong color={BaseColors.primaryDark}>
              {correctAnswers}/{totalQuestions}
            </StyledParagraph>
          </Col>
          <Col span={8}>
            <Paragraph>Status</Paragraph>
            <Row align="middle">
              {isPassed ? <CompleteIcon /> : <InCompleteIcon />}
              <Spacer axis="horizontal" size={4} />
              <StyledParagraph
                strong
                color={isPassed ? BaseColors.primaryDark : BaseColors.errors}
              >
                {isPassed ? 'Passed' : 'Failed'}
              </StyledParagraph>
            </Row>
          </Col>
        </SectionContainer>
      </Col>
    </BorderedRow>
  );
};

import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

type Props = {
  title: string;
};

export const FormLabel: React.FC<Props> = ({ title }) => (
  <Text>
    {title}
    <Text type="danger">*</Text>
  </Text>
);

import { PlatformRateType } from '@agentsonly/models';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC } from 'react';

import { PLATFORM_RATE_MAPPING } from '../../../PlatformRates/consts';
import { Chip } from '../../../shared/ui/Chip';
import { formatNumber } from '../../../utils/number';
import {
  PlatformRateContainer,
  PlatformRateDescription,
  PlatformRateTitle,
  PlatformRateValue,
} from './styledComponents';

const DESCRIPTION_DISPLAYING_LENGTH = 90;

type Props = {
  rateName: string;
  description: string;
  rateInUSD: number;
  rateType: PlatformRateType;
  active: boolean;
  disabled: boolean;
  onSelected?: (selected: boolean) => void;
};

export const PlatformRateItem: FC<Props> = ({
  rateName,
  description,
  rateInUSD,
  rateType,
  active,
  onSelected,
  disabled,
}) => {
  const isExceededMaxLength =
    description.length > DESCRIPTION_DISPLAYING_LENGTH;
  const displayingDesc = isExceededMaxLength
    ? `${description.substring(0, DESCRIPTION_DISPLAYING_LENGTH)}...`
    : description;

  const handleOnSelected = (e: CheckboxChangeEvent) =>
    onSelected?.(e.target.checked);

  return (
    <PlatformRateContainer $disabled={disabled} align="middle">
      <Col span={20}>
        <Row align="middle">
          <Col span={12} lg={{ span: 13 }} xl={{ span: 16 }} xxl={{ span: 19 }}>
            <PlatformRateTitle
              data-testid={`project:PlatformRates:rateName-${rateName}`}
            >
              {rateName}
            </PlatformRateTitle>
            <Tooltip title={description} placement="right">
              <PlatformRateDescription>
                {displayingDesc}
              </PlatformRateDescription>
            </Tooltip>
          </Col>
          <Col span={12} lg={{ span: 11 }} xl={{ span: 8 }} xxl={{ span: 5 }}>
            <Chip data-testid={`project:PlatformRates:rateType-${rateType}`}>
              {PLATFORM_RATE_MAPPING[rateType]}
            </Chip>
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row align="middle">
          <Col span={19}>
            <PlatformRateValue
              data-testid={`project:PlatformRates:rateNumber-${rateInUSD}`}
            >
              ${formatNumber(rateInUSD, 0, 10)}
            </PlatformRateValue>
          </Col>
          <Col span={5}>
            <Checkbox
              checked={active}
              onChange={handleOnSelected}
              disabled={disabled}
              data-testid={`project:PlatformRates:checkbox-${rateName}`}
            />
          </Col>
        </Row>
      </Col>
    </PlatformRateContainer>
  );
};

import { Project } from '@agentsonly/models';
import { Col, Row, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format, parse } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import { SubmissionGroup } from '../../../../firebase/models/Coaching';
import { DownloadIcon } from '../../../../shared/assets';
import { Button } from '../../../../shared/ui/Button';
import { BaseColors } from '../../../../shared/ui/colors';
import {
  IconType,
  ShowNotification,
} from '../../../../shared/ui/ShowNotification';
import { coachingAPI } from '../../../../utils/firebase/coachingAPI';
import { PAGE_SIZE } from '../../consts';
import { getSubmissionGroups } from '../../helpers';
import { DownloadIconWrapper, StyledDownloadIcon } from './styledComponents';

const { Title } = Typography;
const ID_DATE_FORMAT = 'yyyyMMdd';

type Props = {
  project: Project;
};

const TABLE_ID = 'submission';

export const Submissions: React.FC<Props> = ({ project }) => {
  const [data, setData] = useState<SubmissionGroup[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [tableId, setTableId] = useState(TABLE_ID);
  const [loading, setLoading] = useState<
    | {
        id: string;
        state: boolean;
      }
    | undefined
  >();

  const handleLoadData = useCallback(async () => {
    setFetching(true);
    try {
      const submissionGroups = await getSubmissionGroups(project.id);
      setData(submissionGroups);
    } catch (e) {
      console.error(e);
    } finally {
      setFetching(false);
    }
  }, [project.id]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  const refreshData = async () => {
    await handleLoadData();
    setTableId(`${TABLE_ID}_${Date.now()}`);
  };

  const getColumns = (projectId: string): ColumnsType<SubmissionGroup> => [
    {
      title: 'Date',
      dataIndex: 'id',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        parse(a.id, ID_DATE_FORMAT, new Date()).getTime() -
        parse(b.id, ID_DATE_FORMAT, new Date()).getTime(),
      render: (date: string) =>
        format(parse(date, ID_DATE_FORMAT, new Date()), 'MM-dd-yyyy'),
    },
    {
      title: '# of Submissions',
      dataIndex: 'numSubmissions',
      key: 'numSubmissions',
    },
    {
      title: '',
      key: 'downloadSubmission',
      render: (_, value) => (
        <Spin spinning={loading?.id === value.id && loading?.state}>
          <DownloadIconWrapper
            onClick={async () => {
              const date = format(
                parse(value.id, ID_DATE_FORMAT, new Date()),
                'yyyyMMdd',
              );
              setLoading({ id: value.id, state: true });
              try {
                await coachingAPI.downloadSubmissionFile(projectId, date);
              } catch (err) {
                console.log((err as Error).message);
                ShowNotification('Something went wrong', IconType.Error);
              }
              setLoading({ id: value.id, state: false });
            }}
          >
            <StyledDownloadIcon
              component={DownloadIcon}
              stroke={BaseColors.secondaryLight}
            />
          </DownloadIconWrapper>
        </Spin>
      ),
    },
  ];

  return (
    <Row gutter={[0, 18]}>
      <Col span={24}>
        <Button onClick={refreshData} type="primary">
          Refresh
        </Button>
      </Col>
      <Col span={24}>
        <Title level={5}>Daily Submission</Title>
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          key={tableId}
          pagination={{
            pageSize: PAGE_SIZE,
            position: ['bottomLeft'],
            showSizeChanger: false,
          }}
          dataSource={data}
          loading={fetching}
          columns={getColumns(project.id)}
        />
      </Col>
    </Row>
  );
};

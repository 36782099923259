import { BACKGROUND_KEYS, BACKGROUND_STATUS } from '@agentsonly/models';

export const BackgroundCheckKeysLabels = {
  [BACKGROUND_KEYS.GOVERNMENT_ID]: 'Government id',
  [BACKGROUND_KEYS.POLICE_CLEARANCE]: 'Police Clearance',
  [BACKGROUND_KEYS.SPEED_TEST]: 'Speed Test',
  [BACKGROUND_KEYS.PC_CHECK]: 'PC Check',
  [BACKGROUND_KEYS.VOICE_RECORDING]: 'Voice Recording',
};

export const BackgroundCheckStatusLabels = {
  [BACKGROUND_STATUS.PENDING_REVIEW]: 'Pending Review',
  [BACKGROUND_STATUS.MISSING]: 'Missing',
  [BACKGROUND_STATUS.INCORRECT]: 'Incorrect',
  [BACKGROUND_STATUS.FAILED]: 'Failed',
  [BACKGROUND_STATUS.PASSED]: 'Passed',
};

import { NotificationType } from '@agentsonly/models';

import { functions } from '../../firebase/firebaseEntity';

type NotificationOptions = {
  objectId: string;
  title: string;
  body: string;
  source?: string;
};

const sendPushToAgentFunctionName = 'sendPushToAgent';
const sendEmailToAgentFunctionName = 'sendEmailToAgent';
const sendSMSToAgentFunctionName = 'sendSMSToAgent';

const sendPushToAgentNotification = async (
  data: NotificationOptions,
): Promise<any> => {
  const sendPushToAgent = functions.httpsCallable(sendPushToAgentFunctionName);
  return sendPushToAgent(data);
};

const sendEmailToAgentNotification = async (
  data: NotificationOptions,
): Promise<any> => {
  const sendEmailToAgent = functions.httpsCallable(
    sendEmailToAgentFunctionName,
  );

  return sendEmailToAgent(data);
};

const sendSMSToAgentNotification = async (
  data: NotificationOptions,
): Promise<any> => {
  const sendSMSToAgent = functions.httpsCallable(sendSMSToAgentFunctionName);
  return sendSMSToAgent(data);
};

const sendNotificationMap: Record<
  string,
  (data: NotificationOptions) => Promise<any>
> = {
  email: sendEmailToAgentNotification,
  sms: sendSMSToAgentNotification,
  push: sendPushToAgentNotification,
};

export const sendNotification = async (
  type: NotificationType,
  objectId: string,
  title: string,
  body: string,
  source?: string,
) => {
  try {
    const fn = sendNotificationMap[type];
    await fn({ objectId, title, body, source });
  } catch (err) {
    if (err instanceof Error) {
      console.log(`Error sending notifications ${err.message}`);
    }
  }
};

import Icon from '@ant-design/icons';
import styled from 'styled-components';

export const DownloadIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & :hover {
    cursor: pointer;
  }
`;

export const StyledDownloadIcon = styled(Icon)<{ stroke: string }>`
  path {
    stroke: ${({ stroke }) => stroke};
  }
  color: transparent;
  font-size: 16px;
`;

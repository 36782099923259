import { message } from 'antd';
import { format, formatISO } from 'date-fns';
import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { agentByIdAtom, getAgentsByIdAtom } from '../../../../atoms/agent';
import {
  ExtendedPlatformInvoice,
  PlatformPayment,
  Referral,
} from '../../../../firebase/models/interfaces';
import { getPlatformInvoicesByDate } from '../../../../firebase/platformInvoices';
import { getReferralsByIds } from '../../../../firebase/referrals';
import { reduceToObjectByIds } from '../../../../utils/dataUtils';
import { createFileToDownload } from '../../../../utils/file';
import { fetchAgentPayments } from '../../../networks/platformInvoices';
import { createFileBody } from './downloadCsv';
import { INVOICE_TYPES, InvoiceType } from './invoices';

type Props = {
  invoiceDate: Date;
};

export const useInvoiceDownloader = ({ invoiceDate }: Props) => {
  const [data, setData] = useState<PlatformPayment[]>([]);
  const [payments, setPayments] = useState<PlatformPayment[]>([]);
  const [invoices, setInvoices] = useState<
    Record<string, ExtendedPlatformInvoice>
  >({});
  const [referrals, setReferrals] = useState<Record<string, Referral>>({});
  const [agents] = useAtom(agentByIdAtom);
  const [, getAgentsById] = useAtom(getAgentsByIdAtom);

  const updateData = useCallback(
    async (date: Date) => {
      const platformInvoices = await getPlatformInvoicesByDate(date);
      const { payments: paymentsFromInvoices } =
        await fetchAgentPayments(platformInvoices);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const referrals = await getReferralsByIds(
        paymentsFromInvoices.map((p) => p.reference),
      );

      getAgentsById(platformInvoices.map((invoice) => invoice.agentId));
      setData(paymentsFromInvoices);
      setPayments(paymentsFromInvoices);
      setInvoices(
        reduceToObjectByIds<ExtendedPlatformInvoice>(platformInvoices),
      );
      setReferrals(reduceToObjectByIds<Referral>(referrals));
    },
    [getAgentsById],
  );

  const handleDownloadClick = useCallback(async () => {
    // create all the information to generate a CSV file

    const title = `ao_payment_${INVOICE_TYPES[InvoiceType.WISE].name}_${format(
      invoiceDate,
      't', // formats to milliseconds
    )}.csv`;

    if (
      agents &&
      Object.keys(agents).length > 0 &&
      payments.length > 0
      //   unpaidInvoicesForDate &&   these probably are the payments without invoiceId
      //   unpaidInvoicesForDate.length > 0
    ) {
      const paymentsAndAgents = payments.map((pay) => ({
        ...pay,
        ...agents[pay.agentId],
        referral: referrals[pay.reference],
      }));

      const bodies = createFileBody(Object.values(agents), paymentsAndAgents, {
        type: InvoiceType.WISE,
        name: `wise-${formatISO(new Date())}`,
      });

      createFileToDownload(title, bodies);

      message.info(`Download complete`);
    } else {
      console.log('Error with invoices');
      console.log(agents);

      message.error(`Invalid agents or payment data`);
    }
  }, [agents, payments, invoiceDate, referrals]);

  return {
    data: data.filter((item) => (item.agentId ? agents[item.agentId] : true)),
    invoices,
    referrals,
    agents,
    updateData,
    handleDownloadClick,
  };
};

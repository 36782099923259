import axios from 'axios';

import { API_FIREBASE_CUSTOM_TOKEN, auth0ManagementAPI } from './base';
import { Auth0ManagementApiPath, Auth0User } from './consts';

const getFirebaseCustomToken = async (idToken: string): Promise<string> => {
  const firebaseCustomToken = await axios.get(API_FIREBASE_CUSTOM_TOKEN, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  const body = await firebaseCustomToken.data;
  return body.firebaseToken;
};

const getAuth0User = async (email: string) => {
  const users = await auth0ManagementAPI.get<Auth0User[]>(
    `${Auth0ManagementApiPath.GET_USER_BY_EMAIL}?email=${encodeURIComponent(
      email,
    )}`,
  );

  return users.data[0];
};

const updateAuth0UserPassword = async (
  userId: string,
  password: string,
  token: string,
) =>
  auth0ManagementAPI.patch(
    `${Auth0ManagementApiPath.UPDATE_USER}`.replace(':id', userId),
    {
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const Auth0API = {
  getFirebaseCustomToken,
  getAuth0User,
  updateAuth0UserPassword,
};

import { Col, Row, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useAtom } from 'jotai';
import React from 'react';

import { preferentialWeeksAtom } from '../../../atoms/prefAgents';
import { projectByIdAtom } from '../../../atoms/project';
import { TierEnum } from '../../../firebase/models/ScheduleConfig';
import { useModal } from '../../../shared/hooks/useModal';
import { Button } from '../../../shared/ui/Button';
import { ImportCSVModal } from '../../../shared/ui/ImportCSVModal';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { PreferentialAgentTableType } from '../helper';
import { usePreferredAgents } from '../hooks';

const columns: ColumnType<PreferentialAgentTableType>[] = [
  {
    title: 'Shift Week Start',
    dataIndex: 'weekStart',
    key: 'weekStart',
  },
  {
    title: 'Shift Week End',
    dataIndex: 'weekEnd',
    key: 'weekEnd',
  },
  {
    title: 'Tier 1 total',
    dataIndex: 'tiers',
    key: 'tier-1',
    render: (value: Record<TierEnum, number>) => value['tier-1'] ?? 0,
  },
  {
    title: 'Tier 2 Total',
    dataIndex: 'tiers',
    key: 'tier-2',
    render: (value: Record<TierEnum, number>) => value['tier-2'] ?? 0,
  },
  {
    title: 'Tier 3 Total',
    dataIndex: 'tiers',
    key: 'tier-3',
    render: (value: Record<TierEnum, number>) => value['tier-3'] ?? 0,
  },
];

type Props = {
  projectId: string;
  disabled: boolean;
};

export const AgentList: React.FC<Props> = ({ projectId, disabled }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [projects] = useAtom(projectByIdAtom);
  const [{ loading }] = useAtom(preferentialWeeksAtom);
  const project = projects[projectId];
  const { data, handleDownloadTemplate, handleUpload } =
    usePreferredAgents(project);
  return (
    <>
      <Row>
        <Col span={12}>
          <Row>
            <Button
              ghost
              type="primary"
              onClick={handleDownloadTemplate}
              disabled={disabled}
            >
              Download Import Template
            </Button>
            <Spacer axis="horizontal" size={16} />
            <Button
              ghost
              type="primary"
              onClick={openModal}
              disabled={disabled}
            >
              Import a CSV file
            </Button>
          </Row>
        </Col>
      </Row>
      <Spacer axis="vertical" size={32} />
      <Table
        key="table"
        loading={loading}
        showHeader
        rowKey="id"
        dataSource={data}
        columns={columns}
        bordered
        pagination={{
          position: ['bottomLeft'],
          showSizeChanger: false,
        }}
      />
      <ImportCSVModal
        open={isOpen}
        onClose={closeModal}
        onUpload={handleUpload}
      />
    </>
  );
};

import { Col } from 'antd';
import { FC } from 'react';

import { ProjectStatus } from '../../../firebase/models/AgentsProject';
import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';
import { ProjectsList } from './ProjectsList';
import { StyledRow } from './styledComponents';
import { useAgentProjects } from './useAgentProjects';

type Props = {
  agentId: string;
};

export const ProjectsSection: FC<Props> = ({ agentId }) => {
  const { projects, loading } = useAgentProjects(agentId);

  if (loading) {
    return null;
  }

  return (
    <BorderedRow>
      <Col span={24}>
        <SectionContainer title="Current Projects">
          <StyledRow>
            <Col span={6}>
              <ProjectsList
                status={ProjectStatus.Pending}
                projectNames={projects?.pending}
              />
            </Col>
            <Col span={6}>
              <ProjectsList
                status={ProjectStatus.Training}
                projectNames={projects?.training}
              />
            </Col>
            <Col span={6}>
              <ProjectsList
                status={ProjectStatus.Approved}
                projectNames={projects?.approved}
              />
            </Col>
            <Col span={6}>
              <ProjectsList
                status={ProjectStatus.Removed}
                projectNames={projects?.removed}
              />
            </Col>
          </StyledRow>
        </SectionContainer>
      </Col>
    </BorderedRow>
  );
};

import Icon from '@ant-design/icons';
import { Col, Menu } from 'antd';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';

export const Star = styled.span`
  color: #08a17c;
  padding: 0 5px;
  font-weight: 700;
  line-height: 18px;
`;

export const LiveBehaviorContainer = styled.div<{ $hoverPointer?: string }>`
  width: 100%;
  background: #ffffff;
  border: 1px dashed #a5b1b9;
  border-radius: 8px;
  padding: 1.2rem 2rem 1.2rem 1rem;
  margin-top: 24px;

  &:hover {
    cursor: ${({ $hoverPointer }) => $hoverPointer || 'pointer'};
  }
`;

export const OfflineBehaviorContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #a5b1b9;
  border-radius: 8px;
  padding: 1.2rem 2rem 1.2rem 1rem;
  margin-top: 24px;
`;
export const LiveBehaviorContent = styled.div`
  width: 100%;
`;
export const EmptyBehaviorContent = styled.div`
  width: 70%;
  height: 70%;
  padding-left: 30px;
`;
export const RecommendedList = styled.ul`
  color: #666666;
`;
export const RecommendedItem = styled.li`
  color: #666666;
  cursor: default;
`;
export const LiveBehaviorStatus = styled.div`
  color: white;
  cursor: pointer;
  background: #08a17c;
  border-radius: 24px;
  font-size: 12px;
  padding: 2px 5px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 0px;
`;

export const StyledMenu = styled(Menu)`
  width: 100%;
`;

export const StyledCol = styled(Col)`
  margin-top: 2rem;
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDownloadButton = styled.button<{ $disabled: boolean }>`
  border: none;
  background: none;

  ${({ $disabled }) =>
    $disabled
      ? '&:hover{ cursor: not-allowed; }'
      : '&:hover{ cursor: pointer; }'};
`;

export const StyledDownloadIcon = styled(Icon)<{ $stroke: string }>`
  path {
    stroke: ${({ $stroke }) => $stroke};
  }
  color: transparent;
  font-size: 16px;
`;

import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

import { fetchAIAssistantsAtom } from '../../../atoms/aiAssistants';

export const useOpenAIAssistant = (projectId?: string) => {
  const [{ data: assistants, loading }, fetchAssistants] = useAtom(
    fetchAIAssistantsAtom,
  );
  useEffect(() => {
    if (projectId) {
      fetchAssistants(projectId);
    }
  }, [projectId]);

  const options = useMemo(() => {
    if (!assistants) {
      return [];
    }

    return assistants.map((a) => ({ label: a.name ?? a.id, value: a.id }));
  }, [assistants]);

  return { options, loading };
};

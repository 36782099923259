import { ISOCountry } from '@agentsonly/models';
import firebase from 'firebase/compat/app';

export type FirestoreDocument = {
  readonly id: string;
};

export type PlatformPaymentExtra = {
  planType: ReferralPlanType;
  refereeEmail: string;
  referralLevel: number;
  referralGate: number;
  stars?: number;
};

export type BasePlatformPayment = {
  readonly agentId: string;
  readonly reference: string;
  readonly description: string;
  readonly type: 'referral';
  readonly amount: number;
  readonly invoiceId: string | null;
  readonly data?: PlatformPaymentExtra;
};

export type FirebasePlatformPayment = BasePlatformPayment & {
  readonly createdAt: firebase.firestore.Timestamp;
};

export type PlatformPayment = BasePlatformPayment & {
  readonly createdAt: Date;
};

type BasePlatformInvoice = {
  readonly paymentAmount: number;
  readonly items: string[];
  readonly status: string;
};

export type FirebasePlatformInvoice = BasePlatformInvoice & {
  readonly createdAt: firebase.firestore.Timestamp;
};

export type PlatformInvoice = BasePlatformInvoice & {
  readonly createdAt: Date;
  agentId?: string;
};

export type ExtendedPlatformInvoice = PlatformInvoice & {
  readonly key: string;
  readonly id: string;
};

export enum ReferralStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export type ReferralValues = {
  readonly createdAt: firebase.firestore.Timestamp;
  readonly planId?: string;
  readonly refereeEmail: string;
  readonly refereeFirstName: string;
  readonly refereeLastName: string;
  readonly referrerEmail: string;
  readonly referrerId: string;
  readonly status: ReferralStatus;
  readonly achievedMilestone?: number;
};

export type Referral = ReferralValues & FirestoreDocument;

export type Amounts = {
  readonly grandparentAmount: number;
  readonly parentAmount: number;
};

export type Milestone = Partial<Record<ISOCountry, Amounts>> & {
  readonly milestone: number;
};

export type ReferralPlanType = 'fixed' | 'starMultiply';

export type ReferralPlanValues = {
  readonly type: ReferralPlanType;
  readonly createdAt: firebase.firestore.Timestamp;
  readonly milestones: Milestone[];
};

export type ReferralPlan = ReferralPlanValues & FirestoreDocument;

export type Dimensions = Record<string, QualityRates>;

export type QualityItem = {
  low?: number;
  high?: number;
};

export type QualityRates = {
  oneStar: QualityItem[];
  twoStar: QualityItem[];
  threeStar: QualityItem[];
  fourStar: QualityItem[];
  fiveStar: QualityItem[];
  weighting: number;
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Form as AntdForm, Input, Modal } from 'antd';
import React, { MouseEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { emailSchema } from '../../../utils/validator';

export type EmailUpdateValues = {
  email: string;
};

type Props = {
  open: boolean;
  onCancel?: MouseEventHandler<HTMLElement>;
  onOk: (values: EmailUpdateValues) => void;
};

export const EmailUpdateModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { reset, handleSubmit, formState, control } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(emailSchema),
  });

  return (
    <Modal
      title="Update Email"
      open={open}
      onOk={handleSubmit(onOk)}
      confirmLoading={formState.isSubmitting}
      onCancel={onCancel}
      afterClose={reset}
    >
      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <AntdForm.Item
            help={fieldState.error?.message}
            validateStatus={fieldState.error ? 'error' : ''}
          >
            <Input
              placeholder="Email"
              name={field.name}
              disabled={field.disabled}
              value={field.value}
              ref={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          </AntdForm.Item>
        )}
      />
    </Modal>
  );
};

import { Form as AntdForm, notification } from 'antd';
import { add, startOfDay } from 'date-fns';
import React, { useState } from 'react';

import { functions } from '../../firebase';
import { Button } from '../../shared/ui/Button';
import { Form } from '../../shared/ui/form/Form';
import { toVancouverTime } from '../../utils/timezone';
import { DateFnsPicker } from '../components/DateFnsPicker';
import { PlatformInvoicesTable } from './PlatformInvoicesTable';

type Props = {
  disabled: boolean;
};

type Response = {
  data: {
    message: string;
  };
};

const onFinish = async () => {
  try {
    const createPlatformInvoices = functions.httpsCallable(
      'createPlatformInvoices',
    );
    const response: Response = await createPlatformInvoices();

    notification.open({
      message: response.data.message,
    });
  } catch (err) {
    if (err instanceof Error) {
      notification.open({
        message: `${err?.message as any}`,
      });
    }
  }
};

export const PlatformInvoices: React.FC<Props> = ({ disabled }) => {
  const convertVancouverTime = startOfDay(toVancouverTime(new Date()));
  const yesterday = add(convertVancouverTime, { days: -1 });
  const [date, setDate] = useState(yesterday);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onChange = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  return (
    <>
      <Form
        name="basic"
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        disabled={disabled}
      >
        <AntdForm.Item>
          <Button type="primary" htmlType="submit" disabled={disabled}>
            Generate Invoices
          </Button>
        </AntdForm.Item>

        <AntdForm.Item>
          <DateFnsPicker defaultValue={date} onChange={onChange} />
        </AntdForm.Item>
      </Form>

      <hr />
      <PlatformInvoicesTable date={date} />
    </>
  );
};

import { PlatformRate, PlatformRateType } from '@agentsonly/models';
import * as yup from 'yup';

const MAX_DECIMAL_DIGITS = 6;
const decimalPattern = `^\\d+(\\.\\d{1,${MAX_DECIMAL_DIGITS}})?$`;

export const platformRateValidator: yup.SchemaOf<
  Pick<PlatformRate, 'name' | 'description' | 'rateInUSD' | 'rateType'>
> = yup.object({
  name: yup.string().trim().required('Name is a required field'),
  description: yup.string().trim().required('Description is a required field'),
  rateInUSD: yup
    .number()
    .positive('Rate in USD must be a positive number')
    .test(
      'maxDecimalDigits',
      `The rate should not have more than ${MAX_DECIMAL_DIGITS} digits after decimal`,
      (number) => new RegExp(decimalPattern).test(number?.toString() || ''),
    )
    .typeError('Wrong input type, numerical characters only.')
    .required('Rate (in USD) is a required field'),
  rateType: yup
    .mixed<PlatformRateType>()
    .oneOf(['perCall', 'scheduled', 'transactional'])
    .required('Rate type is a required field'),
});

import { isProd, isStage } from './consts';

const CLOUD_RUN_ID = (() => {
  if (isStage()) return 'kkwhngkz5q';
  if (isProd()) return '7hs56lngvq';
  return '7zkcit7loa';
})();

const serviceName = [
  'ao-wallet',
  'management-api',
  'get-firebase-custom-token',
] as const;

export const getCloudRunUrl = (feature: (typeof serviceName)[number]) =>
  `https://${feature}-${CLOUD_RUN_ID}-uc.a.run.app`;

import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

import { BaseColors } from '../../../shared/ui/colors';

export const Container = styled(Col)`
  border-radius: 8px;
  border: 1px solid ${BaseColors.dividers};
  background: ${BaseColors.neutral00};
  box-shadow:
    0px 12px 24px 0px rgba(29, 173, 142, 0.05),
    0px 0px 4px 0px rgba(38, 110, 95, 0.05);
  padding: 48px;
  width: 100%;
`;

export const StyledSpace = styled(Space)`
  width: 100%;
`;

export const AlignedRow = styled(Row)`
  min-height: 113px;
`;

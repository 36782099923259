export enum InvoiceType {
  WISE = 0,
  SPEI,
}

export interface CSVEntry {
  toCSV(): string;
}

export interface InvoiceTypeConfiguration {
  type: InvoiceType;
  name: string;
}

export const INVOICE_TYPES: Array<InvoiceTypeConfiguration> = [
  { type: InvoiceType.WISE, name: 'Wise' },
  { type: InvoiceType.SPEI, name: 'SPEI' },
];

export const formatCSVData = (
  dataArray: Array<string>,
  newLine: boolean = true,
): string => {
  const formatted = dataArray
    .map((data) => `${data}`.replaceAll(',', ''))
    .reduce((acc, currentValue) => `${acc},"${currentValue}"`);
  return `${formatted}${newLine ? `\n` : ''}`;
};

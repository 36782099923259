import { Col, Divider, notification, Row, Table } from 'antd';
import { useAtom } from 'jotai';
import { FC } from 'react';

import { adminRolesAtom } from '../atoms/adminRoles';
import { adminUsersAtom } from '../atoms/adminUsers';
import { AdminPermissions, AdminRole } from '../firebase/adminRoles/model';
import { AdminUser } from '../firebase/adminUsers/model';
import { useDidUpdateEffect } from '../shared/hooks/useDidUpdateEffect';
import { ActionsColumn } from './components/ActionsColumn';
import { AddRoleModal } from './components/AddRoleModal';
import { PermissionsColumn } from './components/PermissionsColumn';
import { UsersColumn } from './components/UsersColumn';

type Props = {
  disabled: boolean;
};

export const RolesPage: FC<Props> = ({ disabled }) => {
  const [roles] = useAtom(adminRolesAtom);
  const [users] = useAtom(adminUsersAtom);

  const columns = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render: (users: AdminUser[], role: AdminRole) => (
        <UsersColumn users={users} role={role} disabled={disabled} />
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (permissions: AdminPermissions) => (
        <PermissionsColumn adminPermissions={permissions} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: undefined, role: AdminRole) => (
        <ActionsColumn role={role} disabled={disabled} />
      ),
    },
  ];

  const usersByRole = users.data?.reduce<Record<string, AdminUser[]>>(
    (ubr, user) => {
      const addRoleToObject = (role: string) => {
        if (!ubr[role]) {
          // FIXME: enable no-param-reassign rule
          // eslint-disable-next-line no-param-reassign
          ubr[role] = [user];
        } else {
          ubr[role].push(user);
        }
      };

      if (user?.roles && user.roles.length > 0) {
        user.roles.forEach(addRoleToObject);
      } else if (user?.role) {
        addRoleToObject(user.role);
      }

      return ubr;
    },
    {},
  );

  const data =
    roles.data?.map((role) => ({
      key: role.id,
      ...role,
      users: usersByRole?.[role.id] ?? [],
    })) ?? [];

  useDidUpdateEffect(() => {
    if (!users.loading && users?.error) {
      notification.error({
        message: `${users?.error}`,
      });
    }
  }, [users.error, users.loading]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        gap: '32px',
        overflowY: 'scroll',
      }}
    >
      <Row justify="end">
        <Col>
          <AddRoleModal disabled={disabled} />
        </Col>
      </Row>
      <Divider />
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

import { Col, Typography } from 'antd';
import React from 'react';

import { BorderedRow } from '../AgentInformation/styledComponents';
import { SectionContainer } from '../SectionContainer';

const { Paragraph } = Typography;

type Props = {
  title: string;
  subTitle?: string;
  content?: React.ReactNode;
};

export const ExperienceContainer: React.FC<Props> = ({
  title,
  subTitle,
  content,
}) => (
  <BorderedRow>
    <Col span={24}>
      <SectionContainer title={title} subTitle={subTitle}>
        <Paragraph strong>{content}</Paragraph>
      </SectionContainer>
    </Col>
  </BorderedRow>
);
